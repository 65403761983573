import { useEffect } from "react";

import { getScrollbarWidth, isScrollbarVisible } from "@utils/scrollbarWidth";

export const useBlockBodyScroll = (...conditions: boolean[]) => {
  useEffect(() => {
    const clear = () => {
      document.body.style.removeProperty("overflow");
      document.body.style.removeProperty("padding-right");
    };

    if (conditions.some(Boolean)) {
      document.body.style.overflow = "hidden";
      if (isScrollbarVisible(document.body)) {
        const width = getScrollbarWidth();
        document.body.style.paddingRight = `${width}px`;
      }
    } else {
      clear();
    }

    return clear;
  }, conditions);
};
