import styled, { css } from "styled-components";

import { getVariantColour } from "./LikeIcon";
import { LikeIconVariants } from "./types";

import { ReactComponent as HeartIcon } from "assets/icons/heart.svg";

export const LikeButtonStyling = (options: { variant: LikeIconVariants }) => {
  const colour = getVariantColour(options.variant);

  return css`
    &:not(:disabled)&:hover {
      svg {
        &[data-is-active="true"] {
          fill: transparent;
        }

        &[data-is-active="false"] {
          fill: ${colour};
        }

        path {
          transition: fill 500ms ease-in-out;
          opacity: 1;
        }
      }
    }
  `;
};

export const LikeIcon = styled(HeartIcon)`
  width: 20px;
  height: 20px;

  path {
    fill: inherit;
    stroke: inherit;
    opacity: 0.9;
    transition: all 500ms ease-in-out;
  }
`;
