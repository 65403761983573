import React from "react";
import { FormattedMessage } from "react-intl";

import { commonMessages } from "@messages/common";

export const Header = (
  <FormattedMessage
    defaultMessage="You have successfully connected your spotify account!"
    id="FRF600"
  />
);

export const DescriptionPremium = (
  <FormattedMessage
    defaultMessage="You can now listen to tracks and follow your favorite artists."
    id="mYiAu4"
  />
);

export const Description = (
  <FormattedMessage
    defaultMessage="You can now listen to full tracks and follow your favorite artists."
    id="a1yBF0"
  />
);

export const FollowOnSpotify = (
  <FormattedMessage {...commonMessages.followOnSpotify} />
);

export const FollowSuccess = (
  <FormattedMessage {...commonMessages.followOnSpotifySuccess} />
);

export const VisitSpotifyPage = (
  <FormattedMessage {...commonMessages.visitSpotifyPage} />
);

export const ErrorVisitSpotifyPage = (
  <FormattedMessage
    defaultMessage="Something went wrong. {message}"
    id="nEZs1b"
    values={{ message: commonMessages.visitSpotifyPage.defaultMessage }}
  />
);
