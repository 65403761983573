import { Product_collections } from "@gqlTypes/Product";
import React from "react";

import { AlgoliaResult } from "@integrations";

import {
  BadgeVariant,
  ButtonType,
  ProductBadge,
} from "./components/ProductBadge";

import * as S from "./styles";

export interface ProductBadgesProps {
  collections: AlgoliaResult["collections"] | Product_collections[] | null;
  exclusive: boolean;
  filters?: (ButtonType | never)[];
  sale: boolean;
  sellingFast?: boolean;
  signed: boolean;
  singleBadge?: boolean;
  variant?: BadgeVariant;
}

export const ProductBadges = ({
  sale,
  signed,
  exclusive,
  collections,
  sellingFast = false,
  singleBadge = true,
  filters = [],
  variant = "listing",
}: ProductBadgesProps) => {
  if (!collections) return null;

  const isEssential =
    collections.some(
      collection => collection.slug.toLowerCase() === "essential"
    ) || false;

  const badges = [
    {
      isActive: sale,
      priority: 1,
      type: "sale" as ButtonType,
    },
    {
      isActive: exclusive,
      priority: 2,
      type: "exclusive" as ButtonType,
    },
    {
      isActive: isEssential,
      priority: 3,
      type: "essential" as ButtonType,
    },
    {
      isActive: signed,
      priority: 4,
      type: "signed" as ButtonType,
    },
    {
      isActive: sellingFast,
      priority: 5,
      type: "stock" as ButtonType,
    },
  ].filter(badge => badge.isActive && !filters.includes(badge.type));

  const content = (
    singleBadge ? [badges.sort((a, b) => a.priority - b.priority)?.[0]] : badges
  ).filter(Boolean);

  if (content.length === 0 || !content) {
    return null;
  }

  return (
    <S.BadgeWrapper variant={variant}>
      {content?.map((badge, index) => (
        <ProductBadge
          isActive={badge.isActive}
          key={`${badge.type}-${index}}`}
          type={badge.type}
          variant={variant}
        />
      ))}
    </S.BadgeWrapper>
  );
};
