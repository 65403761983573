import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { initializeApollo } from "@graphql";
import { useMemo } from "react";

export function useApollo(
  initialState: any
): ApolloClient<NormalizedCacheObject> {
  const store = useMemo(() => initializeApollo(initialState), [initialState]);
  return store;
}
