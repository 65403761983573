import React from "react";

import * as S from "./styles";

type LogoProps = Partial<
  Pick<S.LogoProps, "size"> & {
    dark: S.LogoProps["$dark"];
  }
>;

export const Logo = ({ dark = false, size = "100%" }: LogoProps) => (
  <S.Logo $dark={dark} size={size} />
);
