import React from "react";
import { FormattedMessage } from "react-intl";

import { commonMessages } from "@messages/common";

import * as S from "./styles";

export const PreOrderBadge = () => (
  <S.Wrapper>
    <FormattedMessage {...commonMessages.preOrder} />
  </S.Wrapper>
);
