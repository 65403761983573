import { paths } from "@paths";
import { SliceZone } from "@prismicio/client";
import { format } from "date-fns";
import { ClubTierFormatSlice } from "prismicio-types";

import { generateHref } from "@components/ButtonLink";

import {
  CheckRedirectRecipientProps,
  ClubFormValues,
  RemoveQueryParamProps,
} from "./types";

export const getDateDiff = (date: Date) => {
  const today = new Date();
  const createdOn = new Date(date);
  const formattedDate = format(createdOn, "dd/MM/yyyy");
  const msInDay = 24 * 60 * 60 * 1000;
  const diffInDays = (+today - +createdOn) / msInDay;
  const diffInMonths = diffInDays / 30;

  if (diffInDays < 30) {
    return `${diffInDays.toFixed(0)} days ago`;
  }
  if (diffInMonths < 12) {
    return `${diffInMonths.toFixed(0)} months ago`;
  }
  return `${formattedDate}`;
};

export enum ClubActions {
  GIFT = "gift",
  JOIN = "join",
}

export type ReturnDefaultFormValuesProps = {
  action: ClubActions;
  formats: SliceZone<ClubTierFormatSlice>;
};

export const returnDefaultFormValues = ({
  formats,
  action,
}: ReturnDefaultFormValuesProps): ClubFormValues | null => {
  const isJoinAction = action === "join";

  const popularFormat =
    formats.find(format => format.primary.most_popular === true) || formats[0];

  const { primary, items } = popularFormat || {};

  const {
    base_checkout_page: primaryCheckoutPage,
    base_price: primaryBasePrice = 0,
    type: format = "default",
  } = primary || {};

  if (items?.length === 0 || !items) {
    return null;
  }

  const popularSubscription =
    items.find(subscription => subscription.most_popular === true) ||
    items.find(subscription => subscription.type === action) ||
    items[0];

  if (!popularSubscription) {
    return null;
  }

  const {
    checkout_page: subCheckoutPage,
    total_price: subTotalPrice,
    subscription_length: subLength,
  } = popularSubscription;

  const recipient = isJoinAction ? "self" : action;
  const length = (isJoinAction ? "1" : subLength) as string;
  const checkoutPage = (
    isJoinAction ? primaryCheckoutPage : subCheckoutPage
  ) as string;
  const totalCost =
    (isJoinAction
      ? primaryBasePrice
        ? +primaryBasePrice
        : 0
      : subTotalPrice) || 0;

  return {
    checkoutPage,
    format,
    length,
    recipient,
    totalCost,
  };
};

export const checkRedirectRecipient = async ({
  page,
  recipientTarget,
  currentAction,
  tier,
  router,
}: CheckRedirectRecipientProps) => {
  if (!tier || !router || !currentAction) {
    return false;
  }

  const isRecipientStep = page === 1;
  const action = currentAction !== "join" ? currentAction : "self";
  const slug = (router.query?.page as string) || undefined;

  if (isRecipientStep && action !== recipientTarget) {
    const hasGiftPage = (tier?.data?.gift_page as { uid?: string })?.uid;
    const hasJoinPage = (tier?.data?.join_page as { uid?: string })?.uid;
    const generatePath = {
      action: "",
      path: paths.clubForm,
      slug,
    };

    if (currentAction === "join" && hasJoinPage) {
      generatePath.action = "gift";
    }

    if (currentAction === "gift" && hasGiftPage) {
      generatePath.action = "join";
    }

    if (slug && generatePath.action) {
      const pathname = generateHref(false, generatePath, "");
      router.push({
        pathname,
        query: { p: 2 },
      });
      return true;
    }
  }

  return false;
};

export const removeQueryParam = ({ key, router }: RemoveQueryParamProps) => {
  const { query, replace } = router;

  if (key in query) {
    delete query[key];

    replace(
      {
        pathname: router.pathname,
        query,
      },
      undefined,
      { shallow: true }
    );
  }
};
