import { IntlShape } from "react-intl";
import { Asserts, object, string } from "yup";

import { validationMessages } from "@messages/forms";

export type PromoSchema = Asserts<ReturnType<typeof promoSchema>>;

export const promoSchema = (intl: IntlShape) =>
  object({
    promoCode: string().required(
      intl.formatMessage(validationMessages.required)
    ),
  });
