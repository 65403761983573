import { media, px2rem, styled } from "@styles";

export const PlaylistWrapper = styled.div`
  position: relative;
`;

export const Album = styled.div<{ isExpanded?: boolean }>`
  z-index: 4;
  bottom: 0;
`;

export const Playlist = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.black};
  max-height: 66vh;
  overflow-y: auto;
  bottom: 74px;
  left: 0;
  padding: 1.5rem 1.25rem;
  width: 100%;

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #000;
  }

  ::-webkit-scrollbar-thumb {
    background: #fff4;
    border-radius: 4px;
  }

  ${media.lg.up} {
    max-width: 390px;
    bottom: 0;
    padding-bottom: 0;
  }
`;

export const TrackList = styled.div`
  padding: 1.5rem 0;
`;

export const TrackName = styled.button<{ isActive?: boolean }>`
  display: block;
  width: 100%;
  color: ${({ isActive, theme, disabled }) =>
    isActive
      ? theme.colors.gold
      : disabled
      ? theme.colors.dark
      : theme.colors.light};
  font-weight: 500;
  font-size: ${px2rem(14)};
  line-height: 1.4;
  padding: 10px 0 10px 5px;
  background: none;
  border: none;
  cursor: ${({ disabled }) => (disabled ? `default` : `pointer`)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;

  &:hover {
    background-color: ${({ theme }) => theme.colors.medium};
  }
`;

export const FlexWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 5px;
`;

export const SpotifyButtons = styled.div`
  margin: 25px 0 0;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  flex: 0;

  a {
    text-decoration: none;
    flex-shrink: 0;
  }

  button {
    height: 40px;
    padding: 0 22px;
    border-color: ${({ theme }) => theme.colors.medium};

    &:hover {
      background-color: ${({ theme }) => theme.colors.white};
      color: ${({ theme }) => theme.colors.black};

      svg {
        filter: invert(100%);
      }
    }

    span {
      font-size: ${px2rem(12)};
      font-weight: bold;
    }

    svg {
      height: 21px;
      margin-top: -2px;
    }
  }
`;
