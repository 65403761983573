import { paths } from "@paths";
import React from "react";

import { Container } from "@components/Container";
import { Link } from "@components/Link";

import * as M from "./messages";
import * as S from "./styles";

export type FooterProps = {
  className?: string;
  dark: boolean;
};

export const Footer = ({ className, dark }: FooterProps) => (
  <Container className={className} maxWidth="md">
    <S.Wrapper $dark={dark}>
      <S.FlexContent $fullWidth={false}>
        <Link passHref href={paths.termsAndConditions}>
          <a>
            <S.P>{M.TermsOfSale}</S.P>
          </a>
        </Link>
      </S.FlexContent>
      <S.FlexContent $fullWidth={false}>
        <Link passHref href={paths.privacyPolicy}>
          <a>
            <S.P>{M.PrivacyPolicy}</S.P>
          </a>
        </Link>
      </S.FlexContent>
    </S.Wrapper>
  </Container>
);
