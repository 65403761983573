import {
  CarouselSliceDefaultPrimary,
  ClubHomePageDocumentData,
  OfTheYearPageDocumentData,
} from "prismicio-types";

import { AlgoliaResult, getAlgoliaGroupedResults } from "@integrations";
import { Locale } from "@providers/TranslationProvider";

export type PrismicCarousel = CarouselSliceDefaultPrimary & {
  data?: AlgoliaResult[];
};

const HITS_PER_PAGE = 20;

/** Make a batch request to Algolia for all carousels in the source data. */
export const batchFetchAlgolia = async <T>(
  locale: string,
  urls: string[],
  sourceData: T,
  carouselIndexes?: number[]
): Promise<T> => {
  try {
    const groupedResults = await getAlgoliaGroupedResults(
      urls,
      locale as Locale,
      HITS_PER_PAGE
    );

    if (!groupedResults) {
      return sourceData;
    }

    return combineBatchWithSource(
      sourceData,
      groupedResults,
      carouselIndexes
    ) as T;
  } catch (e) {
    console.warn(e);
    return sourceData;
  }
};

export type CarouselParentContainer =
  | ClubHomePageDocumentData["slices"]
  | OfTheYearPageDocumentData["slices"];

/** Retain the order of slice items while extracting the carousel items to be manipulated */
export const separateCarouselSlice = (body: CarouselParentContainer) => {
  const carouselIndexes: number[] = [];
  const urls = body
    .map((val, i) => {
      if (val.slice_type === "carousel") {
        const { link } = val.primary as CarouselSliceDefaultPrimary;
        carouselIndexes.push(i);
        return link ?? "";
      }
    })
    .filter(Boolean) as string[];

  return { carouselIndexes, urls };
};

/** Reinsert new data into source data ensuring the order is retained */
const combineBatchWithSource = <T>(
  sourceData: T,
  groupedResults: AlgoliaResult[][],
  carouselIndexes?: number[]
): T => {
  if (carouselIndexes?.length) {
    // Is a Prismic Slice item
    return (sourceData as unknown as CarouselParentContainer)?.map(
      (val, index) => {
        if (carouselIndexes!.indexOf(index) >= 0) {
          return {
            ...val,
            primary: {
              ...val?.primary,
              data: groupedResults[carouselIndexes!.indexOf(index)],
            },
          };
        }
        return val;
      }
    ) as unknown as T;
  }
  return (sourceData as unknown as PrismicCarousel[]).map((carousel, i) => ({
    ...carousel,
    data: groupedResults[i],
  })) as unknown as T;
};

export interface AddCarouselDataParams {
  carouselIndexes?: number[];
  urls: string[];
}

export async function fetchBatchCarousel<T>(
  sourceData: T,
  isSlice: boolean,
  locale: string
): Promise<Awaited<T> | T> {
  if (!sourceData) {
    return [] as unknown as T;
  }

  const addCarouselData = async (
    urls: string[],
    carouselIndexes: number[] | undefined
  ) => {
    if (!urls.length) {
      return sourceData;
    }

    const data = await batchFetchAlgolia(
      locale,
      urls,
      sourceData,
      carouselIndexes
    );

    return data;
  };

  const mergeData = async () => {
    const data: AddCarouselDataParams = {
      urls: [],
    };

    if (isSlice) {
      const { carouselIndexes, urls: separateUrls } = separateCarouselSlice(
        sourceData as unknown as CarouselParentContainer
      );
      data.carouselIndexes = carouselIndexes;
      data.urls = separateUrls;
    } else {
      data.urls = (sourceData as unknown as PrismicCarousel[]).map(
        ({ link }) => link ?? ""
      ) as string[];
    }

    return addCarouselData(data.urls, data.carouselIndexes);
  };

  return mergeData() as unknown as T;
}
