import { paths } from "@paths";
import Link from "next/link";
import React from "react";

import { Logo } from "@components/Logo";

import { FormStepCounter } from "../FormStepCounter";

import * as S from "./styles";

export const FormHeader = () => (
  <S.ClubFormHeader>
    <S.LogoWrapper>
      <Link passHref href={paths.home}>
        <S.LogoLink
          aria-label="Rough Trade"
          onClick={evt => evt.stopPropagation()}
          title="Rough Trade"
        >
          <Logo size="38px" />
        </S.LogoLink>
      </Link>
    </S.LogoWrapper>
    <FormStepCounter displayMobile />
  </S.ClubFormHeader>
);
