import {
  checkoutBaseFragment,
  checkoutErrorFragment,
  checkoutFragment,
  checkoutVoucherErrorFragment,
  checkoutVoucherFragment,
  giftCardFragment,
  moneyFragment,
  orderErrorFragment,
  paymentErrorFragment,
  purchaseOrderFragment,
  taxedPriceFragment,
  useTypedMutation,
} from "@graphql";
import gql from "graphql-tag";

import {
  CheckoutCreate,
  CheckoutCreateVariables,
} from "@providers/CheckoutProvider/gqlTypes/CheckoutCreate";

import {
  CheckoutAddPromoCode,
  CheckoutAddPromoCodeVariables,
} from "./gqlTypes/CheckoutAddPromoCode";
import {
  CheckoutBillingAddressUpdate,
  CheckoutBillingAddressUpdateVariables,
} from "./gqlTypes/CheckoutBillingAddressUpdate";
import {
  CheckoutComplete,
  CheckoutCompleteVariables,
} from "./gqlTypes/CheckoutComplete";
import {
  CheckoutCustomerAttach,
  CheckoutCustomerAttachVariables,
} from "./gqlTypes/CheckoutCustomerAttach";
import {
  CheckoutDeliveryMethodUpdate,
  CheckoutDeliveryMethodUpdateVariables,
} from "./gqlTypes/CheckoutDeliveryMethodUpdate";
import {
  CheckoutEmailUpdate,
  CheckoutEmailUpdateVariables,
} from "./gqlTypes/CheckoutEmailUpdate";
import {
  CheckoutLinesAdd,
  CheckoutLinesAddVariables,
} from "./gqlTypes/CheckoutLinesAdd";
import {
  CheckoutLinesUpdate,
  CheckoutLinesUpdateVariables,
} from "./gqlTypes/CheckoutLinesUpdate";
import {
  CheckoutPaymentCreate,
  CheckoutPaymentCreateVariables,
} from "./gqlTypes/CheckoutPaymentCreate";
import {
  CheckoutRemovePromoCode,
  CheckoutRemovePromoCodeVariables,
} from "./gqlTypes/CheckoutRemovePromoCode";
import {
  CheckoutShippingAddressUpdate,
  CheckoutShippingAddressUpdateVariables,
} from "./gqlTypes/CheckoutShippingAddressUpdate";

export const checkoutCaptureOrder = gql`
  ${orderErrorFragment}
  ${purchaseOrderFragment}
  mutation CheckoutCaptureOrder(
    $amount: PositiveDecimal!
    $id: ID!
    $languageCode: LanguageCodeEnum = EN
  ) {
    orderCapture(amount: $amount, id: $id) {
      order {
        ...PurchaseOrder
      }
      errors {
        ...OrderError
      }
    }
  }
`;

export const checkoutCreateMutation = gql`
  ${checkoutErrorFragment}
  ${checkoutBaseFragment}
  mutation CheckoutCreate(
    $input: CheckoutCreateInput!
    $languageCode: LanguageCodeEnum = EN
  ) {
    checkoutCreate(input: $input) {
      checkout {
        ...CheckoutBase
      }
      errors {
        ...CheckoutError
      }
    }
  }
`;

export const useCheckoutCreateMutation = () =>
  useTypedMutation<CheckoutCreate, CheckoutCreateVariables>(
    checkoutCreateMutation
  );

export const checkoutUpdateMutation = gql`
  ${checkoutErrorFragment}
  ${checkoutBaseFragment}
  mutation CheckoutEmailUpdate(
    $email: String!
    $id: ID
    $languageCode: LanguageCodeEnum = EN
  ) {
    checkoutEmailUpdate(email: $email, id: $id) {
      checkout {
        ...CheckoutBase
      }
      errors {
        ...CheckoutError
      }
    }
  }
`;

export const useCheckoutUpdateMutation = () =>
  useTypedMutation<CheckoutEmailUpdate, CheckoutEmailUpdateVariables>(
    checkoutUpdateMutation
  );

export const checkoutCreateFullMutation = gql`
  ${checkoutErrorFragment}
  ${checkoutFragment}
  mutation CheckoutCreateFull(
    $input: CheckoutCreateInput!
    $languageCode: LanguageCodeEnum = EN
  ) {
    checkoutCreate(input: $input) {
      created
      checkout {
        ...Checkout
      }
      errors {
        ...CheckoutError
      }
    }
  }
`;

export const checkoutCustomerAttachMutation = gql`
  ${checkoutErrorFragment}
  mutation CheckoutCustomerAttach($id: ID!) {
    checkoutCustomerAttach(id: $id) {
      checkout {
        email
      }
      errors {
        ...CheckoutError
      }
    }
  }
`;

export const useCheckoutCustomerAttachMutation = () =>
  useTypedMutation<CheckoutCustomerAttach, CheckoutCustomerAttachVariables>(
    checkoutCustomerAttachMutation
  );

const checkoutBillingAddressUpdateMutation = gql`
  ${checkoutErrorFragment}
  ${checkoutFragment}
  mutation CheckoutBillingAddressUpdate(
    $address: AddressInput!
    $id: ID!
    $languageCode: LanguageCodeEnum = EN
  ) {
    checkoutBillingAddressUpdate(billingAddress: $address, id: $id) {
      checkout {
        ...Checkout
      }
      errors {
        ...CheckoutError
      }
    }
  }
`;

export const useCheckoutBillingAddressUpdateMutation = () =>
  useTypedMutation<
    CheckoutBillingAddressUpdate,
    CheckoutBillingAddressUpdateVariables
  >(checkoutBillingAddressUpdateMutation);

const checkoutShippingAddressUpdateMutation = gql`
  ${checkoutErrorFragment}
  ${checkoutFragment}
  mutation CheckoutShippingAddressUpdate(
    $address: AddressInput!
    $id: ID!
    $languageCode: LanguageCodeEnum = EN
  ) {
    checkoutShippingAddressUpdate(shippingAddress: $address, id: $id) {
      checkout {
        ...Checkout
      }
      errors {
        ...CheckoutError
      }
    }
  }
`;

export const useCheckoutShippingAddressUpdateMutation = () =>
  useTypedMutation<
    CheckoutShippingAddressUpdate,
    CheckoutShippingAddressUpdateVariables
  >(checkoutShippingAddressUpdateMutation);

export const checkoutDeliveryMethodUpdateMutation = gql`
  ${checkoutErrorFragment}
  ${checkoutFragment}
  mutation CheckoutDeliveryMethodUpdate(
    $deliveryMethodId: ID
    $id: ID!
    $languageCode: LanguageCodeEnum = EN
  ) {
    checkoutDeliveryMethodUpdate(deliveryMethodId: $deliveryMethodId, id: $id) {
      checkout {
        ...Checkout
      }
      errors {
        ...CheckoutError
      }
    }
  }
`;

export const useCheckoutDeliveryMethodUpdateMutation = () =>
  useTypedMutation<
    CheckoutDeliveryMethodUpdate,
    CheckoutDeliveryMethodUpdateVariables
  >(checkoutDeliveryMethodUpdateMutation);

const checkoutLinesAddMutation = gql`
  ${checkoutBaseFragment}
  ${checkoutErrorFragment}
  mutation CheckoutLinesAdd(
    $id: ID!
    $lines: [CheckoutLineInput!]!
    $languageCode: LanguageCodeEnum = EN
  ) {
    checkoutLinesAdd(id: $id, lines: $lines) {
      checkout {
        ...CheckoutBase
      }
      errors {
        ...CheckoutError
      }
    }
  }
`;

export const useCheckoutLinesAddMutation = () =>
  useTypedMutation<CheckoutLinesAdd, CheckoutLinesAddVariables>(
    checkoutLinesAddMutation
  );

export const checkoutLinesUpdateMutation = gql`
  ${checkoutBaseFragment}
  ${checkoutErrorFragment}
  mutation CheckoutLinesUpdate(
    $id: ID!
    $lines: [CheckoutLineUpdateInput!]!
    $languageCode: LanguageCodeEnum = EN
  ) {
    checkoutLinesUpdate(id: $id, lines: $lines) {
      checkout {
        ...CheckoutBase
      }
      errors {
        ...CheckoutError
      }
    }
  }
`;

export const useCheckoutLinesUpdateMutation = () =>
  useTypedMutation<CheckoutLinesUpdate, CheckoutLinesUpdateVariables>(
    checkoutLinesUpdateMutation
  );

const checkoutPaymentCreateMutation = gql`
  ${paymentErrorFragment}
  ${taxedPriceFragment}
  mutation CheckoutPaymentCreate($id: ID, $input: PaymentInput!) {
    checkoutPaymentCreate(id: $id, input: $input) {
      checkout {
        shippingPrice {
          ...TaxedPrice
        }
        subtotalPrice {
          ...TaxedPrice
        }
        totalPrice {
          ...TaxedPrice
        }
      }
      payment {
        id
      }
      errors {
        ...PaymentError
      }
    }
  }
`;

export const useCheckoutPaymentCreateMutation = () =>
  useTypedMutation<CheckoutPaymentCreate, CheckoutPaymentCreateVariables>(
    checkoutPaymentCreateMutation
  );

const checkoutCompleteMutation = gql`
  ${checkoutErrorFragment}
  ${purchaseOrderFragment}
  mutation CheckoutComplete(
    $id: ID!
    $storeSource: Boolean
    $paymentData: JSONString
    $redirectUrl: String
    $languageCode: LanguageCodeEnum = EN
  ) {
    checkoutComplete(
      id: $id
      storeSource: $storeSource
      paymentData: $paymentData
      redirectUrl: $redirectUrl
    ) {
      order {
        ...PurchaseOrder
      }
      confirmationNeeded
      confirmationData
      errors {
        ...CheckoutError
      }
    }
  }
`;

export const useCheckoutCompleteMutation = () =>
  useTypedMutation<CheckoutComplete, CheckoutCompleteVariables>(
    checkoutCompleteMutation
  );
export const checkoutAddPromoCodeMutation = gql`
  ${checkoutErrorFragment}
  ${taxedPriceFragment}
  ${moneyFragment}
  ${giftCardFragment}
  mutation CheckoutAddPromoCode($checkoutId: ID!, $code: String!) {
    checkoutAddPromoCode(checkoutId: $checkoutId, promoCode: $code) {
      checkout {
        giftCards {
          ...GiftCard
        }
        shippingPrice {
          ...TaxedPrice
        }
        totalPrice {
          ...TaxedPrice
        }
        subtotalPrice {
          ...TaxedPrice
        }
        discount {
          ...Money
        }
        voucherCode
        discountName
      }
      errors {
        ...CheckoutError
      }
    }
  }
`;

export const useCheckoutAddPromoCodeMutation = () =>
  useTypedMutation<CheckoutAddPromoCode, CheckoutAddPromoCodeVariables>(
    checkoutAddPromoCodeMutation
  );

export const checkoutRemovePromoCodeMutation = gql`
  ${checkoutErrorFragment}
  ${taxedPriceFragment}
  ${moneyFragment}
  ${giftCardFragment}
  mutation CheckoutRemovePromoCode($checkoutId: ID!, $code: String, $id: ID) {
    checkoutRemovePromoCode(
      checkoutId: $checkoutId
      promoCode: $code
      promoCodeId: $id
    ) {
      checkout {
        giftCards {
          ...GiftCard
        }
        shippingPrice {
          ...TaxedPrice
        }
        totalPrice {
          ...TaxedPrice
        }
        subtotalPrice {
          ...TaxedPrice
        }
        discount {
          ...Money
        }
        voucherCode
        discountName
      }
      errors {
        ...CheckoutError
      }
    }
  }
`;

export const useCheckoutRemovePromoCodeMutation = () =>
  useTypedMutation<CheckoutRemovePromoCode, CheckoutRemovePromoCodeVariables>(
    checkoutRemovePromoCodeMutation
  );

export const checkoutCreatePromoCodeMutation = gql`
  ${checkoutVoucherFragment}
  ${checkoutVoucherErrorFragment}
  mutation CheckoutCreatePromoCode($input: VoucherInput!) {
    voucherCreate(input: $input) {
      voucher {
        ...Voucher
      }
      errors {
        ...DiscountError
      }
    }
  }
`;

export const checkoutDeletePromoCodeMutation = gql`
  ${checkoutVoucherFragment}
  ${checkoutVoucherErrorFragment}
  mutation CheckoutDeletePromoCode($id: ID!) {
    voucherDelete(id: $id) {
      voucher {
        ...Voucher
      }
      errors {
        ...DiscountError
      }
    }
  }
`;

export const voucherUpdateChannelMutation = gql`
  ${checkoutVoucherFragment}
  ${checkoutVoucherErrorFragment}
  mutation VoucherUpdateChannel($id: ID!, $input: VoucherChannelListingInput!) {
    voucherChannelListingUpdate(id: $id, input: $input) {
      voucher {
        ...Voucher
      }
      errors {
        ...DiscountError
      }
    }
  }
`;
