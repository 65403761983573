import { useAuthState } from "@saleor/sdk";
import { setUser } from "@sentry/nextjs";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";

import { getChannel, getCurrency } from "@providers/ConfigProvider/helpers";
import type { Locale } from "@providers/TranslationProvider/types";
import { isEmptyObject } from "@utils/js";

import { ConfigContext, ConfigContextData, defaultContext } from "./context";

interface ConfigProviderProps {
  children?: React.ReactNode;
  config: ConfigContextData;
}

export const ConfigProvider = ({ config, children }: ConfigProviderProps) => {
  const { user } = useAuthState();
  const { locale } = useRouter();
  const ctx = useState<ConfigContextData>(
    config && !isEmptyObject(config) ? config : defaultContext
  );
  const channel = getChannel(locale as Locale);
  const currency = getCurrency(channel);

  useEffect(() => {
    if (user) {
      setUser({ id: user.id });
    }
  }, [user]);

  return (
    <ConfigContext.Provider value={{ ...ctx, channel, currency }}>
      {children}
    </ConfigContext.Provider>
  );
};
