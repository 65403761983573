import { MainNavigationDocumentDataMenuItemItem } from "prismicio-types";
import { css } from "styled-components";

import { Container } from "@components/Container";
import { media, styled, theme } from "@styles";

export const DropdownOuter = styled.div<{
  isActive?: boolean;
  isBannerVisible?: boolean;
}>`
  ${media.lg.up} {
    display: grid;
    background: none;
    overflow: hidden;
    top: 100%;
    position: absolute;
    left: 0;
    width: 100%;

    ${({ isActive }) =>
      isActive
        ? css`
            grid-template-rows: 1fr;
          `
        : css`
            grid-template-rows: 0fr;
          `}

    transition: grid-template-rows 350ms ease-in-out;
  }
`;

export const DropdownWrapper = styled.div<{
  isActive?: boolean;
}>`
  display: flex;
  flex-flow: row;
  background: ${({ theme }) => theme.colors.black};
  position: absolute;
  top: 0;
  opacity: 1;
  z-index: 2;
  left: ${({ isActive }) => (isActive ? "0" : "-100%")};
  min-height: 70vh;
  transition: left 250ms linear;
  height: 100%;
  color: white;
  width: 100%;
  justify-content: center;
  align-content: stretch;
  gap: 1rem;
  cursor: default;

  ${media.lg.up} {
    overflow: hidden;
    min-height: 0;
    margin: 0;
    position: relative;
    left: 0;
    padding: 0;
    gap: 0;

    ${({ isActive }) =>
      isActive &&
      css`
        border-bottom: 4px solid transparent;
      `}
  }
`;

export const InnerMenuWrapper = styled(Container)<{ isActive?: boolean }>`
  display: flex;
  flex-flow: column;
  gap: 20px;
  width: 100%;
  height: max-content;
  max-height: 100%;
  overflow: scroll;
  background: ${({ theme }) => theme.colors.black};

  ${media.lg.down} {
    padding: 0;
  }

  ${media.lg.up} {
    overflow: initial;
    flex-flow: row;

    > :last-child {
      border-right-color: transparent;
    }
  }
`;

export const getBackButtonStyling = (
  variant: MainNavigationDocumentDataMenuItemItem["variant"],
  isActive?: boolean
) => {
  const { items } = theme.navigation;
  const background =
    isActive && variant === "default" ? "white" : items[variant].active.accent;
  const opacity = isActive && variant === "default" ? "0.15" : "0.9";

  return css`
    &:before {
      content: "";
      background: ${background};
      opacity: ${opacity};
      position: absolute;
      inset: 0;
      z-index: -1;
      transition: height 250ms ease-out;
      height: 100%;
      width: 100%;
    }
  `;
};

export const BackButton = styled.button<{
  isActive?: boolean;
  variant: MainNavigationDocumentDataMenuItemItem["variant"];
}>`
  width: 40px;
  z-index: 2;
  position: relative;
  background: transparent;
  border: none;
  transition: opacity 250ms ease-out;

  svg {
    width: 20px;
    path {
      stroke: ${({ theme }) => theme.colors.white};
    }
  }

  ${({ variant, isActive }) => getBackButtonStyling(variant, isActive)}

  &:before {
    content: "";
    position: absolute;
    inset: 0;
    z-index: -1;
    transition: height 250ms ease-out;
    height: 100%;
    width: 100%;
  }

  ${media.lg.up} {
    display: none;
  }
`;
