import { useApolloClient } from "@apollo/client";
import { Checkout } from "@gqlTypes/Checkout";
import { ProductVariant } from "@gqlTypes/ProductVariant";
import {
  handleMutationResponse,
  HandleMutationResponseMutation,
} from "@graphql/utils";
import { useAuthState } from "@saleor/sdk";
import React, { useEffect, useRef, useState } from "react";

import { TOTAL_ANIMATION_DURATION } from "@components/Toast/constants";
import { useConfigContext } from "@hooks/providers";
import { useRouter } from "@hooks/useRouter";
import { getLanguageCode } from "@providers/ConfigProvider/helpers";
import { useHeaderStore } from "@stores/useHeaderStore";
import { useSearchInsightsStore } from "@stores/useSearchInsightsStore";
import { shouldNotBeDisplayed } from "@utils";

import { CheckoutProviderContext } from "./context";
import {
  ActionType,
  calculateLinesInput,
  getCachedCheckoutLines,
  getCachedId,
  getCachedQueue,
  getCheckoutById,
  getCurrentCheckout,
  getLine,
  removeCachedCheckoutLines,
  removeCachedId,
  removeCachedQueue,
  removeSingleCachedCheckoutLine,
  updateCachedId,
  updateCachedQueue,
  updateCheckoutLocalStorageLines,
} from "./helpers";
import { useClearCheckoutBetweenRegion } from "./hooks";
import {
  useCheckoutCreateMutation,
  useCheckoutLinesAddMutation,
  useCheckoutLinesUpdateMutation,
} from "./mutations";
import {
  CheckoutContext,
  CheckoutsQueue,
  PostLineCallback,
  PostLineCallbackAction,
  UpdateCheckout,
  UpdateLine,
} from "./types";

type ContextState = Pick<
  CheckoutContext,
  "processing" | "checkout" | "queue" | "cartInfo"
>;

type CheckoutProviderProps = {
  children?: React.ReactNode;
};

export const CheckoutProvider = ({ children }: CheckoutProviderProps) => {
  const { user, authenticating } = useAuthState();
  const [ctx, setCtx] = useState<ContextState>({
    cartInfo: null,
    checkout: null,
    processing: !!getCachedId() || authenticating,
    queue: getCachedQueue(),
  });
  const { locale } = useRouter();
  const { channel } = useConfigContext();
  const [checkoutCreateMutation] = useCheckoutCreateMutation();
  const [lineAddMutation] = useCheckoutLinesAddMutation();
  const [lineUpdateMutation] = useCheckoutLinesUpdateMutation();
  const { searchQueryID } = useSearchInsightsStore();
  const { basket } = useHeaderStore();
  const apolloClient = useApolloClient();
  const lineChangeSubscribersRef = useRef<{
    [key in PostLineCallbackAction]: PostLineCallback[];
  }>({ add: [], remove: [] });
  const initializingRef = useRef(false);

  const isCartEmpty = !ctx.checkout?.lines?.length;
  const isCartNotEmpty = !isCartEmpty;

  const setProcessing = (processing: boolean) =>
    setCtx(ctx => ({ ...ctx, processing }));

  const setQueueProcessing = (processing: boolean) => {
    if (ctx.queue) {
      setCtx(ctx => ({
        ...ctx,
        queue: { ...ctx.queue, processing } as CheckoutsQueue,
      }));
    }
  };

  const updateCheckout: UpdateCheckout = (checkout, processing) => {
    const newCheckout = { ...ctx.checkout, ...checkout } as Checkout;

    setCtx(ctx => ({
      ...ctx,
      checkout: newCheckout,
      processing: processing ?? ctx.processing,
    }));

    updateCachedId(newCheckout);

    const variantIds = newCheckout.lines.map(line => line.variant.id);
    const cachedLines = getCachedCheckoutLines()?.map(line => line.id);

    cachedLines?.map(id => {
      if (!variantIds.includes(id)) {
        removeSingleCachedCheckoutLine(id);
      }
    });

    return newCheckout;
  };

  const onPostLineChange = (
    action: PostLineCallbackAction,
    cb: PostLineCallback,
    catchAll?: boolean
  ) => {
    if (catchAll) {
      cb.catchAll = true;
    }
    lineChangeSubscribersRef.current[action].push(cb);
  };

  const updateQueue: CheckoutContext["updateQueue"] = queue => {
    const newQueue = { ...ctx.queue, ...queue } as CheckoutsQueue;
    setCtx(p => ({ ...p, queue: newQueue }));
    updateCachedQueue(newQueue);
    return newQueue;
  };

  const clearQueue: CheckoutContext["clearQueue"] = () => {
    setCtx(p => ({ ...p, queue: null }));
    removeCachedQueue();
  };

  const lineAdd: UpdateLine = async (variantId, opts) => {
    if (ctx.processing) {
      return ctx.checkout;
    }

    setProcessing(true);
    const { dispatchProductType, releaseDate, quantity, notify } = {
      notify: false,
      quantity: 1,
      ...opts,
    };

    updateCheckoutLocalStorageLines(
      {
        date: releaseDate?.toDateString(),
        id: variantId,
        quantity,
        type: dispatchProductType ?? "unknown",
      },
      ActionType.ADD
    );

    let newCheckout: Checkout;
    let mutation: HandleMutationResponseMutation;

    if (!ctx.checkout) {
      const userEmail = user?.email;

      const {
        data: { checkoutCreate },
      } = await checkoutCreateMutation({
        variables: {
          input: {
            channel,
            email: userEmail,
            languageCode: getLanguageCode(locale),
            lines: [{ quantity, variantId }],
          },
          languageCode: getLanguageCode(locale),
        },
      });
      newCheckout = checkoutCreate?.checkout as Checkout;
      mutation = checkoutCreate;
    } else {
      const {
        data: { checkoutLinesAdd },
      } = await lineAddMutation({
        variables: {
          id: ctx.checkout!.id,
          lines: [{ quantity, variantId }],
        },
      });
      newCheckout = checkoutLinesAdd?.checkout as Checkout;
      mutation = checkoutLinesAdd;
    }

    const errors = handleMutationResponse(
      mutation,
      () => {
        const line = getLine(variantId, newCheckout.lines);

        lineChangeSubscribersRef.current.add.map(cb => {
          if ((notify || cb.catchAll) && newCheckout) {
            cb(line, quantity, searchQueryID);
          }
        });
      },

      {
        extra: { checkout: newCheckout, variantId },
        reason: "add checkout line",
      }
    );

    if (errors.length) {
      setProcessing(false);
      return ctx?.checkout;
    }
    basket.toggleActive(true, TOTAL_ANIMATION_DURATION);

    return updateCheckout(newCheckout, false);
  };

  const lineRemove: UpdateLine = async (variantId, opts) => {
    if (ctx.processing || !ctx.checkout) {
      return ctx.checkout;
    }

    setProcessing(true);

    const { lines } = ctx.checkout!;
    const { dispatchProductType, quantity, notify } = {
      notify: false,
      quantity: 1,
      ...opts,
    };

    updateCheckoutLocalStorageLines(
      {
        id: variantId,
        quantity,
        type: dispatchProductType ?? "unknown",
      },
      ActionType.REMOVE
    );

    const { newLine } = calculateLinesInput(lines, {
      quantityToRemove: quantity,
      variantId,
    });
    const removedLine = getLine(variantId, lines);
    const {
      data: { checkoutLinesUpdate },
    } = await lineUpdateMutation({
      variables: {
        id: ctx.checkout!.id,
        lines: [newLine],
      },
    });
    const newCheckout = checkoutLinesUpdate?.checkout as Checkout;
    const errors = handleMutationResponse(
      checkoutLinesUpdate,
      () => {
        lineChangeSubscribersRef.current.remove.map(cb => {
          if (notify || cb.catchAll) {
            cb(removedLine, quantity, searchQueryID);
          }
        });
      },
      {
        extra: { checkout: newCheckout, variantId },
        reason: "remove checkout line",
      }
    );

    if (errors.length) {
      return ctx.checkout;
    }

    return updateCheckout(newCheckout, false);
  };

  const clear: CheckoutContext["clear"] = () => {
    setCtx(p => ({
      cartInfo: null,
      checkout: null,
      processing: false,
      queue: null,
    }));
    removeCachedCheckoutLines();
    removeCachedId();
    removeCachedQueue();
  };

  const refresh = async () => {
    if (!ctx.checkout) {
      return null;
    }
    setProcessing(true);

    const checkout = await getCheckoutById(
      apolloClient,
      ctx.checkout!.id,
      "network-only"
    );

    return updateCheckout(checkout!, false);
  };

  useEffect(() => {
    if (!authenticating && !initializingRef.current) {
      (async () => {
        if (!ctx.checkout) {
          initializingRef.current = true;
          const checkout = await getCurrentCheckout(
            apolloClient,
            user!,
            channel
          );

          if (checkout) {
            updateCheckout(checkout);
          }

          setProcessing(false);
          initializingRef.current = false;
        }
      })();
    }
  }, [ctx.checkout, user, authenticating]);

  // TODO: Move to a separate fn
  useEffect(() => {
    ctx.checkout?.lines?.map(line => {
      const limit =
        (line.variant.quantityLimitPerCustomer &&
        line.variant.quantityAvailable &&
        line.variant.quantityLimitPerCustomer < line.variant.quantityAvailable
          ? line.variant.quantityLimitPerCustomer
          : line.variant.quantityAvailable) ?? 1;

      if (
        (line?.variant &&
          shouldNotBeDisplayed(line.variant as unknown as ProductVariant)) ||
        line.quantity > limit
      ) {
        if (limit) {
          lineRemove(line.variant.id, {
            quantity: line.quantity - limit,
          });
          return;
        }
        lineRemove(line.variant.id);
      }
    });
  }, [ctx.checkout?.lines]);

  const { cartInfo } = ctx;

  useClearCheckoutBetweenRegion(ctx.checkout, ctx.processing, clear);

  return (
    <CheckoutProviderContext.Provider
      value={{
        ...ctx,
        cartInfo,
        clear,
        clearQueue,
        isCartEmpty,
        isCartNotEmpty,
        lineAdd,
        lineRemove,
        onPostLineChange,
        refresh,
        setProcessing,
        setQueueProcessing,
        updateCheckout,
        updateQueue,
      }}
    >
      {children}
    </CheckoutProviderContext.Provider>
  );
};
