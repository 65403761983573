import React from "react";
import {
  FieldValues,
  FormProvider as HooksFormProvider,
  FormProviderProps as HooksFormProviderProps,
} from "react-hook-form";
import { SubmitHandler } from "react-hook-form/dist/types/form";

import { getTestAttribute } from "@utils";

interface FormProviderProps<TFieldValues extends FieldValues = any>
  extends HooksFormProviderProps<TFieldValues> {
  formProps?: Omit<
    React.DetailedHTMLProps<
      React.FormHTMLAttributes<HTMLFormElement>,
      HTMLFormElement
    >,
    "onSubmit"
  >;
  name: string;
  onSubmit: SubmitHandler<TFieldValues>;
  ref?: React.Ref<HTMLFormElement>;
  relative?: boolean;
}

/**
 * Simple wrapper to avoid manually adding RHF FormProvider & required form.
 */
export const FormProvider = ({
  onSubmit,
  children,
  ref,
  formProps,
  name,
  relative = true,
  ...providerProps
}: FormProviderProps) => (
  <HooksFormProvider {...providerProps}>
    <form
      onSubmit={providerProps.handleSubmit(onSubmit, err => {
        console.error(`${name} - handleSubmit error:`, err);
      })}
      ref={ref}
      style={relative ? { position: "relative" } : undefined}
      {...getTestAttribute(name, "form")}
      {...formProps}
    >
      {children}
    </form>
  </HooksFormProvider>
);
