import { CountryCode } from "@gqlTypes/globalTypes";

import { COUNTRIES, DEFAULT_LOCALE, SUPPORTED_LOCALE_PATHS } from "./context";
import { CountryDetails, Locale } from "./types";

export const isSupportedLocale = (locale: string | undefined) =>
  SUPPORTED_LOCALE_PATHS.includes(locale as Locale);

export const getEnglishLanguageLocale = (
  country: CountryDetails
): Locale | undefined =>
  country.supportedLocales.find(locale => locale.startsWith("en"));

export const getLocaleForCountry = (
  country: CountryCode | string | undefined,
  englishLanguage = false
): string => {
  if (!country) return DEFAULT_LOCALE;

  const match = COUNTRIES.find(({ code, supportedCountries }) =>
    [code, ...supportedCountries].includes(country as CountryCode)
  );

  if (!match) return DEFAULT_LOCALE;

  if (englishLanguage) {
    const englishLocale = getEnglishLanguageLocale(match);
    if (englishLocale) return englishLocale;
  }

  if (country.toLowerCase() === match.intlLocale) return match.locale;

  return match.locale;
};

export const getLocaleFromChannel = (channel: string): Locale => {
  const country = COUNTRIES.find(({ channel: c }) => c === channel);
  return country?.locale || DEFAULT_LOCALE;
};
