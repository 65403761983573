import recommend from "@algolia/recommend";
import algoliasearch, { SearchIndex } from "algoliasearch";

import {
  ALGOLIA_BLOG_INDEX_EU,
  ALGOLIA_BLOG_INDEX_GB,
  ALGOLIA_BLOG_INDEX_US,
  ALGOLIA_EVENT_INDEX_EU,
  ALGOLIA_EVENT_INDEX_GB,
  ALGOLIA_EVENT_INDEX_US,
  ALGOLIA_INDEX_DE_EU,
  ALGOLIA_INDEX_EN_EU,
  ALGOLIA_INDEX_GB,
  ALGOLIA_INDEX_US,
  algoliaApiKey,
  algoliaApplicationId,
  publicUrl,
} from "@config";
import { Locale } from "@providers/TranslationProvider";

export const ALGOLIA_PRODUCT_SEARCH_INDEXES: { [key in Locale]: string } = {
  "de-de": ALGOLIA_INDEX_DE_EU,
  "en-de": ALGOLIA_INDEX_EN_EU,
  "en-gb": ALGOLIA_INDEX_GB,
  "en-us": ALGOLIA_INDEX_US,
  "fr-fr": ALGOLIA_INDEX_DE_EU,
};

export const ALGOLIA_EVENT_SEARCH_INDEXES: { [key in Locale]: string } = {
  "de-de": ALGOLIA_EVENT_INDEX_EU,
  "en-de": ALGOLIA_EVENT_INDEX_EU,
  "en-gb": ALGOLIA_EVENT_INDEX_GB,
  "en-us": ALGOLIA_EVENT_INDEX_US,
  "fr-fr": ALGOLIA_EVENT_INDEX_EU,
};

export const ALGOLIA_BLOG_SEARCH_INDEXES: { [key in Locale]: string } = {
  "de-de": ALGOLIA_BLOG_INDEX_EU,
  "en-de": ALGOLIA_BLOG_INDEX_EU,
  "en-gb": ALGOLIA_BLOG_INDEX_GB,
  "en-us": ALGOLIA_BLOG_INDEX_US,
  "fr-fr": ALGOLIA_BLOG_INDEX_EU,
};

const CACHE: { [key in Locale]?: SearchIndex } = {};

export const algoliaClient = algoliasearch(
  algoliaApplicationId,
  algoliaApiKey,
  {
    headers: {
      referer: publicUrl ?? "https://www.roughtrade.com",
    },
  }
);

export const getAlgoliaProductIndex = (locale: Locale): SearchIndex => {
  let index = CACHE[locale];

  if (!index) {
    index = algoliaClient.initIndex(ALGOLIA_PRODUCT_SEARCH_INDEXES[locale]);
  }

  return index;
};

const EVENT_CACHE: { [key in Locale]?: SearchIndex } = {};

export const getAlgoliaEventIndex = (locale: Locale): SearchIndex => {
  let index = EVENT_CACHE[locale];

  if (!index) {
    index = algoliaClient.initIndex(ALGOLIA_EVENT_SEARCH_INDEXES[locale]);
  }

  return index;
};

const BLOG_CACHE: { [key in Locale]?: SearchIndex } = {};

export const getAlgoliaBlogIndex = (locale: Locale): SearchIndex => {
  let index = BLOG_CACHE[locale];

  if (!index) {
    index = algoliaClient.initIndex(ALGOLIA_BLOG_SEARCH_INDEXES[locale]);
  }

  return index;
};

export const recommendClient = recommend(algoliaApplicationId, algoliaApiKey);
