import { media, px2rem, styled } from "@styles";

export const AlbumInfoContainer = styled.div<{ isExpanded?: boolean }>`
  display: flex;
  align-items: ${({ isExpanded }) => (isExpanded ? "flex-start" : "center")};
  padding-left: ${({ isExpanded }) => (isExpanded ? "0" : "1.5rem")};
`;

export const Thumbnail = styled.div<{ isExpanded?: boolean }>`
  width: ${({ isExpanded }) => (isExpanded ? "90px" : "44px")};
  height: ${({ isExpanded }) => (isExpanded ? "90px" : "44px")};
  flex: 0 0 ${({ isExpanded }) => (isExpanded ? "90px" : "44px")};

  img {
    max-width: 100%;
    max-height: 100%;
  }

  ${media.lg.up} {
    display: block;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 14px;
`;

export const MobileLabel = styled.p`
  text-align: center;
  font-size: ${px2rem(13)};
  color: ${({ theme }) => theme.colors.dark};
  padding: 0 6px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ArtistName = styled.span<{
  isConnectedWithSpotify: boolean;
  isFollowed: boolean;
}>`
  color: ${({ isFollowed, theme }) =>
    isFollowed ? theme.colors.orange : theme.colors.light};
  cursor: ${({ isConnectedWithSpotify }) =>
    isConnectedWithSpotify ? "pointer" : "default"};

  &:not(:first-of-type) {
    &::before {
      content: ", ";
    }
  }
`;
