import React from "react";
import { keyframes } from "styled-components";

import { media, styled } from "@styles";

export interface TopBannerWrapperProps
  extends React.HTMLAttributes<HTMLDivElement> {
  backgroundColor?: string;
  isActive: boolean;
  isPrev: boolean;
  showTransition: boolean;
}

export const TopBannerWrapper = styled.div<{
  isVisible: boolean;
  slideUp?: boolean;
}>`
  height: ${({ theme }) => theme.promoBanner.size.mobile}px;
  overflow-x: hidden;
  transition: transform 0.2s linear, margin-top 0.2s linear;
  transform: translateY(${({ slideUp }) => (slideUp ? "-100%" : "0%")});
  position: relative;
  width: 100%;
  margin-top: ${({ isVisible, theme }) =>
    isVisible ? 0 : -theme.promoBanner.size.mobile}px;

  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;

  &:hover {
    .announcement-ticker {
      animation-play-state: paused;
    }
  }

  ${media.lg.up} {
    height: ${({ theme }) => theme.promoBanner.size.desktop}px;
    margin-top: ${({ isVisible, theme }) =>
      isVisible ? 0 : -theme.promoBanner.size.desktop}px;
  }
`;

const Ticker = keyframes`
	100% { transform: translateX(calc(-50% - 50px))}
`;

export const AnnouncementTicker = styled.div<{
  animationSpeed?: number;
}>`
  flex: 1 1 100%;
  display: flex;
  gap: 60px;
  height: 100%;

  will-change: transform;
  transform: translate3d(0, 0, 0);
  animation: ${Ticker} ${({ animationSpeed }) => animationSpeed ?? 20}s linear
    infinite;

  ${media.md.up} {
    gap: 100px;
  }
`;

export const TickerItem = styled.div`
  display: flex;
  text-align: center;
  height: 100%;
  flex: 1 1 10%;

  ${media.md.up} {
    padding-right: 0;
  }
`;

export const TickerItemText = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  white-space: nowrap;
  color: ${({ theme: { promoBanner } }) => promoBanner.foregroundColor};
  font-size: ${({ theme }) => theme.typography.sizes.small};
  font-weight: 500;
  gap: 10px;
  overflow: hidden;
  opacity: 0.8;

  a {
    color: inherit;
  }
`;

export const CloseIconWrapper = styled.button`
  display: flex;
  align-self: flex-end;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: 0;
  padding-block: 10px;
  z-index: 999;
  height: 100%;
  justify-content: center;
  align-items: center;
  width: 40px;
  background-color: ${({ theme }) => theme.colors.white};

  &:hover {
    cursor: pointer;
    opacity: 1;
  }
`;
