import { checkoutBaseFragment, checkoutFragment } from "@graphql";
import { gql } from "graphql-tag";

export const checkoutByIdQuery = gql`
  ${checkoutFragment}
  query CheckoutById($id: ID, $languageCode: LanguageCodeEnum = EN) {
    checkout(id: $id) {
      ...Checkout
    }
  }
`;

export const checkoutBaseByIdQuery = gql`
  ${checkoutBaseFragment}
  query CheckoutBaseById($id: ID, $languageCode: LanguageCodeEnum = EN) {
    checkout(id: $id) {
      ...CheckoutBase
    }
  }
`;

export const checkoutExistenceQuery = gql`
  query CheckoutByExistence($id: ID) {
    checkout(id: $id) {
      email
    }
  }
`;

export const userCheckoutIds = gql`
  query UserCheckoutIds($channel: String!) {
    me {
      id
      checkoutIds(channel: $channel)
    }
  }
`;
