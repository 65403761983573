import { useMetadataUpdate } from "@graphql/mutations";
import { useAuthState } from "@saleor/sdk";
import React, { useEffect, useState } from "react";
import algoliaAnalytics from "search-insights";

import { algoliaApiKey, algoliaApplicationId, MetadataKey } from "@config";
import { gtmAlgoliaUserToken } from "@providers/GoogleTagManagerProvider";
import { useGoogleTagManagerStore } from "@stores/useGoogleTagManagerStore";
import { generateRandomString } from "@utils/text";

import {
  defaultSearchInsightsState,
  SearchInsightsContext,
  SearchInsightsState,
} from "./context";

type SearchInsightsProviderProps = {
  children?: React.ReactNode;
};

export const SearchInsightsProvider = ({
  children,
}: SearchInsightsProviderProps) => {
  const [ctx, setCtx] = useState<SearchInsightsState>(
    defaultSearchInsightsState
  );
  const [updateMetadata] = useMetadataUpdate();
  const { ready } = useGoogleTagManagerStore();
  const { user, authenticated } = useAuthState();

  const setAnonymousToken = () => {
    const storageKey = `anonymous.${MetadataKey.ALGOLIA_USER_TOKEN}`;
    const token = ctx.insightsUserToken ?? localStorage.getItem(storageKey);

    algoliaAnalytics("setUserToken", token);
    gtmAlgoliaUserToken(token);
    setCtx(ctx => ({ ...ctx, insightsUserToken: token! }));
    localStorage.setItem(storageKey, token);
  };

  const setUserToken = () => {
    const userAlgoliaToken = user?.metadata.find(
      item => item?.key === MetadataKey.ALGOLIA_USER_TOKEN
    )?.value;
    const userToken: string = userAlgoliaToken || generateRandomString();

    updateMetadata({
      variables: {
        id: user!.id,
        input: [
          {
            key: MetadataKey.ALGOLIA_USER_TOKEN,
            value: userToken,
          },
        ],
      },
    });

    algoliaAnalytics("setUserToken", userToken);
    gtmAlgoliaUserToken(userToken);
    setCtx(ctx => ({ ...ctx, insightsUserToken: userToken }));
  };

  useEffect(() => {
    algoliaAnalytics("getUserToken", {}, () => {
      algoliaAnalytics("init", {
        apiKey: algoliaApiKey,
        appId: algoliaApplicationId,
        userToken: ctx.insightsUserToken,
      });
    });
  }, []);

  useEffect(() => {
    if (ready) {
      if (authenticated) {
        setUserToken();
      } else {
        setAnonymousToken();
      }
    }
  }, [ready, authenticated]);

  return (
    <SearchInsightsContext.Provider value={ctx}>
      {children}
    </SearchInsightsContext.Provider>
  );
};
