import { CSSProperties } from "react";

import {
  Breakpoints,
  hiddenDown,
  hiddenUp,
  media,
  styled,
  theme,
} from "@styles";

type GridSizes = "xs" | "sm" | "md" | "lg" | "xl" | "xxl" | "retina";
type GridSize = number | boolean;

interface GridColumnProps
  extends Partial<Pick<CSSProperties, "alignSelf" | "alignItems">> {
  align?: CSSProperties["textAlign"];
  className?: string;
  direction?: CSSProperties["flexDirection"];
  flex?: boolean;
  grow?: CSSProperties["flexGrow"];
  hiddenDown?: Breakpoints;
  hiddenUp?: Breakpoints;
  justify?: CSSProperties["justifyContent"];
  lg?: GridSize;
  md?: GridSize;
  retina?: GridSize;
  sm?: GridSize;
  xl?: GridSize;
  xs?: GridSize;
  xxl?: GridSize;
}

const gridColumnProps = (
  size: GridSizes,
  columns: number | boolean | undefined
) =>
  columns
    ? typeof columns === "boolean"
      ? `
        ${media[size].up} {
          flex-grow: 1;
          width: 100%;
          flex-basis: 0;
        }
      `
      : `
        ${media[size].up} {
          flex-grow: 0;
          flex-basis: ${(columns / theme.grid.columns) * 100}%;
          margin: 0;
          width: ${(columns / theme.grid.columns) * 100}%;
        }
      `
    : undefined;

export const GridColumn = styled.div<GridColumnProps>`
  flex: 0 0 100%;

  ${({ flex }) => flex && `display: flex;`}
  ${({ justify }) => justify && `justify-content: ${justify};`}
  ${({ alignSelf }) => alignSelf && `align-self: ${alignSelf};`}
  ${({ align }) => align && `text-align: ${align};`}
  ${({ alignItems }) => alignItems && `align-items: ${alignItems};`}
  ${({ direction }) => direction && `flex-direction: ${direction};`}
  ${({ hidden }) => hidden && `display: none;`}

  ${({ xs }) => gridColumnProps("xs", xs)}
  ${({ sm }) => gridColumnProps("sm", sm)}
  ${({ md }) => gridColumnProps("md", md)}
  ${({ lg }) => gridColumnProps("lg", lg)}
  ${({ xl }) => gridColumnProps("xl", xl)}
  ${({ xl }) => gridColumnProps("xl", xl)}
  ${({ xxl }) => gridColumnProps("xxl", xxl)}
  ${({ retina }) => gridColumnProps("retina", retina)}

  ${({ grow }) => grow !== undefined && `flex-grow: ${grow} !important;`}

  ${({ hiddenDown: breakpoint }) => breakpoint && hiddenDown(breakpoint)}
  ${({ hiddenUp: breakpoint }) => breakpoint && hiddenUp(breakpoint)}
`;
GridColumn.displayName = "GridColumn";

GridColumn.defaultProps = {
  xs: 12,
};
