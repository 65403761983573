import React from "react";

import { useClubSubscriptionStore } from "@stores/useClubSubscriptionStore";

import * as S from "./styles";

export const FormContent = ({ children }: { children: React.ReactNode }) => {
  const { pages, isAnimating } = useClubSubscriptionStore();

  const FormWrapperStyle = {
    transform: `translateX(-${(pages.current - 1) * 100}vw)`,
  };

  return (
    <S.ClubFormWrapper className="form-content-wrapper" id="main-content">
      <S.ClubInnerWrapper isAnimating={isAnimating} style={FormWrapperStyle}>
        {children}
      </S.ClubInnerWrapper>
    </S.ClubFormWrapper>
  );
};
