import { CountryCode, LanguageCodeEnum } from "@gqlTypes/globalTypes";

import { Channel, CountryDetails, Locale } from "./types";

import { ReactComponent as FlagDE } from "assets/flags/flag-de.svg";
import { ReactComponent as FlagFR } from "assets/flags/flag-fr.svg";
import { ReactComponent as FlagGB } from "assets/flags/flag-gb.svg";
import { ReactComponent as FlagUSA } from "assets/flags/flag-usa.svg";

export const COUNTRIES: CountryDetails[] = [
  {
    channel: "eu",
    code: CountryCode.DE,
    currency: "EUR",
    flag: FlagDE,
    intlLocale: "de",
    language: "de-DE",
    languageCode: LanguageCodeEnum.DE,
    locale: "de-de",
    name: "Germany",
    supportedCountries: [
      CountryCode.AT,
      CountryCode.BE,
      CountryCode.BG,
      CountryCode.CY,
      CountryCode.CZ,
      CountryCode.DE,
      CountryCode.DK,
      CountryCode.EE,
      CountryCode.ES,
      CountryCode.FI,
      CountryCode.GR,
      CountryCode.HR,
      CountryCode.HU,
      CountryCode.IE,
      CountryCode.IT,
      CountryCode.LT,
      CountryCode.LU,
      CountryCode.LV,
      CountryCode.MT,
      CountryCode.NL,
      CountryCode.PL,
      CountryCode.PT,
      CountryCode.RO,
      CountryCode.SE,
      CountryCode.SI,
      CountryCode.SK,
      CountryCode.FR,
      CountryCode.AD,
      CountryCode.MC,
      CountryCode.SM,
      CountryCode.VA,
    ],
    supportedLocales: ["de-de", "en-de"],
  },
  {
    channel: "eu",
    code: CountryCode.FR,
    currency: "EUR",
    flag: FlagFR,
    intlLocale: "fr",
    language: "fr-FR",
    languageCode: LanguageCodeEnum.FR,
    locale: "fr-fr",
    name: "France",
    supportedCountries: [],
    supportedLocales: ["fr-fr"],
  },
  {
    channel: "gb",
    code: CountryCode.GB,
    currency: "GBP",
    flag: FlagGB,
    intlLocale: "en",
    language: "en-GB",
    languageCode: LanguageCodeEnum.EN,
    locale: "en-gb",
    name: "United Kingdom",
    supportedCountries: [CountryCode.GB],
    supportedLocales: ["en-gb"],
  },
  {
    channel: "us",
    code: CountryCode.US,
    currency: "USD",
    flag: FlagUSA,
    intlLocale: "en",
    language: "en-US",
    languageCode: LanguageCodeEnum.EN,
    locale: "en-us",
    name: "United States",
    supportedCountries: [
      CountryCode.AG,
      CountryCode.AI,
      CountryCode.AS,
      CountryCode.AW,
      CountryCode.BB,
      CountryCode.BL,
      CountryCode.BO,
      CountryCode.BR,
      CountryCode.BS,
      CountryCode.BZ,
      CountryCode.CL,
      CountryCode.CR,
      CountryCode.CU,
      CountryCode.DO,
      CountryCode.EC,
      CountryCode.GP,
      CountryCode.GT,
      CountryCode.GU,
      CountryCode.GY,
      CountryCode.HN,
      CountryCode.HT,
      CountryCode.JM,
      CountryCode.KN,
      CountryCode.KY,
      CountryCode.LC,
      CountryCode.MF,
      CountryCode.MQ,
      CountryCode.MS,
      CountryCode.NI,
      CountryCode.PA,
      CountryCode.PE,
      CountryCode.PM,
      CountryCode.PR,
      CountryCode.PY,
      CountryCode.SR,
      CountryCode.SV,
      CountryCode.TC,
      CountryCode.TT,
      CountryCode.UM,
      CountryCode.UY,
      CountryCode.VC,
      CountryCode.VE,
      CountryCode.VG,
      CountryCode.VI,
      CountryCode.WS,
    ],
    supportedLocales: ["en-us"],
  },
];

export const currencySymbols: Record<string, string> = {
  EUR: "€",
  GBP: "£",
  USD: "$",
};

// export const CHANNELS = COUNTRIES.map(({ channel }) => channel);
const createChannelObjects = () => {
  const channelMap: Record<
    Channel,
    {
      currency: {
        symbol: string;
        text: string;
      };
      defaultLocale: Locale;
      locales: Set<Locale>;
    }
  > = COUNTRIES.reduce(
    (accumulator, country) => {
      const { channel, currency, locale } = country;

      if (!accumulator[channel]) {
        accumulator[channel] = {
          currency: {
            symbol: currencySymbols[currency],
            text: currency,
          },
          defaultLocale: locale as unknown as Locale,
          locales: new Set(),
        };
      }

      country.supportedLocales.forEach(locale =>
        accumulator[channel].locales.add(locale as unknown as Locale)
      );

      return accumulator;
    },
    {} as Record<
      Channel,
      {
        currency: {
          symbol: string;
          text: string;
        };
        defaultLocale: Locale;
        locales: Set<Locale>;
      }
    >
  );

  return Object.entries(channelMap).map(
    ([channel, { locales, currency, defaultLocale }]) => ({
      channel: channel as Channel,
      currency,
      defaultLocale,
      supportedLocales: Array.from(locales),
    })
  );
};

export type ChannelType = {
  channel: Channel;
  currency: {
    symbol: string;
    text: string;
  };
  defaultLocale: Locale;
  supportedLocales: Locale[];
};

export const CHANNELS = createChannelObjects() as ChannelType[];

export const DEFAULT_COUNTRY = COUNTRIES.find(
  ({ code }) => code === CountryCode.GB
)!;
export const DEFAULT_LOCALE = DEFAULT_COUNTRY.locale;

export const getCountryByLocale = (locale: Locale): CountryDetails => {
  const country = COUNTRIES.find(
    c => c.locale === locale || c.supportedLocales.includes(locale)
  );
  return country || DEFAULT_COUNTRY;
};
export const getCurrentChannel = (currentChannel: Channel): ChannelType =>
  CHANNELS.find(channel => channel.channel === currentChannel) ?? CHANNELS[0];

export const SUPPORTED_LOCALE_PATHS = COUNTRIES.map(
  ({ supportedLocales }) => supportedLocales
).flat(1);
