import { defaultAnimation, shortNotificationAnimation } from "./constants";
import { notificationComponents } from "./notifications";
import {
  MessagesProps,
  NotificationComponents,
  NotificationTypes,
  ToastMetadata,
} from "./types";

import { cart as CM, favouriting as FM } from "./messages";

export const getNotificationComponent = (type: string) =>
  notificationComponents[type as NotificationComponents];

export const getToast = (props: ToastMetadata) => {
  const Component = getNotificationComponent(props.type);
  const messages = getNotificationMessages(props);
  const animations = getAnimation(props);

  return {
    Component,
    animations,
    messages,
  };
};

// Notification messages
export const getNotificationMessages = ({
  action,
  type,
  status,
}: ToastMetadata): MessagesProps | null =>
  status === "success"
    ? getSuccessMessages({ action, type })
    : getFailureMessages({ type });

export const getSuccessMessages = ({
  action,
  type,
}: Pick<ToastMetadata, "action" | "type">) => {
  switch (type) {
    case NotificationTypes.CART:
      return {
        button: CM.ShowBag,
        desktop: CM.AddedToCart,
        mobile: CM.AddedToCartMobile,
      };

    case NotificationTypes.LIKE:
      if (action === "add") {
        return {
          button: FM.ShowLikes,
          desktop: FM.LikeAdded,
          mobile: FM.LikeAddedMobile,
        };
      }
      return {
        button: FM.ShowLikes,
        desktop: FM.LikeRemoved,
        mobile: FM.LikeRemovedMobile,
      };

    default:
      return null;
  }
};

export const getFailureMessages = ({ type }: Pick<ToastMetadata, "type">) => {
  switch (type) {
    case NotificationTypes.CART:
      return {
        button: CM.ShowBag,
        desktop: CM.CartFailed,
        mobile: CM.CartFailedMobile,
      };

    case NotificationTypes.LIKE:
      return {
        button: FM.ShowLikes,
        desktop: FM.LikeFailed,
        mobile: FM.LikeFailedMobile,
      };
    default:
      return null;
  }
};

// Animations
export const getAnimation = ({ type }: Pick<ToastMetadata, "type">) => {
  switch (type) {
    case NotificationTypes.CART:
      return defaultAnimation;
    case NotificationTypes.LIKE:
      return shortNotificationAnimation;
    default:
      return shortNotificationAnimation;
  }
};
