import { removeSpecialCharacters, removeWhiteSpace } from "@utils/text";

import { ColLimit, ColWidths } from "./types";

export const configSliceHelpers = <T,>(options?: {
  fallbackLimit: number;
  items: Array<T>;
  setLimit: string;
}) => {
  const { fallbackLimit = 4, items, setLimit = "4" } = options || {};

  const limit = parseInt(setLimit, 10) || fallbackLimit;
  const trimmedList = items ? items.slice(0, limit) : items;

  const numOfColumns = Math.ceil(limit / fallbackLimit) as ColLimit;
  const maxColWidth = Math.ceil(100 / numOfColumns) as ColWidths;

  return {
    limit,
    maxColWidth,
    numOfColumns,
    trimmedList,
  };
};

export const generateNavItemUid = (label: string) =>
  removeWhiteSpace(removeSpecialCharacters(label).toLowerCase());
