import { PricedProductVariant } from "@gqlTypes/PricedProductVariant";
import { SliderCollection_collection_products_edges_node } from "@gqlTypes/SliderCollection";
import React from "react";

import { Container } from "@components/Container";
import { Link } from "@components/Link";
import { Slider, SliderProps } from "@components/Slider";
import { useIsLgUp } from "@hooks/responsive";
import { AlgoliaResult } from "@integrations/algolia";

import {
  AlgoliaHitComponent,
  CustomHitComponent,
  CustomProductHit,
  SaleorHitComponent,
  SaleorVariantHitComponent,
} from "./components";

import * as M from "./messages";
import * as S from "./styles";

type CollectionType = "algolia" | "saleor" | "custom" | "saleorVariant";

export interface CollectionSliderProps extends Pick<SliderProps, "arrows"> {
  children?: React.ReactNode;
  collectionName?: string;
  collectionType?: CollectionType;
  data?:
    | AlgoliaResult[]
    | SliderCollection_collection_products_edges_node[]
    | CustomProductHit[]
    | PricedProductVariant[];
  desktopCoverSize?: number;
  disableGutters?: boolean;
  horizontalScroll?: boolean;
  id?: string;
  inNavigation?: boolean;
  isAlgolia?: boolean;
  isPriority?: boolean;
  light?: boolean;
  location: string;
  paddingLeft?: boolean;
  paddingTop?: number;
  showPrice?: boolean;
  slug?: string;
  variant?: "default" | "container";
  withContainer?: boolean;
  withHeader?: boolean;
}

interface SliderWrapperProps {
  children?: React.ReactNode;
  marginTop: boolean;
  paddingLeft: boolean;
  withContainer?: boolean;
}

const SliderWrapper = ({
  children,
  marginTop,
  withContainer = false,
  paddingLeft,
}: SliderWrapperProps) =>
  withContainer ? (
    <Container>{children}</Container>
  ) : (
    <S.CollectionSlider marginTop={marginTop} paddingLeft={paddingLeft}>
      {children}
    </S.CollectionSlider>
  );

export const CollectionSlider = ({
  id,
  collectionName,
  location,
  paddingTop,
  slug,
  data,
  collectionType,
  children,
  variant = "default",
  light = false,
  disableGutters = false,
  arrows = true,
  isAlgolia = true,
  inNavigation = false,
  horizontalScroll = true,
  paddingLeft = true,
  withContainer = false,
  withHeader = true,
  desktopCoverSize = 290,
  isPriority = false,
  showPrice = false,
}: CollectionSliderProps) => {
  const isDesktop = useIsLgUp();

  if (!data && !children) {
    return null;
  }

  return (
    <S.CollectionSliderWrapper
      id={id}
      paddingLeft={paddingLeft}
      paddingTop={paddingTop}
    >
      {withHeader && (
        <Container
          disableGutters={disableGutters || isDesktop}
          overflow="hidden"
        >
          <S.CollectionHeader>
            {collectionName && (
              <S.CollectionName light={light}>
                {collectionName}
              </S.CollectionName>
            )}
            {slug && (
              <Link href={slug} wrapperTag={false}>
                <S.SeeAll light={light}>{M.SeeAll}</S.SeeAll>
              </Link>
            )}
          </S.CollectionHeader>
        </Container>
      )}

      <SliderWrapper
        marginTop={!data}
        paddingLeft={paddingLeft}
        withContainer={withContainer}
      >
        {data ? (
          <Slider
            arrows={arrows && data.length > 4}
            horizontalScroll={horizontalScroll}
            light={light}
          >
            {data?.map((product, i) => {
              if (collectionType === "algolia") {
                const algoliaProduct = product as AlgoliaResult;
                return (
                  <AlgoliaHitComponent
                    desktopCoverSize={desktopCoverSize}
                    hit={algoliaProduct}
                    inNavigation={inNavigation}
                    isPriority={isPriority}
                    key={algoliaProduct.objectID}
                    light={light}
                    location={`${location}_Carousel`}
                    onVisibleFireTag={false}
                  />
                );
              }
              if (collectionType === "saleor") {
                const saleorProduct =
                  product as SliderCollection_collection_products_edges_node;

                return (
                  <SaleorHitComponent
                    desktopCoverSize={desktopCoverSize}
                    hit={saleorProduct}
                    inNavigation={inNavigation}
                    isPriority={isPriority}
                    key={saleorProduct.id}
                    light={light}
                    location={`${location}_Carousel`}
                    onVisibleFireTag={false}
                    position={i}
                    showPrice={showPrice}
                  />
                );
              }

              if (collectionType === "saleorVariant") {
                const saleorProduct = product as PricedProductVariant;

                return (
                  <SaleorVariantHitComponent
                    desktopCoverSize={desktopCoverSize}
                    hit={saleorProduct}
                    inNavigation={inNavigation}
                    isPriority={isPriority}
                    key={saleorProduct.id}
                    light={light}
                    location={`${location}_Carousel`}
                    position={i}
                    showPrice={showPrice}
                  />
                );
              }

              if (collectionType === "custom") {
                const customStoredProduct = product as CustomProductHit;

                return (
                  <CustomHitComponent
                    hit={customStoredProduct}
                    inNavigation={inNavigation}
                    key={customStoredProduct.id}
                    location={`${location}_Recently_Viewed_Carousel`}
                  />
                );
              }
            })}
          </Slider>
        ) : (
          children
        )}
      </SliderWrapper>
    </S.CollectionSliderWrapper>
  );
};

CollectionSlider.displayName = "CollectionSlider";
