import { ToastAnimationProps } from "@components/Toast/types";
import { media, px2rem, styled } from "@styles";

import {
  barAnimation,
  containerAnimation,
  containerAnimationMobile,
} from "./animations";

export const ToastContainer = styled.div<{ hidden: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  overflow: hidden;

  .desktop-content {
    display: none;
  }

  .mobile-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  ${media.md.up} {
    position: relative;
    inset: auto;
    height: ${({ hidden }) => (hidden ? "0px" : "auto")};

    .desktop-content {
      display: flex;
      flex-flow: column;
      gap: inherit;
    }

    .mobile-content {
      display: none;
    }
  }
`;

export const ToastInnerWrapper = styled.div<ToastAnimationProps>`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  padding: 1rem 2rem;
  background: ${({ theme }) => theme.colors.black};
  transform: translate(0, -100%);

  ${({ delay, speed, totalDuration }) =>
    containerAnimationMobile({ delay, speed, totalDuration })}

  ${media.md.up} {
    flex-flow: column;
    padding: 20px 2rem;
    border: 2px solid ${({ theme }) => theme.colors.white};
    gap: 20px;
    ${({ delay, speed, totalDuration }) =>
      containerAnimation({ delay, speed, totalDuration })}
  }
`;

export const Title = styled.p`
  font-size: ${px2rem(14)};
  text-align: right;
  color: ${({ theme }) => theme.colors.orange};

  ${media.md.up} {
    font-size: 1rem;
  }
`;

export const BarContainer = styled.div`
  width: 100%;
  height: 2px;
  overflow: hidden;
`;

export const Bar = styled.div<ToastAnimationProps>`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  transform: translateX(-100%);

  ${({ delay, speed, totalDuration }) =>
    barAnimation({ delay, speed, totalDuration })}
`;

export const MobileButton = styled.button`
  border: none;
  background: none;
  text-decoration: underline;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.orange};
`;
