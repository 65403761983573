import { Checkout } from "@gqlTypes/Checkout";
import {
  CheckoutLine,
  CheckoutLine_variant_media,
} from "@gqlTypes/CheckoutLine";
import { DeliveryMethod } from "@gqlTypes/DeliveryMethod";
import { PaymentChargeStatusEnum } from "@gqlTypes/globalTypes";
import { MetadataItem } from "@gqlTypes/MetadataItem";
import { Money } from "@gqlTypes/Money";
import { ShippingMethod } from "@gqlTypes/ShippingMethod";
import { User } from "@gqlTypes/User";
import { Warehouse } from "@gqlTypes/Warehouse";
import {
  AddressFragment,
  UserDetailsFragment,
} from "@saleor/sdk/dist/apollo/types";

import { AddressSchema } from "@components/AddressForm";
import { LocalStorageKey, MetadataKey } from "@config";

import { capitalize } from "./misc";
import { getItem, removeItem, setItem } from "./storage";
import { Discount, Maybe } from "./typescript";

import FallbackImage from "assets/images/fallback-image.jpg";

type CheckoutLineMedia = CheckoutLine_variant_media;

export const getProductImage = (
  variant: CheckoutLine["variant"]
): Pick<CheckoutLineMedia, "alt" | "src"> =>
  variant!.media?.[0] ||
  variant!.product.media?.[0] || {
    alt: "",
    src: FallbackImage,
  };

export const getCollectionPoint = (
  deliveryMethod: DeliveryMethod | null
): Warehouse | null =>
  deliveryMethod?.__typename === "Warehouse" ? deliveryMethod : null;

export const getDeliveryMethod = (
  deliveryMethod: DeliveryMethod | null
): ShippingMethod | null =>
  deliveryMethod?.__typename === "ShippingMethod" ? deliveryMethod : null;

export const getUserLastPaymentMethod = (data: MetadataItem[]) =>
  data.find(({ key }) => key === MetadataKey.LAST_PAYMENT_METHOD)?.value;

export const getUserLastShippingMethodId = (data: MetadataItem[]) =>
  data.find(({ key }) => key === MetadataKey.LAST_SHIPPING_METHOD_ID)?.value;

export const isFallbackShippingMethod = (
  shippingMethod: Maybe<ShippingMethod>
) =>
  shippingMethod?.metadata.some(
    meta => meta?.key === MetadataKey.SHIPMENT_FALLBACK
  );

export const getUserLastShippingMethod = (
  user: UserDetailsFragment | User,
  availableShippingMethods: Maybe<ShippingMethod>[]
) => {
  const lastShippingMethodName = getUserLastShippingMethodId(
    user.metadata as MetadataItem[]
  );
  return availableShippingMethods?.find(
    method => method!.name === lastShippingMethodName
  );
};

export const extractDiscounts = ({
  giftCards,
  voucherCode,
  discount,
}: Checkout): {
  discount: Discount | undefined;
  giftCards: Discount[];
} => ({
  discount: voucherCode
    ? {
        code: voucherCode,
        currentBalance: undefined,
        discount: discount as Money,
        id: undefined,
      }
    : undefined,
  giftCards:
    giftCards?.map(giftCard => ({
      code: giftCard!.displayCode,
      currentBalance: giftCard!.currentBalance,
      discount: undefined,
      id: giftCard!.id,
    })) ?? [],
});

export const isClickAndCollect = (deliveryMethod: DeliveryMethod) =>
  !!getCollectionPoint(deliveryMethod);

/**
 * Shipping methods with meta key of `fallback`, are threaded af fallback methods,
 * in case EP service is down.
 * If Saleor returns EP shipping methods, we need to filter them out.
 */
export const filterFallbackShipments = (shippingMethods: ShippingMethod[]) => {
  const areOnlyFallbackShippingMethodsAvailable = shippingMethods.every(
    shipping =>
      shipping.metadata?.some(
        entry => entry?.key === MetadataKey.SHIPMENT_FALLBACK
      )
  );

  if (areOnlyFallbackShippingMethodsAvailable) {
    return shippingMethods;
  }

  return shippingMethods.filter(
    shipping =>
      !shipping.metadata?.some(
        entry => entry?.key === MetadataKey.SHIPMENT_FALLBACK
      )
  );
};

/**
 * Remove local storage for all checkout related data
 */
export const removeCheckoutLocalStorage = () => {
  const keys = [
    LocalStorageKey.DELIVERY_OPTION,
    LocalStorageKey.CHECKOUT_TYPE,
    LocalStorageKey.GUEST_USER,
  ];

  keys.map(key => removeItem(key));
};

export type GuestUserLocalStorage = {
  email: string;
  firstName: string;
  lastName: string;
};

/**
 * Retrieves the guest user data from local storage and capitalizes their names.
 */
export const fetchGuestUser = (): GuestUserLocalStorage | null => {
  const guestUser = getItem(
    LocalStorageKey.GUEST_USER,
    true
  ) as GuestUserLocalStorage | null;

  if (guestUser) {
    guestUser.firstName = capitalize(guestUser.firstName);
    guestUser.lastName = capitalize(guestUser.lastName);
  }

  return guestUser;
};

/**
 * Sets the guest user data in local storage.
 */

export const setGuestUser = ({
  email,
  firstName,
  lastName,
}: GuestUserLocalStorage) => {
  setItem(LocalStorageKey.GUEST_USER, { email, firstName, lastName }, true);
};

type CheckoutType = "guest" | "user";

/**
 * Determines if the current checkout type matches the specified type.
 */
export const isCheckoutType = (targetType: CheckoutType): boolean =>
  getItem(LocalStorageKey.CHECKOUT_TYPE) === targetType;

export const setCheckoutType = (passedType: CheckoutType) => {
  setItem(LocalStorageKey.CHECKOUT_TYPE, passedType);
};

/**
 * Formats and validates an address schema to conform to a checkout-friendly address fragment.
 */
export const formatCheckoutAddress = (
  address: AddressSchema
): AddressFragment => ({
  city: address.city ?? "",
  cityArea: "",
  companyName: address.companyName ?? "",
  country: {
    code: address.country?.value ?? "",
    country: address.country?.label ?? "",
  },
  countryArea: address.countryArea?.value ?? "",
  firstName: address.firstName ?? "",
  id: "",
  isDefaultBillingAddress: null,
  isDefaultShippingAddress: null,
  lastName: address.lastName ?? "",
  phone: address.phone ?? null,
  postalCode: address.postalCode ?? "",
  streetAddress1: address.streetAddress1 ?? "",
  streetAddress2: "",
});

export const capturePaymentApiCall = async (id: string) => {
  const apiUrl = new URL("api/order", window.location.origin);
  apiUrl.searchParams.set("id", id);

  const response = await fetch(apiUrl.toString());

  if (!response.ok) {
    const { errors } = (await response.json()) || {};

    return {
      errors,
      status: PaymentChargeStatusEnum.NOT_CHARGED,
    };
  }

  const { paymentStatus } = await response.json();

  return {
    status: paymentStatus as PaymentChargeStatusEnum,
  };
};

/**
 *
 * Needed for the stripe search functionality (in order-app) to register a newly created used
 */
export const waitFunc = (ms: number): Promise<void> =>
  new Promise(resolve => setTimeout(resolve, ms));
