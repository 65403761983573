import { ClubTierDocument } from "prismicio-types";
import { create } from "zustand";

import { ClubActions, ClubFormValues } from "@views/ClubFormView/types";

type ClubFormPages = {
  current: number;
  total: number;
};

export type FormValueKeys = keyof ClubFormValues;

type ClubInitialState = {
  action: ClubActions | null;
  isAnimating: boolean;
  pages: ClubFormPages;
  tier: ClubTierDocument | null;
  values: ClubFormValues;
};

type ClubSubscriptionStore = ClubInitialState & {
  setAction: (action: ClubActions) => void;
  setAnimating: (animating: boolean) => void;
  setPages: (pages: Partial<ClubFormPages>) => void;
  setTier: (tier: ClubTierDocument) => void;
  updateValues: (value: Partial<ClubFormValues>) => void;
};

const initialState: ClubInitialState = {
  action: null,
  isAnimating: false,
  pages: {
    current: 1,
    total: 1,
  },
  tier: null,
  values: {
    checkoutPage: "",
    format: "",
    length: "",
    recipient: "",
    totalCost: 0,
  },
};

export const useClubSubscriptionStore = create<ClubSubscriptionStore>()(
  set => ({
    ...initialState,
    setAction: action => {
      set(() => ({
        action,
      }));
    },
    setAnimating: animating =>
      set(() => ({
        isAnimating: animating,
      })),
    setPages: pages => {
      set(state => ({
        pages: {
          ...state.pages,
          ...pages,
        },
      }));
    },
    setTier: tier =>
      set(() => ({
        tier,
      })),
    updateValues: value => {
      set(state => ({
        ...state,
        values: {
          ...state.values,
          ...value,
        },
      }));
    },
  })
);
