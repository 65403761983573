import { px2rem, styled } from "@styles";

export const Container = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
  margin-right: 16px;
`;

export const Label = styled.label<{ withError: boolean }>`
  cursor: pointer;
  display: inline-flex;
  font-size: ${({ theme }) => theme.typography.baseFontSize};
  outline: none;
  margin: 10px 0;
  position: relative;
  align-items: center;

  ${Container} {
    input:checked ~ span:after {
      display: block;
      content: "";
      position: absolute;
    }

    + input:checked {
      padding-left: 10px;
    }
  }

  ${({ withError, theme }) =>
    withError &&
    `
      &, * {
        color: ${theme.colors.red};
      }
      ${CheckboxInput},
      ${CheckboxInput}::after {
        border-color: ${theme.colors.red};
      }
  `}
`;

export const Input = styled.input`
  cursor: pointer;
  height: 100%;
  margin: 0;
  opacity: 0;
  outline: none;
  position: absolute;
  width: 100%;
`;

export const CheckboxInput = styled.span<{ disabled?: boolean }>`
  background-color: ${({ theme }) => theme.colors.white};
  border: solid 2px
    ${({ theme, disabled }) =>
      disabled ? theme.input.disabled.borderColor : theme.colors.black};
  height: 24px;
  left: 0;
  top: 0;
  width: 24px;
  display: block;

  &::after {
    left: 9px;
    top: 5px;
    width: 7px;
    height: 12px;
    border: solid
      ${({ theme: { input, colors }, disabled }) =>
        disabled ? input.disabled.color : colors.black};
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`;

export const Content = styled.div<{ disabled?: boolean }>`
  font-size: ${px2rem(14)};
  line-height: 140%;
  align-self: center;

  ${({ disabled, theme }) =>
    disabled &&
    `
    color: ${theme.input.disabled.color}
  `}
`;
