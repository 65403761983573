import React from "react";
import { FormattedMessage } from "react-intl";

import { commonMessages } from "@messages/common";

import { ReactComponent as CheckSvg } from "assets/icons/check.svg";

export const emptyBasket = (
  <FormattedMessage defaultMessage="Your basket is empty" id="qLYUng" />
);

export const activeBasket = (
  <FormattedMessage defaultMessage="Your basket" id="EWbmo5" />
);

export const upsellModalHeader = (
  <FormattedMessage defaultMessage="Items you might like" id="Qhq/ag" />
);

export const continueShoppingButton = (
  <FormattedMessage defaultMessage="Continue Shopping" id="IUXVjJ" />
);

export const checkoutButton = (
  <FormattedMessage defaultMessage="Checkout" id="BJ2TKX" />
);

export const FinalPriceNote = (
  <FormattedMessage {...commonMessages.finalPriceAtCheckout} />
);

export const FreeShippingMessage = (value: string) => (
  <FormattedMessage
    defaultMessage="You’re {value} away from free shipping!"
    id="51ksLW"
    values={{ value }}
  />
);

export const FreeShippingQualified = (
  <FormattedMessage
    defaultMessage="{icon} Congratulations! You’ve qualified for free shipping"
    id="XkVE70"
    values={{
      icon: <CheckSvg />,
    }}
  />
);
