import { GiftCard as TGiftCard } from "@gqlTypes/GiftCard";
import { logMutationError } from "@graphql/utils";
import { paths } from "@paths";
import React, { useEffect, useRef, useState } from "react";

import { Grid, GridColumn } from "@components/Grid";
import { Spacer } from "@components/Spacer";
import { useCheckoutContext } from "@hooks/providers";
import { useIsLgDown } from "@hooks/responsive";
import { useIsMounted } from "@hooks/useIsMounted";
import { useToggle } from "@hooks/useToggle";
import { useCheckoutRemovePromoCodeMutation } from "@providers/CheckoutProvider";
import { theme } from "@styles";
import { extractDiscounts } from "@utils/checkout";
import { calculateRefChildrenHeight, getTestAttribute } from "@utils/misc";

import { GiftCard, PromoCodeSection } from "./components";

import { ReactComponent as ArrowSvg } from "assets/icons/arrow-down.svg";

import * as M from "./messages";
import * as S from "./styles";

const NOTIFICATION_DELAY = 3000;

type CartProps = {
  header?: JSX.Element;
};

export const Cart = ({ header = M.Basket }: CartProps) => {
  const {
    checkout: {
      lines,
      totalPrice,
      shippingPrice,
      subtotalPrice,
      id: checkoutId,
    },
    updateCheckout,
    checkout,
  } = useCheckoutContext<true>();
  const [open, setOpen] = useToggle();
  const isLgDown = useIsLgDown();
  const cartRef = useRef<HTMLDivElement>(null);
  const [cartHeight, setCartHeight] = useState(
    calculateRefChildrenHeight(cartRef)
  );
  const [mutation] = useCheckoutRemovePromoCodeMutation();
  const [giftCardNotifications, setGiftCardNotifications] = useState<{
    added: TGiftCard | null;
    addedCards: string[];
  }>({
    added: null,
    addedCards: checkout.giftCards?.map(({ id }) => id) ?? [],
  });
  const isMounted = useIsMounted();

  const handleDiscountRemove = async ({
    code,
    id,
  }: {
    code: string;
    id: string | undefined;
  }) => {
    const { data } = await mutation({
      variables: { checkoutId, ...(id ? { id } : { code }) },
    });

    if (
      !logMutationError(data.checkoutRemovePromoCode!.errors, {
        extra: { checkout },
        reason: "remove promo code",
      })
    ) {
      updateCheckout(data.checkoutRemovePromoCode!.checkout!);
    }
  };

  useEffect(() => {
    setCartHeight(calculateRefChildrenHeight(cartRef));
  }, [checkout, isLgDown]);

  useEffect(() => {
    if (!checkout.giftCards) return;
    const added =
      checkout.giftCards?.find(
        ({ id }) => !giftCardNotifications?.addedCards.includes(id)
      ) ?? null;

    const addedCards = checkout.giftCards?.map(({ id }) => id);

    setGiftCardNotifications({
      added,
      addedCards,
    });
  }, [checkout.giftCards]);

  useEffect(() => {
    if (giftCardNotifications.added) {
      const timeout = setTimeout(
        () => setGiftCardNotifications(p => ({ ...p, added: null })),
        NOTIFICATION_DELAY
      );

      return () => {
        if (isMounted.current) {
          clearTimeout(timeout);
        }
      };
    }
  }, [giftCardNotifications]);

  return (
    <S.Container>
      <S.Wrapper>
        <Grid onlyInnerSpacing verticalSpacing={2}>
          {isLgDown && (
            <GridColumn>
              <Spacer height={8} />
            </GridColumn>
          )}
          <GridColumn flex alignItems="center" justify="space-between">
            <S.Header>{header}</S.Header>
            <S.ArrowContainer>
              {(open || !isLgDown) && (
                <S.Edit wrapperTag href={paths.cart}>
                  {M.Edit}
                </S.Edit>
              )}
              {isLgDown && (
                <S.MobileArrow
                  {...getTestAttribute("mobileCartTrigger", "navigation")}
                  onClick={setOpen}
                  open={open}
                >
                  <ArrowSvg />
                </S.MobileArrow>
              )}
            </S.ArrowContainer>
          </GridColumn>
          {(open || !isLgDown) && (
            <S.Hr background={theme.colors.white} height={2} />
          )}
        </Grid>
        <Grid>
          <GridColumn>
            <S.Cart
              height={cartHeight}
              lines={lines}
              onDiscountRemove={handleDiscountRemove}
              open={open}
              quantityControls={false}
              ref={cartRef}
              shipping={shippingPrice}
              subtotal={subtotalPrice}
              total={totalPrice}
              {...extractDiscounts(checkout)}
            >
              <PromoCodeSection
                onFormToggle={() =>
                  setCartHeight(calculateRefChildrenHeight(cartRef))
                }
              />
            </S.Cart>
            {isLgDown && <GiftCard giftCard={giftCardNotifications.added} />}
          </GridColumn>
        </Grid>
      </S.Wrapper>
    </S.Container>
  );
};
