import { create } from "zustand";

type GoogleTagManagerStore = {
  previousPath: string | null;
  ready: boolean;
  setPreviousPath: (path: string | null) => void;
  setReadyState: (state: boolean) => void;
};

export const useGoogleTagManagerStore = create<GoogleTagManagerStore>()(
  set => ({
    previousPath: null,
    ready: false,
    setPreviousPath: (previousPath: string | null) => {
      set(() => ({
        previousPath,
      }));
    },
    setReadyState: ready => {
      set(() => ({
        ready,
      }));
    },
  })
);
