import { paths } from "@paths";

import {
  AuthFormLocationEnum,
  useAuthFormStore,
} from "@stores/useAuthFormStore";

import { useRouter } from "./useRouter";

export type useAuthType = {
  location: AuthFormLocationEnum;
};

export type handleAuthSuccessParams = {
  location: AuthFormLocationEnum;
  optionalFunction?: () => void;
  path?: string;
};

const getDefaultPathForLocation = (location: AuthFormLocationEnum) => {
  switch (location) {
    case AuthFormLocationEnum.ACCOUNT:
      return paths.accountLogin;
    case AuthFormLocationEnum.CHECKOUT:
      return paths.checkout;
    case AuthFormLocationEnum.FAVOURITES:
      return paths.accountFavourites;
    case AuthFormLocationEnum.ORDER:
      return paths.accountOrders;
    case AuthFormLocationEnum.PROFILE:
      return paths.account;
    case AuthFormLocationEnum.CLUB:
      return paths.clubHome;
    default:
      return paths.home;
  }
};

const getAuthPathForLocation = (location: AuthFormLocationEnum) => {
  switch (location) {
    case AuthFormLocationEnum.CHECKOUT:
      return paths.accountLoginCheckout;
    default:
      return paths.accountLogin;
  }
};

export const useAuthHook = () => {
  const { push, locale } = useRouter();

  const { setLocation, successPath, setSuccessPath } = useAuthFormStore();

  /**
   * If user is not authenticated, redirect to login page with the view based on the location
   */
  const redirectToLoginPage = ({
    location = AuthFormLocationEnum.DEFAULT,
  }: useAuthType) => {
    setLocation(location);

    const authPath = getAuthPathForLocation(location);

    push(authPath, authPath, {
      locale,
    });
  };

  /**
   * On successful login, redirect to the path passed in the params
   */
  const handleAuthSuccess = ({
    location,
    path,
    optionalFunction,
  }: handleAuthSuccessParams) => {
    const redirectPath =
      successPath ?? path ?? getDefaultPathForLocation(location);

    if (successPath) {
      setSuccessPath(null);
    }

    if (!redirectPath) {
      console.error(
        "A path or location must be provided to redirect the user successfully "
      );
      return;
    }

    if (optionalFunction) {
      optionalFunction();
    }

    push(redirectPath, redirectPath, {
      locale,
    });
  };

  return {
    handleAuthSuccess,
    redirectToLoginPage,
  };
};
