import { Button as ButtonComponent } from "@components/Button";
import { SpotifyLogo } from "@components/SpotifyLogo";
import { media, px2rem, styled } from "@styles";

export const ModalBody = styled.div`
  max-width: 400px;
  text-align: center;

  ${media.md.up} {
    padding: ${px2rem(60)} 0;
  }

  ${media.md.down} {
    margin-left: auto;
    margin-right: auto;
  }

  & > * {
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }

    &:not(button) {
      line-height: ${px2rem(35)};
    }
  }
`;

export const Description = styled.p`
  font-size: ${px2rem(18)};
  color: ${({ theme }) => theme.colors.dark};
  line-height: ${px2rem(28)} !important;
`;

export const ModalSpotifyLogo = styled(SpotifyLogo)`
  width: 60px;
  margin-bottom: 30px;
`;

export const Button = styled(ButtonComponent)`
  text-transform: none;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-flow: column;
  gap: 10px;
  width: 100%;
`;
