import { FieldName, FieldValues } from "react-hook-form";

export const generateFieldPrefix =
  <T extends FieldValues>(schemaPrefix: string | undefined) =>
  (name: string) =>
    (schemaPrefix ? `${schemaPrefix}.${name}` : name) as FieldName<T>;

enum TrackingSuppressionAttributes {
  HotJar = "data-hj-suppress",
}

export const trackingSuppressionAttributes = {
  [TrackingSuppressionAttributes.HotJar]: true,
};

// Function to extract default values from Yup schema
export const extractDefaultValues = (schema: any) =>
  Object.fromEntries(
    Object.entries(schema.fields).map(([key, field]: [string, any]) => [
      key,
      field?.spec.default,
    ])
  );
