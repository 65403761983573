import { px2rem, styled } from "@styles";

export const Container = styled.div<{ processing: boolean }>`
  display: flex;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.white};
  font-weight: 500;
  padding: ${px2rem(23, 0, 28)};
  gap: 5px;

  ${({ processing, theme }) => processing && `opacity: ${theme.opacity};`}
`;

export const DiscountWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: 10px;
  font-size: ${({ theme }) => theme.typography.sizes.small};
`;

export const DiscountAmount = styled.span`
  display: flex;
  flex-flow: row;
  gap: 5px;
`;

export const Remove = styled.span<{ processing: boolean }>`
  color: ${({ theme }) => theme.colors.orange};
  font-size: ${({ theme }) => theme.typography.sizes.small};
  cursor: pointer;

  ${({ processing }) => processing && `cursor: initial;`}
`;

export const GiftCard = styled.span`
  font-size: ${px2rem(13)};
  color: ${({ theme }) => theme.colors.medium};
  display: block;
  margin-top: ${px2rem(5)};
`;

export const Approve = styled.span`
  color: ${({ theme }) => theme.colors.turquoise};
  font-weight: 500;
  display: flex;
  align-items: center;
`;
