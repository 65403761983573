import { media, px2rem, styled } from "@styles";

import { SaleorHitProps } from "./SaleorHitComponent";

export const DEFAULT_MOBILE_COVER_SIZE = 150;

export const ProductCard = styled.div<Pick<SaleorHitProps, "textAlign">>`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  text-align: ${({ textAlign }) => textAlign || "unset"};

  a {
    text-decoration: none;
  }

  .carousel-product-link {
    display: flex;
    flex-flow: column;
    gap: 10px;
  }
`;

export const ThumbnailWrapper = styled.div<{
  coverSize?: number;
}>`
  position: relative;
  justify-content: center;
  width: ${DEFAULT_MOBILE_COVER_SIZE}px;
  overflow: hidden;
  aspect-ratio: 1;
  place-content: center;

  ${media.lg.up} {
    width: ${({ coverSize }) => `${coverSize}px`};
  }
`;

export const Name = styled.span<{ inNavigation: boolean; light: boolean }>`
  font-size: ${px2rem(13)};
  font-weight: 700;
  color: ${({ theme, light }) =>
    light ? theme.colors.white : theme.colors.black};

  ${media.md.up} {
    font-size: ${({ inNavigation }) =>
      inNavigation ? px2rem(13) : px2rem(16)};
  }
`;

export const Creators = styled.p<{ inNavigation: boolean; light: boolean }>`
  margin-top: 2px;

  ${media.md.down} {
    line-height: 1.25rem;
  }

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.dark};
    font-weight: 500;
    font-size: ${px2rem(12)};

    ${media.md.up} {
      font-size: ${({ inNavigation }) =>
        inNavigation ? px2rem(12) : px2rem(14)};
    }

    :hover {
      color: ${({ theme, light }) =>
        light ? theme.colors.white : theme.colors.black};
      text-decoration: underline;
    }
  }
`;

export const PricesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Price = styled.p`
  font-size: ${px2rem(12)};
  display: inline;

  ${media.md.up} {
    font-size: ${px2rem(14)};
  }
`;
