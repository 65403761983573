import { media, px2rem, styled } from "@styles";

import { Link } from "../Link";

export type Variant = "light" | "dark";

export const Thumbnail = styled(Link)<{ thumbnailSize: number }>`
  display: grid;
  aspect-ratio: 1;
  margin: 0 ${px2rem(14)} 0 0;
  padding: 0;
  place-content: center;
  overflow: hidden;
  ${({ thumbnailSize }) => `
    max-width: ${thumbnailSize}px;
    min-width: ${thumbnailSize}px;
    height: ${thumbnailSize}px;
  `}

  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    align-self: center;
  }
`;

export const Wrapper = styled.div<{
  $noCover: boolean;
  border: boolean;
  processing: boolean;
}>`
  padding: ${px2rem(20, 0)};
  display: flex;
  position: relative;

  ${({ border, theme }) =>
    border &&
    `
      border-bottom: 1px solid ${theme.colors.white};

      &:last-child {
        border-bottom-width: 2px;
      }
    `}

  ${({ processing, theme }) => processing && `opacity: ${theme.opacity};`}

  img {
    box-shadow: 0 0 0 1.5px
      ${({ $noCover, theme }) =>
        $noCover ? theme.colors.medium : "transparent"};
  }
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  overflow: hidden;
`;

export const Name = styled(Link)<{ variant: Variant }>`
  color: ${({ theme, variant }) =>
    variant === "light" ? theme.colors.white : theme.colors.black};
  font-size: ${px2rem(16)};
  text-decoration: none;
  font-weight: bold;
`;

export const Artist = styled(Link)<{ variant: Variant }>`
  color: inherit;
  text-decoration: none;
  font-weight: 500;

  &:hover {
    color: ${({ theme, variant }) =>
      variant === "light" ? theme.colors.white : theme.colors.black};
  }
`;

export const Price = styled.p`
  color: ${({ theme }) => theme.colors.white};
  text-align: right;
  font-weight: 700;

  a {
    display: block;
    width: ${px2rem(64)};
  }
`;

export const Info = styled.div<{ variant: Variant }>`
  font-size: ${px2rem(13)};
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &,
  p {
    color: ${({ theme, variant }) =>
      variant === "light" ? theme.colors.white : theme.colors.black};
  }

  & > * {
    overflow: hidden;
    line-height: 20px;
    white-space: nowrap;
    max-width: 100%;
    text-overflow: ellipsis;
    display: inline;
  }
`;

export const ActionWrapper = styled.div`
  display: flex;
  flex-flow: column-reverse;
  align-items: flex-end;

  ${media.md.up} {
    flex-flow: row;
  }
`;
