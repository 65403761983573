import { MutableRefObject, useEffect, useRef } from "react";

export const useOnUpdateRef = <T>(updater: T): MutableRefObject<T> => {
  const updaterRef = useRef(updater);

  useEffect(() => {
    updaterRef.current = updater;
  }, [updater]);

  return updaterRef;
};
