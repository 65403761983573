import React from "react";

import { ssrMode } from "@config";

import { Footer } from "./components/Footer";
import { Header } from "./components/Header";

import * as S from "./styles";

type AuthLayoutProps = {
  children?: React.ReactNode;
};

const dark = false;

export const AuthLayout = ({ children }: AuthLayoutProps) =>
  ssrMode ? null : (
    <S.Wrapper>
      <S.Column>
        <Header dark={dark} />
        <S.Content>{children}</S.Content>
        <Footer dark={dark} />
      </S.Column>
    </S.Wrapper>
  );
