import { useApolloClient } from "@apollo/client";
import { User } from "@gqlTypes/User";
import { PaymentMethod as OrderApiPaymentMethod } from "@graphql/orderAppGqlTypes/PaymentMethod";
import { getUserData } from "@graphql/utils";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";

import { Loader } from "@components/Loader";
import { useCheckoutContext } from "@hooks/providers";
import { useIsMounted } from "@hooks/useIsMounted";
import { getOrderAppPaymentGetewayId } from "@utils";
import { Error } from "@utils/typescript";

import { CheckoutFlowContext, Context, initialContext } from "./context";
import { useCollectionPointsForCheckoutQuery } from "./queries.orderApp";
import {
  CHECKOUT_STEPS,
  CheckoutState,
  CheckoutStep,
  PaymentMethod,
  SetCheckoutState,
} from "./types";
import { getFlowInitialState } from "./utils";

type CheckoutFlowProps = Pick<CheckoutFlowContext, "enabledCountries">;

interface ProviderProps extends CheckoutFlowProps {
  children?: React.ReactNode;
  defaultCard: OrderApiPaymentMethod;
}

export const Provider = ({
  children,
  defaultCard,
  ...config
}: ProviderProps) => {
  const { checkout } = useCheckoutContext<true>();
  const apolloClient = useApolloClient();
  const { locale, asPath } = useRouter();
  const isMounted = useIsMounted();
  const [user, setUser] = useState<User | null>(null);
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [state, setState_] = useState<CheckoutState>(initialContext.state);

  const { data: collectionPointsData, loading: collectionPointsLoading } =
    useCollectionPointsForCheckoutQuery(checkout.id);

  const setState = (state: SetCheckoutState) =>
    setState_(p => ({ ...p, ...state }));

  const setStep = (step: CheckoutStep) =>
    setState_(p => ({ ...p, type: step }));

  const isStep = (step: CheckoutStep) => state.type === step;

  const isStepDone = (nextStep: CheckoutStep) =>
    CHECKOUT_STEPS.indexOf(state.type) >= CHECKOUT_STEPS.indexOf(nextStep);

  const setProcessing = (isProcessing: boolean) =>
    setState_(s => ({ ...s, isProcessing }));

  const setErrors = (errors: Error[]) => setState_(s => ({ ...s, errors }));

  const setPaymentMethod = (paymentMethod: PaymentMethod | null) =>
    setState_(p => ({ ...p, paymentMethod }));

  const isClickAndCollect = () => state.deliveryOption === "clickAndCollect";

  useEffect(() => {
    (async () => {
      const user = await getUserData(apolloClient, locale!);

      if (isMounted.current) {
        setUser(user);
        setState_(getFlowInitialState(checkout, user));
      }
    })();
  }, [asPath]);

  return collectionPointsLoading ? (
    <Loader verticalCenter />
  ) : (
    <Context.Provider
      value={{
        ...config,
        availableCollectionPoints:
          collectionPointsData?.collectionPointsForCheckout ?? [],
        defaultCard,
        isClickAndCollect,
        isPaid: checkout.totalPrice.gross.amount === 0,
        isStep,
        isStepDone,
        paymentGateway: getOrderAppPaymentGetewayId(
          checkout.availablePaymentGateways
        ),
        setErrors,
        setPaymentMethod,
        setProcessing,
        setState,
        setStep,
        setUser,
        state,
        user,
      }}
    >
      {children}
    </Context.Provider>
  );
};
