import { initializeSaleor } from "@graphql";
import { useRouter } from "next/router";
import { useEffect } from "react";

import { getChannel } from "@providers/ConfigProvider/helpers";
import {
  Channel,
  CHANNELS,
  DEFAULT_COUNTRY,
} from "@providers/TranslationProvider";

export const saleorClient = initializeSaleor(DEFAULT_COUNTRY.channel);

export const useSaleor = () => {
  const { locale } = useRouter();

  useEffect(() => {
    const currentChannel = getChannel(locale);
    const channels = CHANNELS.map(({ channel }) => channel);

    if (
      channels.includes(saleorClient.config.channel as Channel) &&
      saleorClient.config.channel !== currentChannel
    ) {
      saleorClient.config.setChannel(locale!);
    }
  }, [locale]);

  return saleorClient;
};
