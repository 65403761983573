import { media, noPrint, px2rem, responsive, styled, theme } from "@styles";

export type HeaderProps = { dark: boolean };

export const Wrapper = styled.div<HeaderProps>`
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  padding: ${px2rem(31)} ${px2rem(35)} ${px2rem(10)};

  ${responsive("margin-bottom", px2rem(42), px2rem(45))}
  ${noPrint}


  ${({ theme, dark }) =>
    dark &&
    `
    color: ${theme.colors.white};
    background-color: ${theme.colors.black};
  `}


  h2 {
    ${responsive("font-size", px2rem(16), theme.typography.sizes.large)}
  }

  svg path {
    fill: ${({ theme, dark }) =>
      dark ? theme.colors.white : theme.colors.black};
  }

  ${media.md.up} {
    padding: 2rem 2.25rem;
  }
`;

export const HomeLink = styled.a<HeaderProps>`
  color: ${({ theme, dark }) =>
    dark ? theme.colors.black : theme.colors.white};
  text-decoration: none;
  display: flex;
  align-items: center;
  margin-left: 20px;
  gap: 10px;
  opacity: 0.9;
  transition: opacity 0.3s ease-in-out;

  &:hover {
    opacity: 1;
  }

  svg {
    width: 16px;
    height: 16px;
    path {
      stroke: ${({ theme, dark }) =>
        dark ? theme.colors.black : theme.colors.white} !important;
    }
  }

  ${media.md.up} {
    margin-left: 40px;
  }
`;
