import { useMetadataDelete, useMetadataUpdate } from "@graphql/mutations";
import { logMutationError } from "@graphql/utils";
import { paths } from "@paths";
import { useAuth, useAuthState } from "@saleor/sdk";
import debounce from "lodash/debounce";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { MetadataKey, spotifyRoughTradeId } from "@config";
import { useConfigContext } from "@hooks/providers";
import { useRouter } from "@hooks/useRouter";
import { ExchangeToken } from "@providers/Spotify/types";
import { captureMessage } from "@utils/errors";

import { SpotifyContext, SpotifyProviderContext } from "./context";
import {
  checkIfUserFollowsArtists,
  exchangeSpotifyToken,
  extractRefreshToken,
  followArtist,
  getUserData,
  getUserFollowedArtists,
  refreshSpotifyToken,
  storeUserSpotifyData,
  unFollowArtist,
} from "./helpers";

const TOKEN_EXPIRATION_THRESHOLD = 60 * 1000;

type ProviderState = Pick<
  SpotifyContext,
  "token" | "processing" | "followedArtists" | "user" | "isFollowingRT"
>;

const DEFAULT_STATE: ProviderState = {
  followedArtists: [],
  isFollowingRT: false,
  processing: false,
  token: null,
  user: null,
};

type SpotifyProviderProps = {
  children: React.ReactNode;
};

export const SpotifyProvider = ({ children }: SpotifyProviderProps) => {
  const { asPath, push } = useRouter();
  const { channel } = useConfigContext();
  const { user, authenticated, authenticating } = useAuthState();
  const { refreshToken: refreshUserToken } = useAuth();
  const [ctx, setCtx] = useState<ProviderState>({
    ...DEFAULT_STATE,
    processing: authenticating,
  });
  const [updateMetadata] = useMetadataUpdate();
  const [deleteMetadata] = useMetadataDelete();

  const updateContext = (context: Partial<ProviderState>) =>
    setCtx(ctx => ({ ...ctx, ...context }));

  const getRefreshToken = () => extractRefreshToken(user);

  const isConnected = useMemo(() => !!getRefreshToken(), [user]);

  const updateUserRefreshToken = useCallback(
    debounce(async (token: ExchangeToken) => {
      if (authenticated) {
        await storeUserRefreshToken(token.refresh_token);
        await refreshUserToken(true);
      }
    }, 1000),
    [authenticated]
  );

  const storeUserRefreshToken = async (token: string) => {
    const { data } = await updateMetadata({
      variables: {
        id: user!.id,
        input: [{ key: MetadataKey.SPOTIFY_REFRESH_TOKEN, value: token }],
      },
    });
    logMutationError(data?.updateMetadata?.errors ?? [], {
      extra: { user },
      reason: "update user spotify refresh token",
    });
  };

  const clearUserRefreshToken = async () => {
    updateContext({ followedArtists: [], token: null, user: null });

    if (!authenticated) {
      return;
    }

    const { data } = await deleteMetadata({
      variables: {
        id: user!.id,
        keys: [MetadataKey.SPOTIFY_REFRESH_TOKEN],
      },
    });
    logMutationError(data?.deleteMetadata?.errors ?? [], {
      extra: { user },
      reason: "delete user spotify refresh token",
    });
    await refreshUserToken(true);
  };

  /**
   * Refreshes existing token.
   * @see https://developer.spotify.com/documentation/general/guides/authorization-guide/#6-requesting-a-refreshed-access-token
   */
  const refreshToken = useCallback(
    async (
      refreshToken?: string,
      callback?: (accessToken: string) => Promise<Partial<ProviderState>>,
      delay: number = 0
    ) =>
      new Promise(resolve => {
        setTimeout(async () => {
          updateContext({ processing: true });

          const data = await refreshSpotifyToken(
            refreshToken || ctx!.token!.refresh_token
          );

          if ("error" in data) {
            captureMessage("Failed to refresh spotify token", {
              data,
              user,
            });
            updateContext({ processing: false, token: null });

            await Promise.all([
              clearUserRefreshToken(),
              push({
                pathname: paths.spotifyAuthorize,
                query: { from_url: asPath },
              }),
            ]);
          } else {
            await updateUserRefreshToken(data);

            let extra;
            if (callback) {
              extra = await callback(data.access_token);
            }
            updateContext({ processing: false, token: data, ...extra });
          }

          resolve(true);
        }, delay);
      }),
    [user]
  );

  /**
   * Exchanges code returned via spotify auth flow and exchanges it for an access token.
   * @see https://developer.spotify.com/documentation/general/guides/authorization-guide/#4-your-app-exchanges-the-code-for-an-access-token
   */
  const exchangeToken: SpotifyContext["exchangeToken"] = async (
    code,
    codeVerifier
  ) => {
    updateContext({ processing: true });
    const data = await exchangeSpotifyToken(code, codeVerifier);

    if ("error" in data) {
      captureMessage("Failed to exchange spotify token", { data, user });
      updateContext({ processing: false });
      return false;
    }
    if (!(await getUserData(data.access_token))) {
      captureMessage("Failed to use exchanged token", { data, user });
      return false;
    }

    await updateUserRefreshToken(data);
    updateContext({ processing: false, token: data });

    return true;
  };

  /**
   * Automatically refresh spotify token including it's expiration time.
   * Also include default threshold to avoid situations when spotify communication might
   * not have permission due to ending expiration.
   */
  const handleTokenExpiration = (token: NonNullable<SpotifyContext["token"]>) =>
    setTimeout(
      () => refreshToken(token.refresh_token),
      token.expires_in * 1000 - TOKEN_EXPIRATION_THRESHOLD
    );

  const follow: SpotifyContext["follow"] = async (id, type) => {
    const token = ctx.token?.access_token;
    const following = ctx.followedArtists;

    if (!token) {
      return {
        following,
        status: {
          ok: false,
        },
      };
    }

    const success = await followArtist(token, [id], type);

    if (!success) {
      return {
        following,
        status: {
          ok: false,
        },
      };
    }

    const newFollowing = success ? [...following, id] : following;
    const isFollowingRT = id === spotifyRoughTradeId[channel];

    updateContext({
      followedArtists: newFollowing,
      isFollowingRT,
      processing: false,
    });

    return {
      following: newFollowing,
      status: {
        ok: true,
      },
    };
  };

  const unFollow: SpotifyContext["unFollow"] = async id => {
    const following = ctx.followedArtists;
    const token = ctx.token?.access_token;
    if (!token) {
      return following;
    }

    const success = await unFollowArtist(token, [id]);
    const newFollowing = success ? following.filter(f => f !== id) : following;

    updateContext({ followedArtists: newFollowing, processing: false });
    return newFollowing;
  };

  const fetchUserSpotifyData = async (
    accessToken: string
  ): Promise<
    Pick<ProviderState, "user" | "followedArtists" | "isFollowingRT">
  > => {
    const [user, artists, isFollowingRT] = await Promise.all([
      getUserData(accessToken),
      getUserFollowedArtists(accessToken),
      checkIfUserFollowsArtists(accessToken, [spotifyRoughTradeId[channel]], {
        type: "user",
      }),
    ]);

    return {
      followedArtists: artists.map(({ id }) => id),
      isFollowingRT: isFollowingRT?.[0],
      user,
    };
  };

  useEffect(() => {
    (async () => {
      if (!ctx.token && authenticated && !ctx.processing) {
        const token = getRefreshToken();

        if (token) {
          await refreshToken(token, fetchUserSpotifyData, 500);
        }
      } else if (ctx.processing) {
        updateContext({ processing: false });
      }
    })();
  }, [authenticated]);

  useEffect(() => {
    (async () => {
      if (ctx.token && !ctx.user && !ctx.processing) {
        const accessToken = ctx.token!.access_token;
        updateContext({ processing: true });

        const spotifyData = await fetchUserSpotifyData(accessToken);
        updateContext({
          processing: false,
          ...spotifyData,
        });
      }
    })();
  }, [ctx.token, ctx.user]);

  useEffect(() => {
    (async () => {
      if (ctx.token && ctx.user && user) {
        await storeUserSpotifyData(user, ctx.user, updateMetadata);
        await updateUserRefreshToken(ctx.token);
      }
    })();
  }, [ctx.token, ctx.user, user]);

  useEffect(() => {
    if (ctx.token) {
      const timeout = handleTokenExpiration(ctx.token);
      return () => clearTimeout(timeout);
    }
  }, [ctx.token]);

  useEffect(() => {
    // Handle logout
    if (!user && ctx.token) {
      updateContext(DEFAULT_STATE);
    }
  }, [user]);

  return (
    <SpotifyProviderContext.Provider
      value={{
        clearUserRefreshToken,
        exchangeToken,
        follow,
        isConnected,
        isPremiumAccount: ctx?.user?.product === "premium",
        unFollow,
        ...ctx,
      }}
    >
      {children}
    </SpotifyProviderContext.Provider>
  );
};
