import { useMetadataUpdate } from "@graphql";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAuthState } from "@saleor/sdk";
import React, { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { defineMessage, useIntl } from "react-intl";

import { CheckboxField } from "@components/CheckboxField";
import { FormProvider } from "@components/FormProvider";
import { MetadataKey } from "@config";
import { useIsMdUp } from "@hooks/responsive";
import { useIsMounted } from "@hooks/useIsMounted";
import { useRouter } from "@hooks/useRouter";
import { useToggle } from "@hooks/useToggle";
import { createUserInKlaviyo } from "@integrations/klaviyo/api";
import { commonMessages } from "@messages/common";
import { getChannel } from "@providers/ConfigProvider/helpers";
import { extractDefaultValues } from "@utils/forms";
import { getTestAttribute } from "@utils/misc";

import { NewsletterSchema, newsletterSchema } from "./schema";

import * as S from "./styles";

const newsletterSentMessage = defineMessage({
  defaultMessage: "Sent!",
  id: "/xwYHP",
});

export const NewsletterInput = () => {
  const intl = useIntl();
  const isMounted = useIsMounted();
  const isMdUp = useIsMdUp();
  const [isProcessing, toggleProcessing] = useToggle();
  const [completed, setCompleted] = useState(false);
  const { authenticated, user } = useAuthState();
  const { formatMessage } = useIntl();
  const [updateMutation] = useMetadataUpdate();
  const { locale } = useRouter();
  const channel = getChannel(locale).toUpperCase();

  const schema = newsletterSchema(formatMessage);

  const formContext = useForm<NewsletterSchema>({
    defaultValues: extractDefaultValues(schema),
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
  });

  const resetForm = () => {
    setCompleted(true);
    formContext.setValue("email", intl.formatMessage(newsletterSentMessage));

    setTimeout(() => {
      if (isMounted.current) {
        setCompleted(false);
        formContext.setValue("email", "");
      }
    }, 3000);
  };

  const handleSubmit: SubmitHandler<NewsletterSchema> = async data => {
    toggleProcessing();

    if (authenticated && data.email === user?.email) {
      await updateMutation({
        variables: {
          id: user?.id!,
          input: [
            {
              key: MetadataKey.NEWSLETTER_CONSENT,
              value: "true",
            },
          ],
        },
      });
    }

    await createUserInKlaviyo(data.email, channel);
    toggleProcessing();
    resetForm();
  };

  return (
    <FormProvider
      {...formContext}
      name="footerNewsletter"
      onSubmit={handleSubmit}
    >
      <S.FormWrapper>
        <S.CheckboxWrapper>
          <CheckboxField name="marketingConsent">
            {formatMessage(commonMessages.marketingConsent)}
          </CheckboxField>
          <S.InputErrors>
            {formContext.formState.errors.marketingConsent?.message}
          </S.InputErrors>
        </S.CheckboxWrapper>
        <S.InputWrapper>
          <S.SentButton
            $completed={completed}
            aria-label="Sign up for newsletter"
            disabled={isProcessing}
          >
            <S.SentButtonIcon $completed={completed} />
          </S.SentButton>

          <S.Input
            $completed={completed}
            aria-label="Newsletter e-mail input"
            autoComplete="off"
            disabled={isProcessing}
            placeholder={formatMessage(
              isMdUp ? commonMessages.email : commonMessages.emailMobile
            )}
            {...formContext.register("email")}
            type="text"
            {...getTestAttribute("input", "footerNewsletter")}
          />
        </S.InputWrapper>
        <S.InputErrors>
          {formContext.formState.errors.email?.message}
        </S.InputErrors>
      </S.FormWrapper>
    </FormProvider>
  );
};
