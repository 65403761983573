import React from "react";
import { FormattedMessage } from "react-intl";

import * as S from "./styles";

export const FormStepIndicator = (step: number, max: number) => (
  <FormattedMessage
    defaultMessage="Step {value}"
    id="5hJafh"
    values={{
      value: (
        <S.StepWrapper>
          <S.StepIndicator>{step}</S.StepIndicator>/<span>{max}</span>
        </S.StepWrapper>
      ),
    }}
  />
);
