import React from "react";

import { ErrorPage } from "@components/ErrorPage"; // Adjust the import path as necessary
import { Seo } from "@components/Seo";

import * as M from "./messages";

// ... rest of your InternalServerErrorView component

type InternalServerErrorViewProps = {
  clearError?: () => void;
  errorId?: string | number | null;
  isModalView?: boolean;
  statusCode?: number;
};

export const InternalServerErrorView = ({
  statusCode,
  errorId,
  clearError,
  isModalView = false,
}: InternalServerErrorViewProps) => (
  <>
    <Seo nofollow noindex />
    <ErrorPage
      clearError={clearError}
      isModalView={isModalView}
      subtitle={
        <>
          {M.SubTitle(statusCode)}
          {errorId && M.ErrorId(errorId)}
        </>
      }
      title={M.ServerErrorMessage}
    />
  </>
);
