import { px2rem, styled } from "@styles";

export const Container = styled.div<{ isTotal: boolean }>`
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  font-size: ${px2rem(20)};
  justify-content: space-between;
  font-weight: bold;
  padding: ${px2rem(10, 0, 10)};

  ${({ isTotal, theme }) =>
    !isTotal &&
    `
    color: ${theme.colors.medium};
    font-size: ${px2rem(15)};
    font-weight: 500;
    padding: 0;
  `}
`;
