import { getElementOffset } from "@utils/misc";
import { RefHook } from "@utils/typescript";

export const isFunction = (obj: any): obj is Function =>
  obj &&
  {}.toString.call(obj) === "[object Function]" &&
  typeof obj === "function";

export const isEmptyObject = (obj: {}) => !Object.keys(obj).length;

type Sort = (a: string | null, b: string | null) => number;

export const sortDesc: Sort = (a, b) => {
  if (a! > b!) {
    return -1;
  }
  if (b! > a!) {
    return 1;
  }
  return 0;
};

export const sortAsc: Sort = (a, b) => {
  if (a! < b!) {
    return -1;
  }
  if (b! < a!) {
    return 1;
  }
  return 0;
};

export const count = <T = string | number>(needle: T, list: T[]) =>
  list.reduce((n, val) => {
    if (val === needle) {
      ++n;
    }
    return n;
  }, 0);

export const range = (n: number) => [...Array(n).keys()];

export const scrollToRef = (ref?: RefHook<any>, delay = 250) =>
  setTimeout(() => {
    window?.scrollTo({
      behavior: "smooth",
      top: ref?.current ? getElementOffset(ref.current).top : 0,
    });
  }, delay);
