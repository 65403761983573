// Code generated by Slice Machine. DO NOT EDIT.

import dynamic from "next/dynamic";

export const components = {
  accordion: dynamic(() => import("./Accordion")),
  banner_item: dynamic(() => import("./BannerItem")),
  blog: dynamic(() => import("./Blog")),
  carousel: dynamic(() => import("./Carousel")),
  checkout_upsell: dynamic(() => import("./CheckoutUpsell")),
  club_hero: dynamic(() => import("./ClubHero")),
  club_tier_benefits: dynamic(() => import("./ClubTierBenefits")),
  club_tier_format: dynamic(() => import("./ClubTierFormat")),
  countdown: dynamic(() => import("./Countdown")),
  dice_events: dynamic(() => import("./DiceEvents")),
  donation_notification: dynamic(() => import("./DonationNotification")),
  embed: dynamic(() => import("./Embed")),
  full_width_banner: dynamic(() => import("./FullWidthBanner")),
  hero_slider: dynamic(() => import("./HeroSlider")),
  image_grid: dynamic(() => import("./ImageGrid")),
  image_stack: dynamic(() => import("./ImageStack")),
  index_pages: dynamic(() => import("./IndexPages")),
  list_image: dynamic(() => import("./ListImage")),
  notifcation: dynamic(() => import("./Notifcation")),
  reasons_to_buy: dynamic(() => import("./ReasonsToBuy")),
  search_featured_collections: dynamic(
    () => import("./SearchFeaturedCollections")
  ),
  search_featured_results: dynamic(() => import("./SearchFeaturedResults")),
  section_carousel: dynamic(() => import("./SectionCarousel")),
  section_choice: dynamic(() => import("./SectionChoice")),
  section_grid: dynamic(() => import("./SectionGrid")),
  section_testimonials: dynamic(() => import("./SectionTestimonials")),
  spotify_banner: dynamic(() => import("./SpotifyBanner")),
  subscription_step: dynamic(() => import("./SubscriptionStep")),
  subscription_type: dynamic(() => import("./SubscriptionType")),
  teaser: dynamic(() => import("./Teaser")),
  text_list: dynamic(() => import("./TextList")),
  text_only_banner: dynamic(() => import("./TextOnlyBanner")),
};
