export enum EventType {
  ADD_TO_CART = "add_to_cart",
  ADD_TO_FAVOURITE = "add_to_favourite",
  CHECKOUT = "checkout",
  HITS_VIEWED = "Hits Viewed",
  PAGE_VIEW = "page_view",
  PURCHASE = "purchase",
  REMOVE_FROM_CART = "remove_from_cart",
  REMOVE_FROM_FAVOURITE = "remove_from_favourite",
  SELECT_ITEM = "select_item",
  SELECT_PROMOTION = "select_promotion",
  USER = "user",
  VIEW_ITEM = "view_item",
  VIEW_ITEM_LIST = "view_item_list",
  VIEW_PROMOTION = "view_promotion",
}

export type CheckoutType = "anonymous" | "preorder" | "normal";

export type PurchaseType = "upfront" | "deferred";

export enum PaymentTypeEnum {
  DEFERRED = "deferred",
  UPFRONT = "upfront",
}
