import styled from "styled-components";

import { media, Theme } from "@styles";
import { dynamicFullHeight } from "@utils/navigation";
import { LayoutProps, PageContentLayout } from "@utils/typescript";

export const PageLayoutWrapper = styled.div<LayoutProps>`
  width: 100%;
  align-items: stretch;
  height: ${() => dynamicFullHeight("mobile", [])};
  display: flex;
  flex-direction: column;
  position: relative;
  top: 0;
  background-color: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};

  ${media.md.up} {
    height: ${() => dynamicFullHeight("desktop", [])};
  }
`;

export const ContentWrapper = styled.main<PageContentLayout>`
  flex: 1 1 0;
  z-index: ${({ isFilterActive }) => (isFilterActive ? "auto" : 0)};
  background-color: ${({ variant, theme }) =>
    variantBackground(variant, theme)};
`;

const variantBackground = (variant: string | undefined, theme: Theme) => {
  switch (variant) {
    case "dark":
      return theme.colors.black;
    case "product-page":
      return theme.colors.light;
    case "club":
      return theme.colors.yellow;
    default:
      return "transparent";
  }
};
