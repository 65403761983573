import { media, styled } from "@styles";

export const CollectionSliderWrapper = styled.article`
  overflow-x: auto;
  position: relative;
`;

export const Slider = styled.input<{
  active: boolean;
  light: boolean;
  variant?: string;
}>`
  display: "none";

  appearance: none;
  cursor: inherit;
  outline: none;
  width: 100%;
  height: 3px;
  margin: 19px 0 29px;
  background-color: ${({ active, theme, light }) => {
    const color = light ? theme.colors.white : theme.colors.black;
    return active ? `${color}50` : color;
  }};

  ${media.md.up} {
    display: block;
    margin: 20px 0 40px;
  }

  &::-webkit-slider-thumb {
    background-clip: none;
    box-shadow: none;
    appearance: none;
    width: 25%;
    height: 3px;
    background-color: ${({ theme, light }) =>
      light ? theme.colors.white : theme.colors.black};
    cursor: inherit;
  }
`;

export const ProductsWrapper = styled.div`
  display: flex;
  padding: 0;
`;

export const Products = styled.div<{
  maxWidth: number;
  offset: number;
  productsNumber: number;
}>`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 14px;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    display: none;
  }

  > * {
    scroll-snap-align: start;
    scroll-snap-stop: always;
    max-width: ${({ maxWidth }) => maxWidth}px;
    flex-basis: ${({ maxWidth }) => maxWidth}px;
    cursor: pointer;

    &:last-of-type:after {
      width: ${({ offset }) => offset}px;
      content: "";
      display: block;
      left: 100%;
      position: relative;
      top: -100%;
    }
  }

  ${media.lg.up} {
    padding-left: ${({ offset }) => offset}px;
    padding-right: ${({ offset }) => offset}px;
    scroll-padding-left: ${({ offset }) => offset}px;

    > * {
      max-width: 290px;
      flex-basis: 290px;
    }
  }
`;

export const Arrows = styled.div<{ offset: number }>`
  position: absolute;
  z-index: 9;
  inset: 0;
  display: none;
  justify-content: space-between;
  align-items: center;
  pointer-events: none;
  padding: 4px;

  ${media.lg.up} {
    display: flex;
  }
`;

export const Arrow = styled.button<{ showArrow: boolean }>`
  visibility: ${({ showArrow }) => (showArrow ? "initial" : "hidden")};
  pointer-events: ${({ showArrow }) => (showArrow ? "all" : "none")};
  padding: 13px 17px;
  background-color: ${({ theme }) => theme.colors.black};
  cursor: pointer;
  border: 0 none;

  svg {
    fill: ${({ theme }) => theme.colors.white};
  }
`;
