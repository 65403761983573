import { getCookie } from "cookies-next";
import dynamic from "next/dynamic";
import React, { useMemo } from "react";

import { AuthLayout } from "@layouts/AuthLayout";
import {
  CheckoutLayout,
  CheckoutWithCartLayout,
} from "@layouts/CheckoutLayout";
import { ClubFormLayout } from "@layouts/ClubFormLayout";
import { PageLayout } from "@layouts/PageLayout";

export type Layout =
  | "checkoutWithCart"
  | "checkout"
  | "default"
  | "clubForm"
  | "auth";

const layouts: { [key in Layout]: React.FC<any> } = {
  auth: AuthLayout,
  checkout: CheckoutLayout,
  checkoutWithCart: CheckoutWithCartLayout,
  clubForm: ClubFormLayout,
  default: PageLayout,
};

type LayoutWrapperProps = { children: React.ReactElement };

export const LayoutSwitcher = (props: LayoutWrapperProps) => {
  const isInMaintenanceMode = getCookie("isInMaintenanceMode");
  const key: Layout =
    (props.children!.type as any).layout || props.children.props?.layout;
  const { layoutProps } = props.children!.type as any;
  const Layout = useMemo(() => layouts[key], [key]);

  if (isInMaintenanceMode === "true") {
    return <>{props.children}</>;
  }

  const layoutContent = (
    <>
      {props.children}
      <DynamicLanguagePreferencesModal />
    </>
  );

  if (Layout) {
    return (
      <Layout {...layoutProps} {...props}>
        {layoutContent}
      </Layout>
    );
  }

  return (
    <PageLayout {...layoutProps} {...props}>
      {layoutContent}
    </PageLayout>
  );
};

const DynamicLanguagePreferencesModal = dynamic<{}>(
  () =>
    import(
      "../components/LanguagePreferencesModal/LanguagePreferencesModal"
    ).then(mod => mod.LanguagePreferencesModalOverlay),
  {
    ssr: false,
  }
);
