import { RefObject } from "react";

import { defaultLocale } from "@config";
import { DEFAULT_LOCALE, Locale } from "@providers/TranslationProvider";

export const capitalize = (string: string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const DATA_TEST_ATTRIBUTE = "data-testid";

/**
 * @param name - follow convention: typeActionExtraInformation, like buttonLogin, linkLogout
 * @param context - e.g. navigation, footer, account
 */
export const getTestAttribute = (
  name: string,
  context: string
): { [DATA_TEST_ATTRIBUTE]: string } | null =>
  name
    ? {
        [DATA_TEST_ATTRIBUTE]: `${name}-${context}`,
      }
    : null;

/**
 * Truncate string to desired length
 */
export function truncate(
  str: string,
  options?: Partial<{
    ending: string;
    length: number;
    useWordBoundary: boolean;
  }>
) {
  const { length, useWordBoundary, ending } = {
    ...options,
    ending: "...",
    length: 150,
    useWordBoundary: true,
  };

  if (str?.length <= length) {
    return str;
  }

  const subString = str.substr(0, length - 1);

  return `${
    useWordBoundary
      ? subString.substr(0, subString.lastIndexOf(" "))
      : subString
  } ${ending}`;
}

export const buildVariablesFromUrl = (url: string) => {
  const parsedUrl = new URL(url);
  const search = new URLSearchParams(parsedUrl.search);

  return {
    filter: {
      productTypes: search.get("product_type"),
    },
  };
};

/**
 * Calculates element offset from the window top & left edges.
 */
export function getElementOffset(el: HTMLElement) {
  const rect = el.getBoundingClientRect();
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return { left: rect.left + scrollLeft, top: rect.top + scrollTop };
}

export const calculateRefChildrenHeight = (ref: RefObject<HTMLDivElement>) => {
  if (!ref.current) {
    return 0;
  }
  const children = ref.current!.children!;
  const height = Array.from(children)
    .map(child => child.getBoundingClientRect().height)
    .reduce((a, b) => a + b, 0);
  return height;
};

export const duplicateArray = <T>(arr: T[], repeats: number) =>
  ([] as T[]).concat(...Array.from({ length: repeats }, () => arr));

export const scrollToTopRef = (
  ref: React.RefObject<HTMLDivElement>,
  cb?: any
) => {
  if (ref.current) {
    ref.current.scroll({
      behavior: "smooth",
      top: 0,
    });
  }

  if (cb) {
    cb();
  }
};

export const getAvailableLocales = (locales?: string[]) => {
  if (!locales) {
    return [DEFAULT_LOCALE] as Locale[];
  }
  return locales!.filter(locale => locale !== defaultLocale) as Locale[];
};

export const parsePathWithSelectors = (
  path: string,
  selectors: string[]
): Record<string, string> => {
  const values = path.split("/");

  const params = selectors.reduce((acc, selector, index) => {
    if (values[index]) {
      acc[selector] = values[index];
    }
    return acc;
  }, {} as Record<string, string>);

  return params;
};

type PathProps<T> = {
  locale?: string;
  params: T;
};

export type FetchPathProps = {
  availableLocales: string[];
  excludePageByUID?: string[];
  pageSpecificFunction: (locale: string) => Promise<any>;
  selectors: string[];
};
export const fetchAllPaths = async <T>({
  availableLocales,
  pageSpecificFunction,
  selectors,
  excludePageByUID = [],
}: FetchPathProps) => {
  const paths: Array<PathProps<T>> = [];

  await Promise.allSettled(
    availableLocales.map(async (locale: string) => {
      const data: { paths: string[] } = await pageSpecificFunction(locale);
      if (data && data.paths.length) {
        const filteredPaths = data.paths.filter(
          uid => !excludePageByUID.includes(uid as unknown as string)
        );
        const transformedPaths = filteredPaths.map(path => {
          const params = parsePathWithSelectors(
            path,
            selectors
          ) as unknown as T;

          return {
            locale,
            params,
          };
        });
        paths.push(...transformedPaths);
      }
    })
  );

  return paths;
};

export const isNumberEven = (num: number) => num % 2 === 0;
