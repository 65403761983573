import { useEffect } from "react";

import { useConfigContext } from "@hooks/providers";

import { CheckoutContext } from "./types";

export const useClearCheckoutBetweenRegion = (
  checkout: CheckoutContext<false>["checkout"],
  processing: boolean,
  clear: CheckoutContext["clear"]
) => {
  const { channel } = useConfigContext();

  useEffect(() => {
    if (checkout && !processing && channel !== checkout.channel.slug) {
      clear();
    }
  }, [checkout?.channel, channel]);
};
