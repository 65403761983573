import { styled } from "@styles";

import { BadgeVariant } from "./components/ProductBadge";

export const BadgeWrapper = styled.div<{ variant?: BadgeVariant }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${({ variant }) =>
    variant === "productPage" ? "flex-end" : "flex-start"};
  column-gap: 8px;
  flex-wrap: wrap;
`;
