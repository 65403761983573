import { media, px2rem, styled } from "@styles";

export const TrackName = styled.p<{ isActive?: boolean }>`
  max-width: calc(100vw - 150px);
  color: ${({ isActive, theme }) =>
    isActive ? theme.colors.gold : theme.colors.light};
  font-weight: 500;
  font-size: ${px2rem(14)};
  line-height: 1.4;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${media.lg.up} {
    max-width: 240px;
  }
`;

export const ArtistName = styled.p`
  max-width: calc(100vw - 150px);
  color: ${({ theme }) => theme.colors.medium};
  font-size: ${px2rem(13)};
  line-height: 1.4;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${media.lg.up} {
    max-width: 240px;
  }
`;

export const AlbumName = styled.p`
  max-width: calc(100vw - 150px);
  color: ${({ theme }) => theme.colors.white};
  font-size: ${px2rem(16)};
  line-height: 1.4;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${media.lg.up} {
    max-width: 240px;
  }
`;

export const PlayerContainer = styled.div<{ isVisible?: boolean }>`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 74px;
  z-index: 5;
  background-color: ${({ theme }) => theme.colors.black};
  transform: translateY(${({ isVisible }) => (isVisible ? 0 : "100%")});
  transition: 0.15s all ease-in-out;

  ${media.lg.up} {
    height: 66px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: center;
  height: 100%;

  ${media.lg.up} {
    flex-direction: row;
    margin-right: 1rem;
  }
`;

export const PlaylistWrapper = styled.div`
  width: 100%;

  ${media.lg.up} {
    flex: 0 0 400px;
  }
`;

export const ControlsWrapper = styled.div`
  display: flex;
  margin: -3px 0 -6px;
  justify-content: space-between;
  width: 100%;

  ${media.lg.up} {
    margin: auto;
    width: auto;
  }
`;

export const ControlButton = styled.button<{
  isActive?: boolean;
  isFlipped?: boolean;
}>`
  ${({ isFlipped }) => isFlipped && `transform: scaleX(-1);`}

  ${({ disabled }) =>
    disabled &&
    `
      opacity: 0.7;
      cursor: not-allowed;
  `}

  display: flex;
  align-items: center;
  flex: 0 0 32px;
  width: 32px;
  height: 32px;
  margin: 10px 20px;
  padding: 6px;
  border: none;
  background: none;
  cursor: pointer;

  svg {
    opacity: 0.8;
    height: 32px;
  }

  svg,
  g,
  path,
  rect {
    fill: ${({ theme }) => theme.colors.white};
  }

  &:hover {
    svg {
      opacity: 1;
    }

    svg,
    g,
    path,
    rect {
      fill: ${({ theme }) => theme.colors.gold};
    }
  }

  ${({ isActive, theme }) =>
    isActive &&
    `
      svg {
        opacity: 1;
      }

      svg,
      path {
        fill: ${theme.colors.gold};
      }
    `}

  ${media.lg.up} {
    flex: 0 0 28px;
    width: 28px;
    height: 28px;
  }
`;

export const Controls = styled.div`
  display: flex;
`;

export const PlayerErrorWrapper = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 74px;
  width: 100%;
  height: 40px;
  z-index: 5;
  transition: 1s all ease-in-out;
  background-color: ${({ theme }) => theme.colors.red};
  padding: 9px;

  ${media.lg.up} {
    bottom: 66px;
  }
`;

export const PlayerError = styled.div`
  font-size: ${px2rem(14)};
  line-height: ${px2rem(22)};
  text-align: center;
`;

export const RefreshLink = styled.span`
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;
`;

export const SpotifyOpenUrl = styled.a`
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const SpotifyLogoWrapper = styled.div`
  position: absolute;
  left: 360px;
  top: 23px;
  z-index: -1;
`;
