import { css } from "styled-components";

import { media, styled, Theme, theme } from "@styles";
import { SvgIcon } from "@utils";

export type ButtonVariant = keyof Theme["button"]["colors"];

export type FontSizingProps = "small" | "medium" | "large";

export interface ButtonStyledProps {
  fullWidth?: boolean;
  icon?: SvgIcon;
  large?: boolean;
  processing?: boolean;
  variant: ButtonVariant;
  withIcon?: boolean;
  withTransition?: boolean;
}
const getVariantStyle = (variant: ButtonVariant, disabled: boolean) => {
  const { colors } = theme.button;
  return `
    color: ${colors[variant].color};
    border: 2px solid ${colors[variant]?.borderColor};
    background-color: ${
      disabled
        ? colors[variant].hoverBackgroundColor
        : colors[variant].backgroundColor
    };

    svg {
      fill: ${colors[variant].color};
    }

    &:hover {
      color: ${colors[variant].hoverColor};
      background-color: ${colors[variant].hoverBackgroundColor};
      border-color: ${!disabled && colors[variant].hoverBorderColor};
      svg {
        fill: ${colors[variant].hoverColor};
      }
    }
`;
};

const getVariantColors = (variant: ButtonVariant, disabled: boolean) => {
  const { colors } = theme.button;

  // eslint-disable-next-line default-case
  switch (variant) {
    case "spotify":
      return `
        ${getVariantStyle(variant, disabled)};
        svg path{
          fill: ${colors[variant].color};
        }
      `;

    case "danger":
      return `
        ${getVariantStyle(variant, disabled)}

        ${
          !disabled &&
          `&:hover {
            color: ${colors.danger.hoverColor};
            background-color: ${colors[variant].hoverBackgroundColor};
          }`
        }`;

    case "primary":
      return `
        ${getVariantStyle(variant, disabled)}

        &.disabled {
          color: ${colors[variant].color} !important;
         &:hover {
            color: ${colors[variant].color};
          }
        }

        ${
          disabled &&
          `
          color: ${colors[variant].color} !important;
          &:hover {
            color: ${colors[variant].color};
          }`
        }`;

    default:
      return getVariantStyle(variant, disabled);
  }
};

export const buttonStyles = css<ButtonStyledProps & { disabled?: boolean }>`
  padding: ${({ theme, withIcon }) =>
    withIcon ? "11px" : theme.button.padding.main};
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  border: none;
  margin: 0;
  font-size: ${({ theme }) => theme.button.fontSize};
  font-weight: 500;
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "max-content")};
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  transition: ${({ withTransition }) => (withTransition ? "0.5s all" : "none")};

  ${({ variant, disabled }) => getVariantColors(variant, !!disabled)}

  ${media.md.up} {
    padding: ${({ theme, withIcon, large }) =>
      withIcon
        ? "11px"
        : large
        ? theme.button.padding.large
        : theme.button.padding.main};
  }

  &:focus,
  &:active,
  &:focus:active {
    outline: none;
  }

  &:disabled,
  &.disabled {
    cursor: not-allowed;
    color: ${theme.colors.white};
    opacity: ${({ theme }) => theme.opacity};
  }

  ${({ processing }) =>
    processing &&
    `
    &,
    &:disabled {
      cursor: wait;
    }
  `}
`;

export const Button = styled.button<ButtonStyledProps>`
  ${buttonStyles}
`;

export const IconWrapper = styled.span`
  margin-right: 10px;
  display: flex;
  align-items: center;
`;

export const Content = styled.span<{ fontSize: FontSizingProps }>`
  caret-color: rgba(0, 0, 0, 0);
  font-size: ${({ theme, fontSize }) => theme.button.fontSizes[fontSize]};
`;
