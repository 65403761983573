import React from "react";
import {
  FieldError,
  FieldErrorsImpl,
  Merge,
  UseFormReturn,
} from "react-hook-form";

import { getTestAttribute, RefHook } from "@utils";

import * as S from "./styles";

export interface CheckboxProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  children?: React.ReactNode;
  disabled?: boolean;
  error?:
    | boolean
    | string
    | React.ReactNode
    | FieldError
    | Merge<FieldError, FieldErrorsImpl<any>>;
  inputRef?: UseFormReturn["register"] | RefHook<HTMLInputElement>;
  isReactHook?: boolean;
  name: string;
}
export const Checkbox = ({
  children,
  disabled,
  error,
  name,
  inputRef,
  isReactHook = false,
  ...inputProps
}: CheckboxProps) => (
  <div>
    <S.Label htmlFor={name} withError={!!error}>
      <S.Container>
        {isReactHook ? (
          <S.Input
            {...getTestAttribute("checkbox", name)}
            disabled={disabled}
            id={name}
            type="checkbox"
            {...inputProps}
            {...(inputRef as UseFormReturn["register"])(name)}
          />
        ) : (
          <S.Input
            {...getTestAttribute("checkbox", name)}
            disabled={disabled}
            id={name}
            name={name}
            ref={inputRef as RefHook<HTMLInputElement>}
            type="checkbox"
            {...inputProps}
          />
        )}

        <S.CheckboxInput disabled={disabled} />
      </S.Container>

      {children && <S.Content disabled={disabled}>{children}</S.Content>}
    </S.Label>
  </div>
);
