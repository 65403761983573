import { Collection } from "@gqlTypes/Collection";
import { createContext } from "react";

import { Filters } from "@components/ProductFilters/types";
import { searchQueryParam } from "@config";

export type ListingType =
  | "collection"
  | "artist"
  | "genre"
  | "label"
  | "search";

export type ListingContextState = {
  collection: Collection | null;
  filters: Filters;
  listingType: ListingType;
};

export type ListingContextHandlers = {
  resetFilters: () => void;
  updateFilters: (filters: Partial<Filters>) => void;
};

export type ListingContextData = ListingContextState & ListingContextHandlers;

export type CollectionPageListingContext = Pick<
  ListingContextData,
  "collection" | "filters" | "listingType"
>;

export const defaultFilters: Filters = {
  "artists.name": "",
  "artists.slug": "",
  bonus: false,
  "collections.name": "",
  "collections.slug": "",
  colouredVinyl: false,
  exclusive: false,
  "formats.name": "",
  "formats.slug": "",
  "genres.name": "",
  "genres.slug": "",
  inStock: false,
  includesDownload: false,
  "labels.name": "",
  "labels.slug": "",
  limited: false,
  preorders: false,
  "productType.name": "",
  "productType.slug": "",
  recordStoreDay: false,
  released: "anytime",
  sale: false,
  [searchQueryParam]: "",
  signed: false,
  sortBy: "most-relevant",
};

export const defaultListingContext: ListingContextData = {
  collection: null,
  filters: defaultFilters,
  listingType: "search",
  resetFilters: () => {},
  updateFilters: () => {},
};

export const ListingContext = createContext<ListingContextData>(
  defaultListingContext
);
