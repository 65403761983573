import { px2rem, styled } from "@styles";

export const Wrapper = styled.div`
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 15%;
  right: 0;
  color: ${({ theme }) => theme.colors.white};
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  font-size: ${px2rem(10)};
  padding: 2px 0;
`;
