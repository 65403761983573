import { defineMessages } from "react-intl";

export const NavigationMessages = defineMessages({
  fallbackHeader: {
    defaultMessage: "Open to see more",
    id: "1PT662",
  },
  seeMore: {
    defaultMessage: "See more {name}",
    id: "m/BYul",
  },
});
