import { useContext } from "react";

import {
  CheckoutContext,
  CheckoutProviderContext,
} from "@providers/CheckoutProvider";
import { ConfigContext } from "@providers/ConfigProvider";
import { ListingContext } from "@providers/ListingProvider";
import { PlayerProviderContext } from "@providers/PlayerProvider/context";
import { SpotifyProviderContext } from "@providers/Spotify";

export const useConfigContext = () => useContext(ConfigContext);

export const useCheckoutContext = <Require extends boolean>() =>
  useContext<CheckoutContext<Require>>(CheckoutProviderContext);

export const useListingContext = () => useContext(ListingContext);

export const useSpotifyContext = () => useContext(SpotifyProviderContext);

export const usePlayerContext = () => useContext(PlayerProviderContext);
