import { initializeSecureApolloClient } from "@graphql";
import { getSliderCollection } from "@graphql/utils";
import { ContentRelationshipField } from "@prismicio/client";
import {
  BasketDocument,
  CheckoutDocument,
  FooterDocument,
  MainNavigationDocument,
  ProductPageDocument,
  RedirectModalConfigurationDocument,
  SearchFeaturedCollectionsSliceDefault,
  SearchFeaturedCollectionsSliceDefaultItem,
  SearchPanelDocument,
  TopBannerDocument,
} from "prismicio-types";

import { cachingDurations, serverSideCaching } from "@config";
import { getGlobalContent } from "@integrations/prismic/api";
import { ModifiedBasketDocument } from "@integrations/prismic/types";
import {
  fetchBasketVariants,
  getChannel,
} from "@providers/ConfigProvider/helpers";
import { BasketVariant } from "@providers/ConfigProvider/types";
import { DEFAULT_LOCALE, Locale } from "@providers/TranslationProvider";
import { isSupportedLocale } from "@providers/TranslationProvider/helpers";
import {
  handlerWithHelpers,
  RequestWithHelpers,
  ResponseWithHelpers,
} from "@utils/api";

import { CollectionApiResponse } from "../collection/[locale]/[id]";

export type CmsGlobalReturn = {
  basket: ModifiedBasketDocument | null;
  checkout: CheckoutDocument | null;
  footer: FooterDocument | null;
  locale_switcher: RedirectModalConfigurationDocument | null;
  navigation: MainNavigationDocument | null;
  product_page: ProductPageDocument | null;
  search: SearchPanelDocument | null;
  top_banner: TopBannerDocument | null;
};

export const fetchLinkFields = [
  {
    custom_type: "basket",
    fields: [
      "empty_basket_text",
      "empty_collection_header",
      "empty_collection_id",
      "active_collection_header",
      "active_collection_id",
      "free_shipping_min_amount",
      "active_variants",
      "empty_variants",
    ],
  },
  {
    custom_type: "redirect_modal_configuration",
    fields: [
      "current_locale",
      "switch_text_1",
      "switch_text_continued",
      "button_text_remain",
      "button_text_switch",
      "footer_note",
    ],
  },
  {
    custom_type: "footer",
    fields: [
      "header",
      "body",
      "copyright",
      "left_footer_title",
      "left_footer_nav",
      "right_footer_title",
      "right_footer_nav",
      "social_links",
      "payment_icons",
    ],
  },
  {
    custom_type: "main_navigation",
    fields: ["menu_item"],
  },
  {
    custom_type: "nav_sub_menu",
    fields: ["slices"],
  },
  {
    custom_type: "checkout",
    fields: ["slices"],
  },
  {
    custom_type: "product_page",
    fields: ["slices"],
  },
  {
    custom_type: "top_banner",
    fields: ["items"],
  },
  {
    custom_type: "search_panel",
    fields: ["slices"],
  },
];

/**
 *
 * @param rawBasket
 * @param lang
 * @returns Fetch and attach the active and empty collection to the basket
 */
export const fetchBasketCollection = async (
  rawBasket: ContentRelationshipField<"basket">,
  lang: string
) => {
  const basket = rawBasket as unknown as BasketDocument;
  const channel = getChannel(lang as Locale);

  const variantsArray = [
    {
      data: basket.data?.active_variants as BasketVariant[],
      selector: "activeCollection",
    },
    {
      data: basket.data?.empty_variants as BasketVariant[],
      selector: "emptyCollection",
    },
  ];

  const collections = await fetchBasketVariants(variantsArray, channel);

  return {
    ...basket,
    collections,
  } as ModifiedBasketDocument;
};

export type ModifiedSearchFeaturedCollectionsSliceDefaultItem =
  SearchFeaturedCollectionsSliceDefaultItem & {
    data: CollectionApiResponse | null;
  };
/**
 *
 * @param rawBasket
 * @param lang
 * @returns Fetch and attach the active and empty collection to the basket
 */
export const fetchSearchPanelCollections = async (
  rawSearchPanel: ContentRelationshipField<"search_panel">,
  lang: string
) => {
  if (!rawSearchPanel) {
    return rawSearchPanel;
  }

  const apolloClient = initializeSecureApolloClient();
  const search = rawSearchPanel as unknown as SearchPanelDocument;
  const searchCollections =
    (
      search?.data.slices.find(
        slice => slice.slice_type === "search_featured_collections"
      ) as unknown as SearchFeaturedCollectionsSliceDefault
    )?.items || ([] as SearchFeaturedCollectionsSliceDefaultItem[]);
  const channel = getChannel(lang as Locale);

  const modifiedSearchCollections = (await Promise.all(
    searchCollections.map(async item => {
      const { collection_id } =
        (item as unknown as SearchFeaturedCollectionsSliceDefaultItem) || {};

      if (!collection_id) {
        return {
          ...item,
        };
      }

      return {
        ...item,
        data: await getSliderCollection(
          collection_id,
          channel,
          5,
          "",
          apolloClient
        ),
      };
    })
  )) as unknown as ModifiedSearchFeaturedCollectionsSliceDefaultItem[];

  return {
    ...search,
    data: {
      slices: search.data.slices.map(slice => {
        if (slice.slice_type === "search_featured_collections") {
          return {
            ...slice,
            items: modifiedSearchCollections,
          };
        }
        return {
          ...slice,
        };
      }),
    },
  };
};

const handler = async (
  { isMethod, query }: RequestWithHelpers,
  { jsonResponse }: ResponseWithHelpers<CmsGlobalReturn>
) => {
  if (!isMethod("GET")) {
    return jsonResponse(405, { error: "Method not allowed" });
  }

  const locale = query.locale as string;

  if (!locale) {
    return jsonResponse(400, {
      error: "Locale parameter is missing",
    });
  }

  const supportedLocale = isSupportedLocale(locale);

  if (!supportedLocale) {
    return jsonResponse(400, {
      error: "Locale parameter is not supported",
    });
  }

  const lang = supportedLocale ? locale : DEFAULT_LOCALE;
  const { status, data, message } = (await getGlobalContent({
    fetchLinkFields,
    lang,
  })) || { status: 400 };

  if (status !== 200 || !data) {
    return jsonResponse(status, { error: message || "Error fetching content" });
  }

  return jsonResponse(
    200,
    data,
    serverSideCaching(cachingDurations.FIVE_MINUTES)
  );
};

export default handlerWithHelpers<CmsGlobalReturn>(handler);
