import { css } from "styled-components";

import { px2rem, styled } from "@styles";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin: ${px2rem(0, 0, -8)} auto;
  color: ${({ theme }) => theme.colors.white};

  & > span {
    width: 18px;
    text-align: center;
  }
`;

export const QuantityControl = styled.div<{
  disabled?: boolean;
  processing: boolean;
}>`
  cursor: pointer;
  padding: 10px;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }

  ${({ disabled, theme }) =>
    disabled &&
    `
      opacity: ${theme.opacity};
      cursor: not-allowed;

      span {
        border-color: ${theme.colors.dark} !important;
        color: ${theme.colors.dark} !important;
      }
  `}

  ${({ disabled, theme: { colors } }) =>
    !disabled &&
    css`
      &:hover span {
        color: ${colors.black};
        background-color: ${colors.white};
      }
    `}

  ${({ processing }) => processing && `cursor: wait;`}


  span {
    display: block;
    width: 21px;
    height: 21px;
    text-align: center;
    border-width: 1px;
    border-style: solid;

    ${({ theme: { colors } }) => `
      color: ${colors.white};
      background-color: ${colors.black};
      border-color: ${colors.white};
    `}
  }
`;
