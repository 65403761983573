import ReactTooltip from "react-tooltip";

import { px2rem, styled } from "@styles";

export const Tooltip = styled(ReactTooltip)<{ autoWidth?: boolean }>`
  width: ${({ autoWidth }) => (autoWidth ? "auto" : "300px")};
  background-color: ${({ theme }) => theme.colors.black} !important;
  line-height: 137.1%;
  padding: ${px2rem(9, 18)} !important;

  &::after,
  &::before {
    display: none !important;
  }
`;

export const Trigger = styled.span`
  display: flex;
  align-items: center;
`;
