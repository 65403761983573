import { ssrMode } from "@config";

import { Object } from "./typescript";

export const getItem = <T = string | Object>(
  key: string,
  parse?: boolean
): T | null => {
  if (ssrMode) {
    return null;
  }

  try {
    const data = localStorage.getItem(key);
    return parse ? JSON.parse(data!) : data;
  } catch (err) {
    return null;
  }
};

export const setItem = <V = string | Object>(
  key: string,
  value: V,
  stringify?: boolean
): void => {
  if (!ssrMode) {
    try {
      localStorage.setItem(
        key,
        stringify
          ? JSON.stringify(value as unknown as Object)
          : (value as unknown as string)
      );
    } catch (err) {
      //
    }
  }
};

export const removeItem = (key: string) =>
  ssrMode ? undefined : localStorage.removeItem(key);
