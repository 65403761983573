import { defineMessages } from "react-intl";

export const messages = defineMessages({
  leftArrowMessage: {
    defaultMessage: "Move collection to the right",
    id: "Lbm6ZD",
  },
  rightArrowMessage: {
    defaultMessage: "Move collection to the left",
    id: "rFauby",
  },
  slider: {
    defaultMessage: "Slider",
    id: "PJfWgi",
  },
});
