import { Attribute } from "@gqlTypes/Attribute";
import { AttributeValue } from "@gqlTypes/AttributeValue";
import { ProductVariant } from "@gqlTypes/ProductVariant";
import { SelectedAttribute } from "@gqlTypes/SelectedAttribute";

import { Channel } from "@providers/TranslationProvider";

export const ProductTypeFormatMapping = {
  "accessories-eu": "format-accessories-eu",
  "accessories-gb": "format-accessories-gb",
  "accessories-us": "format-accessories-us",
  "books-eu": "format-books-eu",
  "books-gb": "format-books-gb",
  "books-us": "format-books-us",
  "clothing-eu": "format-clothing-eu",
  "clothing-us": "format-clothing-us",
  "deprecated-us": "format-deprecated-us",
  "film-eu": "format-film-eu",
  "film-gb": "format-film-gb",
  "film-us": "format-film-us",
  "gift-cards-eu": "format-gift-cards-eu",
  "gift-cards-gb": "format-gift-cards-gb",
  "gift-cards-us": "format-gift-cards-us",
  "hardware-eu": "format-hardware-eu",
  "hardware-gb": "format-hardware-gb",
  "hardware-us": "format-hardware-us",
  "merchandise-eu": "format-merchandise-eu",
  "merchandise-gb": "format-merchandise-gb",
  "merchandise-us": "format-merchandise-us",
  "music-eu": "format-music-eu",
  "music-gb": "format-music-gb",
  "music-us": "format-music-us",
  "tickets-eu": "format-tickets-eu",
  "tickets-gb": "format-tickets-gb",
};

export enum LocalizedAttributes {
  GENRE = "genre",
  LABEL = "label",
  SUPPLIER = "supplier",
}

export enum GlobalAttributes {
  ARTIST = "artist",
  BONUS_DISC = "bonus-disc",
  CATALOGUE_NUMBER = "catalogue-number",
  COLOUR = "colour",
  COLOUR_DETAILS = "colour-details",
  DISCS = "discs",
  EXCLUSIVE = "exclusive",
  EXTENDED_FORMAT = "extended-format",
  FORMAT_DETAIL = "format-detail",
  INCLUDES_DOWNLOAD = "download",
  LIMITED = "limited",
  RELEASE_DATE = "release-date",
  SIGNED = "signed",
}

export type NonNullFilterAttribute = {
  attribute: Attribute;
  values: AttributeValue[];
};

export type VariantSlug = {
  slug: string;
  suffix: number | null;
  variant: ProductVariant;
};

// TODO: Type it better
export type GetAttribute<T> = <Bare extends boolean>(
  attributes: SelectedAttribute[],
  bare?: Bare
) => T | AttributeValue | null;

export type GetLocalizedAttribute<T> = <Bare extends boolean>(
  attributes: SelectedAttribute[],
  channel: Channel,
  bare?: Bare
) => T | AttributeValue | null;

export type GetListAttribute<T> = <Bare extends boolean>(
  attributes: SelectedAttribute[],
  bare?: Bare
) => T[] | AttributeValue[];

export type GetLocalizedListAttribute<T> = <Bare extends boolean>(
  attributes: SelectedAttribute[],
  channel: Channel,
  bare?: Bare
) => T[] | AttributeValue[];
