import Image from "next/image";
import React from "react";

import { useSpotifyContext } from "@hooks/providers";
import { SpotifyAlbum } from "@providers/PlayerProvider/types";

import FallbackImage from "assets/images/fallback-image.jpg";

import * as GS from "../../styles";
import * as S from "./styles";

export interface AlbumInfoProps {
  albumName: string;
  albumOpenUrl: string;
  artists: SpotifyAlbum["artists"];
  image: string | null | undefined;
  isExpanded?: boolean;
  trackName?: string;
  trackOpenUrl: string;
}

export const AlbumInfo = ({
  artists,
  albumName,
  image = FallbackImage,
  isExpanded,
  trackName,
  albumOpenUrl,
  trackOpenUrl,
}: AlbumInfoProps) => {
  const { token, followedArtists } = useSpotifyContext();

  const artistsName = artists.map(({ open_url, name, id }) => {
    const isFollowed = followedArtists.includes(id);

    return (
      <S.ArtistName
        isConnectedWithSpotify={!!token}
        isFollowed={isFollowed}
        key={id}
      >
        <GS.SpotifyOpenUrl href={open_url} rel="noreferrer" target="_blank">
          {name}
        </GS.SpotifyOpenUrl>
      </S.ArtistName>
    );
  });
  const albumInfo = (
    <>
      <GS.AlbumName>
        <GS.SpotifyOpenUrl href={albumOpenUrl} rel="noreferrer" target="_blank">
          {albumName}
        </GS.SpotifyOpenUrl>
      </GS.AlbumName>
      <GS.ArtistName>{artistsName}</GS.ArtistName>
    </>
  );

  const trackInfo = (
    <>
      <GS.TrackName>
        <GS.SpotifyOpenUrl href={trackOpenUrl} rel="noreferrer" target="_blank">
          {trackName}
        </GS.SpotifyOpenUrl>
      </GS.TrackName>
      <GS.ArtistName>{artistsName}</GS.ArtistName>
    </>
  );

  return (
    <S.AlbumInfoContainer isExpanded={isExpanded}>
      <a href={albumOpenUrl} rel="noreferrer" target="_blank">
        <S.Thumbnail isExpanded={isExpanded}>
          {image && (
            <Image
              alt={albumName}
              height={isExpanded ? 90 : 44}
              layout="fixed"
              objectFit="contain"
              src={image}
              width={isExpanded ? 90 : 44}
            />
          )}
        </S.Thumbnail>
      </a>

      <S.InfoContainer>{isExpanded ? albumInfo : trackInfo}</S.InfoContainer>
    </S.AlbumInfoContainer>
  );
};
