import React from "react";
import { FieldError, UseFormReturn } from "react-hook-form";

import { getTestAttribute, RefHook, SvgIcon } from "@utils";

import * as S from "./styles";

export interface IconProps {
  Icon: SvgIcon;
  iconOnClickHandler?: () => void;
}

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  error?: FieldError;
  icon?: IconProps;
  inputRef?: UseFormReturn["register"] | RefHook<HTMLInputElement>;
  isReactHook?: boolean;
  label: string;
  name: string;
  textComponent?: React.ReactNode;
  variant?: S.InputVariant;
}

export const Input = ({
  name,
  inputRef,
  icon,
  label,
  error,
  textComponent,
  className,
  variant = "default",
  isReactHook = false,
  ...props
}: InputProps) => (
  <S.InputWrapper className={className} {...getTestAttribute("input", name)}>
    {isReactHook ? (
      <S.Input
        error={!!error}
        id={name}
        placeholder=" "
        type="text"
        variant={variant}
        {...getTestAttribute("input", name)}
        {...(inputRef as UseFormReturn["register"])(name)}
        {...props}
      />
    ) : (
      <S.Input
        error={!!error}
        id={name}
        name={name}
        placeholder=" "
        ref={inputRef as RefHook<HTMLInputElement>}
        type="text"
        variant={variant}
        {...getTestAttribute("input", name)}
        {...props}
      />
    )}

    {label && (
      <S.FloatingLabel
        disabled={!!props.disabled}
        error={!!error}
        htmlFor={label}
      >
        {label}
      </S.FloatingLabel>
    )}

    {icon?.Icon && (
      <S.Icon
        disabled={!!props.disabled}
        onClick={icon?.iconOnClickHandler}
        {...getTestAttribute("icon", name)}
      >
        <icon.Icon width={15} />
      </S.Icon>
    )}

    {textComponent && (
      <S.ComponentWrapper disabled={!!props.disabled} error={!!error}>
        {textComponent}
      </S.ComponentWrapper>
    )}

    {error && (
      <S.Error {...getTestAttribute("inputError", name)}>
        {error.message}
      </S.Error>
    )}
  </S.InputWrapper>
);
