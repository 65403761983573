import debounce from "lodash/debounce";
import { useCallback, useEffect } from "react";

export const useOnWindowEvent = <K extends keyof WindowEventMap>(
  event: K,
  cb: (this: Window, ev: WindowEventMap[K]) => void,
  deps?: any[]
) => {
  const listener = useCallback(debounce(cb, 100), [cb]);

  useEffect(() => {
    window.addEventListener(event, listener);
    return () => window.removeEventListener(event, listener);
  }, deps ?? []);
};
