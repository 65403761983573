import { RefObject, useEffect, useState } from "react";

type Args = IntersectionObserverInit & {
  freezeOnceVisible?: boolean;
};

/**
 * Hook for IntersectionObserver.
 *
 * @see  https://developer.mozilla.org/en-us/docs/Web/API/Intersection_Observer_API
 *
 * @param {RefObject<Element>}  elementRef  Description.
 * @param {object}  [config]  Config options
 * @param {number}  [config.threshold=0]  Either a single number or an array of numbers which indicate at what percentage of the target's visibility the observer's callback should be executed.
 * @param {number}  [config.root=null]  The element that is used as the viewport for checking visibility of the target. Must be the ancestor of the target.
 * @param {string}  [config.rootMargin="0px 0px 0px 0px"] Margin around the root. Can have values similar to the CSS margin property. This set of values serves to grow or shrink each side of the root element's bounding box before computing intersections. Defaults to all zeros.
 * @param {boolean} [config.freezeOnceVisible=false]  Determines whether the checking the visibility of the target should be called just once, or always (default).
 *
 * @return {IntersectionObserverEntry}
 */
export const useIntersectionObserver = (
  elementRef: RefObject<Element>,
  {
    threshold = 0,
    root = null,
    rootMargin = "0px 0px 0px 0px",
    freezeOnceVisible = false,
  }: Args
): IntersectionObserverEntry | undefined => {
  const [entry, setEntry] = useState<IntersectionObserverEntry>();
  const frozen = entry?.isIntersecting && freezeOnceVisible;
  const updateEntry = ([entry]: IntersectionObserverEntry[]): void => {
    setEntry(entry);
  };

  useEffect(() => {
    const node = elementRef?.current;
    const hasIOSupport = !!window.IntersectionObserver;

    if (!hasIOSupport || frozen || !node) {
      return;
    }

    const observerParams = { root, rootMargin, threshold };
    const observer = new IntersectionObserver(updateEntry, observerParams);

    observer.observe(node);

    return () => observer.disconnect();
  }, [elementRef, threshold, root, rootMargin, frozen]);

  return entry;
};
