import { css, CSSProperties } from "styled-components";

import { theme } from "@styles/theme";

import { ColLimit, ColWidths } from "./types";

export const imageHoverRotate = (options?: {
  delay: number;
  length: number;
  rotate: number;
  scale: number;
}) => {
  const { scale = 1.1, delay = 0, rotate = 1, length = 450 } = options || {};

  return css`
    img {
      transition: ${length}ms transform ${delay}ms ease-in-out;
    }

    &:hover {
      img {
        transform: scale(${scale}) rotate(${rotate}deg);
      }
    }
  `;
};

export const imageHoverBorder = (options?: {
  color?: string;
  delay?: number;
  length?: number;
  width?: number;
}) => {
  const {
    color = "orange",
    delay = 0,
    width = 1,
    length = 450,
  } = options || {};

  return css`
    border: ${width}px solid transparent;

    &::before {
      opacity: 0;
      background: ${color};
    }

    &:hover {
      border-color: ${color};
      transition: ${length}ms border-color ${delay}ms ease-in-out;

      &::before {
        opacity: 0.2;
        transition: ${length}ms opacity ${delay}ms ease-in-out;
      }
    }
  `;
};

export const truncateText = (options?: {
  lines?: number;
  textAlign?: CSSProperties["textAlign"];
  textOverflow?: CSSProperties["textOverflow"];
}) => {
  const {
    lines = 1,
    textOverflow = "ellipsis",
    textAlign = "left",
  } = options || {};

  return css`
    max-width: 100%;
    text-overflow: ${textOverflow};
    overflow: hidden;
    white-space: normal;
    text-align: ${textAlign};

    display: -webkit-box;
    -webkit-line-clamp: ${lines};
    line-clamp: ${lines};
    -webkit-box-orient: vertical;
  `;
};

export const psuedoElement = (options?: {
  height?: CSSProperties["height"];
  inset?: CSSProperties["inset"];
  position?: CSSProperties["position"];
  textOverflow?: CSSProperties["textOverflow"];
  width?: CSSProperties["width"];
  zIndex?: CSSProperties["zIndex"];
}) => {
  const {
    width = "100%",
    height = "100%",
    inset = "0",
    zIndex = "1",
    position = "absolute",
  } = options || {};

  return css`
    content: "";
    width: ${width};
    height: ${height};
    inset: ${inset};
    position: ${position};
    z-index: ${zIndex};
  `;
};

export const navButtonStyling = (options?: {
  alignItems?: CSSProperties["alignItems"];
  color?: keyof typeof theme.colors;
  fontSize?: keyof typeof theme.typography.sizes;
  fontWeight?: CSSProperties["fontWeight"];
  height?: CSSProperties["height"];
  iconFirst?: boolean;
  justifyContent?: CSSProperties["justifyContent"];
  padding?: CSSProperties["padding"];
  position?: CSSProperties["position"];
  textTransform?: CSSProperties["textTransform"];
  width?: CSSProperties["width"];
}) => {
  const {
    fontSize = "medium",
    position = "relative",
    textTransform = "uppercase",
    iconFirst = true,
    fontWeight = "600",
    width = "100%",
    height = "100%",
    padding = "16px",
    color = "white",
    alignItems = "center",
    justifyContent = "space-between",
  } = options || {};

  return css`
    font-family: ${({ theme }) => theme.typography.condensedFontFamily};
    font-size: ${({ theme }) => theme.typography.sizes[fontSize]};

    position: ${position};
    width: ${width};
    height: ${height};
    padding: ${padding};
    text-transform: ${textTransform};
    font-weight: ${fontWeight};

    display: flex;
    flex-direction: ${iconFirst ? "row-reverse" : "row"};
    justify-content: ${justifyContent};
    align-items: ${alignItems};
    gap: 8px;
    color: ${({ theme }) => theme.colors[color]};

    svg {
      width: 20px;
      height: 20px;
      z-index: 1;

      path {
        fill: white;
      }
    }

    span {
      ${truncateText()}
      z-index: 1;
    }

    &::before {
      ${psuedoElement({ zIndex: 0 })}
      background: ${({ theme }) => theme.colors[color]};
      opacity: 0.15;
    }

    text-decoration: none;
    background: none;
    border: none;
    cursor: pointer;
  `;
};

export const gridColumns = (options?: {
  gap: CSSProperties["gap"];
  itemsPerColumn: number;
  maxColWidth: ColWidths;
  numOfColumns: ColLimit;
}) => {
  const {
    itemsPerColumn = 4,
    maxColWidth = "100%",
    gap = "10px",
    numOfColumns = 1,
  } = options || {};

  return css`
    grid-auto-flow: column;
    grid-template-rows: repeat(${itemsPerColumn}, 1fr);
    grid-template-columns: repeat(${numOfColumns}, ${maxColWidth}%);
    gap: ${gap};
  `;
};
