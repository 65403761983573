import { useRouter } from "next/router";
import { useMemo } from "react";

import { COUNTRIES, DEFAULT_COUNTRY } from "@providers/TranslationProvider";

export const useRouterCountry = () => {
  const { locale } = useRouter();
  const country = useMemo(
    () =>
      COUNTRIES.find(({ locale: countryLocale }) => countryLocale === locale) ||
      DEFAULT_COUNTRY,
    [locale]
  );

  return country;
};
