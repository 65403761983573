import { keyframes } from "styled-components";

import { styled, verticalCenter } from "@styles";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export interface WrapperProps {
  absoluteCenter: boolean;
  big: boolean;
  cover: boolean;
  diskSize: number;
  size: number;
  variant?: "light" | "dark";
  verticalCenter: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  text-align: center;
  display: flex;
  overflow: hidden;
  justify-content: center;
  padding: 1rem 0;

  ${({ big }) => big && `height: 200px;`}

  ${({ cover, variant }) =>
    cover &&
    `
    background: ${
      variant === "light" ? "rgba(0, 0, 0, 0.5)" : "rgba(255, 255, 255, 0.5)"
    };

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
    }
  `}

  ${({ absoluteCenter: center }) =>
    center &&
    `
      display: flex;
      justify-content: center;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;

      svg {
        position: absolute;
        display: block;
        align-self: center;
      }
    `}

  ${({ verticalCenter: center }) =>
    center &&
    `svg {
      ${verticalCenter}
      top: 25%;
    }
  `}

  svg {
    animation: ${rotate} 1.5s linear infinite;
    height: ${({ diskSize }) => diskSize}px !important;
  }
`;
