import React, { useEffect, useMemo, useState } from "react";

import { ToastItem } from "@components/Toast/components";
import { getToast } from "@components/Toast/utils";
import { useToastStore } from "@stores/useToastStore";

type UseToastProps = {
  ToastRef: React.MutableRefObject<HTMLDivElement | null>;
};

export const useToast = ({ ToastRef }: UseToastProps) => {
  const { toasts, finished, removeToast } = useToastStore();
  const [hasToasts, setHasToasts] = useState(toasts.length > 0);

  useEffect(() => {
    setHasToasts(toasts.length > 0);
  }, [toasts]);

  const toastItems = toasts.map((toast, index) => {
    const { action, type, status, props, id } = toast;
    const { Component, messages, animations } = getToast({
      action,
      status,
      type,
    });

    return (
      <ToastItem
        Component={Component}
        animations={animations}
        id={id}
        key={`${id}_${index}`}
        messages={messages}
        props={props}
        removeToast={() => {
          removeToast(toast.id);
        }}
      />
    );
  });

  const hideToasts = useMemo(
    () => toasts.length === finished.length,
    [toasts, finished]
  );

  useEffect(() => {
    if (ToastRef.current && hasToasts) {
      if (toasts.length > 0 && ToastRef.current.innerHTML !== "") {
        setHasToasts(false);
        ToastRef.current.innerHTML = "";
      }
    }
  }, [hideToasts, ToastRef]);

  return {
    hasToasts,
    toastItems,
  };
};
