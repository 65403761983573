import React from "react";

import { Footer } from "@components/Footer";
import { Header } from "@components/Header";
import { SpotifySuccessModal } from "@components/SpotifySuccessModal";
import { useFilterStore } from "@stores/useFilterStore";
import { LayoutProps } from "@utils/typescript";

import * as S from "./styles";

export const PageLayout = ({ variant, children }: LayoutProps) => {
  const { filterActive } = useFilterStore();
  return (
    <S.PageLayoutWrapper variant={variant}>
      <Header variant={variant} />
      <S.ContentWrapper
        id="main-content"
        isFilterActive={filterActive}
        variant={variant}
      >
        {children}
      </S.ContentWrapper>
      <SpotifySuccessModal />
      <Footer />
    </S.PageLayoutWrapper>
  );
};
