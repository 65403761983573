import styled from "styled-components";

export const AlsoBoughtModal = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.black};
`;

export const ModalHeader = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  gap: 10px;
  padding: 20px 2rem;
  cursor: pointer;
`;

export const CartItemsWrapper = styled.div`
  display: flex;
  flex-flow: column;
  gap: 20px;

  .cart-info-wrapper {
    display: flex;
    flex-flow: column;
    gap: 20px;
  }

  .cart-line-wrapper {
    display: flex;
    flex-flow: column;
    overflow: auto;

    > :last-child {
      border-bottom: transparent;
    }
  }
`;

export const AlsoBoughtModallHeader = styled.p`
  font-weight: 600;
  font-size: ${({ theme }) => theme.typography.sizes.small};
`;

export const MobileArrow = styled.label<{ isActive: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    transition: transform ${({ theme }) => theme.transitionDuration * 2.5};
    width: 15px;
    ${({ isActive }) => isActive && `transform: scaleY(-1);`}

    path {
      fill: ${({ theme }) => theme.colors.black} !important;
      stroke: ${({ theme }) => theme.colors.white};
    }
  }
`;

export const CollectionWrapper = styled.div<{ isActive: boolean }>`
  height: ${({ isActive }) => (isActive ? "300px" : "0")};
  padding: ${({ isActive }) => (isActive ? "20px 2rem" : "0")};
  transition: ${({ isActive }) =>
    isActive ? `height 350ms ease-in-out` : "none"};
`;
