import { css } from "styled-components";

export const px2rem = (...size: number[]): string =>
  size.map(s => `${s / 16}rem`).join(" ");

type GenerateFlexItemSizeProps = {
  cols: number;
  maxWidth?: boolean;
  offset?: number;
};
export const generateFlexItemSize = ({
  cols,
  offset = 0,
  maxWidth = true,
}: GenerateFlexItemSizeProps) => {
  const size = 100 / cols;
  return css`
    flex: 1 1 calc(${size}% - ${offset}px);
    max-width: ${maxWidth ? `calc(${size}% - ${offset}px)` : "initial"};
  `;
};
