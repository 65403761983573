import { asLink } from "@prismicio/client";
import Link from "next/link";
import { FooterDocumentData } from "prismicio-types";
import React from "react";
import { useIntl } from "react-intl";

import { FooterMessages } from "@components/Footer/message";

import { SOCIAL_LOGOS } from "../../misc";

import * as S from "../../styles";

interface SocialIconProps {
  list: FooterDocumentData["social_links"];
}

export const SocialIcons = ({ list }: SocialIconProps) => {
  const { formatMessage } = useIntl();

  return (
    <>
      {list.map(({ link, name }) => {
        if (asLink(link) && name) {
          return (
            <Link passHref href={asLink(link) as string} key={name}>
              <S.Link title={formatMessage(FooterMessages.iconTitle, { name })}>
                {SOCIAL_LOGOS[name?.toLowerCase()]}
              </S.Link>
            </Link>
          );
        }
      })}
    </>
  );
};
