import { createContext } from "react";

import { PlayerContext, PlayerContextState, PlayerState } from "./types";

const defaultPlayerState: PlayerState = {
  album: null,
  controlsDisabled: false,
  error: null,
  isPlaying: false,
  isPlaylistExpanded: false,
  isVisible: false,
  trackCompletionRatio: 0,
  trackDuration: 0,
  trackIndex: 0,
  trackList: [],
};

export const defaultPlayerContextState: PlayerContextState = {
  albums: [],
  isReady: false,
  isVisible: false,
  playerState: defaultPlayerState,
};
export const defaultPlayerContext: PlayerContext = {
  albumPush: () => undefined,
  albums: [],
  closePlayer: () => undefined,
  handlers: {
    trackPause: () => undefined,
    trackPlay: () => undefined,
    trackStop: () => undefined,
    trackToggle: () => undefined,
  },
  isReady: false,
  isVisible: false,
  openPlayer: () => undefined,
  playerState: defaultPlayerState,
};

export const PlayerProviderContext =
  createContext<PlayerContext>(defaultPlayerContext);

PlayerProviderContext.displayName = "PlayerProviderContext";
