const accountBasePath = "/account";
const spotifyBasePath = "/spotify";

export const paths = {
  account: accountBasePath,
  accountAddressBook: `${accountBasePath}/address-book`,
  accountCommunicationPreferences: `${accountBasePath}/communication-preferences`,
  accountFavourites: `${accountBasePath}/favourites`,
  accountLogin: `${accountBasePath}/login`,
  accountLoginCheckout: `${accountBasePath}/login/checkout`,
  accountMemberships: `${accountBasePath}/my-memberships`,
  accountOrderDetails: `${accountBasePath}/orders/:token`,
  accountOrders: `${accountBasePath}/orders`,
  accountPaymentMethods: `${accountBasePath}/payment-methods`,
  accountProfile: `${accountBasePath}/profile`,
  allEvents: "/events",
  allGenres: "/genres",
  artist: "/artist/:slug",
  artistIndex: "/artist/",
  basepath: "/:locale([a-z]{2})",
  cart: "/cart",
  checkout: "/checkout",
  club: "/club/:slug",
  clubForm: "/club/:slug/:action",
  clubHome: "/club/subscribe",
  clubPartnerBenefits: "/club/partner-discounts",
  collection: "/collection/:slug",
  companyDetails: "/company-details",
  competitions: "/comp",
  competitionsTermsAndConditions: "/comp/terms",
  event: "/events/:slug",
  genre: "/genres/:slug",
  genreIndex: "/genres/",
  giftCards: "/gift-cards",
  help: "/help",
  home: "/",
  internalServerError: "/500",
  label: "/label/:slug",
  labelIndex: "/label/",
  maintenance: "/maintenance",
  ordersConfirmation: "/confirmation/:tokens+",
  passwordReset: "/reset-password",
  privacyPolicy: "/privacy",
  product: "/product/:artist/:productSlug",
  productVariant: "/product/:artist/:productSlug/:variantSlug",
  search: "/search",
  searchArtist: "/search/artist/:slug",
  searchCollection: "/search/collection/:slug",
  searchItemType: "/search/product-type/:slug",
  splashPageEu: "/eu_coming_soon",
  spotifyAuthorize: `${spotifyBasePath}/authorize`,
  storePage: "/stores/:slug",
  termsAndConditions: "/terms",
};

// FIXME: how can we do dynamic paths using react router generatePath
export const clientPaths = {
  collectionView: "/collection/[collectionSlug]",
  collectionViewWithFilters: "/collection/[collectionSlug]/[...filters]",
  listingSearch: "/search",
};

export const externalPaths = {
  spotify: {
    eu: `https://open.spotify.com/user/${process.env.NEXT_PUBLIC_SPOTIFY_RT_USER_ID_EU}`,
    gb: `https://open.spotify.com/user/${process.env.NEXT_PUBLIC_SPOTIFY_RT_USER_ID_GB}`,
    us: `https://open.spotify.com/user/${process.env.NEXT_PUBLIC_SPOTIFY_RT_USER_ID_US}`,
  },
};
