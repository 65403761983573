import { SliceZone } from "@prismicio/react";
import { components } from "@slicesComponents";
import {
  MainNavigationDocumentDataMenuItemItem,
  NavSubMenuDocumentData,
} from "prismicio-types";
import React from "react";

import { useHeaderStore } from "@stores/useHeaderStore";

import { ReactComponent as ArrowBack } from "assets/icons/arrow-back.svg";

import * as S from "./styles";

interface DropdownProps {
  data: NavSubMenuDocumentData;
  isActive?: boolean;
  variant: MainNavigationDocumentDataMenuItemItem["variant"];
}

const SUBMENU_LIMIT = 4;

export const Dropdown = ({ data, isActive, variant }: DropdownProps) => {
  const {
    topBanner,
    nav: { menu },
  } = useHeaderStore();

  const { slices } = data || {};
  const count = Array.isArray(slices) ? slices?.length : 0;

  return (
    <>
      <S.DropdownOuter isActive={isActive} isBannerVisible={topBanner.isActive}>
        <S.DropdownWrapper isActive={isActive}>
          <S.InnerMenuWrapper>
            {Array.isArray(slices) && (
              <SliceZone
                components={components}
                context={{ totalCount: count, variant }}
                slices={slices.slice(0, SUBMENU_LIMIT)}
              />
            )}
          </S.InnerMenuWrapper>

          <S.BackButton
            isActive={isActive}
            onClick={() => menu.updateMenu({ activeTab: null })}
            variant={variant}
          >
            <ArrowBack />
          </S.BackButton>
        </S.DropdownWrapper>
      </S.DropdownOuter>
    </>
  );
};

Dropdown.displayName = "Dropdown";
