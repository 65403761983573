import { styled } from "@styles";

export const Container = styled.div`
  pointer-events: none;
  transition: opacity 200ms linear;
`;

export const Bar = styled.div`
  background: rgba(238, 238, 238, 0.8);
  height: 6px;
  left: 0;
  right: 0;
  position: fixed;
  top: 0;
  transition: width 200ms linear;
  z-index: 9999;
`;

export const Spinner = styled.div`
  box-shadow: 0 0 10px rgba(238, 238, 238, 0.37),
    0 0 5px rgba(238, 238, 238, 0.47);
  display: block;
  height: 100%;
  opacity: 1;
  position: absolute;
  right: 0;
  transform: rotate(3deg) translate(0px, -4px);
  width: 100px;
`;
