import styled from "styled-components";

import { px2rem } from "@styles";

export const BasketLink = styled.a`
  font-size: ${px2rem(16)};
  color: ${({ theme }) => theme.colors.orange};
  cursor: pointer;
  font-weight: 500;
`;
