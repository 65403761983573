import { ApolloClient } from "@apollo/client";
import { Checkout } from "@gqlTypes/Checkout";
import { CheckoutLine } from "@gqlTypes/CheckoutLine";
import { User } from "@gqlTypes/User";
import { logMutationError } from "@graphql/utils";

import { LocalStorageKey } from "@config";
import { getReleaseDate } from "@utils/attributes";
import { getCollectionPoint } from "@utils/checkout";
import { isInPreorder } from "@utils/products";
import { ApiError } from "@utils/typescript";

import { initialContext } from "./context";
import {
  SplitCheckoutLines,
  SplitCheckoutLinesVariables,
} from "./gqlTypes/SplitCheckoutLines";
import { splitCheckoutLinesMutation } from "./mutations";
import { CheckoutState, DeliveryOption } from "./types";

export const getFlowInitialState = (
  checkout: Checkout,
  user: User
): CheckoutState => {
  const defaultState = initialContext.state;
  const { defaultCard, paymentGateway } = initialContext;
  const hasDeliveryMethod = !!checkout.deliveryMethod;
  const hasBillingAddress = !!checkout.billingAddress;
  const hasShippingAddress = !!checkout.shippingAddress;
  const { isShippingRequired } = checkout;
  const storedDeliveryOption = localStorage.getItem(
    LocalStorageKey.DELIVERY_OPTION
  ) as DeliveryOption | undefined;

  if (
    hasDeliveryMethod &&
    hasShippingAddress &&
    hasBillingAddress &&
    defaultCard
  ) {
    return {
      ...defaultState,
      paymentMethod: {
        data: defaultCard.card,
        gatewayId: paymentGateway,
        id: defaultCard.id,
        type: "card",
      },
      type: "summary",
    };
  }

  if (hasDeliveryMethod) {
    const collectionPoint = getCollectionPoint(checkout.deliveryMethod!);
    return {
      ...defaultState,
      deliveryOption: collectionPoint ? "clickAndCollect" : "delivery",
      type: "payment",
    };
  }

  if (!isShippingRequired) {
    return { ...defaultState, type: "payment" };
  }

  if (hasShippingAddress) {
    return {
      ...defaultState,
      deliveryOption: storedDeliveryOption ?? defaultState.deliveryOption,
      type: "deliveryAddress",
    };
  }

  return defaultState;
};

/**
 * Groups lines based on preorder dates.
 */
export const groupCheckoutLines = (lines: Checkout["lines"]) => {
  const today = new Date();

  const sortByPreorderDate = (a: CheckoutLine, b: CheckoutLine) => {
    const aReleaseDate = getReleaseDate(a.variant.attributes) as Date | null;
    const bReleaseDate = getReleaseDate(b.variant.attributes) as Date | null;
    const aDate: Date = aReleaseDate || today;
    const bDate: Date = bReleaseDate || today;

    return aDate.getTime() - bDate.getTime();
  };
  const groupByPreorderDate = (
    groups: { [key: string]: CheckoutLine[] },
    line: CheckoutLine
  ) => {
    const releaseDate = getReleaseDate(line.variant.attributes) as Date | null;
    const date = releaseDate
      ? isInPreorder(line.variant.attributes)
        ? releaseDate.toISOString()
        : "now"
      : "now";

    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(line!);
    return groups;
  };

  const groups = [...(lines as CheckoutLine[])]
    .sort(sortByPreorderDate)
    .reduce(groupByPreorderDate, {});

  return Object.values(groups);
};

export const clearCheckoutQueue = () =>
  localStorage.removeItem(LocalStorageKey.CHECKOUT_QUEUE);

export const updateCheckoutLines = async (
  apolloClient: ApolloClient<any>,
  newCheckoutLines: CheckoutLine[],
  checkout: Checkout
): Promise<{ checkout: Partial<Checkout> | null; errors: ApiError[] }> => {
  const newLineVariantIds = newCheckoutLines.map(({ variant: { id } }) => id);
  const lines = (checkout!.lines as CheckoutLine[]).map(
    ({ quantity, variant: { id: variantId } }) => ({
      quantity: newLineVariantIds.includes(variantId) ? quantity : 0,
      variantId,
    })
  );
  const { data } = await apolloClient.mutate<
    SplitCheckoutLines,
    SplitCheckoutLinesVariables
  >({
    mutation: splitCheckoutLinesMutation,
    variables: { id: checkout.id, lines },
  });
  const errors = data?.checkoutLinesUpdate?.errors ?? [];

  if (errors.length) {
    logMutationError(errors, {
      extra: { checkout },
      reason: "split checkout lines",
    });
    return { checkout: null, errors };
  }
  return { checkout: data!.checkoutLinesUpdate!.checkout, errors: [] };
};
