import { AttributeValue } from "@gqlTypes/AttributeValue";
import { SliderCollection_collection_products_edges_node } from "@gqlTypes/SliderCollection";
import Image from "next/image";
import React, { CSSProperties, useRef } from "react";

import { Link } from "@components/Link";
import { ProductBadges } from "@components/ProductBadges";
import { useIsLgUp } from "@hooks/responsive";
import { useRouter } from "@hooks/useRouter";
import { gtmSelectItemListingHit } from "@providers/GoogleTagManagerProvider";
import { getCountryByLocale } from "@providers/TranslationProvider";
import { useSearchInsightsStore } from "@stores/useSearchInsightsStore";
import {
  generateArtistPath,
  generateProductPath,
  getArtists,
  getExclusive,
  getPriceRange,
  getSigned,
} from "@utils";

import FallbackImage from "assets/images/fallback-image.jpg";

import * as S from "./styles";
import { DEFAULT_MOBILE_COVER_SIZE } from "./styles";

export interface SaleorHitProps {
  desktopCoverSize?: number;
  hit: SliderCollection_collection_products_edges_node;
  inNavigation?: boolean;
  isPriority?: boolean;
  light?: boolean;
  location?: string;
  onVisibleFireTag?: boolean;
  position?: number;
  showPrice?: boolean;
  sizes?: string;
  textAlign?: CSSProperties["textAlign"];
}

export const SaleorHitComponent = ({
  hit,
  textAlign,
  sizes,
  inNavigation = false,
  light = false,
  desktopCoverSize = DEFAULT_MOBILE_COVER_SIZE,
  showPrice = true,
  location = "Search",
  position = 1,
  onVisibleFireTag = false,
  isPriority = false,
}: SaleorHitProps) => {
  const { locale } = useRouter();
  const isDesktop = useIsLgUp();
  const { setSearchQueryID } = useSearchInsightsStore();
  const { id, name, pricing, attributes, slug, collections, thumbnail } = hit;

  const signed = getSigned(attributes);
  const exclusive = getExclusive(attributes);
  const sale = pricing?.onSale;
  const artists = (getArtists(attributes, true) as AttributeValue[])
    .map(({ name, slug }) => ({
      name,
      slug,
    }))
    .filter(Boolean);

  const productRef = useRef(null);
  const creators = artists.map(({ name }) => name).join(", ");
  const coverSize = isDesktop ? desktopCoverSize : DEFAULT_MOBILE_COVER_SIZE;
  const productPath = generateProductPath({
    artist: artists.map(({ slug }) => slug as string),
    slug,
  });
  const country = getCountryByLocale(locale);

  const handleClick = (
    hit: SliderCollection_collection_products_edges_node
  ) => {
    setSearchQueryID(id);
    gtmSelectItemListingHit(
      {
        __position: position,
        artists,
        currency: country?.currency,
        id,
        name,
      },
      "Basket Modal",
      location
    );
  };

  const priceRange =
    pricing &&
    showPrice &&
    getPriceRange({
      currency: country?.currency,
      language: country?.language,
      max: pricing?.priceRange?.stop?.net.amount || 0,
      min: pricing?.priceRange?.start?.net.amount || 0,
    });

  return (
    <S.ProductCard
      data-insights-query-id={id}
      key={slug}
      onClick={() => handleClick(hit)}
      ref={productRef}
      textAlign={textAlign}
    >
      <Link
        wrapperTag
        aProps={{
          "aria-label": name,
          className: "carousel-product-link",
          title: `${name} by ${creators}`,
        }}
        href={productPath}
      >
        <S.ThumbnailWrapper coverSize={coverSize}>
          <Image
            alt={`Album artwork for ${name} by ${creators}`}
            layout="fill"
            objectFit="contain"
            placeholder="empty"
            priority={isPriority}
            sizes={sizes ?? "(min-width: 1200px) 12vw, 30vw"}
            src={thumbnail?.url ?? FallbackImage}
            title={`${creators} - ${name}`}
          />
        </S.ThumbnailWrapper>
        <S.Name inNavigation={inNavigation} light={light}>
          {name}
        </S.Name>
      </Link>
      <S.Creators inNavigation={inNavigation} light={light}>
        {artists.map(({ name, slug }, i) => {
          if (slug) {
            return (
              <React.Fragment key={slug}>
                <Link wrapperTag href={generateArtistPath({ artist: [slug] })}>
                  {name}
                </Link>
                {i + 1 < artists.length && ", "}
              </React.Fragment>
            );
          }
        })}
      </S.Creators>
      {showPrice && (
        <S.PricesContainer>
          <S.Price>{priceRange}</S.Price>
          <ProductBadges
            collections={collections}
            exclusive={(exclusive as boolean) || false}
            sale={sale || false}
            signed={(signed as boolean) || false}
          />
        </S.PricesContainer>
      )}
    </S.ProductCard>
  );
};

SaleorHitComponent.displayName = "SaleorHitComponent";
