import { Money } from "@gqlTypes/Money";
import React, { useMemo } from "react";

import { useRouter } from "@hooks/useRouter";
import { getChannel } from "@providers/ConfigProvider/helpers";
import {
  CountryDetails,
  getCountryByLocale,
} from "@providers/TranslationProvider";
import { formatNumber } from "@utils/payment";

import * as S from "./styles";

interface FormattedPriceProps {
  extraSuffix?: React.ReactNode;
  noCents?: boolean;
  price: Omit<Money, "__typename">;
  salePrice?: Omit<Money, "__typename">;
  tag?: keyof JSX.IntrinsicElements;
  variant?: "cart";
}

export const FormattedPrice = ({
  price: { amount, currency },
  noCents,
  extraSuffix,
  tag,
  salePrice,
  variant,
}: FormattedPriceProps) => {
  const { locale } = useRouter();
  const fractionDigits = noCents ? 0 : 2;
  const Tag = tag || React.Fragment;
  const isOnSale = (!!salePrice && salePrice?.amount !== amount) || false;
  const isDiscountedCart = variant === "cart" && isOnSale;
  const channel = getChannel(locale);
  const enforceLocale = channel !== "eu";
  const country: CountryDetails = useMemo(
    () => getCountryByLocale(locale),
    [locale]
  );

  return (
    <S.Wrapper discounted={isDiscountedCart}>
      {isOnSale ? (
        <Tag>
          <S.Discount discounted={isDiscountedCart}>
            {formatNumber({
              amount: salePrice?.amount || 0,
              currency: currency || country.currency,
              fractionDigits,
              language: enforceLocale ? country.language : "de",
            })}
          </S.Discount>
          {extraSuffix && extraSuffix}
        </Tag>
      ) : null}
      <Tag>
        <S.Price discounted={isDiscountedCart} isOnSale={isOnSale}>
          {formatNumber({
            amount,
            currency: currency || country.currency,
            fractionDigits,
            language: enforceLocale ? country.language : "de",
          })}
          {extraSuffix && extraSuffix}
        </S.Price>
      </Tag>
    </S.Wrapper>
  );
};
