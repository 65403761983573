import { media, styled } from "@styles";
import { dynamicFullHeight } from "@utils/navigation";

export const ToastList = styled.div<{ isBannerVisible: boolean }>`
  height: 60px;
  width: 100%;
  position: absolute;
  top: 100%;
  right: 0;
  overflow: hidden;

  &.empty {
    display: none;
  }

  ${media.md.up} {
    height: auto;
    display: block;
    overflow: scroll;
    max-height: ${({ isBannerVisible }) =>
      dynamicFullHeight(
        "desktop",
        isBannerVisible ? ["promoBanner", "header"] : ["header"]
      )};
    width: 400px;
  }
`;
