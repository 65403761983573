import gql from "graphql-tag";

import { MetadataKey } from "@config";

export const moneyFragment = gql`
  fragment Money on Money {
    amount
    currency
  }
`;

export const taxedPriceFragment = gql`
  ${moneyFragment}
  fragment TaxedPrice on TaxedMoney {
    gross {
      ...Money
    }
    net {
      ...Money
    }
    tax {
      ...Money
    }
  }
`;

export const pricingFragment = gql`
  ${taxedPriceFragment}
  fragment Pricing on ProductPricingInfo {
    priceRange {
      start {
        ...TaxedPrice
      }
      stop {
        ...TaxedPrice
      }
    }
    onSale
  }
`;

export const variantPricingInfoFragment = gql`
  ${taxedPriceFragment}
  fragment VariantPricing on VariantPricingInfo {
    price {
      ...TaxedPrice
    }
    onSale
    priceUndiscounted {
      ...TaxedPrice
    }
  }
`;

export const metadataErrorFragment = gql`
  fragment MetadataError on MetadataError {
    field
    message
    code
  }
`;

export const metadataFragment = gql`
  fragment MetadataItem on MetadataItem {
    value
    key
  }
`;

export const productChannelListingFragment = gql`
  fragment ProductChannelListing on ProductChannelListing {
    isPublished
    publishedAt
  }
`;

export const productCollectionFragment = gql`
  ${metadataFragment}
  fragment Collection on Collection {
    id
    slug
    name
    description
    backgroundImage {
      url
      alt
    }
    metadata {
      ...MetadataItem
    }
    translation(languageCode: $languageCode) {
      id
      description
      name
    }
  }
`;

export const productCollectionFragmentSSR = gql`
  ${metadataFragment}
  fragment Collection on Collection {
    id
    slug
    name
    description
    backgroundImage {
      url
      alt
    }
    metadata {
      ...MetadataItem
    }
    channelListings {
      isPublished
      channel {
        id
        name
      }
    }
    translation(languageCode: $languageCode) {
      id
      description
      name
    }
  }
`;

export const productCollectionSEOFragment = gql`
  fragment CollectionSeoFields on Collection {
    seoDescription
    seoTitle
  }
`;

export const checkoutErrorFragment = gql`
  fragment CheckoutError on CheckoutError {
    field
    message
    code
  }
`;

export const checkoutVoucherFragment = gql`
  fragment Voucher on Voucher {
    id
    name
    code
  }
`;

export const checkoutVoucherErrorFragment = gql`
  fragment DiscountError on DiscountError {
    field
    message
    code
  }
`;

export const creditCardFragment = gql`
  fragment CreditCard on CreditCard {
    brand
    expMonth
    expYear
    lastDigits
  }
`;

export const paymentFragment = gql`
  ${moneyFragment}
  ${creditCardFragment}
  fragment Payment on Payment {
    id
    created
    gateway
    total {
      ...Money
    }
    creditCard {
      ...CreditCard
    }
  }
`;

export const paymentErrorFragment = gql`
  fragment PaymentError on PaymentError {
    field
    message
    code
  }
`;

export const orderErrorFragment = gql`
  fragment OrderError on OrderError {
    field
    message
    code
  }
`;

export const accountErrorFragment = gql`
  fragment AccountError on AccountError {
    field
    message
    code
  }
`;

export const giftCardFragment = gql`
  ${moneyFragment}
  fragment GiftCard on GiftCard {
    displayCode
    id
    isActive
    currentBalance {
      ...Money
    }
  }
`;

export const userGiftCardFragment = gql`
  ${giftCardFragment}
  fragment UserGiftCard on GiftCard {
    ...GiftCard
    code
  }
`;

export const productThumbnailFragment = gql`
  fragment Thumbnail on Image {
    url
    alt
  }
`;

export const productTypeFragment = gql`
  fragment ProductType on ProductType {
    name
    id
    slug
  }
`;

export const attributeValueFragment = gql`
  fragment AttributeValue on AttributeValue {
    name
    id
    slug
    date
    dateTime
    boolean
    richText
    value
    translation(languageCode: $languageCode) {
      id
      name
      richText
    }
  }
`;

export const attributeFragment = gql`
  fragment Attribute on Attribute {
    id
    name
    slug
  }
`;

export const selectedAttributeFragment = gql`
  ${attributeValueFragment}
  ${attributeFragment}
  fragment SelectedAttribute on SelectedAttribute {
    attribute {
      ...Attribute
    }
    values {
      ...AttributeValue
    }
  }
`;

export const collectionProductFragment = gql`
  ${pricingFragment}
  ${productThumbnailFragment}
  ${selectedAttributeFragment}
  ${productCollectionFragment}
  ${productChannelListingFragment}
  fragment CollectionProduct on Product {
    id
    name
    slug
    channelListings {
      ...ProductChannelListing
    }
    collections {
      ...Collection
    }
    pricing {
      ...Pricing
    }
    attributes {
      ...SelectedAttribute
    }
    thumbnail(size: 1024, format: WEBP) {
      ...Thumbnail
    }
    variants {
      id
      metafields(keys: [
        "${MetadataKey.VARIANT_RATING}",
        "${MetadataKey.VARIANT_UNPUBLISHED}",
        "${MetadataKey.VARIANT_ARCHIVING}"
      ])
    }
  }
`;

export const productMediaFragment = gql`
  fragment ProductMedia on ProductMedia {
    id
    sortOrder
    alt
    oembedData
    type
    url(size: 1024, format: WEBP)
    thumbnail: url(size: 1024, format: WEBP)
  }
`;

export const productVariantFragment = gql`
  ${selectedAttributeFragment}
  ${productMediaFragment}
  ${metadataFragment}
  ${variantPricingInfoFragment}
  fragment ProductVariant on ProductVariant {
    id
    name
    sku
    quantityAvailable
    quantityLimitPerCustomer
    media {
      ...ProductMedia
    }
    attributes {
      ...SelectedAttribute
    }
    metafields(keys: [
      "${MetadataKey.VARIANT_RATING}",
      "${MetadataKey.VARIANT_UNPUBLISHED}",
      "${MetadataKey.VARIANT_ARCHIVING}"
    ])
    metadata {
      ...MetadataItem
    }
    pricing {
      ...VariantPricing
    }
  }
`;

export const productFragment = gql`
  ${selectedAttributeFragment}
  ${productMediaFragment}
  ${productVariantFragment}
  ${productTypeFragment}
  ${productChannelListingFragment}
  ${productCollectionFragmentSSR}
  ${pricingFragment}
  ${metadataFragment}
  ${productThumbnailFragment}
  fragment Product on Product {
    id
    name
    slug
    description
    channelListings {
      ...ProductChannelListing
    }
    pricing {
      ...Pricing
    }
    collections {
      ...Collection
    }
    attributes {
      ...SelectedAttribute
      values {
        translation(languageCode: $languageCode) {
          id
          name
          richText
        }
      }
    }
    variants {
      ...ProductVariant
      stocks {
        quantity
        quantityAllocated
        warehouse {
          id
        }
      }
    }
    media {
      ...ProductMedia
    }
    thumbnail(size: 1024, format: WEBP) {
      ...Thumbnail
    }
    productType {
      ...ProductType
    }
    seoDescription
    metafields(keys: [
      "${MetadataKey.SPOTIFY_ALBUM_ID}",
      "${MetadataKey.deprecated_SPOTIFY_ID}",
      "${MetadataKey.REGION_US_PRODUCT_ID}",
      "${MetadataKey.REGION_GB_PRODUCT_ID}",
      "${MetadataKey.REGION_EU_PRODUCT_ID}",
    ])
    metadata {
      ...MetadataItem
    }
    translation(languageCode: $languageCode) {
      name
      description
      seoDescription
    }
  }
`;

export const pageInfoFragment = gql`
  fragment PageInfo on PageInfo {
    hasNextPage
    hasPreviousPage
    startCursor
    endCursor
  }
`;

export const countryFragment = gql`
  fragment Country on CountryDisplay {
    code
    country
  }
`;

export const addressFragment = gql`
  ${countryFragment}
  fragment Address on Address {
    id
    firstName
    lastName
    companyName
    streetAddress1
    streetAddress2
    city
    postalCode
    country {
      ...Country
    }
    countryArea
    phone
  }
`;

export const addressValidationFragment = gql`
  fragment AddressValidation on AddressValidationData {
    postalCodeExamples
    requiredFields
    countryAreaChoices {
      verbose
      raw
    }
    cityChoices {
      verbose
      raw
    }
  }
`;

export const checkoutLineFragment = gql`
  ${taxedPriceFragment}
  ${productMediaFragment}
  ${selectedAttributeFragment}
  ${productTypeFragment}
  ${productCollectionFragment}
  ${variantPricingInfoFragment}
  fragment CheckoutLine on CheckoutLine {
    id
    variant {
      id
      name
      sku
      media {
        ...ProductMedia
        src: url(size: 1024, format: WEBP)
      }
      attributes {
        ...SelectedAttribute
      }
      metadata {
        key
        value
      }
      metafields(keys: [
        "${MetadataKey.VARIANT_RATING}",
        "${MetadataKey.VARIANT_UNPUBLISHED}",
        "${MetadataKey.VARIANT_ARCHIVING}"
      ])
      quantityAvailable
      quantityLimitPerCustomer
      pricing {
        ...VariantPricing
      }
      product {
        name
        id
        slug
        collections {
          ...Collection
        }
        attributes {
          ...SelectedAttribute
        }
        media {
          ...ProductMedia
          src: url(size: 1024, format: WEBP)
        }
        productType {
          ...ProductType
        }
        variants {
          id
          media {
            url
          }
          attributes {
            ...SelectedAttribute
          }
          metafields(keys: [
            "${MetadataKey.VARIANT_RATING}",
            "${MetadataKey.VARIANT_UNPUBLISHED}",
            "${MetadataKey.VARIANT_ARCHIVING}"
          ])
        }
      }
    }
    quantity
    totalPrice {
      ...TaxedPrice
    }
  }
`;

export const shippingMethodFragment = gql`
  ${moneyFragment}
  ${metadataFragment}
  fragment ShippingMethod on ShippingMethod {
    id
    name
    minimumDeliveryDays
    maximumDeliveryDays
    price {
      ...Money
    }
    metadata {
      ...MetadataItem
    }
  }
`;

export const warehouseFragment = gql`
  ${addressFragment}
  fragment Warehouse on Warehouse {
    name
    id
    address {
      ...Address
    }
  }
`;

export const channelFragment = gql`
  fragment Channel on Channel {
    slug
  }
`;

export const paymentGatewayFragment = gql`
  fragment PaymentGateway on PaymentGateway {
    name
    id
    config {
      field
      value
    }
    currencies
  }
`;

export const checkoutBaseFragment = gql`
  ${checkoutLineFragment}
  ${taxedPriceFragment}
  ${channelFragment}
  fragment CheckoutBase on Checkout {
    id
    quantity
    email
    channel {
      ...Channel
    }
    lines {
      ...CheckoutLine
    }
    totalPrice {
      ...TaxedPrice
    }
    subtotalPrice {
      ...TaxedPrice
    }
  }
`;

export const deliveryMethodFragment = gql`
  ${warehouseFragment}
  ${shippingMethodFragment}
  fragment DeliveryMethod on DeliveryMethod {
    ...Warehouse
    ...ShippingMethod
  }
`;

export const checkoutFragment = gql`
  ${addressFragment}
  ${taxedPriceFragment}
  ${checkoutLineFragment}
  ${checkoutBaseFragment}
  ${deliveryMethodFragment}
  ${shippingMethodFragment}
  ${giftCardFragment}
  ${moneyFragment}
  ${paymentGatewayFragment}
  fragment Checkout on Checkout {
    ...CheckoutBase
    billingAddress {
      ...Address
    }
    shippingAddress {
      ...Address
    }
    availableShippingMethods {
      ...ShippingMethod
    }
    deliveryMethod {
      ...DeliveryMethod
    }
    shippingPrice {
      ...TaxedPrice
    }
    giftCards {
      ...GiftCard
    }
    voucherCode
    isShippingRequired
    discount {
      ...Money
    }
    availablePaymentGateways {
      ...PaymentGateway
    }
  }
`;

export const pageFragment = gql`
  fragment Page on Page {
    id
    seoDescription
    seoTitle
    seoTitle
    title
    content
  }
`;

export const avatarFragment = gql`
  fragment Avatar on Image {
    alt
    url
  }
`;

export const orderLineFragment = gql`
  ${taxedPriceFragment}
  ${productVariantFragment}
  ${productThumbnailFragment}
  ${selectedAttributeFragment}
  ${productTypeFragment}
  ${productCollectionFragment}
  fragment OrderLine on OrderLine {
    id
    productName
    quantity
    unitPrice {
      ...TaxedPrice
    }
    variant {
      metafields(keys: [
        "${MetadataKey.VARIANT_RATING}",
        "${MetadataKey.VARIANT_UNPUBLISHED}",
        "${MetadataKey.VARIANT_ARCHIVING}"
      ])
       stocks {
        quantity
        quantityAllocated
        warehouse {
          id
        }
      }
      product {
        id
        name
        collections {
          ...Collection
        }
        productType {
          ...ProductType
        }
        slug
        attributes {
          ...SelectedAttribute
        }
        variants {
          id
        }
      }
      ...ProductVariant
    }
    variantName
    undiscountedUnitPrice {
      ...TaxedPrice
    }
    thumbnail(size: 1024, format: WEBP) {
      ...Thumbnail
    }
  }
`;

export const fulfillmentFragment = gql`
  fragment Fulfillment on Fulfillment {
    id
    trackingNumber
    fulfillmentOrder
    status
  }
`;

export const orderDiscountFragment = gql`
  ${moneyFragment}
  fragment OrderDiscount on OrderDiscount {
    name
    value
    amount {
      ...Money
    }
  }
`;

export const orderFragment = gql`
  ${orderLineFragment}
  ${taxedPriceFragment}
  ${addressFragment}
  ${deliveryMethodFragment}
  ${fulfillmentFragment}
  ${orderDiscountFragment}
  ${paymentFragment}
  ${giftCardFragment}
  ${shippingMethodFragment}
  fragment Order on Order {
    created
    id
    number
    token
    isShippingRequired
    metafields(keys: [
      "${MetadataKey.CHECKOUT_TYPE}",
    ])
    shippingAddress {
      ...Address
    }
    shippingPrice {
      ...TaxedPrice
    }
    deliveryMethod {
      ...DeliveryMethod
    }
    billingAddress {
      ...Address
    }
    lines {
      ...OrderLine
    }
    fulfillments {
      ...Fulfillment
    }
    discounts {
      ...OrderDiscount
    }
    payments {
      ...Payment
    }
    paymentStatus
    paymentStatusDisplay
    status
    subtotal {
      ...TaxedPrice
    }
    total {
      ...TaxedPrice
    }
    voucher {
      discountValue
      code
      currency
      type
      discountValueType
    }
    giftCards {
      ...GiftCard
    }
  }
`;

export const paymentSourceFragment = gql`
  ${creditCardFragment}
  fragment PaymentSource on PaymentSource {
    paymentMethodId
    gateway
    creditCardInfo {
      ...CreditCard
    }
  }
`;

export const userFragment = gql`
  ${avatarFragment}
  ${addressFragment}
  ${metadataFragment}
  ${paymentSourceFragment}
  ${userGiftCardFragment}
  fragment User on User {
    id
    email
    firstName
    lastName
    avatar {
      ...Avatar
    }
    lastLogin
    metadata {
      ...MetadataItem
    }
    addresses {
      ...Address
    }
    defaultBillingAddress {
      ...Address
    }
    defaultShippingAddress {
      ...Address
    }
    storedPaymentSources(channel: $channel) {
      ...PaymentSource
    }
    giftCards(first: 100) {
      edges {
        node {
          ...UserGiftCard
        }
      }
    }
  }
`;

export const pricedProductVariantFragment = gql`
  ${productVariantFragment}
  ${collectionProductFragment}
  ${productTypeFragment}
  fragment PricedProductVariant on ProductVariant {
    ...ProductVariant
    product {
      ...CollectionProduct
      productType {
        ...ProductType
      }
    }
  }
`;

export const purchaseOrderLineFragment = gql`
  fragment PurchaseOrderLine on OrderLine {
    quantity
    taxRate
    variant {
      sku
      name
      attributes {
        ...SelectedAttribute
      }
      pricing {
        price {
          gross {
            amount
          }
        }
      }
      product {
        id
        name
        attributes {
          ...SelectedAttribute
        }
        collections {
          name
        }
      }
    }
  }
`;

export const purchaseOrderFragment = gql`
  ${selectedAttributeFragment}
  ${purchaseOrderLineFragment}
  fragment PurchaseOrder on Order {
    id
    token
    paymentStatus
    total {
      tax {
        amount
        currency
      }
      net {
        amount
        currency
      }
      gross {
        amount
        currency
      }
    }
    voucher {
      code
    }
    shippingPrice {
      gross {
        amount
      }
    }
    lines {
      ...PurchaseOrderLine
    }
  }
`;
