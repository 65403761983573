import { css } from "styled-components";

import { Button } from "@components/Button";
import { media, px2rem, styled, theme } from "@styles";

import { ReactComponent as LightSearchIcon } from "assets/icons/search.svg";

export const ErrorPageWrapper = styled.div<{ isModalView: boolean }>`
  display: flex;
  color: ${({ theme }) => theme.colors.white};
  padding: 10vh ${theme.spacing.gutter};

  ${({ isModalView }) =>
    isModalView &&
    css`
      height: 100vh;
      max-width: 100vw;
      justify-content: center;
      align-items: center;
      background: black;
    `}

  ${media.md.up} {
    overflow: hidden;
  }
`;

export const ErrorPageContent = styled.div`
  padding-top: 50px;
  padding-bottom: 30px;
  text-align: left;
`;

export const ErrorPageHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  ${media.md.up} {
    max-width: 80vw;
  }
  margin-left: ${theme.spacing.gutter};
  margin-bottom: ${px2rem(32)};
`;

export const LinksWrapper = styled.div`
  display: flex;
  flex-flow: column;
  gap: ${px2rem(16)};
  margin-left: ${theme.spacing.gutter};
`;

export const LinkText = styled.p`
  font-size: ${px2rem(18)};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: ${px2rem(16)};
`;

export const ErrorPageHeaderTitle = styled.header`
  font-size: ${px2rem(64)};
  font-weight: 400;
  margin-bottom: ${px2rem(18)};
`;

export const buttonStyles = css<{ active?: boolean }>`
  padding: ${px2rem(12, 22)};
  border: 1px solid transparent;
  text-decoration: none;
  width: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  margin: 0;
  font-weight: 500;
  font-size: ${({ theme }) => theme.button.fontSize};

  ${({ active, theme }) =>
    active
      ? `
  color: ${theme.colors.white};
  background-color: ${theme.colors.black};
  border-color: ${theme.colors.white};
  &:hover {
    color: ${theme.colors.black};
    background-color: ${theme.colors.white};
  }
  `
      : `
  color: ${theme.colors.black};
  background-color: ${theme.colors.white};
  border-color: ${theme.colors.white};
  &:hover {
    color: ${theme.colors.white};
    background-color: ${theme.colors.black};
  }
  `}
`;
export const Link = styled.a<{ active?: boolean }>`
  ${buttonStyles}
`;

export const ErrorButton = styled(Button)<{ active?: boolean }>`
  ${buttonStyles}
`;

export const ErrorPageSubheader = styled.p`
  font-family: ${({ theme }) => theme.typography.baseFontFamily};
  font-weight: 700;
  font-size: ${px2rem(32)};
  line-height: 1.2;
  text-transform: none;
  padding-top: 8px;
  padding-bottom: 8px;

  ${media.md.up} {
    font-weight: 400;
  }
`;

export const SearchIcon = styled(LightSearchIcon)`
  stroke: ${({ theme }) => theme.colors.black};
`;
