import type { NextApiRequest, NextApiResponse } from "next";

import { RequestWithHelpers, ResponseWithHelpers } from "./types";

export const isMethod =
  (req: Pick<NextApiRequest, "method">): RequestWithHelpers["isMethod"] =>
  method =>
    req.method === method;

export const extractFromJson =
  (req: Pick<NextApiRequest, "body">): RequestWithHelpers["extractFromJson"] =>
  (...keysToExtract) => {
    try {
      const json = JSON.parse(req.body);

      return Object.entries(json).reduce((obj, [key, value]) => {
        if (keysToExtract.includes(key)) {
          obj[key] = value;
        }
        return obj;
      }, {} as Record<string, any>);
    } catch (_) {
      return {};
    }
  };

export const extractBearerToken =
  (
    req: Pick<NextApiRequest, "headers">
  ): RequestWithHelpers["extractBearerToken"] =>
  () =>
    req.headers["authorization-bearer"] as string | undefined;

export const jsonResponse =
  (
    req: Pick<NextApiResponse, "status" | "json" | "setHeader">
  ): ResponseWithHelpers["jsonResponse"] =>
  (statusCode, data, cacheLength = null) => {
    if (cacheLength) {
      req.setHeader("Cache-Control", cacheLength);
    }

    req.status(statusCode).json(data ?? null);
  };
