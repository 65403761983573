import React, { forwardRef, useEffect, useRef, useState } from "react";

import { useInterval } from "@hooks/useInterval";
import { AlbumData, PlayerApi } from "@providers/PlayerProvider/types";

import * as S from "./styles";

export interface ProgressBarProps {
  album: AlbumData;
  isExpanded: boolean;
  isPlaying: boolean;
  onSeek: PlayerApi["onSeek"];
  onTrackEnd: () => Promise<void> | undefined;
  trackDuration: number;
  trackIndex: number;
}

export const ProgressBar = forwardRef<HTMLDivElement, ProgressBarProps>(
  (
    {
      trackDuration,
      isExpanded,
      onSeek,
      isPlaying,
      trackIndex,
      onTrackEnd,
      album,
    },
    ref
  ) => {
    const processingRef = useRef(false);
    const trackSwapRef = useRef(false);
    const [width, setWidth] = useState(0);

    const onTrackClick = async (event: React.MouseEvent<HTMLDivElement>) => {
      if (processingRef.current) {
        return false;
      }
      processingRef.current = true;

      const progressBar = event.currentTarget.getBoundingClientRect();
      const clickedCompletionRatio =
        (event.clientX - progressBar.left) / progressBar.width;
      setWidth(Math.round(clickedCompletionRatio * 100));
      await onSeek(clickedCompletionRatio);

      processingRef.current = false;
    };

    useEffect(() => {
      setWidth(0);
      trackSwapRef.current = false;
    }, [trackIndex]);

    useEffect(() => {
      if (width === 100) {
        if (!trackSwapRef.current) {
          trackSwapRef.current = true;
        }
        onTrackEnd();
        setWidth(0);
      }
    }, [width]);

    useInterval(
      () => {
        if (width >= 0 && width < 100) {
          setWidth(w => ++w);
        }
      },
      isPlaying && width <= 100 ? trackDuration / 100 : null
    );

    useEffect(() => {
      // Reset progress bar at API or album change.
      if (width) {
        setWidth(0);
      }
    }, [trackDuration, album]);

    return (
      <S.ProgressBarContainer
        isExpanded={isExpanded}
        onClick={onTrackClick}
        ref={ref}
      >
        <S.Track>
          <S.Progress style={{ width: `${width}%` }}>
            <S.Dragger />
          </S.Progress>
        </S.Track>
      </S.ProgressBarContainer>
    );
  }
);
