import { create } from "zustand";

type FilterStore = {
  filterActive: boolean;
  setFilterActive: (filterActive: boolean) => void;
};

export const useFilterStore = create<FilterStore>()(set => ({
  filterActive: false,
  setFilterActive: filterActive =>
    set(() => ({
      filterActive,
    })),
}));
