import { CheckoutLine } from "@gqlTypes/CheckoutLine";
import { CollectionProduct } from "@gqlTypes/CollectionProduct";
import { Product } from "@gqlTypes/Product";
import { PurchaseOrderLine } from "@gqlTypes/PurchaseOrderLine";

import { getArtists } from "@utils/attributes";

export const serializeProduct = (
  product: Product,
  activeVariantSku?: string
) => {
  const brand = getArtists(product.attributes).join(", ");
  const category = product.collections
    ?.map(collection => collection?.name)
    .join("/");

  const defaultVariantSku = product.variants?.[0]?.sku;
  const variant = activeVariantSku || defaultVariantSku;

  return {
    brand,
    category,
    id: product.id,
    name: product.name,
    variant,
  };
};

export const serializeLine = (line: CheckoutLine | PurchaseOrderLine) => {
  const { variant, quantity } = line;

  const brand = getArtists(variant!.product!.attributes).join(", ");
  const category = variant!.product.collections
    ?.map(collection => collection?.name)
    .join("/");

  return {
    brand,
    category,
    id: variant!.product.id,
    name: variant!.product.name,
    "object-id": variant!.product.id,
    price: variant!.pricing?.price?.gross.amount,
    quantity,
    variant: variant!.sku,
  };
};

export const serializeCollectionProduct = (
  product: CollectionProduct,
  idx: number
) => {
  const brand = getArtists(product.attributes).join(", ");
  const category = product.collections
    ?.map(collection => collection?.name)
    .join("/");

  return {
    brand,
    category,
    id: product.id,
    name: product.name,
    position: idx + 1,
  };
};
