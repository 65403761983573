import { paths } from "@paths";
import React from "react";

import { Grid, GridColumn } from "@components/Grid";
import { Link } from "@components/Link";
import { Logo } from "@components/Logo";

import { ReactComponent as ArrowBack } from "assets/icons/arrow-back.svg";

import * as S from "./styles";

export type HeaderProps = { children?: React.ReactNode; dark: boolean };

export const Header = ({ dark, children }: HeaderProps) => (
  <S.Wrapper dark={dark}>
    <Grid>
      <GridColumn flex alignItems="center">
        <Link passHref href={paths.home}>
          <a>
            <Logo dark={dark} size={`${!dark ? 42 : 36}px`} />
          </a>
        </Link>
        <Link passHref href={paths.home}>
          <S.HomeLink dark={dark}>
            <ArrowBack />
            Continue shopping
          </S.HomeLink>
        </Link>
      </GridColumn>
      <GridColumn>{children}</GridColumn>
    </Grid>
  </S.Wrapper>
);
