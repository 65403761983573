import { NextRouter } from "next/router";
import { ClubTierDocument } from "prismicio-types";

export enum ClubActions {
  GIFT = "gift",
  JOIN = "join",
}

export enum ClubRecipientValues {
  GIFT = "gift",
  SELF = "self",
}

export type GetDefaultFormValuesProps = {
  action: ClubActions;
  body: ClubTierDocument["data"]["slices"];
};

export type ClubFormValues = {
  checkoutPage: string;
  format: string;
  length: string;
  recipient: string;
  totalCost: number;
};

export type CheckRedirectRecipientProps = {
  currentAction: ClubActions | null;
  page: number;
  recipientTarget: ClubRecipientValues;
  router: NextRouter;
  tier: ClubTierDocument | null;
};

export type RemoveQueryParamProps = {
  key: string | number;
  router: NextRouter;
};
