import { getCookie } from "cookies-next";
import React, { useEffect, useMemo, useState } from "react";

import { Button } from "@components/Button";
import { Modal } from "@components/Modal";
import { useRouter } from "@hooks/useRouter";
import { getChannel, getCurrency } from "@providers/ConfigProvider/helpers";
import {
  getLocaleForCountry,
  getLocaleFromChannel,
} from "@providers/TranslationProvider/helpers";
import type { Channel, Locale } from "@providers/TranslationProvider/types";
import { useGlobalConfigStore } from "@stores/useGlobalConfigStore";
import { getCurrencySymbolOnly } from "@utils/payment";

import * as S from "./styles";

export const RegionRedirectModal = () => {
  const { locale, country: requestCountry, asPath, push } = useRouter();
  const { locale_switcher } = useGlobalConfigStore();

  const preferredChannelCookie = getCookie("PREFERRED_CHANNEL") as Channel;

  const {
    button_text_remain,
    button_text_switch,
    switch_text_continued,
    current_locale,
    footer_note,
    switch_text_1,
  } = locale_switcher?.data || {};

  const { showModal, channel, suggested, currencyText } = useMemo(() => {
    const channel = getChannel(locale as Locale);

    const currency = getCurrency(channel);
    const symbol = getCurrencySymbolOnly(channel, currency);
    const current = {
      channel,
      currency,
      currencyText: `${currency} (${symbol})`,
    };

    // NOTE: When testing replace the `requestCountry` with a country code e.g. "AF"
    const requiredLocale = getLocaleForCountry(requestCountry, true);
    const suggestedChannel =
      preferredChannelCookie || getChannel(requiredLocale as Locale);
    const suggestedLocale = getLocaleFromChannel(suggestedChannel);
    const suggestedCurrency = getCurrency(suggestedChannel);
    const suggestedSymbol = getCurrencySymbolOnly(
      suggestedChannel,
      suggestedCurrency
    );

    const showModal = suggestedChannel !== channel;

    const suggested = {
      channel: suggestedChannel,
      currency: suggestedCurrency,
      currencyText: `${suggestedCurrency} (${suggestedSymbol})`,
      locale: suggestedLocale,
    };

    return {
      ...current,
      showModal,
      suggested,
    };
  }, [locale, requestCountry, preferredChannelCookie]);

  const [isOpen, setIsOpen] = useState(showModal);

  const handleClose = (passedChannel?: string) => {
    const setChannel = passedChannel || channel;

    if (setChannel) {
      document.cookie = `PREFERRED_CHANNEL=${setChannel}; max-age=604800; path=/`; // 1 week
    }

    setIsOpen(false);
  };

  const switchChannel = () => {
    if (suggested.channel && showModal) {
      const path = asPath.startsWith("/product") ? "/" : asPath;
      document.cookie = `NEXT_LOCALE=${suggested.locale}; max-age=31536000; path=/`; // 1 year

      handleClose(suggested.channel);
      push(path, path, {
        locale: suggested.locale,
      });
    }
  };

  useEffect(() => {
    setIsOpen(showModal);
  }, [showModal]);

  if (!locale_switcher) {
    return null;
  }

  return (
    <Modal
      hasOverlay={false}
      isOpen={isOpen}
      onClose={() => handleClose()}
      variant="small-left"
    >
      <S.Label>{`${current_locale} ${currencyText} `}</S.Label>
      <S.SwitchLabel>{`${switch_text_1} ${suggested.currencyText} ${switch_text_continued}`}</S.SwitchLabel>

      <S.ButtonWrapper>
        <Button
          fullWidth
          fontSize="medium"
          onClick={() => handleClose()}
          variant="hollow"
        >
          {`${button_text_remain} ${currencyText} `}
        </Button>
        <Button
          fullWidth
          fontSize="medium"
          onClick={() => switchChannel()}
          variant="dark"
        >
          {`${button_text_switch} ${suggested.currencyText}`}
        </Button>
      </S.ButtonWrapper>

      <S.Label>{`${footer_note}`}</S.Label>
    </Modal>
  );
};
