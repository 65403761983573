import React, { useRef } from "react";

import { useToast } from "@hooks/useToast";
import { useHeaderStore } from "@stores/useHeaderStore";

import * as S from "./styles";

export const Toast = () => {
  const { topBanner } = useHeaderStore();
  const ToastRef = useRef<HTMLDivElement>(null);
  const { toastItems, hasToasts } = useToast({
    ToastRef,
  });

  return (
    <S.ToastList
      className={`toast-container ${!hasToasts ? "empty" : "active"}`}
      isBannerVisible={topBanner.isActive}
      ref={ToastRef}
    >
      {toastItems}
    </S.ToastList>
  );
};
