import React from "react";
import { FormattedMessage } from "react-intl";

import { FormattedPrice } from "@components/FormattedPrice";
import { clubVoucherCode } from "@config";
import { useIsMounted } from "@hooks/useIsMounted";
import { useToggle } from "@hooks/useToggle";
import { commonMessages } from "@messages/common";
import { getTestAttribute } from "@utils";
import { Discount as TDiscount } from "@utils/typescript";

import * as S from "./styles";

type DiscountProps = TDiscount & {
  onDiscountRemove?: (opts: {
    code: string;
    id: string | undefined;
  }) => Promise<void>;
  type: "promoCode" | "giftCard";
};

export const Discount = ({
  code,
  currentBalance,
  discount,
  type,
  onDiscountRemove,
  id,
}: DiscountProps) => {
  const [processing, toggleProcessing] = useToggle();
  const isMounted = useIsMounted();
  const isGiftCard = type === "giftCard";

  const handleRemove = async () => {
    toggleProcessing();
    await onDiscountRemove!({ code, id });
    if (isMounted.current) {
      toggleProcessing();
    }
  };

  const displayCode = code.includes(clubVoucherCode) ? clubVoucherCode : code;

  return (
    <S.Container processing={processing} {...getTestAttribute(code, type)}>
      <S.DiscountWrapper>
        <span>
          {type === "promoCode" && displayCode}
          {type === "giftCard" && currentBalance && (
            <FormattedPrice price={currentBalance} />
          )}
        </span>
        {onDiscountRemove && (
          <S.Remove onClick={handleRemove} processing={processing}>
            <FormattedMessage {...commonMessages.remove} />
          </S.Remove>
        )}
        {isGiftCard && (
          <S.GiftCard>
            {" "}
            <FormattedMessage {...commonMessages.giftCard} />
          </S.GiftCard>
        )}
      </S.DiscountWrapper>
      {discount && (
        <S.DiscountAmount>
          - <FormattedPrice price={discount} />
        </S.DiscountAmount>
      )}
      {isGiftCard && (
        <S.Approve>
          <FormattedMessage {...commonMessages.approved} />
        </S.Approve>
      )}
    </S.Container>
  );
};
