import { clubFixedElements } from "@layouts/ClubFormLayout/common.styles";
import { media, styled } from "@styles";

export const ClubFormFooter = styled.footer`
  justify-content: space-between;
  ${clubFixedElements}
`;

export const FormActions = styled.div`
  flex: 1;
  display: flex;
  flex-flow: row;
  gap: 1rem;
`;

export const FormStepCounter = styled.span<{ mobileOnly?: boolean }>`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 14px;
  ${({ mobileOnly }) =>
    mobileOnly ? "flex: 0; display: flex;" : "display: none;"}

  ${media.md.up} {
    ${({ mobileOnly }) => (mobileOnly ? "display: none" : "display: flex;")}
  }
`;

export const StepIndicator = styled.span`
  font-weight: 500;
  font-size: 2rem;
  margin-right: 4px;
`;

export const StepWrapper = styled.span`
  font-weight: 600;
  display: flex;
  flex-flow: row;
  align-items: center;
  gap: 3px;
`;
