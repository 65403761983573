import React, { useEffect, useRef } from "react";
import ReactTooltip from "react-tooltip";

import * as S from "./styles";

export type TooltipProps = {
  autoWidth?: boolean;
  children?: React.ReactNode;
  className?: string;
  trigger: React.ReactNode;
};

export const Tooltip = ({
  className,
  autoWidth,
  trigger,
  children,
}: TooltipProps) => {
  const dataIdRef = useRef(`tooltip-${new Date().getTime()}`);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  return (
    <>
      <S.Trigger
        className={className}
        data-effect="solid"
        data-for={dataIdRef.current}
        data-tip={dataIdRef.current}
      >
        {trigger}
      </S.Trigger>
      <S.Tooltip autoWidth={autoWidth} id={dataIdRef.current}>
        {children}
      </S.Tooltip>
    </>
  );
};
