import React from "react";
import { FormattedMessage } from "react-intl";

export const GoToAlbum = (
  <FormattedMessage defaultMessage="Go to album" id="DYyoZ6" />
);

export const ListenOnSpotify = (
  <FormattedMessage defaultMessage="Listen on Spotify" id="oOVUrW" />
);
