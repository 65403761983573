import { FieldValues, Path, UseFormReturn } from "react-hook-form";
import { IntlShape } from "react-intl";

import { commonMessages } from "@messages/common";
import { ApiError, generateFieldPrefix } from "@utils";

const GENERIC_API_FIELDS = ["checkout", "checkoutId", "deliveryMethodId"];

// INFO: Saleor has a typo in its error message and this needs to be mapped.
const translatedMessage = (intl: IntlShape): { [x: string]: string } => ({
  VOUCHER_NOT_APPLICABLE: intl?.formatMessage(commonMessages.applyVoucherError),
});

export type HandleApiFormErrors = (
  errors: ApiError[],
  apiErrorHandler?: (error: any[]) => void
) => {
  apiErrors: ApiError[];
  isValid: boolean;
};

export const useApiFormErrors =
  <T extends FieldValues>(
    setError: UseFormReturn<T>["setError"],
    options?: Partial<{
      genericApiFields: string[];
      intl: IntlShape;
      schemaPrefix: string;
    }>
  ): HandleApiFormErrors =>
  (errors, apiErrorHandler) => {
    const { schemaPrefix, intl, genericApiFields } = {
      genericApiFields: [],
      ...options,
    };
    const formErrors = errors.filter(({ field }) => field);
    const apiErrors = errors.filter(
      ({ field }) =>
        !field || [...genericApiFields, ...GENERIC_API_FIELDS].includes(field)
    );
    const addFieldPrefix = generateFieldPrefix(schemaPrefix);

    if (formErrors) {
      formErrors.forEach(({ code, field, message }) => {
        if (field) {
          setError(
            addFieldPrefix(field) as `root.${string}` | "root" | Path<T>,
            {
              message: (intl && translatedMessage(intl)[code]) || message!,
              type: "apiError",
            }
          );
        }
      });
    }

    return { apiErrors, isValid: !formErrors.length && !apiErrors.length };
  };
