import React from "react";

import { useClubSubscriptionStore } from "@stores/useClubSubscriptionStore";

import * as M from "./messages";
import * as S from "./styles";

export type FormStepCounterProps = {
  displayMobile?: boolean;
};

export const FormStepCounter = ({ displayMobile }: FormStepCounterProps) => {
  const { pages } = useClubSubscriptionStore();

  return (
    <S.FormStepCounter mobileOnly={displayMobile}>
      {M.FormStepIndicator(pages.current, pages.total)}
    </S.FormStepCounter>
  );
};
