import { CheckoutLine } from "@gqlTypes/CheckoutLine";
import { PricedProductVariant } from "@gqlTypes/PricedProductVariant";
import { paths } from "@paths";
import React from "react";

import { HandleCloseType } from "@components/BasketModal/BasketModal";
import * as M from "@components/BasketModal/messages";
import * as CS from "@components/BasketModal/styles";
import { Button } from "@components/Button";
import { ButtonLink } from "@components/ButtonLink";
import { Cart } from "@components/Cart";
import { useCheckoutContext } from "@hooks/providers";
import { getTestAttribute } from "@utils";

import { CartActions } from "../CartActions";
import { Content } from "../Content";

import * as S from "./styles";

interface ActiveCartProps {
  collection?: PricedProductVariant[] | null;
  collectionHeader?: string;
  handleClose: (e: HandleCloseType) => void;
}

export const ActiveCart = ({
  handleClose,
  collection,
  collectionHeader,
}: ActiveCartProps) => {
  const { checkout, queue } = useCheckoutContext();

  return (
    <CS.BasketContainer hasPadding={false}>
      <Content
        collection={collection}
        collectionHeader={collectionHeader}
        title={M.activeBasket}
      >
        <S.CartItemsWrapper>
          <Cart
            className="cart-info-wrapper"
            lines={checkout!.lines! as CheckoutLine[]}
          />
        </S.CartItemsWrapper>
      </Content>

      <CartActions>
        <Button
          fullWidth
          fontSize="small"
          onClick={handleClose}
          tabIndex={-1}
          variant="hollowLight"
        >
          {M.continueShoppingButton}
        </Button>
        <ButtonLink
          fullWidth
          fontSize="small"
          href={paths.checkout}
          tabIndex={-1}
          {...getTestAttribute("cart", "basket-modal-checkout-btn")}
          processing={!!queue?.processing}
          variant="checkout"
          withIcon={false}
        >
          {M.checkoutButton}
        </ButtonLink>
      </CartActions>
    </CS.BasketContainer>
  );
};
