import { useTypedQuery } from "@graphql";
import gql from "graphql-tag";

import {
  CollectionPointsForCheckout,
  CollectionPointsForCheckoutVariables,
} from "./orderAppGqlTypes/CollectionPointsForCheckout";

export const collectionPointsForCheckoutQuery = gql`
  fragment CollectionPoint on CollectionPoint {
    name
    id
    streetAddress1
    streetAddress2
    city
    postalCode
    country
    countryCode
    countryArea
    phone
    available
  }
  query CollectionPointsForCheckout($id: String!) {
    collectionPointsForCheckout(id: $id) {
      ...CollectionPoint
    }
  }
`;

export const useCollectionPointsForCheckoutQuery = (id: string) =>
  useTypedQuery<
    CollectionPointsForCheckout,
    CollectionPointsForCheckoutVariables
  >(collectionPointsForCheckoutQuery, {
    context: { client: "orderApp" },
    fetchPolicy: "network-only",
    variables: { id },
  });
