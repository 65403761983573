import { MessageDescriptor } from "react-intl";

import { UserActions } from "@utils/typescript";

import { notificationComponents } from "./notifications";

export type NotificationComponents = keyof typeof notificationComponents;

export enum NotificationTypes {
  CART = "productAdd",
  LIKE = "likeNotification",
}

export enum NotificationStatus {
  FAIL = "failure",
  OK = "success",
}

export type ToastBase = {
  action: UserActions;
  id: number;
  status: NotificationStatus;
  type: NotificationTypes;
};

export interface Toast<T> extends ToastBase {
  props: T;
}

export type ToastAnimationProps = {
  delay: number;
  speed: number;
  totalDuration: number;
};

export type UseToastNotificationProps = {
  animation?: ToastAnimationProps;
  autoclose?: boolean;
};

export type ToastMetadata = Pick<ToastBase, "action" | "type" | "status">;

export type MessagesProps = {
  button: MessageDescriptor;
  desktop: MessageDescriptor;
  mobile: MessageDescriptor;
};

export interface ToastItemProps {
  Component: React.ComponentType<any> | null;
  animations: ToastAnimationProps;
  id: number;
  messages: MessagesProps | null;
  props: any;
  removeToast: () => void;
}
