import { MainNavigationDocumentDataMenuItemItem } from "prismicio-types";

import { navButtonStyling, psuedoElement } from "@components/Navigation/mixins";
import { animations, media, styled, theme } from "@styles";

export const getTopItemStyling = (
  variant: MainNavigationDocumentDataMenuItemItem["variant"],
  isActive?: boolean
) => {
  const { items } = theme.navigation;

  const color =
    variant === "default" && isActive ? "white" : items[variant].active.color;

  const background =
    variant === "default" && isActive
      ? "transparent"
      : items[variant].active.accent;

  return `
    color: ${color};
    background: ${background};
  `;
};

export const MobileTopItem = styled.button<{
  iconFirst?: boolean;
  isActive?: boolean;
  justify: string;
  variant: MainNavigationDocumentDataMenuItemItem["variant"];
}>`
  ${navButtonStyling()}
  justify-content: ${({ justify }) => justify};
  flex-direction: ${({ iconFirst }) => (iconFirst ? "row-reverse" : "row")};

  ${({ variant, isActive }) => getTopItemStyling(variant, isActive)}

  ${media.lg.up} {
    display: none;

    &::before {
      width: 0;
    }
  }
`;

export const NavLink = styled.a<{ isActive?: boolean }>`
  ${navButtonStyling({ position: "relative" })}
  padding: 10px;
  color: ${({ isActive }) => (isActive ? "white" : "inherit")};
  opacity: 1;

  &::before {
    ${psuedoElement({ inset: "auto auto 0 0 ", zIndex: -1 })}
    height: 4px;
    opacity: 1;
    transition: height ${animations.timing.medium} ease-in-out;
  }

  height: ${({ isActive }) => (isActive ? "100%" : "auto")};
  transition: ${({ isActive }) =>
    isActive ? `height ${animations.timing.medium} ease-in-out;` : "none"};
`;

export const LinkWrapper = styled.div`
  display: none;
  z-index: 2;

  ${media.lg.up} {
    display: flex;
    height: 100%;
  }
`;

export const IconWrapper = styled.div`
  margin-right: 10px;
  display: flex;
  align-items: center;
`;
