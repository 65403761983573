import * as Sentry from "@sentry/nextjs";
// eslint-disable-next-line import/no-extraneous-dependencies
import { Extras } from "@sentry/types";

import { developmentMode } from "@config";

type Opts = { extra?: Extras; reason: string };

export function captureException(
  error: any,
  { reason, extra }: Opts,
  silent = false
) {
  const extraContext = {
    extra: {
      extra: extra ? JSON.stringify(extra) : undefined,
      reason: `Failed to ${reason}.`,
    },
  };

  Sentry.captureException(error, extraContext);

  if (developmentMode) {
    console.error(error, extraContext);
  }
}

export function captureMessage(
  reason: string,
  extra?: Extras,
  pushToSentry = false
) {
  console.warn(reason, { extra: extra ? JSON.stringify(extra) : undefined });

  if (pushToSentry) {
    Sentry.captureMessage(reason, { extra });
  }
}

export function captureFunction<T = any>(
  fn: () => Promise<T>,
  opts: Opts,
  silent = false
): Promise<T | null> | null {
  try {
    return fn();
  } catch (err) {
    captureException(err, opts, silent);
    return null;
  }
}
