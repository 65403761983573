import { AudiPlayerOpts, PlayerApi, PlayerApiDefinition } from "../types";

export const audioApi: PlayerApiDefinition<AudiPlayerOpts> = ({
  audioElement,
}) => {
  const previewLengthMs = 30000;

  const onPlay: PlayerApi["onPlay"] = async track => {
    if (await isPlaying()) {
      await onPause();
    }

    audioElement.src = track.preview_url;
    audioElement.autoplay = true;
    return true;
  };

  const onResume: PlayerApi["onResume"] = async () => {
    if (!audioElement.paused) {
      await audioElement.pause();
    } else {
      await audioElement.play();
    }
  };

  const onPause: PlayerApi["onPause"] = async () => {
    if (await isPlaying()) {
      await audioElement.pause();
    }
  };

  const isPlaying: PlayerApi["isPlaying"] = async () => !audioElement.paused;

  const getCurrentTime: PlayerApi["getCurrentTime"] = async () => {
    const timeNow = audioElement.currentTime;

    return timeNow * 1000;
  };

  const getDuration: PlayerApi["getDuration"] = async () => {
    const { duration } = audioElement;
    return duration ? Math.round(duration * 1000) : previewLengthMs;
  };

  const onSeek: PlayerApi["onSeek"] = async (trackCompletionRatio: number) => {
    const duration = await getDuration();
    const trackProgress = (trackCompletionRatio * duration) / 1000;

    audioElement.currentTime = trackProgress;
  };

  const destroy: PlayerApi["destroy"] = () => {
    audioElement.src = "";
  };

  return {
    destroy,
    getCurrentTime,
    getDuration,
    isPlaying,
    onPause,
    onPlay,
    onResume,
    onSeek,
    type: "audio",
  };
};
