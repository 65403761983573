import { Image } from "@components/Image";
import { media, px2rem, responsive, styled } from "@styles";

export const Table = styled.table`
  td {
    padding-bottom: ${px2rem(12.5)};
    vertical-align: bottom;

    &:first-child {
      vertical-align: top;
    }

    &:nth-child(2) {
      width: 100%;
      vertical-align: top;
    }

    &:nth-child(3) {
      text-align: right;
    }
  }

  tr {
    &:last-child td {
      padding-bottom: 0;
    }
  }

  ${media.md.up} {
    td {
      padding-bottom: ${px2rem(14.5)};
    }
  }
`;

export const Thumbnail = styled(Image)`
  width: 54px;
  height: auto;

  ${media.md.up} {
    width: 97px;
  }

  @media print {
    width: 63px;
  }
`;

export const Price = styled.p<{ type: "unit" | "sub" | "total" }>`
  ${({ type }) =>
    type === "unit" &&
    `
      ${responsive("font-size", px2rem(16), px2rem(18))}
      font-weight: 500;
    `}

  ${({ type }) =>
    type === "sub" &&
    `
      ${responsive("font-size", px2rem(15), px2rem(18))}
      font-weight: 400;
    `}

  ${({ type }) =>
    type === "total" &&
    `
      ${responsive("font-size", px2rem(20), px2rem(30.5))}
      font-weight: 700;
    `}
`;

export const Name = styled.p`
  font-weight: 500;
  ${responsive("font-size", "16px", "20px")}

  ${media.md.up} {
    padding-bottom: 8px;
  }
`;

export const ProductInfo = styled.div`
  padding: ${px2rem(0, 20)};
  vertical-align: top;

  a {
    color: initial;
    text-decoration: none;
  }

  ${media.md.up} {
    padding: ${px2rem(0, 13.5)};
  }

  ${media.md.down} {
    p {
      line-height: ${px2rem(18)};
    }
  }
`;

export const Artist = styled.p`
  font-weight: 400;
  ${responsive("font-size", "13px", "20px")}

  a {
    color: inherit;
    text-decoration: underline;
  }

  ${media.md.down} {
    opacity: 0.7;
  }

  ${media.md.up} {
    padding-bottom: 7px;
  }
`;

export const Format = styled.p`
  font-weight: 400;
  ${responsive("font-size", "13px", "16px")}
  ${responsive("opacity", 0.7, 0.4)}
`;
