import styled from "styled-components";

import { px2rem } from "@styles";

export const BasketLine = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
`;

export const Total = styled.div`
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-flow: column;
  gap: 16px;
  justify-content: space-between;
  font-weight: bold;
  align-items: baseline;
`;

export const FinalPriceNote = styled.p<{ small?: boolean }>`
  font-size: ${({ theme }) => theme.typography.sizes.smallest};
  color: ${({ theme }) => theme.colors.medium};
  font-weight: normal;
  margin-top: -${px2rem(10)};
  line-height: 15px;
`;

export const LineHeader = styled.p`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.white};
`;

export const LineAmount = styled.p`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.orange};
`;
