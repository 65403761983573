import { ApolloClient } from "@apollo/client";
import {
  DiscountErrorCode,
  VoucherChannelListingInput,
  VoucherInput,
  VoucherTypeEnum,
} from "@gqlTypes/globalTypes";
import { User } from "@gqlTypes/User";
import { UserDetailsFragment } from "@saleor/sdk/dist/apollo/types";
import { updateMetadata } from "@ssr/helpers";
import { format } from "date-fns";

import { clubVoucherCode, MetadataKey } from "@config";
import {
  checkoutCreatePromoCodeMutation,
  checkoutDeletePromoCodeMutation,
  voucherUpdateChannelMutation,
} from "@providers/CheckoutProvider";
import {
  CheckoutCreatePromoCode,
  CheckoutCreatePromoCodeVariables,
} from "@providers/CheckoutProvider/gqlTypes/CheckoutCreatePromoCode";
import {
  CheckoutDeletePromoCode,
  CheckoutDeletePromoCodeVariables,
} from "@providers/CheckoutProvider/gqlTypes/CheckoutDeletePromoCode";
import {
  VoucherUpdateChannel,
  VoucherUpdateChannelVariables,
} from "@providers/CheckoutProvider/gqlTypes/voucherUpdateChannel";

import { VoucherReturn } from "./types";

const defaultVoucherInput = {
  countries: ["GB"],
  name: clubVoucherCode,
  type: "SHIPPING" as VoucherTypeEnum,
};

export const createClubVoucher = async (
  apolloClient: ApolloClient<any>,
  input: VoucherInput
): Promise<VoucherReturn> => {
  const { data } = await apolloClient.mutate<
    CheckoutCreatePromoCode,
    CheckoutCreatePromoCodeVariables
  >({
    mutation: checkoutCreatePromoCodeMutation,
    variables: { input: { ...defaultVoucherInput, ...input } },
  });

  if (!data?.voucherCreate) {
    return {
      message: "Unable to create voucher code.",
      success: false,
    };
  }

  if (data.voucherCreate?.errors.length) {
    const voucherCreateError = data?.voucherCreate?.errors[0];
    if (voucherCreateError.code === DiscountErrorCode.ALREADY_EXISTS) {
      return {
        code: voucherCreateError.code,
        data: { voucherCode: input.code },
        message:
          voucherCreateError?.message ||
          "Voucher code was not created because one a voucher with the same name already exists.",
        success: false,
      };
    }

    return {
      code: voucherCreateError.code,
      data,
      message: voucherCreateError?.message || "Voucher code was not created.",
      success: false,
    };
  }

  return {
    data,
    message: "Voucher code successfully created.",
    success: true,
  };
};

export const assignClubVoucherChannel = async (
  apolloClient: ApolloClient<any>,
  voucherID: string,
  updateInput: VoucherChannelListingInput
): Promise<VoucherReturn> => {
  const { data } = await apolloClient.mutate<
    VoucherUpdateChannel,
    VoucherUpdateChannelVariables
  >({
    mutation: voucherUpdateChannelMutation,
    variables: { id: voucherID, input: updateInput },
  });

  if (!data?.voucherChannelListingUpdate) {
    return {
      message: "Unable to update voucher code to an assigned channel",
      success: false,
    };
  }

  if (data.voucherChannelListingUpdate?.errors.length) {
    const updateVoucherError = data.voucherChannelListingUpdate?.errors[0];

    return {
      code: updateVoucherError.code,
      message:
        updateVoucherError?.message || "Unable to update voucher channel.",
      success: false,
    };
  }

  return {
    data,
    message: "Voucher code successfully assigned to channel.",
    success: true,
  };
};

export const updateUserMetaData = async (
  apolloClient: ApolloClient<any>,
  userID: string,
  voucherCode: string,
  voucherID: string
): Promise<VoucherReturn> => {
  const { data } = await updateMetadata(apolloClient, userID, [
    [
      MetadataKey.CLUB_VOUCHER,
      JSON.stringify({ code: voucherCode, id: voucherID }),
    ],
  ]);

  if (!data?.updateMetadata) {
    return {
      message: "Unable to update user meta data",
      success: false,
    };
  }

  if (data.updateMetadata?.errors.length) {
    const userMetaDataError = data.updateMetadata?.errors[0];

    return {
      code: userMetaDataError.code,
      message: userMetaDataError?.message || "Unable to update user metadata.",
      success: false,
    };
  }

  return {
    message: "Voucher code successfully applied to user metadata.",
    success: true,
  };
};

export const deleteClubVoucher = async (
  apolloClient: ApolloClient<any>,
  user: Pick<User, "metadata">
) => {
  const clubShippingVoucherMetadata = user.metadata.find(
    (entry: any) => entry!.key === MetadataKey.CLUB_VOUCHER
  )?.value;

  const { id } = clubShippingVoucherMetadata
    ? JSON.parse(clubShippingVoucherMetadata)
    : { id: null };

  if (id) {
    await apolloClient.mutate<
      CheckoutDeletePromoCode,
      CheckoutDeletePromoCodeVariables
    >({
      mutation: checkoutDeletePromoCodeMutation,
      variables: { id },
    });
  }
};

export const applyVoucherToCheckout = async (
  voucherCode: string,
  clubVoucher: string,
  isPaymentStep: boolean,
  applyVoucher: any
) => {
  if (
    clubVoucher &&
    isPaymentStep &&
    (!voucherCode || isClubShippingVoucher(voucherCode))
  ) {
    await applyVoucher(clubVoucher, false);
  }
};

export const isClubShippingVoucher = (voucherCode: string) =>
  voucherCode.includes(clubVoucherCode!);

export const clubVoucherValidForUser = (
  voucherCode: string,
  userID: string
) => {
  const userSpecificVoucherCode = `${clubVoucherCode}_${userID}`;

  return voucherCode === userSpecificVoucherCode;
};

export const isActiveClubMember = (user: UserDetailsFragment) => {
  const clubSubscriptionData = user!.metadata.find(
    (entry: any) => entry!.key === MetadataKey.CLUB_SUBSCRIPTION
  );

  if (!clubSubscriptionData?.value) {
    return false;
  }

  const { subscriptions } = JSON.parse(clubSubscriptionData!.value);

  if (!subscriptions) {
    return false;
  }

  const membershipStatus = subscriptions.some(
    ({ state }: { state: string }) => state === "ACTIVE"
  );

  return membershipStatus;
};

export const nextClubShipping = () => {
  const daysFromEndOfMonth = 1;
  const now = new Date();
  const nextMonth = new Date();
  nextMonth.setMonth(nextMonth.getMonth() + 1);
  const nextMonthEdition = format(nextMonth, "MMMM");
  const orderCutOffDate = new Date(now.getFullYear(), now.getMonth() + 1, 1);
  orderCutOffDate.setDate(orderCutOffDate.getDate() - daysFromEndOfMonth);

  return {
    nextMonthEdition,
    orderCutOffDate,
  };
};
