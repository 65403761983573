import React from "react";
import { defineMessage, FormattedMessage } from "react-intl";

import { commonMessages } from "@messages/common";
import { notificationMessages } from "@messages/notifications";

import { ReactComponent as CheckSvg } from "assets/icons/check.svg";

export const enterCode = defineMessage({
  defaultMessage: "Enter promo code",
  id: "86RjOu",
});

export const PromoCode = (
  <FormattedMessage defaultMessage="promo code?" id="N91DZT" />
);

export const HaveACode = (
  <FormattedMessage
    defaultMessage="Do you have a {code}"
    id="au4sAF"
    values={{ code: <span>{PromoCode}</span> }}
  />
);

export const GiftCardsInfo = (
  <FormattedMessage
    defaultMessage="Gift cards are provided on Payment step"
    id="5AQnH/"
  />
);

export const Redeem = <FormattedMessage {...commonMessages.redeemCode} />;

export const Success = (
  <FormattedMessage
    {...notificationMessages.successfullyCompleted}
    values={{
      icon: <CheckSvg />,
    }}
  />
);

export const PromoCodeError = defineMessage({
  defaultMessage: "Promo code is invalid",
  id: "IQplrj",
});
