import { IncomingMessage } from "http";

/**
 * Currently there is no better way to determine what breakpoint initially we should hit,
 * since initial render is don on the server.
 */
export const checkIfMobileFromUserAgent = (request?: IncomingMessage) => {
  const userAgent = request
    ? request.headers["user-agent"]
    : navigator.userAgent;

  return userAgent
    ? !!userAgent.match(
        /Android|BlackBerry|iPhone|iPod|Opera Mini|IEMobile|WPDesktop/i
      )
    : false;
};
