import { media, px2rem, styled } from "@styles";

export const ModalBody = styled.div`
  max-width: 400px;

  ${media.md.up} {
    padding: ${px2rem(45)} ${px2rem(21)} ${px2rem(23)};
  }

  ${media.md.down} {
    margin-left: auto;
    margin-right: auto;
  }
`;

export const Heading = styled.h2`
  font-family: ${({ theme }) => theme.typography.condensedFontFamily};
  font-size: ${px2rem(26)};
  font-style: normal;
  line-height: ${px2rem(33)};
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;

  ${media.md.up} {
    font-size: ${px2rem(30)};
  }
`;

export const Label = styled.label`
  display: inline-block;
  font-size: ${px2rem(16)};
  font-style: normal;
  font-weight: 500;
  line-height: ${px2rem(21)};
  margin-bottom: ${px2rem(16)};
  margin-top: ${px2rem(20)};

  ${media.md.up} {
    font-size: ${px2rem(18)};
    margin-bottom: ${px2rem(21)};
    margin-top: ${px2rem(25)};
  }
`;

export const LanguageSelectWrapper = styled.div`
  margin: ${px2rem(16)} 0 ${px2rem(32)};

  ${media.md.up} {
    margin: ${px2rem(21)} 0 ${px2rem(46)};
  }
`;
