import { ContactPoint } from "next-seo/lib/jsonld/corporateContact";
import { Address, OrganizationCategory } from "next-seo/lib/types";

import { DispatchChannel } from "@components/DispatchDate/types";
import { Channel } from "@providers/TranslationProvider";
import { getCachingDuration } from "@utils/time";

export const apiUri = process.env.NEXT_PUBLIC_API_URI!;

export const ssrMode = typeof window === "undefined";
export const developmentMode = process.env.NODE_ENV === "development";

export const enableDevTools =
  process.env.NEXT_PUBLIC_ENABLE_DEV_TOOLS === "true";

export const serviceWorkerTimeout =
  parseInt(process.env.SERVICE_WORKER_TIMEOUT || "", 10) || 60 * 1000;

export const incrementalStaticRegenerationRevalidate = parseInt(
  process.env.INCREMENTAL_STATIC_REGENERATION_REVALIDATE!,
  10
);

export const cachingDurations = getCachingDuration();

export const serverSideCaching = (
  staleWhileRevalidate: number = cachingDurations.FIVE_MINUTES,
  maxAge?: number
) =>
  [
    "public",
    `s-maxage=${maxAge || staleWhileRevalidate - 10}`,
    `stale-while-revalidate=${staleWhileRevalidate}`,
  ].join(", ");

export const incrementalStaticRegenerationRevalidateStaticPage = parseInt(
  process.env.CACHE_PAGE_ISR!,
  10
);
export const staticPathsFallback: boolean | "blocking" = process.env
  .STATIC_PATHS_FALLBACK
  ? (() => {
      try {
        return JSON.parse(process.env.STATIC_PATHS_FALLBACK);
      } catch {
        return "blocking";
      }
    })()
  : "blocking";

export const ssrFetchLimit = parseInt(process.env.SSR_FETCH_LIMIT!, 10);
export const ssrFetchBatch = parseInt(process.env.SSR_FETCH_BATCH || "1", 10);

export const basicAuthUser = process.env.BASIC_AUTH_USER!;
export const basicAuthPassword = process.env.BASIC_AUTH_PASSWORD!;
export const basicAuthEnabled = process.env.BASIC_AUTH_ENABLED === "true";

export const defaultTopBarInterval = 10 * 1000; // 10 seconds

export const prismicAccessToken = process.env.NEXT_PUBLIC_PRISMIC_ACCESS_TOKEN!;
export const prismicApiUri = process.env.NEXT_PUBLIC_PRISMIC_API_URI!;
export const prismicRepoName = process.env.NEXT_PUBLIC_PRISMIC_REPO_NAME!;

export const homeSliderCollectionPageSize = parseInt(
  process.env.HOME_SLIDER_COLLECTION_PAGE_SIZE || "10",
  10
);

export const blogUrl = process.env.NEXT_PUBLIC_BLOG_URL!;

export const contactEmail = "enquiries@roughtrade.com";
export const contactPhoneGB = "+441172421949";
export const contactPhoneUS = "(212) 664-1110";

export const publicUrl = developmentMode
  ? "http://localhost:3000"
  : process.env.NEXT_PUBLIC_URL!;
export const accountConfirmRedirectUrl = "confirm-account";
export const passwordResetRedirectUrl = "reset-password";
export const orderConfirmationRedirectUrl = "account/orders";

export enum LocalStorageKey {
  CHECKOUT_ID = "checkoutId",
  CHECKOUT_QUEUE = "checkoutState",
  CHECKOUT_TYPE = "checkoutType",
  DELIVERY_OPTION = "deliveryOption",
  GUEST_USER = "guestUser",
  RECENTLY_VIEWED = "RECENTLY_VIEWED_ITEMS_",
  SPOTIFY_AUTHORIZE_STATE = "spotifyAuthState",
}

export enum SessionStorageKey {
  HIDE_TOP_BANNER = "hideTopBanner",
}

export enum MetadataKey {
  ALGOLIA_USER_TOKEN = "algolia.USER.token",
  ARTIST_LIKES = "RT.USER.LIKES.ARTIST",
  CHECKOUT_TYPE = "CHECKOUT_TYPE",
  CLUB_SUBSCRIPTION = "RT.CLUB.SUBSCRIPTIONS",
  CLUB_VOUCHER = "RT.CLUB.VOUCHERS",
  COLLECTION_LIKES = "RT.USER.LIKES.COLLECTION",
  COLLECTION_SOURCE = "collection_source",
  GENRE_LIKES = "RT.USER.LIKES.GENRE",
  LABEL_LIKES = "RT.USER.LIKES.LABEL",
  LAST_PAYMENT_METHOD = "last_payment_method",
  LAST_SHIPPING_METHOD_ID = "last_shipping_method",
  LEGACY_SLUG = "legacy_slug",
  NEWSLETTER_CONSENT = "newsletter_consent",
  NOTIFY = "notify_",
  PRODUCT_LIKES = "RT.USER.LIKES.PRODUCT",
  REGION_EU_PRODUCT_ID = "REGION.EU.PRODUCT_ID",
  REGION_GB_PRODUCT_ID = "REGION.GB.PRODUCT_ID",
  REGION_US_PRODUCT_ID = "REGION.US.PRODUCT_ID",
  REGISTERED_FROM_REGION = "user.regionRegisteredFrom",
  SHIPMENT_FALLBACK = "fallback",
  SOURCE_LIKES = "RT.LIKES.USERS",
  SPOTIFY_ALBUM_DATA = "spotify_album_data",
  SPOTIFY_ALBUM_ID = "spotify.ALBUM.id",
  SPOTIFY_REFRESH_TOKEN = "spotify.USER.refreshToken",
  SPOTIFY_USER_FOLLOWING = "spotify.USER.following",
  SPOTIFY_USER_ID = "spotify.USER.id",
  STRIPE_CUSTOMER_ID = "SALEOR.PAYMENTS.STRIPE.customer_id",
  VARIANT_ARCHIVING = "archiving_state",
  VARIANT_RATING = "rating",
  VARIANT_UNPUBLISHED = "unpublished",
  deprecated_SPOTIFY_ID = "spotify_id",
}

export const clubVoucherCode = process.env.NEXT_PUBLIC_CLUB_VOUCHER_NAME!;
export const clubVoucherValidRegions = JSON.parse(
  process.env.NEXT_PUBLIC_CLUB_VALID_REGIONS || "{}"
) as Record<string, string>;

export const clubVoucherValidCountries = JSON.parse(
  process.env.NEXT_PUBLIC_CLUB_VALID_COUNTRIES || "{}"
) as Record<string, string[]>;

export const collectionSources = ["artist", "label", "genre"];

export type AppEvents = "searchOpen" | "signatureExpired" | "unhandledError";

export const defaultShippingDays = 7;
export const fallbackFreeShipping =
  parseInt(process.env.NEXT_PUBLIC_GLOBAL_FALLBACK_SHIPPING || "", 10) || 100;

export const stripeKeys: Record<
  Channel,
  { private: string; public: string; webhookSecret: string }
> = {
  eu: {
    private: process.env.STRIPE_API_KEY_EU!,
    public: process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY_EU!,
    webhookSecret: process.env.STRIPE_SUBSCRIPTIONS_WEBHOOK_SECRET_EU!,
  },
  gb: {
    private: process.env.STRIPE_API_KEY_GB!,
    public: process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY_GB!,
    webhookSecret: process.env.STRIPE_SUBSCRIPTIONS_WEBHOOK_SECRET_GB!,
  },
  us: {
    private: process.env.STRIPE_API_KEY_US!,
    public: process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY_US!,
    webhookSecret: process.env.STRIPE_SUBSCRIPTIONS_WEBHOOK_SECRET_US!,
  },
};

/**
 * Algolia
 */

// CLIENT CONFIG
export const algoliaApplicationId =
  process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID!;
export const algoliaApiKey = process.env.NEXT_PUBLIC_ALGOLIA_API_KEY!;

// PRODUCT_SEARCH_INDEXES
export const ALGOLIA_INDEX_GB = process.env.NEXT_PUBLIC_ALGOLIA_INDEX_GB!;
export const ALGOLIA_INDEX_US = process.env.NEXT_PUBLIC_ALGOLIA_INDEX_US!;
export const ALGOLIA_INDEX_DE_EU = process.env.NEXT_PUBLIC_ALGOLIA_INDEX_DE_EU!;
export const ALGOLIA_INDEX_EN_EU = process.env.NEXT_PUBLIC_ALGOLIA_INDEX_EN_EU!;

// EVENT_SEARCH_INDEXES
export const ALGOLIA_EVENT_INDEX_GB =
  process.env.NEXT_PUBLIC_ALGOLIA_EVENT_INDEX_GB!;
export const ALGOLIA_EVENT_INDEX_US =
  process.env.NEXT_PUBLIC_ALGOLIA_EVENT_INDEX_US!;
export const ALGOLIA_EVENT_INDEX_EU =
  process.env.NEXT_PUBLIC_ALGOLIA_EVENT_INDEX_EU!;

// BLOG_SEARCH_INDEXES
export const ALGOLIA_BLOG_INDEX_GB =
  process.env.NEXT_PUBLIC_ALGOLIA_BLOG_INDEX_GB!;
export const ALGOLIA_BLOG_INDEX_US =
  process.env.NEXT_PUBLIC_ALGOLIA_BLOG_INDEX_US!;
export const ALGOLIA_BLOG_INDEX_EU =
  process.env.NEXT_PUBLIC_ALGOLIA_BLOG_INDEX_EU!;

export const searchDebounceWait = 750; // ms
export const searchQueryParam = "q";
export const searchMinLetters = 3;
export const pageQueryParam = "page";
export const relayPageQueryParam = "next";
export const searchBestMatchSize = parseInt(
  process.env.NEXT_PUBLIC_SEARCH_BEST_MATCH_SIZE ?? "9",
  10
);

export const searchEventMatchSize = parseInt(
  process.env.NEXT_PUBLIC_SEARCH_EVENT_MATCH_SIZE ?? "6",
  10
);

export const searchBlogMatchSize = parseInt(
  process.env.NEXT_PUBLIC_SEARCH_BLOG_MATCH_SIZE ?? "3",
  10
);

export const saleorAppToken = process.env.SALEOR_APP_TOKEN!;
export const saleorMainWarehouses = JSON.parse(
  process.env.SALEOR_MAIN_WAREHOUSES || "{}"
) as Record<string, string>;

// Newsletter
export const klaviyoUrl = process.env.NEXT_PUBLIC_KLAVIYO_URL!;
export const klaviyoKeys = {
  eu: process.env.NEXT_PUBLIC_KLAVIYO_PUBLIC_KEY_EU,
  gb: process.env.NEXT_PUBLIC_KLAVIYO_PUBLIC_KEY_UK,
  us: process.env.NEXT_PUBLIC_KLAVIYO_PUBLIC_KEY_US,
};
export const klaviyoIds = JSON.parse(
  process.env.NEXT_PUBLIC_KLAVIYO_ID || "{}"
) as Record<string, string>;

export const klaviyoPrivateIds = JSON.parse(
  process.env.KLAVIYO_PRIVATE_ID || "{}"
) as Record<string, string>;

export const klaviyoListIds = JSON.parse(
  process.env.NEXT_PUBLIC_KLAVIYO_LIST_ID || "{}"
) as Record<string, string>;

export const googleTagManagerId =
  process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID!;

export const spotifyRoughTradeId = {
  eu: process.env.NEXT_PUBLIC_SPOTIFY_RT_USER_ID_EU!,
  gb: process.env.NEXT_PUBLIC_SPOTIFY_RT_USER_ID_GB!,
  us: process.env.NEXT_PUBLIC_SPOTIFY_RT_USER_ID_US!,
};
const spotifyAccountBaseUrl = "https://accounts.spotify.com";
export const spotifyApiBaseUrl = "https://api.spotify.com/v1";

export const spotifyAuthorizeUrl = `${spotifyAccountBaseUrl}/authorize`;
export const spotifyTokenUrl = `${spotifyAccountBaseUrl}/api/token`;
export const spotifyUserUrl = `${spotifyApiBaseUrl}/me`;
export const spotifyUserFollowingUrl = `${spotifyApiBaseUrl}/me/following`;
export const spotifyUserFollowContains = `${spotifyApiBaseUrl}/me/following/contains`;
export const spotifyUserPlayerUrl = `${spotifyApiBaseUrl}/me/player`;

export const spotifyPlayerDeviceName = "Rough Trade Web Player";
export const spotifyPlayerInitialVolume = parseFloat(
  process.env.NEXT_PUBLIC_SPOTIFY_PLAYER_INITIAL_VOLUME!
);

export const spotifyClientId = process.env.NEXT_PUBLIC_SPOTIFY_CLIENT_ID!;
export const spotifyClientSecret = process.env.SPOTIFY_CLIENT_SECRET!;
export const spotifyUserAuthorizationScopes = [
  "streaming",
  "user-follow-read",
  "user-follow-modify",
  "user-library-read",
  "user-modify-playback-state",
  "user-read-currently-playing",
  "user-read-email",
  "user-read-playback-position",
  "user-read-playback-state",
  "user-read-private",
  "user-read-recently-played",
  "user-top-read",
];

export const recommendationsCacheTime = parseInt(
  process.env.CACHE_PDP_RECOMMENDATIONS_TIME ?? "86400",
  10
);

export const companyHouseLink =
  "https://find-and-update.company-information.service.gov.uk/company/11315848";

export const commonLinks = [
  "https://en.wikipedia.org/wiki/Rough_Trade_(shops)",
  "https://www.linkedin.com/company/rough-trade/",
];
export const helpCentreUrl = "https://help-centre.roughtrade.com/";

export const defaultVinylColor = "Black";
export const sellingFastTrigger = 70;

export const defaultLocale = "defaultLocale";
export const intercomAppId = process.env.NEXT_PUBLIC_INTERCOM_APP_ID || "";
export const intercomAppDelay = Number.parseInt(
  process.env.NEXT_PUBLIC_INTERCOM_APP_DELAY || "0",
  10
);

export const orderAppPaymentGatewayRegExp = new RegExp(
  process.env.NEXT_PUBLIC_ORDER_APP_PAYMENT_GATEWAY_PATTERN!,
  "g"
);

export const orderAppApiUri = process.env.NEXT_PUBLIC_ORDER_APP_API_URI!;
export const orderCancelAppApiUri =
  process.env.NEXT_PUBLIC_ORDER_CANCEL_APP_API_URI! || "TEST";

export const graphqlOperationPrefix =
  process.env.NEXT_PUBLIC_GRAPHQL_OPERATION_PREFIX || "Storefront";

export const displayPDPRecommendations: boolean = process.env
  .NEXT_PUBLIC_PDP_RECOMMENDATIONS_DISPLAY
  ? (() => {
      try {
        return JSON.parse(process.env.NEXT_PUBLIC_PDP_RECOMMENDATIONS_DISPLAY);
      } catch {
        return false;
      }
    })()
  : false;

export const productThumbnailSize = Number.parseInt(
  process.env.NEXT_PUBLIC_PRODUCT_THUMBNAIL_SIZE || "2048",
  10
);

export const organizationJson = {
  address: {
    addressCountry: "GB",
    addressLocality: "Bristol",
    addressRegion: "Brislington",
    postalCode: "BS4 5NZ",
    streetAddress: "Rough Trade, Unit 3 Carrick Business Centre, Bonville Road",
  } as Address,
  contactPoints: [
    {
      areaServed: "GB",
      availableLanguage: ["English"],
      contactType: "customer service",
      telephone: `${contactPhoneGB}`,
    },
    {
      areaServed: "US",
      availableLanguage: ["English"],
      contactType: "customer service",
      telephone: `${contactPhoneUS}`,
    },
  ] as ContactPoint[],
  legalNames: "Rough Trade Retail Limited",
  logo: "https://www.roughtrade.com/RoughTradeLogo.png",
  name: "Rough Trade",
  organizationType: "Organization" as OrganizationCategory,
};

export const DICE_ELEMENT_ID = "dice-event-list-widget";

export const defaultDispatchTimes = {
  inStock: { max: 3, min: 1 },
  jit: { max: 3, min: 1 },
  preOrder: { max: 3, min: 0 },
};

export const dispatchPDPTimes = (
  process.env.NEXT_PUBLIC_DISPATCH_TIMES
    ? (() => {
        try {
          return JSON.parse(process.env.NEXT_PUBLIC_DISPATCH_TIMES);
        } catch {
          return undefined;
        }
      })()
    : undefined
) as Record<string, DispatchChannel> | undefined;
