import { spotifyPlayerDeviceName, spotifyPlayerInitialVolume } from "@config";
import { audioApi } from "@providers/PlayerProvider/api/audioApi";
import { webPlaybackApi } from "@providers/PlayerProvider/api/webPlaybackApi";

import { AudioTrack, PlayerApi, SpotifyErrorMessage } from "./types";

export const isTrackPlayable = (
  {
    uri,
    preview_url,
    is_playable,
  }: Pick<AudioTrack, "preview_url" | "uri" | "is_playable">,
  isPremiumAccount: boolean
) => (is_playable ? !!(isPremiumAccount ? uri : preview_url) : false);

const SPOTIFY_SDK_TIMEOUT = 15000;

export const initWebPlaybackPlayer = (
  accessToken: string,
  setError: (error: SpotifyErrorMessage | null) => void,
  resolve: (api: PlayerApi | null) => void,
  rejectTimeout: NodeJS.Timeout
): void => {
  const player = new window.Spotify.Player({
    getOAuthToken: cb => cb(accessToken),
    name: spotifyPlayerDeviceName,
    volume: spotifyPlayerInitialVolume,
  });

  player.addListener("ready", ({ device_id }) => {
    clearTimeout(rejectTimeout);

    resolve(
      webPlaybackApi({ accessToken, deviceId: device_id, player, setError })
    );
  });

  player.addListener("not_ready", ({ device_id }) => {
    console.error("Device ID has gone offline", device_id);
  });

  player.on("initialization_error", ({ message }) => {
    console.error(message);
  });

  player.on("authentication_error", ({ message }) => {
    console.error(message);
  });

  player.on("account_error", ({ message }) => {
    console.error(message);
  });

  player.on("playback_error", ({ message }) => {
    console.error("Failed to perform playback", message);
  });

  player.connect();
};

export const initWebPlaybackApi = (
  accessToken: string,
  setError: (error: SpotifyErrorMessage | null) => void
): Promise<PlayerApi | null> =>
  new Promise(resolve => {
    const rejectTimeout = setTimeout(() => {
      console.error("Failed to initialize Spotify Web Player.");
      resolve(null);
    }, SPOTIFY_SDK_TIMEOUT);

    if (!window.Spotify) {
      /**
       * Must be invoked before sdk script is initialized
       */
      window.onSpotifyWebPlaybackSDKReady = async () => {
        initWebPlaybackPlayer(accessToken, setError, resolve, rejectTimeout);
      };

      const scriptTag = document.createElement("script");

      scriptTag.src = "https://sdk.scdn.co/spotify-player.js";
      scriptTag.async = true;
      scriptTag.id = "spotify-player-script";

      document.body.appendChild(scriptTag);
    } else {
      initWebPlaybackPlayer(accessToken, setError, resolve, rejectTimeout);
    }
  });

const audioPlayerSupported = () => typeof Audio !== "undefined";

export const initAudioApi = (): PlayerApi | null => {
  if (!audioPlayerSupported()) {
    return null;
  }
  const audioElement = new Audio();
  audioElement.volume = spotifyPlayerInitialVolume;

  return audioApi({ audioElement });
};
