import * as prismic from "@prismicio/client";
import * as prismicNext from "@prismicio/next";

import { prismicAccessToken, prismicApiUri } from "@config";

import config from "../slicemachine.config.json";

/**
 * The project's Prismic repository name.
 */
export const { repositoryName } = config;

/**
 * A list of Route Resolver objects that define how a document's `url` field is resolved.
 *
 * {@link https://prismic.io/docs/route-resolver#route-resolver}
 */

const routes: prismic.ClientConfig["routes"] = [
  // {
  //   path: "/",
  //   type: "homepage",
  // },
  {
    path: "/:lang/stores/:uid",
    type: "store_page",
  },
  {
    path: "/:lang/stores",
    type: "store_landing_page",
  },
  {
    path: "/:lang/club/:uid",
    type: "club_page",
  },
  {
    path: "/:lang/club/",
    type: "club_home_page",
  },
  {
    path: "/:lang/best-of/:uid",
    type: "of_the_year_page",
  },
  {
    path: "/:lang/comp/:uid",
    type: "competition_page",
  },
];

/**
 * Creates a Prismic client for the project's repository. The client is used to
 * query content from the Prismic API.
 *
 * @param config - Configuration for the Prismic client.
 */
export const createClient = (config: prismicNext.CreateClientConfig = {}) => {
  const client = prismic.createClient(prismicApiUri, {
    accessToken: prismicAccessToken,
    routes,
    ...config,
  });

  prismicNext.enableAutoPreviews({
    client,
    previewData: config.previewData,
    req: config.req,
  });

  return client;
};
