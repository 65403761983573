import { media, px2rem, styled } from "@styles";

export const CollectionSliderWrapper = styled.article<{
  paddingLeft?: boolean;
  paddingTop?: number;
}>`
  padding-top: ${({ paddingTop }) => paddingTop ?? 60}px;
  overflow: hidden;
  position: relative;
  scroll-margin-top: 200px;

  ${media.md.up} {
    padding-left: ${({ paddingLeft }) => (paddingLeft ? `16px` : "0")};
  }

  ${media.lg.up} {
    padding-top: ${({ paddingTop }) => paddingTop ?? 75}px;
  }
`;

export const CollectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding-bottom: 2px;
`;

export const CollectionName = styled.h2<{ light: boolean }>`
  font-size: ${px2rem(25)};
  font-weight: 700;
  color: ${({ theme, light }) =>
    light ? theme.colors.white : theme.colors.black};

  ${media.lg.up} {
    font-size: ${px2rem(34)};
  }
`;

export const SeeAll = styled.a<{ light: boolean }>`
  font-size: ${px2rem(14)};
  font-weight: 500;
  color: ${({ theme, light }) =>
    light ? theme.colors.white : theme.colors.black};
  text-decoration: none;
  cursor: pointer;

  ${media.lg.up} {
    font-size: ${px2rem(18)};
  }
`;

export const CollectionSlider = styled.div<{
  marginTop: boolean;
  paddingLeft: boolean;
}>`
  margin-top: ${({ marginTop }) => (marginTop ? `2rem` : "0")};
  ${media.md.down} {
    padding-left: ${({ paddingLeft }) => (paddingLeft ? `16px` : "0")};

    div:first-child {
      padding-left: 0;
    }
  }
`;
