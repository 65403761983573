import { Cart as CartComponent } from "@components/Cart";
import { Hr as HrComponent } from "@components/Hr";
import { Link } from "@components/Link";
import { media, px2rem, responsive, styled } from "@styles";

export const Container = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.lg.up} {
    padding: ${px2rem(160)} 0 40px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;

  ${media.lg.up} {
    padding: 0 1rem;
    margin-left: -${px2rem(10)};
    width: 90%;
  }

  ${media.xl.up} {
    padding: 0;
    width: 60%;
  }
`;

export const Header = styled.p`
  font-size: ${px2rem(30)};
  font-family: ${({ theme }) => theme.typography.condensedFontFamily};
  color: ${({ theme }) => theme.colors.white};
  text-transform: uppercase;
  font-weight: 700;
  ${responsive("font-size", px2rem(20), px2rem(30), "lg")}
`;

export const Edit = styled(Link)`
  color: ${({ theme }) => theme.colors.dark};
  font-weight: 700;
  text-decoration: underline;
  ${responsive("font-size", px2rem(15), px2rem(18), "lg")}

  ${media.lg.down} {
    margin-right: ${px2rem(15)};
  }
`;

export const ArrowContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const MobileArrow = styled.label<{ open: boolean }>`
  padding: ${px2rem(10, 30)};
  margin-right: -${px2rem(30)};
  cursor: pointer;

  svg {
    transition: transform ${({ theme }) => theme.transitionDuration * 2.5}s;
    width: 15px;
    ${({ open }) => open && `transform: scaleY(-1);`}

    path {
      fill: ${({ theme }) => theme.colors.black} !important;
      stroke: ${({ theme }) => theme.colors.white};
    }
  }
`;

export const Cart = styled(CartComponent)<{
  children?: React.ReactNode;
  height: number;
  open: boolean;
}>`
  transition: ${({ open, theme: { transitionDuration: duration } }) =>
    `
    height ${duration * 2}s ease-in-out,
    opacity ${duration * (open ? 2 : 1)}s ease-in-out ${
      open ? `${duration}s` : ""
    },
    margin ${duration}s ease-in-out
  `};

  ${media.lg.down} {
    padding: 0 1px;
    opacity: 0;
    height: 0;

    ${({ open, height }) =>
      open &&
      `
      height: ${height}px;
      opacity: 1;
      margin: ${px2rem(16, 0, 0)};
    `}
  }
`;

export const Approved = styled.span`
  color: ${({ theme }) => theme.colors.turquoise};
  font-weight: 500;
  display: flex;
  align-items: center;
`;

export const Text = styled.span`
  font-size: ${px2rem(13)};
  color: ${({ theme }) => theme.colors.medium};
`;

export const GridCardNotification = styled.div<{ height: number }>`
  transition: height ${({ theme }) => theme.transitionDuration}s linear;
  height: ${({ height }) => height}px;
`;

export const Hr = styled(HrComponent)`
  display: block;
  width: 100%;
  margin: 30px auto 11px;
`;
