import { LanguageCodeEnum } from "@gqlTypes/globalTypes";
import { fetchVariants } from "@ssr/helpers";

import { fetchBasketCollection } from "@components/BasketModal/utils";
import { ModifiedBasketCollections } from "@integrations/prismic/types";
import { Channel, Currency, Locale } from "@providers/TranslationProvider";
import { isSupportedLocale } from "@providers/TranslationProvider/helpers";
import { filterNotAvailableVariant } from "@utils";

import { DEFAULT_CHANNEL } from "./context";
import {
  BasketVariant,
  BasketVariantArray,
  CollectionArray,
  CollectionDataItem,
} from "./types";

const fetchBasketUpsellVariants = async (
  variantsArr: BasketVariant[] | null,
  channel: Channel
) => {
  const variantIds = variantsArr
    ?.map(({ variant_id }) => {
      if (!variant_id) return null;
      return decodeURIComponent(variant_id);
    })
    ?.filter(Boolean) as string[];

  if (!variantIds.length) {
    return null;
  }

  const variants = await fetchVariants(variantIds, channel, 10);

  return variants.filter(filterNotAvailableVariant);
};

export const fetchBasketVariants = async (
  variants: BasketVariantArray[],
  channel: Channel
) => {
  const modifiedVariantArray = (
    await Promise.all(
      variants.map(async ({ data, selector }) => {
        if (data) {
          const variants = await fetchBasketUpsellVariants(data, channel);
          return {
            data: variants,
            selector,
          };
        }
      })
    )
  )?.filter(Boolean) as any[];

  if (modifiedVariantArray.length === 0) {
    return null;
  }

  return modifiedVariantArray.reduce(
    (acc, { data, selector }) => ({ ...acc, [selector]: data }),
    {}
  ) as ModifiedBasketCollections;
};

export const fetchBasketCollections = async (
  collections: CollectionArray[],
  locale: string
) => {
  const collectionData = (
    await Promise.all(
      collections.map(async ({ collectionId, selector }) => {
        if (collectionId) {
          return {
            data: await fetchBasketCollection(collectionId, locale, "10"),
            selector,
          };
        }
      })
    )
  )?.filter(Boolean) as CollectionDataItem[];

  if (collectionData.length === 0) {
    return null;
  }

  return collectionData.reduce(
    (acc, { data, selector }) => ({ ...acc, [selector]: data }),
    {}
  );
};

export function getChannel(locale: string | undefined): Channel {
  if (!locale || !isSupportedLocale(locale)) {
    return DEFAULT_CHANNEL;
  }
  const channel: Record<Locale, Channel> = {
    "de-de": "eu",
    "en-de": "eu",
    "en-gb": "gb",
    "en-us": "us",
    "fr-fr": "eu",
  };
  return channel[locale as Locale];
}

export function getCurrency(channel: Channel): Currency {
  const currency: Record<Channel, Currency> = {
    eu: "EUR",
    gb: "GBP",
    us: "USD",
  };
  return currency[channel];
}

export function getLanguageCode(locale: string | undefined): LanguageCodeEnum {
  if (!locale || !isSupportedLocale(locale)) {
    return LanguageCodeEnum.EN;
  }
  const languageCode: Record<Locale, LanguageCodeEnum> = {
    "de-de": LanguageCodeEnum.DE,
    "en-de": LanguageCodeEnum.EN,
    "en-gb": LanguageCodeEnum.EN,
    "en-us": LanguageCodeEnum.EN,
    "fr-fr": LanguageCodeEnum.FR,
  };
  return languageCode[locale as Locale];
}
