import { CheckoutLine } from "@gqlTypes/CheckoutLine";
import { TaxedPrice } from "@gqlTypes/TaxedPrice";
import dynamic from "next/dynamic";
import React, { forwardRef } from "react";

import { CartLineProps } from "@components/CartLine";
import { Hr } from "@components/Hr";
import { Spacer } from "@components/Spacer";
import { useConfigContext } from "@hooks/providers";
import { useListActions } from "@hooks/useListActions";
import { useCheckoutStore } from "@stores/useCheckoutStore";
import { theme } from "@styles";
import { Discount as TDiscount } from "@utils/typescript";

import { Discount, Total } from "./components";
import { Tax } from "./components/Tax";

import * as S from "./styles";

const CartLine = dynamic<CartLineProps>(
  () =>
    import(
      /* webpackChunkName: "cartLine.chunk" */
      "@components/CartLine"
    ).then(mod => mod.CartLine),
  { ssr: false }
);

export type CartProps = {
  children?: React.ReactNode;
  className?: string;
  discount?: TDiscount;
  giftCards?: TDiscount[];
  lines: CheckoutLine[];
  onDiscountRemove?: (opts: {
    code: string;
    id: string | undefined;
  }) => Promise<void>;
  quantityControls?: boolean;
  shipping?: TaxedPrice;
  subtotal?: TaxedPrice;
  total?: TaxedPrice;
};

export const Cart = forwardRef<HTMLDivElement, CartProps>(
  (
    {
      discount,
      subtotal,
      total,
      shipping,
      lines,
      quantityControls = true,
      className,
      onDiscountRemove,
      children,
      giftCards,
    },
    ref
  ) => {
    const { upsellClub } = useCheckoutStore();
    const { add, remove, isSelected } = useListActions<string>();
    const { channel } = useConfigContext();

    const isUs = channel === "us";
    const isSingularCost =
      [shipping, subtotal, total].filter(Boolean).length === 1;
    const giftCardsList = giftCards?.map((giftCard, i) => (
      <React.Fragment key={i}>
        <Hr background={theme.colors.white} height={1} />
        <Discount
          onDiscountRemove={onDiscountRemove}
          type="giftCard"
          {...giftCard}
        />
        <Hr
          background={theme.colors.white}
          height={i + 1 === giftCards!.length ? 2 : 1}
        />
      </React.Fragment>
    ));

    return (
      <div className={className} ref={ref}>
        <S.CartLineWrapper className="cart-line-wrapper">
          {lines.map(line => (
            <CartLine
              key={line.id}
              line={line}
              listActions={{ add, isSelected, remove }}
              quantityControls={quantityControls}
            />
          ))}
        </S.CartLineWrapper>
        {discount && (
          <Discount
            onDiscountRemove={onDiscountRemove}
            type="promoCode"
            {...discount}
          />
        )}
        <Hr background={theme.colors.white} height={2} />
        {isSingularCost ? (
          <Total
            price={(total || subtotal)!}
            showNetPrice={isUs}
            type="total"
          />
        ) : (
          <>
            <Spacer height={8} />
            {subtotal && <Total price={subtotal} type="subtotal" />}
            {shipping && (
              <Total
                price={shipping}
                showAsterisk={upsellClub}
                type="shipping"
              />
            )}
            {giftCardsList}
            {isUs && total && <Tax price={total} variant={channel} />}
            {total && <Total price={total} type="total" />}
            {!isUs && total && <Tax price={total} variant={channel} />}
          </>
        )}
        {children}
      </div>
    );
  }
);
