import {
  ObsoletePropertiesHyphen,
  StandardLonghandPropertiesHyphen,
} from "csstype";
import { css, CSSProperties } from "styled-components";

import { Breakpoints, media } from "./media";
import { theme } from "./theme";

type ThemeColor = keyof typeof theme.colors;
type ThemeTypographySize = keyof typeof theme.typography.sizes;
type FontSize = ThemeTypographySize | string;

export const font = (
  size: FontSize,
  options?: Partial<{
    color: keyof typeof theme.colors | CSSProperties["color"];
    sizeMdDown: FontSize;
    sizeMdUp: FontSize;
    weight: CSSProperties["fontWeight"];
  }>
) => {
  const { weight, sizeMdDown, sizeMdUp, color } = options || {};

  return css`
    font-size: ${theme.typography.sizes[size as ThemeTypographySize] || size};
    ${weight && `font-weight: ${weight}`}
    ${color && `color: ${theme.colors[color as ThemeColor] || color}`}
    ${sizeMdDown &&
    `
     ${media.md.down} {
        font-size: ${
          theme.typography.sizes[sizeMdUp as ThemeTypographySize] || size
        };
      }
    `}
    ${sizeMdUp &&
    `
    ${media.md.up} {
      font-size: ${
        theme.typography.sizes[sizeMdUp as ThemeTypographySize] || size
      };
    }
    `}
  `;
};

type Props = ObsoletePropertiesHyphen & StandardLonghandPropertiesHyphen;
export const responsive = <Prop extends keyof Props>(
  prop: Prop,
  mobileValue: Props[Prop],
  desktopValue: Props[Prop],
  breakpoint: Breakpoints = "md"
) => `
  ${prop}: ${mobileValue};
  ${
    breakpoint &&
    `
    ${media[breakpoint].up} {
        ${prop}: ${desktopValue};
      }
    `
  }
`;

export const horizontalCenter = `
  content: "";
  position: absolute;
  left: 50%;
  transform: translate(-50%);
`;

export const verticalCenter = `
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

export const absoluteCenter = `
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const hiddenUp = (breakpoint: Breakpoints) => `
  ${media[breakpoint].up} {
    display: none;
  }
`;

export const hiddenDown = (breakpoint: Breakpoints) => `
  ${media[breakpoint].down} {
    display: none;
  }
`;

export const hiddenOnly = (breakpoint: Breakpoints) => `
  ${media[breakpoint].only} {
    display: none;
  }
`;

export const noPrint = `
  @media print {
    display: none;
  }
`;
