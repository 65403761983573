/** -- Klaviyo API integrations -- */
import { KlaviyoCheckProfileResponse } from "src/pages/api/klaviyo/check_profile";
import { KlaviyoCreateProfileResponse } from "src/pages/api/klaviyo/create_profile";

import {
  klaviyoListIds,
  klaviyoPrivateIds,
  klaviyoUrl,
  ssrMode,
} from "@config";
import { fetchNextAPIData } from "@utils/api";

/**
 *
 * @param KlaviyoKeys
 * @returns Private ID for Klaviyo API and List ID for Klaviyo consent double opt-in
 */

const getKlaviyoPrivateId = (channel: string) =>
  klaviyoPrivateIds[channel] || null;

const getKlaviyoListId = (channel: string) => klaviyoListIds[channel] || null;

/**
 *
 * @returns The following are API FUNCTIONS FOR KLAVIYO INTEGRATION
 */

export const createUserInKlaviyo = async (email: string, channel: string) => {
  const apiUrl = new URL(
    `api/klaviyo/check_profile?email=${email}`,
    window.location.origin
  );

  apiUrl.searchParams.set("email", email);
  apiUrl.searchParams.set("channel", channel);

  const userData = await fetchNextAPIData<KlaviyoCheckProfileResponse>({
    apiUrl,
  });

  let newUserId;

  if (!userData?.id) {
    const apiUrl = new URL(
      `api/klaviyo/create_profile`,
      window.location.origin
    );
    newUserId = await fetchNextAPIData<KlaviyoCreateProfileResponse>({
      apiUrl,
      options: {
        body: JSON.stringify({ channel, email }),
        method: "POST",
      },
    });
  }

  const profileId = userData?.id || newUserId?.id;

  if (profileId) {
    const addProfile = new URL(
      `api/klaviyo/add_profile_consent_list`,
      window.location.origin
    );
    await fetchNextAPIData({
      apiUrl: addProfile,
      options: {
        body: JSON.stringify({ channel, profileId }),
        method: "POST",
      },
    });
  } else {
    console.error("No profile ID found");
  }
};

export const checkUserExists = async (email: string, channel: string) => {
  if (!ssrMode) {
    return null;
  }

  const privateId = getKlaviyoPrivateId(channel);

  if (!privateId) {
    return null;
  }

  const apiUrl = new URL(`${klaviyoUrl}/profiles`);

  apiUrl.searchParams.set("filter", `equals(email,"${email}")`);

  const options = {
    headers: {
      Authorization: `Klaviyo-API-Key ${privateId}`,
      accept: "application/json",
      revision: "2023-12-15",
    },
    method: "GET",
  };

  const response = await fetch(apiUrl, options);

  return response.json();
};

export const createUserProfile = async (email: any, channel: any) => {
  const apiUrl = new URL(`${klaviyoUrl}/profiles`);

  const privateId = getKlaviyoPrivateId(channel);

  if (!privateId) {
    return null;
  }

  const options = {
    body: JSON.stringify({
      data: {
        attributes: {
          email: `${email}`,
          properties: {
            $added_via_footer: "true",
            consent: ["sms", "mobile", "web", "directmail", "mobile"],
          },
        },
        type: "profile",
      },
    }),
    headers: {
      Authorization: `Klaviyo-API-Key ${privateId}`,
      "Content-Type": "application/json",
      accept: "application/json",
      revision: "2023-12-15",
    },
    method: "POST",
    properties: { email: `${email}` },
  };

  const response = await fetch(apiUrl, options);
  const responseData = await response.json();

  if (response.ok) {
    const userId = responseData?.data?.id;
    return { ...responseData, userId };
  }

  return responseData;
};

export const addProfileToList = async (profileId: string, channel: string) => {
  const listId = getKlaviyoListId(channel);

  if (!listId) {
    return null;
  }

  const privateId = getKlaviyoPrivateId(channel);

  if (!privateId) {
    return null;
  }

  const apiUrl = new URL(
    `${klaviyoUrl}/lists/${listId}/relationships/profiles/`
  );

  const options = {
    body: JSON.stringify({ data: [{ id: `${profileId}`, type: "profile" }] }),
    headers: {
      Authorization: `Klaviyo-API-Key ${privateId}`,
      "Content-Type": "application/json",
      accept: "application/json",
      revision: "2023-12-15",
    },
    method: "POST",
  };

  const response = await fetch(apiUrl, options);
  return response;
};
