import {
  ApolloClient,
  FetchPolicy,
  NormalizedCacheObject,
} from "@apollo/client";
import { Checkout } from "@gqlTypes/Checkout";
import { CheckoutLine } from "@gqlTypes/CheckoutLine";
import { CheckoutLineInput } from "@gqlTypes/globalTypes";
import { UserDetailsFragment } from "@saleor/sdk/dist/apollo/types";
// eslint-disable-next-line import/no-extraneous-dependencies
import { DeepNonNullable } from "ts-essentials";

import { ProductStockType } from "@components/DispatchDate";
import { LocalStorageKey, ssrMode } from "@config";
import {
  CheckoutBaseById,
  CheckoutBaseByIdVariables,
} from "@providers/CheckoutProvider/gqlTypes/CheckoutBaseById";
import { Channel } from "@providers/TranslationProvider";
import { RequireOnlyOne } from "@utils/typescript";

import { CheckoutById, CheckoutByIdVariables } from "./gqlTypes/CheckoutById";
import {
  UserCheckoutIds,
  UserCheckoutIdsVariables,
} from "./gqlTypes/UserCheckoutIds";
import {
  checkoutBaseByIdQuery,
  checkoutByIdQuery,
  userCheckoutIds,
} from "./queries";
import { CheckoutPartial, CheckoutsQueue } from "./types";

export const getCachedId = () =>
  ssrMode ? null : localStorage.getItem(LocalStorageKey.CHECKOUT_ID);

export const getCachedQueue = (): CheckoutsQueue | null => {
  if (!ssrMode) {
    try {
      return JSON.parse(localStorage.getItem(LocalStorageKey.CHECKOUT_QUEUE)!);
    } catch (error) {
      console.error("Failed to load checkout state", error);
    }
  }
  return null;
};

export const removeCachedId = () =>
  ssrMode ? null : localStorage.removeItem(LocalStorageKey.CHECKOUT_ID);

export const removeCachedQueue = () =>
  ssrMode ? null : localStorage.removeItem(LocalStorageKey.CHECKOUT_QUEUE);

export const updateCachedId = (checkout: CheckoutPartial) => {
  if (checkout?.id) {
    localStorage?.setItem(LocalStorageKey.CHECKOUT_ID, checkout.id);
  }
};

export const updateCachedQueue = (queue: CheckoutsQueue) => {
  localStorage?.setItem(LocalStorageKey.CHECKOUT_QUEUE, JSON.stringify(queue));
};

export const getLine = (variantId: string, lines: Checkout["lines"]) => {
  const line = (lines as DeepNonNullable<CheckoutLine[]>).find(
    ({ variant: { id } }) => id === variantId
  );
  return line as CheckoutLine;
};

export const getUserCheckouts = async (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  channel: Channel
): Promise<string[]> => {
  const { data } = await apolloClient.query<
    UserCheckoutIds,
    UserCheckoutIdsVariables
  >({
    query: userCheckoutIds,
    variables: { channel },
  });

  return (data.me?.checkoutIds ?? []) as string[];
};

export const getCheckoutById = async (
  apolloClient: ApolloClient<any>,
  id: string,
  fetchPolicy?: FetchPolicy
) => {
  const { data } = await apolloClient.query<
    CheckoutById,
    CheckoutByIdVariables
  >({
    fetchPolicy,
    query: checkoutByIdQuery,
    variables: { id },
  });

  return data?.checkout;
};

export const getCheckoutBaseById = async (
  apolloClient: ApolloClient<any>,
  id: string,
  fetchPolicy?: FetchPolicy
) => {
  const { data } = await apolloClient.query<
    CheckoutBaseById,
    CheckoutBaseByIdVariables
  >({
    fetchPolicy,
    query: checkoutBaseByIdQuery,
    variables: { id },
  });

  return data?.checkout;
};

export const getCurrentCheckout = async (
  apolloClient: ApolloClient<any>,
  user: UserDetailsFragment | undefined,
  channel: Channel
): Promise<Partial<Checkout> | null> => {
  const id = getCachedId();
  let checkout = null;

  if (id) {
    checkout = await getCheckoutBaseById(apolloClient, id);
  }

  if (user) {
    const userCheckoutIds = await getUserCheckouts(apolloClient, channel);

    if (checkout) {
      if (userCheckoutIds.includes(checkout.id)) {
        return checkout;
      }
    }
  }

  return checkout;
};

export const calculateLinesInput = (
  currentLines: Checkout["lines"],
  options: { variantId: string } & RequireOnlyOne<{
    quantityToAdd: number;
    quantityToRemove: number;
  }>
): { lines: CheckoutLineInput[]; newLine: CheckoutLineInput } => {
  const { variantId, quantityToAdd, quantityToRemove } = options;
  const checkoutLines = currentLines as CheckoutLine[];
  const lines = checkoutLines.filter(({ variant: { id } }) => id !== variantId);
  const variantLine = checkoutLines.find(
    ({ variant: { id } }) => id === variantId
  );
  const linesInput = lines.map(({ variant: { id: variantId }, quantity }) => ({
    quantity,
    variantId,
  }));

  const quantity = variantLine?.quantity ?? 0;
  const newQuantity = quantityToAdd
    ? quantity + quantityToAdd
    : quantity - quantityToRemove! < 0
    ? 0
    : quantity - quantityToRemove!;

  return {
    lines: linesInput,
    newLine: { quantity: newQuantity, variantId },
  };
};

/**
 * Types
 */

export interface VariantInfo {
  date?: string;
  id: string;
  quantity: number;
  type: ProductStockType;
}

export enum ActionType {
  ADD = "add",
  REMOVE = "remove",
}

/**
 * Storing data in localStorage
 */
const storageKey = "checkoutLines";

export const getCachedCheckoutLines = (): VariantInfo[] => {
  const data = localStorage.getItem(storageKey);
  return data ? JSON.parse(data) : [];
};

export const saveCheckoutVariant = (variants: VariantInfo[]) => {
  localStorage.setItem(storageKey, JSON.stringify(variants));
};

export const removeCachedCheckoutLines = () => {
  localStorage.removeItem(storageKey);
};

export const removeSingleCachedCheckoutLine = (id: string) => {
  const lines = getCachedCheckoutLines();
  const updatedLines = lines.filter(line => line.id !== id);
  saveCheckoutVariant(updatedLines);
};

/**
 * Adding/Removing Products
 */
export const updateCheckoutLocalStorageLines = (
  variant: VariantInfo,
  action: ActionType
) => {
  const lines = getCachedCheckoutLines();

  const existingProduct = lines?.find(p => p.id === variant.id);

  // Add the product if it doesn't exist
  if (action === ActionType.ADD) {
    if (existingProduct) {
      const updatedProducts = lines?.map(p => {
        if (p.id === variant.id) {
          return { ...p, quantity: p.quantity + variant.quantity };
        }
        return p;
      });
      saveCheckoutVariant(updatedProducts);
    } else {
      lines.push(variant);
      saveCheckoutVariant(lines);
    }
  } else if (action === ActionType.REMOVE) {
    if (existingProduct) {
      // Use .reduce to directly remove items or adjust quantity as needed
      const updatedProducts = lines?.reduce((acc: VariantInfo[], p) => {
        if (p.id === variant.id) {
          const newQuantity = p.quantity - variant.quantity;
          // If newQuantity is less than or equal to 0, do not add the product to the accumulator
          if (newQuantity > 0) {
            acc.push({ ...p, quantity: newQuantity });
          }
        } else {
          acc.push(p); // Always add products that are not being adjusted
        }
        return acc;
      }, []);
      saveCheckoutVariant(updatedProducts);
    } else {
      // Remove the product if it exists
      const updatedProducts = lines?.filter(p => p.id !== variant.id);
      saveCheckoutVariant(updatedProducts);
    }
  }
};
