import { ApolloClient } from "@apollo/client";
import {
  AddressValidationFields,
  AddressValidationFieldsVariables,
} from "@gqlTypes/AddressValidationFields";
import { CountryCode } from "@gqlTypes/globalTypes";
import {
  SliderCollection,
  SliderCollection_collection,
  SliderCollection_collection_products_edges_node,
  SliderCollectionVariables,
} from "@gqlTypes/SliderCollection";
import { User } from "@gqlTypes/User";
import { UserQuery, UserQueryVariables } from "@gqlTypes/UserQuery";

import { Channel } from "@providers/TranslationProvider/types";
import { captureMessage } from "@utils/errors";
import { ApiError } from "@utils/typescript";

import { initializeApollo } from "./client";
import {
  addressValidationFieldsQuery,
  sliderCollectionQuery,
  userQuery,
} from "./queries";

export type SliderItem = Omit<
  SliderCollection_collection,
  "products" | "__typename"
> & {
  customLink?: boolean;
  products: SliderCollection_collection_products_edges_node[];
};

export const getSliderCollection = async (
  id: string,
  channel: Channel,
  pageSize: number = 20,
  slug: string = "",
  apolloClient: ApolloClient<any>
): Promise<SliderItem | null> => {
  if ((!id && !slug) || !apolloClient) {
    return null;
  }

  const collection = await apolloClient
    .query<SliderCollection, SliderCollectionVariables>({
      query: sliderCollectionQuery,
      variables: {
        channel,
        id,
        pageSize,
        slug,
      },
    })
    .then(({ data }) => data?.collection);

  if (!collection) {
    return null;
  }

  const products = collection.products?.edges.map(e => e.node) || [];

  return {
    ...collection,
    products,
  };
};

export type AddressValidationProps = { raw?: string; verbose: string };
export type AddressValidationFormattedProps = { label: string; value: string };

export type AddressValidationData = {
  cityChoices: AddressValidationFormattedProps[];
  countryAreaChoices: AddressValidationFormattedProps[];
  postalCodeExample: string;
  requiredFields: string[];
};

export const formatAddressChoices = (options: AddressValidationProps[]) => {
  if (!options.length) {
    return [];
  }

  const filtered = options.flatMap(option => ({
    label: option.verbose,
    value: option?.raw || option.verbose,
  }));

  return filtered.sort((a, b) => a!.label.localeCompare(b!.label));
};

export const getAddressValidationFields = async (
  countryCode: string,
  countryArea?: string
): Promise<AddressValidationData | null> => {
  if (!countryCode) {
    return null;
  }

  const apolloClient = await initializeApollo();

  const countryCodeFormatted = countryCode as CountryCode;

  const fields = await apolloClient
    .query<AddressValidationFields, AddressValidationFieldsVariables>({
      query: addressValidationFieldsQuery,
      variables: {
        countryArea: countryArea ?? "",
        countryCode: countryCodeFormatted,
      },
    })
    .then(({ data }) => data.addressValidationRules);

  if (!fields) {
    return null;
  }

  const {
    countryAreaChoices,
    cityChoices,
    postalCodeExamples,
    requiredFields: requiredFieldsRaw,
  } = fields!;

  const requiredFields = [...requiredFieldsRaw];

  // append phone number as required field
  if (!requiredFields.includes("phone")) {
    requiredFields.push("phone");
  }

  return {
    cityChoices: formatAddressChoices(cityChoices as AddressValidationProps[]),
    countryAreaChoices: formatAddressChoices(
      countryAreaChoices as AddressValidationProps[]
    ),
    postalCodeExample: postalCodeExamples?.length ? postalCodeExamples[0] : "",
    requiredFields,
  };
};

export const getUserData = async (
  apolloClient: ApolloClient<any>,
  channel: string
): Promise<User> => {
  const { data } = await apolloClient.query<UserQuery, UserQueryVariables>({
    query: userQuery,
    variables: { channel },
  });

  return data!.me!;
};

export type LogErrorOpts = {
  errorCb?: () => void;
  extra?: Object;
  reason: string;
};

export const logMutationError = (errors: ApiError[], opts: LogErrorOpts) => {
  if (errors.length) {
    captureMessage(`Failed to ${opts.reason}.`, {
      errors,
      extra: opts?.extra,
    });
    opts?.errorCb?.();
    return true;
  }
  return false;
};

export type HandleMutationResponseMutation = { errors: ApiError[] } | null;

export const handleMutationResponse = <
  T extends HandleMutationResponseMutation
>(
  type: HandleMutationResponseMutation,
  callback?: (() => any) | null,
  error?: LogErrorOpts
): ApiError[] => {
  const errors = type?.errors ?? [];

  if (errors.length && error) {
    logMutationError(errors, error);
    return errors;
  }
  callback?.();
  return [];
};
