import styled from "styled-components";

import { media, px2rem, responsive } from "@styles";

import { Grid } from "../../../Grid";

import { IconWrapper } from "../../styles";

export const Container = styled.div<{
  isBannerVisible: boolean;
  isMenuVisible: boolean;
}>`
  position: absolute;
  left: 0;
  background: black;
  z-index: 2;
  opacity: 0;
  pointer-events: none;
  overflow: hidden;
  overflow-y: auto;
  transition: ${({ theme: { transitionDuration: duration } }) => `
      top ${duration}s ease-in-out,
      height ${duration * 2}s ease-in-out,
      opacity ${duration}s ease-in 0.3s,
      padding ${duration}s ease-in-out
  `};
  width: 100%;

  ${media.lg.up} {
    top: 100%;
    padding: ${px2rem(20, 40)};
    border: 4px solid ${({ theme }) => theme.colors.white};
    transition: ${({ theme: { transitionDuration: duration } }) => `
      top ${duration}s ease-in-out,
      height ${duration * 2}s ease-in-out,
      opacity ${duration}s ease-in,
      padding ${duration}s ease-in-out
    `};
  }
`;

export const Header = styled.p`
  color: ${({ theme }) => theme.colors.white};
  font-weight: 700;
  text-align: center;
  font-family: ${({ theme }) => theme.typography.condensedFontFamily};
  ${responsive("font-size", px2rem(30), px2rem(35))}
  text-transform: uppercase;
`;

export const ContentWrapper = styled.div`
  position: relative;
  display: block;
  height: calc(100vh + 100px);
  overflow: auto;

  ${media.lg.up} {
    height: auto;
    max-height: 70vh;
  }

  ::-webkit-scrollbar {
    width: 0 !important;
  }
`;

export const ContentGrid = styled(Grid)`
  align-items: flex-start;
  opacity: 0;
  transition: ${({ theme: { transitionDuration: duration } }) => `
    opacity ${duration}s ease-in
  `};

  ${media.lg.down} {
    align-content: flex-start;
    padding-right: ${({ theme }) => theme.spacing.mobileGutter};
    padding-left: ${({ theme }) => theme.spacing.mobileGutter};
  }

  ${media.lg.up} {
    opacity: 1;
  }
`;

export const MobileActionsGrid = styled(Grid)`
  margin-top: 200px;
  opacity: 0;
  padding: ${px2rem(16, 16, 350)};
  transition: ${({ theme: { transitionDuration: duration } }) => `
    margin-top ${duration}s ease-in-out ${duration}s,
    opacity ${duration}s ease-in
  `};
`;

export const MobileTrigger = styled.input<{
  isBannerVisible: boolean;
  isMenuVisible: boolean;
}>`
  display: none;
`;

export const CartWrapper = styled.div<{
  isBannerVisible: boolean;
  isMenuVisible: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
`;

export const CartIconWrapper = styled(IconWrapper)<{
  inactive: boolean;
  isBannerVisible: boolean;
  isMenuVisible: boolean;
}>`
  height: 100%;
  width: 100%;
  justify-content: center;
  display: flex;
  pointer-events: all;
  cursor: pointer;

  ${({ inactive }) =>
    inactive &&
    `
    > div:nth-child(2) {
      display: none;
    }
  `}

  svg path {
    fill: inherit;
  }

  ${media.lg.up} {
    height: ${({ theme }) => theme.header.size.desktop}px;
  }
`;
