import { paths } from "@paths";
import { useAuthState } from "@saleor/sdk";
import React, { useEffect } from "react";

import { TopItem } from "@components/Navigation";
import * as CS from "@components/Navigation/common.styles";
import { useAuthHook } from "@hooks/useAuthHook";
import { useRouter } from "@hooks/useRouter";
import { AuthFormLocationEnum } from "@stores/useAuthFormStore";
import { useHeaderStore } from "@stores/useHeaderStore";

import { ReactComponent as ProfileIcon } from "assets/icons/profile.svg";

import { LanguageSwitcher } from "./styles";

export const Mobile = () => {
  const { asPath, push, locale } = useRouter();
  const { authenticated } = useAuthState();
  const { redirectToLoginPage, handleAuthSuccess } = useAuthHook();

  const {
    nav: { menu },
  } = useHeaderStore();

  const handleAccountClick = async (evt: React.MouseEvent) => {
    evt.stopPropagation();

    const location = AuthFormLocationEnum.PROFILE;

    if (authenticated) {
      handleAuthSuccess({ location });
    } else {
      redirectToLoginPage({ location });
    }
  };

  const handleClubClick = () => {
    push(paths.clubHome);
  };

  useEffect(() => {
    menu.updateMenu({
      activeIndex: 0,
      activeTab: null,
      isActive: false,
    });
  }, [asPath]);

  useEffect(() => {
    document.body.style.overflow = menu.isActive ? "hidden" : "auto";
  }, [menu.isActive]);

  return (
    <CS.MobileNavWrapper>
      <hr />
      <TopItem
        iconFirst
        isButton
        icon={ProfileIcon}
        justifyContent="flex-end"
        label="Account"
        onClick={handleAccountClick}
      />
      {locale === "en-gb" && (
        <TopItem isButton label="Club" onClick={handleClubClick} />
      )}
      <LanguageSwitcher placement="mobile-menu" />
    </CS.MobileNavWrapper>
  );
};
