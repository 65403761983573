import { styled, theme } from "@styles";

import { BadgeVariant, ButtonType, ProductBadgeProps } from "./ProductBadge";

const getColour = (type: ButtonType) => {
  const { colors } = theme.productBadge;
  return `
  color: ${colors[type].color};
  background-color: ${colors[type].backgroundColor};
  `;
};

const getVariantStyles = (variant: BadgeVariant) => {
  switch (variant) {
    case "listing":
      return `
        margin: 8px 0;
      `;
    case "popularSearch":
      return `
        padding: 0 5px;
      `;
    case "productPage":
      return `
        margin: 4px 0
      `;
    default:
      return "";
  }
};

export const Text = styled.span<ProductBadgeProps>`
  font-size: ${theme.typography.sizes.smallest};
  text-align: center;
  padding: 3px 6px;
  font-weight: bold;
  border-width: 2px;
  margin-top: 2px;
  text-transform: uppercase;

  color: ${theme.colors.white};

  ${({ type }) => type && getColour(type)}

  ${({ variant }) => variant && getVariantStyles(variant)}
`;
