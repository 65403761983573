import CryptoJSCore from "crypto-js/core";
import Base64 from "crypto-js/enc-base64";
import EditorJSHTML from "editorjs-html";

export const base64Encode = (wordArray: CryptoJS.lib.WordArray) =>
  Base64.stringify(wordArray)
    // This strip is required for PKCE to properly handle verifier token upon exchange
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, "");

export const generateRandomString = (bytesNumber = 36) =>
  base64Encode(CryptoJSCore.lib.WordArray.random(bytesNumber));

export const isAscii = (text: string) =>
  ![...text].some(char => char.charCodeAt(0) > 127);

export const removeSpecialCharacters = (str: string) =>
  str.replace(/[^\w\s]/gi, "");

export const removeWhiteSpace = (str: string) => str.replace(/\s/g, "");

export const extractRichText = (
  descriptionJSON: string | null
): string | null => {
  if (!descriptionJSON) {
    return null;
  }

  const editorHtml = EditorJSHTML();

  try {
    const descriptionBody = JSON.parse(descriptionJSON);
    const description = editorHtml.parse(descriptionBody).join("");
    if (description.includes("hundefined")) {
      return description.replace(/hundefined/g, "h2");
    }
    if (description.includes("undefined")) {
      return "";
    }

    return description;
  } catch (err) {
    console.error(err, { descriptionJSON });
    return null;
  }
};

export const escapeInnerQuotes = (input: string) => {
  if (typeof input === "string") {
    return input.replace(/"/g, '\\"');
  }
  return input;
};
