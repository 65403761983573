import React from "react";

import { Container } from "@components/Container";
import { ssrMode } from "@config";
import { useIsMdDown } from "@hooks/responsive";

import { Footer } from "./components/Footer";
import { Header } from "./components/Header";

import * as S from "./styles";

type CheckoutLayoutProps = {
  children?: React.ReactNode;
};

export const CheckoutLayout = ({ children }: CheckoutLayoutProps) => {
  const isMdDown = useIsMdDown();

  return ssrMode ? null : (
    <S.Wrapper>
      <S.Column md={12}>
        <Header dark={isMdDown} />
        <Container maxWidth={620}>
          <S.Content>{children}</S.Content>
        </Container>
        <Footer />
      </S.Column>
    </S.Wrapper>
  );
};
