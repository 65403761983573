import { IntlShape } from "react-intl";
import { Asserts, bool, object, string } from "yup";

import { validationMessages } from "@messages/forms";

export const newsletterSchema = (formatMessage: IntlShape["formatMessage"]) =>
  object({
    email: string()
      .email(formatMessage(validationMessages.invalidEmailFormat))
      .required(formatMessage(validationMessages.required)),
    marketingConsent: bool()
      .oneOf([true, undefined], formatMessage(validationMessages.required))
      .default(undefined),
  });

export type NewsletterSchema = Asserts<ReturnType<typeof newsletterSchema>>;
