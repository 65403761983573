import { paths } from "@paths";
import React from "react";

import { Container } from "@components/Container";
import { GridColumn } from "@components/Grid";
import { Link } from "@components/Link";
import { useIsLgDown } from "@hooks/responsive";
import { useRouterCountry } from "@hooks/useRouterCountry";

import * as M from "./messages";
import * as S from "./styles";

export type FooterProps = {
  className?: string;
};

export const Footer = ({ className }: FooterProps) => {
  const { name, flag: Flag } = useRouterCountry();
  const isLgDown = useIsLgDown();

  return (
    <Container className={className} maxWidth="md">
      <S.Wrapper>
        {isLgDown ? (
          <>
            <GridColumn xs={12}>
              <S.P withFlag>
                <Flag />
                {name}
              </S.P>
            </GridColumn>
            <GridColumn xs={6}>
              <Link passHref href={paths.termsAndConditions}>
                <a>
                  <S.P>{M.TermsOfSale}</S.P>
                </a>
              </Link>
            </GridColumn>
            <GridColumn xs={6}>
              <Link passHref href={paths.privacyPolicy}>
                <a>
                  <S.P>{M.PrivacyPolicy}</S.P>
                </a>
              </Link>
            </GridColumn>
          </>
        ) : (
          <>
            <GridColumn flex direction="row" justify="space-between">
              <S.P withFlag>
                <Flag />
                {name}
              </S.P>
              <S.P>
                <Link passHref href={paths.termsAndConditions}>
                  <a>{M.TermsOfSale}</a>
                </Link>
                <Link passHref href={paths.privacyPolicy}>
                  <a>{M.PrivacyPolicy}</a>
                </Link>
              </S.P>
            </GridColumn>
          </>
        )}
      </S.Wrapper>
    </Container>
  );
};
