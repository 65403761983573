import { MainNavigationDocumentDataMenuItemItem } from "prismicio-types";

import {
  navButtonStyling,
  psuedoElement,
  truncateText,
} from "@components/Navigation/mixins";
import { animations, media, px2rem, styled, theme } from "@styles";

export type WrapperWidths = 25 | 33 | 50 | 100;

export const SliceWrapper = styled.div<{ maxWidth?: WrapperWidths }>`
  display: flex;
  flex-flow: row;
  gap: 16px;
  position: relative;
  width: 100%;
  max-width: 100%;
  align-items: stretch;

  ${media.lg.up} {
    flex: 1 1 33%;
    margin-block: 30px;
    padding-inline: 16px;
    align-items: flex-start;
    border-right: 1px ${({ theme }) => theme.colors.medium} solid;
  }
`;

export const HeaderWrapper = styled.div`
  max-width: 100%;

  > * {
    max-width: 100%;
  }
`;

export const SliceContent = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: stretch;
  gap: 20px;

  ${media.lg.up} {
    align-items: flex-start;
  }
`;

export const MenuItemWrapper = styled.div<{ isActive?: boolean }>`
  display: ${({ isActive }) => (isActive ? "flex" : "none")};
  flex: 0;
  gap: 18px;
  position: relative;
  width: 100%;

  ${media.lg.up} {
    flex: 1;
    display: flex;

    &:nth-last-child() {
      display: none;
    }
    &:nth-child(3) hr {
      display: none;
    }
  }
`;

export const SubMenuHeader = styled.div`
  display: none;

  ${media.lg.up} {
    display: flex;
    font-size: ${px2rem(17)};
    position: relative;
    padding: 0 0 0.5em 0;
    margin-inline: 12px;
    font-family: ${({ theme }) => theme.typography.condensedFontFamily};
    font-weight: 600;
    text-decoration: none;
    text-transform: uppercase;

    p {
      ${truncateText()}
    }

    &::before {
      ${psuedoElement({ height: "2px", inset: "auto auto 0 0" })}
      background: ${({ theme }) => theme.colors.white};
      opacity: 1;
    }
  }
`;

export const getMobileHeaderStyling = (
  variant: MainNavigationDocumentDataMenuItemItem["variant"],
  isActive?: boolean
) => {
  const { items } = theme.navigation;
  return `

    &::before {
      background: ${
        variant === "default" ? "white" : items[variant].active.accent
      };
      opacity: ${isActive ? "0.6" : variant === "default" ? "0.15" : "0.4"};)} ;
    }
  `;
};

export const MobileSubMenuHeader = styled.button<{
  iconFirst?: boolean;
  isActive?: boolean;
  variant: MainNavigationDocumentDataMenuItemItem["variant"];
}>`
  ${navButtonStyling({
    fontSize: "small",
    iconFirst: false,
  })}

  &::before {
    z-index: 1;
    opacity: ${({ isActive }) => (isActive ? "0.4" : "0.15")};
  }

  ${({ variant, isActive }) => getMobileHeaderStyling(variant, isActive)}

  svg {
    transform: ${({ isActive }) => (isActive ? "scaleY(-1)" : "scaleY(1)")};
    transition: transform ${animations.timing.medium} ease-out;
  }

  ${media.lg.up} {
    display: none;

    &::before {
      width: 0;
    }
  }
`;

export const IconWrapper = styled.div`
  margin-right: 10px;
  display: flex;
  align-items: center;
`;
