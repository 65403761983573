import { NavSubMenuDocument } from "prismicio-types";
import React, { useMemo, useRef } from "react";

import { LanguageSwitcher } from "@components/LanguageSwitcher";
import { useGlobalConfigStore } from "@stores/useGlobalConfigStore";
import { useHeaderStore } from "@stores/useHeaderStore";
import { scrollToTopRef } from "@utils/misc";
import { LayoutProps } from "@utils/typescript";

import { Mobile, NavItem } from "./components";
import { generateNavItemUid } from "./utils";

import * as S from "./common.styles";

export const Navigation = ({ variant }: LayoutProps) => {
  const { navigation } = useGlobalConfigStore();

  const { data: nav } = navigation || {};

  const menuItems = useMemo(() => nav?.menu_item, [nav]);

  const {
    topBanner,
    nav: { menu },
  } = useHeaderStore();

  const navRef = useRef<HTMLDivElement | null>(null);

  return (
    <>
      <S.NavigationBar
        data-nosnippet
        isActive={menu.isActive}
        isBannerVisible={topBanner.isActive}
        ref={navRef}
        variant={variant}
      >
        <S.NavContent>
          <S.LanguageWrapper id="region-selector-nav">
            <LanguageSwitcher asText placement="navigation" />
          </S.LanguageWrapper>
          <S.MainNav>
            <S.NavList>
              {menuItems &&
                menuItems.map((item, i) => {
                  const uid = generateNavItemUid(item.label as string);

                  return (
                    <NavItem
                      index={i}
                      key={`${item.label}-${i}`}
                      onClick={() => {
                        scrollToTopRef(
                          navRef,
                          menu.updateMenu({
                            activeTab: uid,
                          })
                        );
                      }}
                      uid={uid}
                      {...item}
                      sub_menu={item.sub_menu as unknown as NavSubMenuDocument}
                    />
                  );
                })}
              <Mobile />
            </S.NavList>
          </S.MainNav>
        </S.NavContent>
        <S.BlurContainer
          className="blur-container"
          isActive={menu.isActive}
          isBannerVisible={topBanner.isActive}
        />
      </S.NavigationBar>
    </>
  );
};
