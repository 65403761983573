import { TaxedPrice } from "@gqlTypes/TaxedPrice";
import React from "react";
import { FormattedMessage } from "react-intl";

import { FormattedPrice } from "@components/FormattedPrice";
import { useConfigContext } from "@hooks/providers";
import { commonMessages } from "@messages/common";

import * as M from "../../messages";
import * as S from "./styles";

type TotalProps = {
  price: TaxedPrice;
};

export const Total = ({ price }: TotalProps) => {
  const { channel } = useConfigContext();

  return (
    <S.BasketLine>
      <S.Total>
        <S.LineHeader>
          <FormattedMessage {...commonMessages.subtotal} />
        </S.LineHeader>
        <S.FinalPriceNote>{M.FinalPriceNote}</S.FinalPriceNote>
      </S.Total>

      <S.LineAmount>
        <FormattedPrice price={channel === "us" ? price.net : price.gross} />
      </S.LineAmount>
    </S.BasketLine>
  );
};
