import React from "react";
import { defineMessages, FormattedMessage } from "react-intl";

const messages = defineMessages({
  alreadySubscribed: {
    defaultMessage: "Already subscribed",
    id: "c23gDk",
  },
  authenticated: {
    defaultMessage: "Continue",
    id: "acrOoz",
  },
  placeOrder: {
    defaultMessage: "Place order",
    id: "JItzoH",
  },
  requireAuthentication: {
    defaultMessage: "Log in to continue",
    id: "acWSVG",
  },
});

export enum NextButtonEnum {
  "ALREADY_SUBSCRIBED" = "alreadySubscribed",
  "AUTHENTICATED" = "authenticated",
  "PLACE_ORDER" = "placeOrder",
  "REQUIRE_AUTHENTICATION" = "requireAuthentication",
}

export const DynamicNextButton = (status: NextButtonEnum) => {
  const message = messages[status] || messages.authenticated;

  return <FormattedMessage {...message} />;
};

export const FormNextButton = (text: string) => (
  <FormattedMessage
    defaultMessage="{text}"
    id="ax2mk0"
    values={{
      text,
    }}
  />
);

const backButtonMessages = defineMessages({
  default: {
    defaultMessage: "Back",
    id: "cyR7Kh",
  },
  firstPage: {
    defaultMessage: "Cancel",
    id: "47FYwb",
  },
});

export const FormBackButton = (isFirstStep: boolean) => {
  const message = isFirstStep
    ? backButtonMessages.firstPage
    : backButtonMessages.default;

  return <FormattedMessage {...message} />;
};
