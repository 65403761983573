import { asLink } from "@prismicio/client";
import Link from "next/link";
import { FooterDocumentDataRightFooterNavItem } from "prismicio-types";
import React, { useState } from "react";

import { ReactComponent as ArrowSvg } from "assets/icons/arrow-down.svg";

import * as CS from "../../styles";
import * as S from "./styles";

export interface NavMenu {
  links: FooterDocumentDataRightFooterNavItem[];
  title: string;
}

interface NavLinksProps {
  list: NavMenu[];
}

export const NavLinks = ({ list }: NavLinksProps) => {
  const [activeIndex, setActiveIndex] = useState<number | false>(false);

  return (
    <>
      {list.map(({ links, title }, i) => {
        const isActive = activeIndex === i;

        return (
          <S.Column isActive={isActive} key={title}>
            <S.TitleContainer
              isActive={isActive}
              onClick={() => setActiveIndex(isActive ? false : i)}
            >
              <CS.NavHeading isActive={isActive}>{title}</CS.NavHeading>

              <S.MobileArrow isActive={isActive}>
                <ArrowSvg key={title} />
              </S.MobileArrow>
            </S.TitleContainer>
            <S.LinkContainer isActive={isActive}>
              <S.InnerContainer>
                {links?.map(({ link, text, internal_url }, i) => {
                  if (internal_url) {
                    return (
                      <Link passHref href={internal_url} key={text}>
                        <a className="footer-nav-link">{text}</a>
                      </Link>
                    );
                  }
                  if (asLink(link) && !internal_url) {
                    return (
                      <a
                        className="footer-nav-link"
                        href={asLink(link) as string}
                        key={`${text} -${i}`}
                      >
                        {text}
                      </a>
                    );
                  }
                })}
              </S.InnerContainer>
            </S.LinkContainer>
          </S.Column>
        );
      })}
    </>
  );
};
