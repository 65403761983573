import {
  PublishedPeriods,
  ReleasedFilterValue,
  SearchableAttribute,
  ToggleAttribute,
} from "@components/ProductFilters/types";

interface AlgoliaHighlightResultBase {
  matchLevel: "none" | "partial" | "full";
  matchedWords: string[];
  value: string;
}

type Thumbnail = string | null;

export type Thumbnails = {
  DefaultThumbnailSize: any;
  thumbnail1080: Thumbnail;
  thumbnail120: Thumbnail;
  thumbnail2048: Thumbnail;
  thumbnail255: Thumbnail;
  thumbnail4096: Thumbnail;
  thumbnail510: Thumbnail;
  thumbnail540: Thumbnail;
  thumbnail60: Thumbnail;
};

export enum ThumbnailsEnum {
  "DEFAULT" = "thumbnail2048",
  "FALLBACK" = "thumbnail1080",
  "SIZE_1080" = "thumbnail1080",
  "SIZE_120" = "thumbnail120",
  "SIZE_2048" = "thumbnail2048",
  "SIZE_255" = "thumbnail255",
  "SIZE_4096" = "thumbnail4096",
  "SIZE_510" = "thumbnail510",
  "SIZE_540" = "thumbnail540",
  "SIZE_60" = "thumbnail60",
}

export const DefaultThumbnailSize = ThumbnailsEnum.DEFAULT;

export interface AlgoliaHighlightResult {
  artists?: { name: AlgoliaHighlightResultBase }[];
  name?: {
    fullyHighlighted?: boolean;
  } & AlgoliaHighlightResultBase;
}

export interface AlgoliaResultObject {
  name: string;
  slug: string;
}

export interface AlgoliaResultArtist extends AlgoliaResultObject {
  thumbnail: Thumbnail;
}

export interface AlgoliaResultCollections extends AlgoliaResultObject {
  id: string;
}

export interface AlgoliaResult {
  __position: number;
  __queryID: string;
  _highlightResult: AlgoliaHighlightResult;
  artists: AlgoliaResultArtist[];
  bonus: boolean;
  category: string[];
  collections: AlgoliaResultCollections[];
  colouredVinyl: boolean;
  currency: string;
  exclusive: boolean;
  formats: AlgoliaResultObject[];
  genres: AlgoliaResultObject[];
  id: string;
  includesDownload: boolean;
  isAvailableForPurchase: boolean;
  isPublished: boolean;
  labels: AlgoliaResultObject[];
  limited: boolean;
  maxPrice: string;
  minPrice: string;
  name: string;
  objectID: string;
  preorders: false;
  productType: AlgoliaResultObject;
  releaseDates: number[];
  sale: boolean;
  signed: boolean;
  slug: string;
  thumbnail: Thumbnail;
  thumbnails: Thumbnails;
}

export interface AlgoliaEventResult {
  __position: number;
  __queryID: string;
  _highlightResult: AlgoliaHighlightResult;
  announceDate: string;
  dateTime: string;
  eventType: string;
  id: string;
  image: string;
  name: string;
  objectID: string;
  offSaleDate: string;
  unixDateTime: string;
  url: string;
  venue: string;
}

type BlogTagProperty = {
  matchLevel: string;
  value: string;
};

interface BlogTag {
  accent_color: string | null;
  canonical_url: string | null;
  codeinjection_foot: string | null;
  codeinjection_head: string | null;
  description: string | null;
  feature_image: string | null;
  id: string;
  meta_description: string | null;
  meta_title: string | null;
  name: string;
  og_description: string | null;
  og_image: string | null;
  og_title: string | null;
  slug: string;
  twitter_description: string | null;
  twitter_image: string | null;
  twitter_title: string | null;
  url: string | null;
  visibility: "public" | "internal";
}

export interface AlgoliaBlogResult {
  __position: number;
  __queryID: string;
  _snippetResult: {
    comment_id: BlogTagProperty;
    created_at: BlogTagProperty;
    feature_image: BlogTagProperty;
    html: BlogTagProperty;
    id: BlogTagProperty;
    primary_tag: {
      description: BlogTagProperty;
      feature_image: BlogTagProperty;
      id: BlogTagProperty;
      meta_description: BlogTagProperty;
      meta_title: BlogTagProperty;
      name: BlogTagProperty;
      slug: BlogTagProperty;
      url: BlogTagProperty;
      visibility: BlogTagProperty;
    };
    published_at: BlogTagProperty;
    slug: BlogTagProperty;
    tags: Array<{
      description: BlogTagProperty;
      feature_image: BlogTagProperty;
      id: BlogTagProperty;
      meta_description: BlogTagProperty;
      meta_title: BlogTagProperty;
      name: BlogTagProperty;
      slug: BlogTagProperty;
      url: BlogTagProperty;
      visibility: BlogTagProperty;
    }>;
    title: BlogTagProperty;
    updated_at: BlogTagProperty;
    uuid: BlogTagProperty;
    visibility: BlogTagProperty;
  };
  access: boolean;
  canonical_url: string | null;
  codeinjection_foot: string | null;
  codeinjection_head: string | null;
  comment_id: string;
  comments: boolean;
  created_at: string;
  custom_excerpt: string | null;
  custom_template: string | null;
  email_subject: string | null;
  excerpt: string;
  feature_image: string;
  feature_image_alt: string | null;
  feature_image_caption: string | null;
  featured: boolean;
  frontmatter: string | null;
  html: string;
  id: string;
  meta_description: string | null;
  meta_title: string | null;
  objectID: string;
  og_description: string | null;
  og_image: string | null;
  og_title: string | null;
  primary_tag: BlogTag;
  published_at: string;
  reading_time: number;
  slug: string;
  tags: BlogTag[];
  title: string;
  twitter_description: string | null;
  twitter_image: string | null;
  twitter_title: string | null;
  updated_at: string;
  url: string;
  uuid: string;
  visibility: "public" | "internal";
}

export interface AlgoliaQuery {
  facet: string;
  indexName: string;
  params: {
    clickAnalytics: boolean;
    enablePersonalization: boolean;
    filters: string;
    hitsPerPage: number;
    numericFilters: string[];
    userToken: string | undefined;
  };
  query: string;
}

export interface AlgoliaCustomQuery {
  filters: string;
  query: string;
  sortByIndex: string;
}

export type SearchState = {
  menu?: Partial<Record<SearchableAttribute, string | null>>;
  multiRange: Partial<Record<"releaseDates" | "publishedAt", string>>;
  page: number;
  query?: string;
  sortBy?: string;
  toggle?: Partial<Record<ToggleAttribute, boolean>>;
};

export type PublishedItem = {
  end?: number;
  label: PublishedPeriods;
  start: number;
};

export type ReleaseDateItem = {
  end?: number;
  label: ReleasedFilterValue;
  start: number;
};
