import { Grid } from "@components/Grid";
import { media, noPrint, px2rem, styled } from "@styles";

export const Wrapper = styled(Grid)`
  font-size: ${px2rem(13)};
  padding: ${px2rem(60, 0, 20)};

  ${noPrint}

  svg {
    height: 16px;
    padding-right: 1rem;
  }

  a {
    text-decoration: none;
    color: inherit;
    padding: 0 ${px2rem(18)};
  }
`;

export const P = styled.p<{ withFlag?: boolean }>`
  opacity: 0.7;
  display: flex;
  align-items: center;
  padding: ${px2rem(6)} 0;

  ${media.lg.down} {
    justify-content: center;
    text-align: center;

    ${({ withFlag }) => withFlag && `padding-bottom: 1rem;`}
  }
`;
