import {
  FiltersAttribute,
  ListingParam,
  SearchableAttributeName,
} from "./types";

export const SearchableSlugs = [
  "productType.slug",
  "artists.slug",
  "formats.slug",
  "genres.slug",
  "collections.slug",
  "labels.slug",
] as const;

export const SearchableNames = [
  "productType.name",
  "artists.name",
  "formats.name",
  "genres.name",
  "collections.name",
  "labels.name",
] as const;

export const ToggleAttributes = [
  "bonus",
  "colouredVinyl",
  "exclusive",
  "includesDownload",
  "limited",
  "preorders",
  "sale",
  "signed",
  "recordStoreDay",
  "inStock",
] as const;

export const ToggleURLParams = [
  "bonus-disc",
  "coloured-vinyl",
  "rough-trade-exclusive",
  "includes-download",
  "limited",
  "pre-order",
  "record-store-day",
  "sale",
  "signed",
  "in-stock",
] as const;

export const SearchableURLValues = [
  "product-type",
  "format",
  "genre",
  "label",
  "artist",
  "collection",
] as const;

export const SortByValues = [
  "most-relevant",
  "most-popular",
  "newest-listed",
  "alpha-asc",
  "alpha-desc",
  "newest-released",
  "oldest-released",
] as const;

export const SortByParams = [
  "most-relevant",
  "most-popular",
  "newest-listed",
  "alpha-asc",
  "alpha-desc",
  "newest-released",
  "oldest-released",
] as const;

export const TimeAttributes = ["released", "publishedAt"];

export const ReleaseDateFilterValues = [
  "anytime",
  "upcoming",
  "new-this-week",
  "new-this-month",
  "new-this-year",
] as const;

export const ReleaseDateParams = [
  "anytime",
  "upcoming",
  "new-this-week",
  "new-this-month",
  "new-this-year",
] as const;

export const URLFilterMapping: {
  [key in ListingParam]: FiltersAttribute;
} = {
  "alpha-asc": "alphaAsc",
  "alpha-desc": "alphaDesc",
  anytime: "anytime",
  artist: "artists.name",
  "bonus-disc": "bonus",
  collection: "collections.slug",
  "coloured-vinyl": "colouredVinyl",
  format: "formats.name",
  genre: "genres.name",
  "in-stock": "inStock",
  "includes-download": "includesDownload",
  label: "labels.name",
  limited: "limited",
  "most-popular": "most-popular",
  "most-relevant": "most-relevant",
  "new-this-month": "new-this-month",
  "new-this-week": "new-this-week",
  "new-this-year": "new-this-year",
  "newest-listed": "newest-listed",
  "newest-released": "newest-released",
  "oldest-released": "oldest-released",
  "pre-order": "preorders",
  "product-type": "productType.name",
  "record-store-day": "recordStoreDay",
  "rough-trade-exclusive": "exclusive",
  sale: "sale",
  signed: "signed",
  upcoming: "upcoming",
};

export const SearchableFields: {
  attribute: SearchableAttributeName;
  searchable: boolean;
}[] = [
  { attribute: "artists.name", searchable: true },
  { attribute: "genres.name", searchable: true },
  {
    attribute: "productType.name",
    searchable: true,
  },
  { attribute: "formats.name", searchable: true },
  { attribute: "labels.name", searchable: true },
];

export const CollectionPageUrls = [
  "collection",
  "artist",
  "label",
  "genres",
] as const;
