import { CSSProperties } from "react";
import { css } from "styled-components";

import { styled, theme } from "@styles";

import { GridColumn } from "../GridColumn";

export type GridSpacing = 0 | 1 | 2 | 3 | 4 | 5 | string;

export interface GridProps
  extends Partial<
    Pick<CSSProperties, "alignSelf" | "alignContent" | "alignItems">
  > {
  align?: CSSProperties["textAlign"];
  className?: string;
  direction?: CSSProperties["flexDirection"];
  evenOuterSpacing?: boolean;
  hidden?: boolean;
  horizontalSpacing?: GridSpacing;
  onlyInnerSpacing?: boolean;
  padding?: number;
  relative?: boolean;
  spacing?: GridSpacing;
  verticalSpacing?: GridSpacing;
}

const getSpacing = (spacing: GridSpacing | string) =>
  typeof spacing === "string" ? spacing : theme.grid.spacing[spacing];

const gridPadding = ({
  spacing,
  type,
  evenOuterSpacing,
  onlyInnerSpacing,
}: {
  evenOuterSpacing: boolean | undefined;
  onlyInnerSpacing: boolean | undefined;
  spacing: GridSpacing | string;
  type: "horizontal" | "vertical" | "both";
}) => {
  const isBoth = type === "both";
  const isHorizontal = type === "horizontal";
  const isVertical = type === "vertical";

  return css`
    ${(isBoth || isHorizontal) &&
    `
      margin-left: -${getSpacing(spacing)};
      margin-right: -${getSpacing(spacing)};
    `}

    ${evenOuterSpacing &&
    (isBoth || isVertical) &&
    `
      padding-top: ${getSpacing(spacing)};
      padding-bottom: ${getSpacing(spacing)};
    `}

    ${(isBoth || isVertical) &&
    onlyInnerSpacing &&
    `
      margin-top: -${getSpacing(spacing)};
      margin-bottom: -${getSpacing(spacing)};
    `}

    ${(isBoth || isHorizontal) &&
    !onlyInnerSpacing &&
    `
      padding-left: calc(${getSpacing(spacing)} * ${evenOuterSpacing ? 2 : 1});
      padding-right: calc(${getSpacing(spacing)} * ${evenOuterSpacing ? 2 : 1});
    `}

    ${(isBoth || isVertical) &&
    `
      & > ${GridColumn} {
        padding-top: ${getSpacing(spacing)};
        padding-bottom: ${getSpacing(spacing)};
      }
    `}

    ${(isBoth || isHorizontal) &&
    `
      & > ${GridColumn} {
        padding-left: ${getSpacing(spacing)};
        padding-right: ${getSpacing(spacing)};
      }
    `}
  `;
};

export const Grid = styled.div<GridProps>`
  width: ${({ horizontalSpacing, spacing }) =>
    horizontalSpacing || spacing
      ? `calc(100% + 2 * ${getSpacing(horizontalSpacing! || spacing!)})`
      : "100%"};
  display: flex;
  flex-wrap: wrap;

  ${({ alignItems }) => alignItems && `align-items: ${alignItems};`}
  ${({ alignSelf }) => alignSelf && `align-self: ${alignSelf};`}
  ${({ alignContent }) => alignContent && `align-content: ${alignContent};`}
  ${({ direction }) => direction && `flex-direction: ${direction};`}
  ${({ align }) => align && `text-align: ${align};`}
  ${({ relative }) => relative && `position: relative;`}

  ${({
    horizontalSpacing,
    verticalSpacing,
    spacing,
    evenOuterSpacing,
    onlyInnerSpacing,
  }) =>
    css`
      ${horizontalSpacing &&
      gridPadding({
        evenOuterSpacing,
        onlyInnerSpacing,
        spacing: horizontalSpacing,
        type: "horizontal",
      })}

      ${verticalSpacing &&
      gridPadding({
        evenOuterSpacing,
        onlyInnerSpacing,
        spacing: verticalSpacing,
        type: "vertical",
      })}

      ${spacing &&
      gridPadding({
        evenOuterSpacing,
        onlyInnerSpacing,
        spacing,
        type: "both",
      })}
    `}
`;
Grid.displayName = "Grid";

export const GridWrapper = styled.div`
  display: flex;
`;
