import styled from "styled-components";

import { media } from "@styles";

export const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  column-gap: 1vw;
  position: relative;
  justify-content: space-between;

  ${media.sm.up} {
    column-gap: 4vw;
    height: 100%;
    position: static;
  }
`;

export const SearchWrapper = styled.div<{ isActive: boolean }>`
  width: 100%;
  display: ${({ isActive }) => (isActive ? "flex" : "none")};
  transition: margin ${({ isActive }) => (isActive ? 0.2 : 0.25)}s ease-in-out;
  margin: ${({ isActive }) => (isActive ? "10px" : "16px")};

  ${media.lg.up} {
    display: flex;
  }
`;

export const ActionWrapper = styled.div`
  display: flex;
  flex-flow: row;
  gap: 0;
  justify-content: space-between;
`;

export const DesktopActionWrapper = styled(ActionWrapper)`
  gap: 4px;
  justify-content: end;

  ${media.lg.up} {
    justify-content: space-between;
  }
`;

export const MobileActionWrapper = styled(ActionWrapper)`
  ${media.lg.up} {
    display: none;
  }
`;

export const HamburgerMenu = styled.div`
  display: flex;
  background: white;
  width: 20px;
  height: 100%;
`;
