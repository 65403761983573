import React from "react";
import { FormattedMessage } from "react-intl";

export const BackToStore = (
  <FormattedMessage defaultMessage="Go back to store" id="Ym+J+Y" />
);

export const TermsOfUse = (
  <FormattedMessage defaultMessage="Terms of Use" id="UhkSyx" />
);

export const PrivacyPolicy = (
  <FormattedMessage defaultMessage="Privacy Policy" id="vx0nkZ" />
);

export const CompanyDetails = (
  <FormattedMessage defaultMessage="Company Details" id="XfpxKL" />
);
