import { paths } from "@paths";
import { useAuthState } from "@saleor/sdk";
import { useRouter } from "next/router";
import React, { useMemo } from "react";

import { Button } from "@components/Button";
import { ButtonLink } from "@components/ButtonLink";
import { useAuthHook } from "@hooks/useAuthHook";
import { AuthFormLocationEnum } from "@stores/useAuthFormStore";
import { useClubSubscriptionStore } from "@stores/useClubSubscriptionStore";
import { isValidHttpUrl } from "@utils/urls";
import { ClubRecipientValues } from "@views/ClubFormView/types";
import { checkRedirectRecipient } from "@views/ClubFormView/utils";

import { FormStepCounter } from "../FormStepCounter";

import * as M from "./messages";
import { NextButtonEnum } from "./messages";
import * as S from "./styles";

const ANIMATION_TIMER = 600;

export const FormFooter = () => {
  const {
    action: currentAction,
    pages,
    tier,
    values,
    setPages,
    setAnimating,
  } = useClubSubscriptionStore();
  const router = useRouter();
  const { authenticated } = useAuthState();
  const { redirectToLoginPage } = useAuthHook();

  const isAlreadySubscribed = false;
  const isFirstStep = pages.current === 1;
  const isLastStep = pages.current === pages.total;
  const finalFormUrl = "";

  const recipientTarget = values.recipient as ClubRecipientValues;

  const handlePageChange = async () => {
    const didRedirect = await checkRedirectRecipient({
      currentAction,
      page: pages.current,
      recipientTarget,
      router,
      tier,
    });

    if (!didRedirect) {
      setPages({
        current: pages.current + 1,
      });
      animationTimeout();
    }
  };

  const animationTimeout = () => {
    setAnimating(true);

    setTimeout(() => {
      setAnimating(false);
    }, ANIMATION_TIMER);
  };

  const handleBack = () => {
    if (pages.current === 1) {
      router.push(paths.clubHome);
    } else {
      setPages({
        current: pages.current - 1,
      });
      animationTimeout();
    }
  };

  const handleNext = async () => {
    if (values.recipient === "self" && isFirstStep && !authenticated) {
      redirectToLoginPage({ location: AuthFormLocationEnum.CLUB });
    } else if (isLastStep) {
      const { checkoutPage } = values;

      if (checkoutPage && isValidHttpUrl(checkoutPage)) {
        const finalUrl = new URL(checkoutPage);
        window.open(finalUrl.href, "_blank");
      }
    } else {
      handlePageChange();
    }
  };

  const nextButtonText = useMemo(() => {
    if (!authenticated && values.recipient === "self") {
      return NextButtonEnum.REQUIRE_AUTHENTICATION;
    }
    if (isLastStep) {
      return isAlreadySubscribed
        ? NextButtonEnum.ALREADY_SUBSCRIBED
        : NextButtonEnum.PLACE_ORDER;
    }

    return NextButtonEnum.AUTHENTICATED;
  }, [values.recipient, isLastStep, authenticated, isAlreadySubscribed]);

  return (
    <S.ClubFormFooter isFooter>
      <FormStepCounter />
      <S.FormActions>
        <Button onClick={handleBack} variant="hollowLight">
          {M.FormBackButton(isFirstStep)}
        </Button>

        {isLastStep && finalFormUrl ? (
          <ButtonLink
            fullWidth
            isExternal
            className={isAlreadySubscribed ? "disabled" : ""}
            fontSize="small"
            href={finalFormUrl}
            onClick={e => {
              if (isAlreadySubscribed) {
                e.preventDefault();
              }
            }}
            variant="primary"
            withIcon={false}
          >
            {M.DynamicNextButton(nextButtonText)}
          </ButtonLink>
        ) : (
          <Button fullWidth onClick={handleNext}>
            {M.DynamicNextButton(nextButtonText)}
          </Button>
        )}
      </S.FormActions>
    </S.ClubFormFooter>
  );
};
