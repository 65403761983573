import { TaxedPrice } from "@gqlTypes/TaxedPrice";
import React from "react";

import { FormattedPrice } from "@components/FormattedPrice";
import { Tooltip } from "@components/Tooltip";
import { Channel } from "@providers/TranslationProvider/types";

import * as M from "./messages";
import * as S from "./styles";

type TaxProps = {
  price: TaxedPrice;
  variant: Channel;
};

export const Tax = ({ price, variant }: TaxProps) => {
  const Message = (
    <S.Container>
      <p>
        {variant === "gb" && M.InclusiveVat}
        {variant === "us" && M.SalesTax}
      </p>
      <p>
        <FormattedPrice price={price.tax} />
      </p>
    </S.Container>
  );

  return <Tooltip trigger={Message}>{M.TooltipInfo}</Tooltip>;
};
