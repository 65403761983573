import { initializeSecureApolloClient } from "@graphql/client";
import { getSliderCollection } from "@graphql/utils";
import { createClient } from "@prismicConfig";
import type * as prismic from "@prismicio/client";
import {
  BasketDocument,
  ClubPageDocument,
  ClubPageDocumentDataSlicesSlice,
  ClubTierDocument,
  DiceEventsSliceDefaultItem,
  NavSubMenuDocument,
} from "prismicio-types";

import { getChannel } from "@providers/ConfigProvider/helpers";
import { CollectionDataItem } from "@providers/ConfigProvider/types";

import { AllCustomTypes, ModifiedBasketDocument } from "./types";

type PrismicQueryWrapperArgs<Q extends (...args: any[]) => Promise<any>> = {
  ignoreError?: boolean;
  params: Parameters<Q>;
  query: Q;
  returnOnError: Awaited<ReturnType<Q>> | null;
};

/**
 *
 * @param param0
 * @returns Wraps a Prismic query in a try catch statement and returns the result or null if an error occurs
 */
export const prismicQueryWrapper = async <
  Q extends (...args: any[]) => Promise<any>
>({
  params,
  query,
  returnOnError,
  ignoreError = false,
}: PrismicQueryWrapperArgs<Q>): Promise<Awaited<ReturnType<Q>> | null> => {
  try {
    return await query(...params);
  } catch (error) {
    if (!ignoreError) {
      console.error("Error during Prismic query:", error);
    }
    return returnOnError;
  }
};

/**
 *
 * @param page
 * @param slug
 * @returns All event paths based on the nav_sub_menu document
 */
export const filterEvents = (page: NavSubMenuDocument, slug: string) => {
  if (!page.data.slices?.[0]) {
    return null;
  }

  const { items } = page.data.slices[0];

  const filteredEvent =
    (items as DiceEventsSliceDefaultItem[]).filter(
      item => item.slug === slug
    )[0] || items[0];

  return filteredEvent;
};

export type ExtractDocumentPathsProps = {
  filters?: string | string[] | undefined;
  locale: string;
  type: AllCustomTypes;
};

const extractDocumentPaths = async ({
  type,
  locale,
  filters,
}: ExtractDocumentPathsProps) => {
  const client = createClient();
  const res = await client.getAllByType(type, {
    filters,
    lang: locale as unknown as string,
  });

  if (!res) {
    return { paths: [] };
  }

  const uids = res
    .map(r => r.uid)
    .flat()
    .filter(uid => uid != null);

  return { paths: uids };
};

/**
 *
 * @param type
 * @param locale
 * @returns Generates a list of all valid document paths based on the type and locale
 */
export const getDocumentStaticPaths = async (
  params: ExtractDocumentPathsProps
) => {
  const returnOnError = {
    paths: [],
  };

  const res = await prismicQueryWrapper({
    params: [
      {
        ...params,
      },
    ],
    query: extractDocumentPaths,
    returnOnError,
  });

  return res;
};

/**
 *
 * @param page
 * @returns Returns club tier benefits from the club_tier document - helps to avoid duplication of the same content
 */
export const attachClubBenefits = async (page: ClubPageDocument) => {
  const { slices, club_tier } = page.data;

  const tierData = (club_tier as unknown as ClubTierDocument).data;

  const data = slices.map(slice => {
    const { slice_type } = slice;

    if (slice_type === "club_tier_benefits" && tierData?.tier_benefits) {
      (slice.primary.club_tier as unknown as any) = tierData?.tier_benefits;
    }

    return slice;
  });

  return data as prismic.SliceZone<ClubPageDocumentDataSlicesSlice>;
};

/**
 *
 * @param rawBasket
 * @param lang
 * @returns Fetch and attach the active and empty collection to the basket
 */
export const fetchBasketCollection = async (
  rawBasket: prismic.ContentRelationshipField<"basket">,
  lang: string
) => {
  const apolloClient = initializeSecureApolloClient();
  const basket = rawBasket as unknown as BasketDocument;
  const channel = getChannel(lang);

  const collectionIDS = [
    {
      collectionId: basket.data?.active_collection_id,
      selector: "activeCollection",
    },
    {
      collectionId: basket.data?.empty_collection_id,
      selector: "emptyCollection",
    },
  ];

  const collectionData = (
    await Promise.all(
      collectionIDS.map(async ({ collectionId, selector }) => {
        if (collectionId) {
          return {
            data: await getSliderCollection(
              collectionId,
              channel,
              10,
              "",
              apolloClient
            ),
            id: collectionId,
            selector,
          };
        }
      })
    )
  )?.filter(Boolean) as CollectionDataItem[];

  const collections =
    collectionData?.reduce(
      (acc, { data, selector }) => ({ ...acc, [selector]: data }),
      {}
    ) || null;

  return {
    ...basket,
    collections,
  } as ModifiedBasketDocument;
};
