import { paths } from "@paths";
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";

import { Container } from "@components/Container";
import { AppEvents, helpCentreUrl } from "@config";

import * as M from "./messages";
import * as S from "./styles";

export interface ErrorPageProps {
  clearError?: () => void;
  isModalView?: boolean;
  subtitle?: React.ReactElement;
  title: React.ReactElement;
}

export const ErrorPage = ({
  title,
  subtitle,
  clearError,
  isModalView = false,
}: ErrorPageProps) => {
  const { push } = useRouter();

  const handleHomeClick = async () => {
    await push(paths.home);

    if (clearError) {
      clearError();
    }
  };

  const handleSearchClick = async () => {
    const evtName: AppEvents = "searchOpen";
    window.dispatchEvent(new Event(evtName));

    if (clearError) {
      await push(paths.home);

      clearError();
    }
  };

  return (
    <Container>
      <S.ErrorPageWrapper isModalView={isModalView}>
        <S.ErrorPageContent>
          <S.ErrorPageHeader>
            <S.ErrorPageHeaderTitle>{title}</S.ErrorPageHeaderTitle>
            <S.ErrorPageSubheader>{subtitle}</S.ErrorPageSubheader>
          </S.ErrorPageHeader>
          <S.LinksWrapper>
            <S.LinkText>{M.HelpLinksMessage}</S.LinkText>
            <S.ButtonWrapper>
              <S.ErrorButton active={false} onClick={handleHomeClick}>
                {M.HomeButtonMessage}
              </S.ErrorButton>
              <S.ErrorButton
                active
                onClick={handleSearchClick}
                variant="hollow"
              >
                {M.SearchButtonMessage}
              </S.ErrorButton>
              <Link
                passHref
                href={helpCentreUrl || paths.home}
                prefetch={false}
              >
                <S.Link active>{M.HelpButtonMessage}</S.Link>
              </Link>
            </S.ButtonWrapper>
          </S.LinksWrapper>
        </S.ErrorPageContent>
      </S.ErrorPageWrapper>
    </Container>
  );
};
