import { createClient } from "@prismicConfig";
import { fetchVariants } from "@ssr/helpers";
import {
  CheckoutDocument,
  DiceEventsSliceDefaultItem,
  FooterDocument,
  MainNavigationDocument,
  ProductPageDocument,
  RedirectModalConfigurationDocument,
  SearchPanelDocument,
  TopBannerDocument,
} from "prismicio-types";
import { OfTheYearPageParams } from "src/pages/[path]/[uid]";
import {
  fetchBasketCollection,
  fetchSearchPanelCollections,
} from "src/pages/api/shared-content";
import { ClubFormPageParams } from "src/pages/club/[page]/[action]";

import { getChannel } from "@providers/ConfigProvider/helpers";
import { fetchBatchCarousel } from "@utils/carousel";
import { filterNotAvailableVariant } from "@utils/products";
import { ClubActions } from "@views/ClubFormView/types";
import { returnDefaultFormValues } from "@views/ClubFormView/utils";
import { SupportedIndexPageTypes } from "@views/IndexView/types";

import { BasicQueryProps } from "./types";
import { attachClubBenefits, filterEvents, prismicQueryWrapper } from "./utils";

/** -- Club Pages -- */

/**
 *
 * @param lang
 * @returns All valid club paths (e.g. /club/basic/join) removing all those that are not linked in the club_tier document
 */
const fetchClubPages = async ({ lang }: BasicQueryProps) => {
  const client = createClient();
  const res = await client.getAllByType("club_tier", {
    lang,
  });

  const paths: Array<ClubFormPageParams> = [];

  res?.forEach(doc => {
    const { join_page, gift_page } = doc.data;

    const pages = [
      { action: ClubActions.JOIN, pageData: join_page },
      { action: ClubActions.GIFT, pageData: gift_page },
    ];

    pages.forEach(({ pageData, action }) => {
      const { uid, isBroken } =
        (pageData as { isBroken: boolean; uid?: string }) || {};

      if (!isBroken && uid) {
        const page = uid?.split("_")?.[1] || uid;
        paths.push({
          action,
          page,
        });
      }
    });
  });

  return {
    paths,
  };
};

/**
 *
 * @param locale
 * @returns Return list of valid club paths as array of strings
 */
export const getClubStaticPaths = async (params: BasicQueryProps) => {
  const returnOnError = {
    paths: [],
  };

  const res = await prismicQueryWrapper({
    params: [
      {
        ...params,
      },
    ],
    query: fetchClubPages,
    returnOnError,
  });

  return res;
};

/** -- Events -- */
type EventQueryProps = BasicQueryProps & {
  excludeAllLocation?: boolean;
};

/**
 *
 * @param locale
 * @param excludeAllLocation
 * @returns Returns all valid event page paths based on the nav_sub_menu document
 */
const fetchEventData = async ({
  lang,
  excludeAllLocation = false,
}: EventQueryProps): Promise<DiceEventsSliceDefaultItem[] | null> => {
  const client = createClient();
  const eventDoc = await client.getByUID("nav_sub_menu", "events", {
    lang,
  });

  const locations = eventDoc?.data?.slices?.[0]
    ?.items as DiceEventsSliceDefaultItem[];

  if (!locations) {
    return null;
  }

  if (excludeAllLocation && locations) {
    return locations.filter(location => location.slug !== "all-events");
  }

  return locations;
};

/**
 *
 * @param lang
 * @param excludeAllLocation
 * @returns Returns all valid event page paths as array of strings
 */
export const getEventStaticPaths = async (
  params: EventQueryProps
): Promise<DiceEventsSliceDefaultItem[] | null> =>
  prismicQueryWrapper({
    ignoreError: true,
    params: [
      {
        ...params,
      },
    ],
    query: fetchEventData,
    returnOnError: null,
  });

/** -- Index Page -- */
type IndexQueryProps = BasicQueryProps & {
  type: SupportedIndexPageTypes;
};

const fetchIndexPageData = async ({ lang, type }: IndexQueryProps) => {
  const uid = type as string;

  const client = createClient();
  const res = await client.getByUID("index_page", uid, {
    lang,
  });

  if (!res.data) {
    return null;
  }

  return res.data;
};

/**
 *
 * @param locale
 * @returns Returns index page data for the specified type/uid
 */
export const getIndexPageConfig = async ({ lang, type }: IndexQueryProps) => {
  if (!type) {
    return null;
  }

  const res = await prismicQueryWrapper({
    params: [
      {
        lang,
        type,
      },
    ],
    query: fetchIndexPageData,
    returnOnError: null,
  });

  return res;
};

/** -- Checkout Notifications -- */

const fetchCheckoutNotifications = async ({ lang }: BasicQueryProps) => {
  const client = createClient();
  const res = await client.getSingle("basket_notification", {
    lang,
  });

  if (!res.data?.slices) {
    return null;
  }

  return res.data.slices;
};

/**
 *
 * @param lang
 * @returns Returns checkout notification slices
 */
export const getCheckoutNotifications = async (params: BasicQueryProps) =>
  prismicQueryWrapper({
    ignoreError: true,
    params: [
      {
        ...params,
      },
    ],
    query: fetchCheckoutNotifications,
    returnOnError: null,
  });

/** -- Shared Content -- */
type FetchLinkObject = {
  custom_type: string;
  fields: string[];
};

type FetchSharedContentQueryProps = BasicQueryProps & {
  fetchLinkFields: FetchLinkObject[];
};

const prependCustomType = (fetchLinkFields: FetchLinkObject[]) =>
  fetchLinkFields.flatMap(item =>
    item.fields.map(field => `${item.custom_type}.${field}`)
  );

/**
 *
 * @returns All global documents and nested slices/data using fetchLinks
 */
export const fetchSharedContentData = async ({
  lang,
  fetchLinkFields,
}: FetchSharedContentQueryProps) => {
  const client = createClient();

  const fetchLinks = prependCustomType(fetchLinkFields) as string[];

  const response = await client.getSingle("shared_content", {
    fetchLinks,
    lang,
  });

  if (!response) {
    return {
      data: null,
      message: "Content not found for the provided locale",
      status: 400,
    };
  }

  const {
    basket,
    footer,
    locale_switcher,
    navigation,
    top_banner,
    checkout,
    product_page,
    search,
  } = response.data;

  const modifiedBasket = await fetchBasketCollection(basket, lang);

  const modifiedSearch = await fetchSearchPanelCollections(search, lang);

  const data = {
    basket: modifiedBasket,
    checkout: checkout as unknown as CheckoutDocument,
    footer: footer as unknown as FooterDocument,
    locale_switcher:
      locale_switcher as unknown as RedirectModalConfigurationDocument,
    navigation: navigation as unknown as MainNavigationDocument,
    product_page: product_page as unknown as ProductPageDocument,
    search: modifiedSearch as unknown as SearchPanelDocument,
    top_banner: top_banner as unknown as TopBannerDocument,
  };

  return {
    data,
    status: 200,
  };
};

/**
 *
 * @param locale
 * @returns Returns all global documents and nested slices/data using fetchLinks - used to consolodate into a single API call and enable caching. NOTE: This could be replaced by server components in next 13.
 */
export const getGlobalContent = async (
  params: FetchSharedContentQueryProps
) => {
  const returnOnError = {
    data: null,
    message: "Content not found for the provided locale",
    status: 400,
  };

  const res = await prismicQueryWrapper({
    params: [
      {
        ...params,
      },
    ],
    query: fetchSharedContentData,
    returnOnError,
  });

  return res;
};

/** -- Best Of Pages -- */
type BestOfQueryProps = BasicQueryProps & OfTheYearPageParams;

/**
 *
 * @param lang
 * @returns All valid club paths (e.g. /club/basic/join) removing all those that are not linked in the club_tier document
 */
export const fetchOfTheYearPages = async ({ lang }: BasicQueryProps) => {
  const client = createClient();
  const res = await client.getAllByType("of_the_year_page", {
    lang,
  });

  const paths: Array<OfTheYearPageParams> = [];

  res?.forEach(doc => {
    const { path } = doc.data;

    paths.push({
      path: path ?? "best-of",
      uid: doc.uid,
    });
  });

  return {
    paths,
  };
};

/**
 *
 * @param locale
 * @returns Return list of valid club paths as array of strings
 */
export const getOfTheYearStaticPaths = async (params: BasicQueryProps) => {
  const returnOnError = {
    paths: [],
  };

  const res = await prismicQueryWrapper({
    params: [
      {
        ...params,
      },
    ],
    query: fetchOfTheYearPages,
    returnOnError,
  });

  return res;
};

const fetchBestOfPages = async ({
  lang,
  uid,
  path,
  previewData,
}: BestOfQueryProps) => {
  const client = createClient({ previewData });
  const page = await client.getByUID("of_the_year_page", uid, {
    lang,
  });

  return page;
};

/**
 *
 * @param lang
 * @param uid
 * @param previewData
 * @returns Returns best of page data for the specified uid
 */
export const getBestOfPage = async (params: BestOfQueryProps) =>
  prismicQueryWrapper({
    params: [
      {
        ...params,
      },
    ],
    query: fetchBestOfPages,
    returnOnError: null,
  });

/** -- Club Home Page -- */
type ClubHomeQueryProps = BasicQueryProps;

const fetchClubComingSoonPage = async ({
  lang,
  previewData,
}: ClubHomeQueryProps) => {
  const client = createClient({ previewData });
  const comingSoon = await client.getByUID("club_page", "coming-soon", {
    lang,
  });

  return {
    ...comingSoon,
    isComingSoon: true,
  };
};

const fetchClubHomePage = async ({ lang, previewData }: ClubHomeQueryProps) => {
  const client = createClient({ previewData });
  const page = await client.getSingle("club_home_page", {
    fetchLinks: [
      "club_tier.slices",
      "club_tier.tier_benefits",
      "club_tier.tier_name",
    ],
    lang,
  });

  if (page.data.tiers.length === 0) {
    const comingSoonPage = await prismicQueryWrapper({
      params: [{ lang, previewData }],
      query: fetchClubComingSoonPage,
      returnOnError: null,
    });

    return comingSoonPage;
  }

  return {
    ...page,
    isComingSoon: false,
  };
};

/**
 *
 * @param locale
 * @returns Returns club landing page data - if no tiers are configured, the coming soon page is returned if this exists
 */
export const getClubHomePage = async (props: ClubHomeQueryProps) => {
  const res = await prismicQueryWrapper({
    params: [
      {
        ...props,
      },
    ],
    query: fetchClubHomePage,
    returnOnError: null,
  });

  if (!res) {
    const comingSoonPage = await prismicQueryWrapper({
      params: [
        {
          ...props,
        },
      ],
      query: fetchClubComingSoonPage,
      returnOnError: null,
    });

    return comingSoonPage;
  }

  return {
    ...res,
    isComingSoon: false,
  };
};

/** -- Club Sign up Page -- */
type ClubFormQueryProps = BasicQueryProps & {
  action: ClubActions;
  uid: string;
};

const fetchClubFormPage = async ({
  lang,
  uid,
  previewData,
}: ClubFormQueryProps) => {
  const client = createClient({ previewData });
  const page = await client.getByUID("club_tier", uid, {
    fetchLinks: ["club_form_modal.slices"],
    lang,
  });

  return page;
};

/**
 *
 * @param locale
 * @param uid
 * @param previewData
 * @returns Returns club sign up page data with default form values.
 */
export const getClubFormPage = async (props: ClubFormQueryProps) => {
  const tierPage = await prismicQueryWrapper({
    ignoreError: true,
    params: [
      {
        ...props,
      },
    ],
    query: fetchClubFormPage,
    returnOnError: null,
  });

  if (tierPage) {
    const tierData = tierPage.data.slices;
    const defaultValues = returnDefaultFormValues({
      action: props.action,
      formats: tierData,
    });
    const selector = props.action === "join" ? "join_page" : "gift_page";
    const page = tierPage.data[selector];

    if (!page) {
      return null;
    }

    return {
      defaultValues,
      page,
      tier: tierPage,
    };
  }

  return null;
};

/** -- Club general page -- */
type ClubPageQueryProps = BasicQueryProps & {
  uid: string;
};

export const fetchClubPage = async ({
  lang,
  uid,
  previewData,
}: ClubPageQueryProps) => {
  const client = createClient({ previewData });
  const page = await client.getByUID("club_page", uid, {
    fetchLinks: ["club_tier.tier_benefits"],
    lang,
  });

  page.data.slices = await attachClubBenefits(page);

  return page;
};

/**
 *
 * @param locale
 * @param uid
 * @param previewData
 * @returns Returns club page data with attached tier benefits
 */
export const getClubPage = async (props: ClubPageQueryProps) => {
  const res = await prismicQueryWrapper({
    ignoreError: true,
    params: [
      {
        ...props,
      },
    ],
    query: fetchClubPage,
    returnOnError: null,
  });

  return res;
};

/** -- Competition pages -- */
type CompPageQueryProps = BasicQueryProps & {
  uid: string;
};

const fetchCompPage = async ({
  lang,
  uid,
  previewData,
}: CompPageQueryProps) => {
  const client = createClient({ previewData });
  const page = await client.getByUID("competition_page", uid, {
    lang,
  });

  return page;
};

export const getCompPage = async (props: CompPageQueryProps) => {
  const res = await prismicQueryWrapper({
    params: [
      {
        ...props,
      },
    ],
    query: fetchCompPage,
    returnOnError: null,
  });

  return res;
};

/** -- Competition home page -- */
type CompHomePageQueryProps = BasicQueryProps;

type CompetitionHomePageQueryProps = BasicQueryProps;

/** Fetches linked data from all the competition_page document */

const fetchCompetitionHomePage = async ({
  lang,
  previewData,
}: CompetitionHomePageQueryProps) => {
  const client = createClient({ previewData });
  const page = await client.getSingle("competition_landing_page", {
    fetchLinks: [
      "competition_page.competition_name",
      "competition_page.end_date",
    ],
    lang,
  });

  return page;
};

export const getCompHomePage = async (props: CompHomePageQueryProps) => {
  const res = await prismicQueryWrapper({
    ignoreError: true,
    params: [
      {
        ...props,
      },
    ],
    query: fetchCompetitionHomePage,
    returnOnError: null,
  });

  return res;
};

/** -- Event page -- */
type EventPageQueryProps = BasicQueryProps & {
  uid: string;
};

/** Fetches all competition_page documents to output on the home page */
const fetchNavMenuEvents = async ({
  lang,
  previewData,
  uid,
}: EventPageQueryProps) => {
  const client = createClient({ previewData });
  const page = await client.getByUID("nav_sub_menu", "events", {
    lang,
  });

  if (page) {
    const eventData = filterEvents(page, uid);
    return eventData;
  }

  return null;
};

/**
 *
 * @param props
 * @returns Event data (snippet) from the nav sub menu for the dice events - this is to avoid duplicating content. NOTE: we will move away from using dice snippets and instead use algolia for events data.
 */
export const getEventPage = async (props: EventPageQueryProps) => {
  const res = await prismicQueryWrapper({
    params: [
      {
        ...props,
      },
    ],
    query: fetchNavMenuEvents,
    returnOnError: null,
  });

  return res;
};

/** -- Stores page -- */
type StorePageQueryProps = BasicQueryProps & {
  uid: string;
};

const fetchStorePage = async ({
  lang,
  previewData,
  uid,
}: StorePageQueryProps) => {
  const client = createClient({ previewData });
  const page = await client.getByUID("store_page", uid, {
    lang,
  });

  return page;
};

export const getStorePage = async (props: StorePageQueryProps) => {
  const res = await prismicQueryWrapper({
    params: [
      {
        ...props,
      },
    ],
    query: fetchStorePage,
    returnOnError: null,
  });

  return res;
};

/** -- Store home page -- */
type StoreHomePageQueryProps = BasicQueryProps;

/** Fetches linked data from all the store_page document, which controls the order the stores are displayed */
const fetchStoreHomePage = async ({
  lang,
  previewData,
}: StoreHomePageQueryProps) => {
  const client = createClient({ previewData });
  const page = await client.getSingle("store_landing_page", {
    fetchLinks: [
      "store_page.name",
      "store_page.image",
      "store_page.google_address_link",
      "store_page.summary",
      "store_page.event_uid",
    ],
    lang,
  });

  return page;
};

export const getStoreHomePage = async (props: StoreHomePageQueryProps) => {
  const res = await prismicQueryWrapper({
    ignoreError: true,
    params: [
      {
        ...props,
      },
    ],
    query: fetchStoreHomePage,
    returnOnError: null,
  });

  return res;
};

/** -- Cart upsell variants -- */
type CartUpsellQueryProps = BasicQueryProps;

const fetchCartUpsell = async ({ lang }: CartUpsellQueryProps) => {
  const client = createClient();
  const res = await client.getSingle("recommended_variants", {
    lang,
  });

  if (!res) {
    return null;
  }

  const { variants: variantsArr } = res.data || {};

  if (!variantsArr.length) {
    return null;
  }

  const variantIds = variantsArr
    .map(({ variantid }) => {
      if (!variantid) return null;
      return decodeURIComponent(variantid);
    })
    ?.filter(Boolean) as string[];

  if (!variantIds.length) {
    return null;
  }

  const variants = await fetchVariants(variantIds, getChannel(lang), 20);

  return variants.filter(filterNotAvailableVariant);
};

export const getRecommendedVariants = async (props: CartUpsellQueryProps) => {
  const res = await prismicQueryWrapper({
    ignoreError: true,
    params: [
      {
        ...props,
      },
    ],
    query: fetchCartUpsell,
    returnOnError: null,
  });

  return res;
};

/** -- Cart upsell variants -- */
type HomePageQueryProps = BasicQueryProps;

const fetchHomePage = async ({ lang, previewData }: HomePageQueryProps) => {
  const client = createClient({ previewData });
  const res = await client.getByUID("home_page", "home", {
    lang,
  });

  res.data.slices = await fetchBatchCarousel(res.data.slices, true, lang);

  return res;
};

/**
 *
 * @param props
 * @returns Fetch the home page data
 */
export const getHomePage = async (props: HomePageQueryProps) => {
  const res = await prismicQueryWrapper({
    params: [
      {
        ...props,
      },
    ],
    query: fetchHomePage,
    returnOnError: null,
  });

  return res;
};
