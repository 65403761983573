import React, { forwardRef } from "react";

import * as S from "./styles";

export type GridProps = S.GridProps & { children?: React.ReactNode };

export const Grid = forwardRef<HTMLDivElement, GridProps>(
  ({ hidden, className, ...props }, ref) =>
    hidden ? null : props.onlyInnerSpacing ? (
      <S.GridWrapper className={className}>
        <S.Grid {...props} />
      </S.GridWrapper>
    ) : (
      <S.Grid className={className} {...props} />
    )
);
