import React from "react";
import { FormattedMessage } from "react-intl";

export const ServerErrorMessage = (
  <FormattedMessage
    defaultMessage="An error has occured and we're working to fix the problem."
    id="ZzZn0S"
  />
);

export const ErrorId = (id: string | number) => (
  <FormattedMessage
    defaultMessage="Error code: {id}."
    id="lFEg7S"
    values={{ id }}
  />
);

export const SubTitle = (statusCode = 500) => (
  <FormattedMessage
    defaultMessage="Error code: {statusCode}"
    id="V7STQQ"
    values={{ statusCode }}
  />
);
