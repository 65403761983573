import { useAuthState } from "@saleor/sdk";
import { useRouter } from "next/router";
import React, { useEffect } from "react";
import TagManager from "react-gtm-module";

import { googleTagManagerId } from "@config";
import { useCheckoutContext } from "@hooks/providers";
import { useGoogleTagManagerStore } from "@stores/useGoogleTagManagerStore";

import {
  dataLayerAvailable,
  gtmAddToCart,
  gtmPageView,
  gtmRemoveFromCart,
  gtmUser,
} from "./actions";

type GoogleTagManagerProviderProps = {
  children?: React.ReactNode;
};

export const GoogleTagManagerProvider = ({
  children,
}: GoogleTagManagerProviderProps) => {
  const router = useRouter();
  const { user } = useAuthState();
  const { onPostLineChange } = useCheckoutContext();
  const { setPreviousPath } = useGoogleTagManagerStore();
  const { setReadyState } = useGoogleTagManagerStore();

  useEffect(() => {
    if (!dataLayerAvailable() && googleTagManagerId) {
      TagManager.initialize({ gtmId: googleTagManagerId });
      setReadyState(true);
      onPostLineChange(
        "add",
        (line, quantity, queryID) => gtmAddToCart(line, quantity, queryID),
        true
      );
      onPostLineChange(
        "remove",
        (line, quantity) => gtmRemoveFromCart(line, quantity),
        true
      );
    }

    gtmPageView();
  }, []);

  useEffect(() => {
    if (user) {
      gtmUser(user);
    }
  }, [user]);

  useEffect(() => {
    const handleRouteChangeStart = () => {
      setPreviousPath(window.location.pathname);
    };

    const handleRouteChangeEnd = () => {
      gtmPageView();

      if (user) {
        gtmUser(user);
      }
    };

    router.events.on("routeChangeStart", handleRouteChangeStart);
    router.events.on("routeChangeComplete", handleRouteChangeEnd);
    router.events.on("routeChangeError", handleRouteChangeEnd);

    return () => {
      router.events.off("routeChangeStart", handleRouteChangeStart);
      router.events.off("routeChangeComplete", handleRouteChangeEnd);
      router.events.off("routeChangeError", handleRouteChangeEnd);
    };
  }, [router.events, user]);

  return <>{children}</>;
};
