import { useEffect, useRef } from "react";

export function useIsMounted() {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  // returning "isMounted.current" wouldn't work because we would return
  // immutable primitive
  return isMounted;
}
