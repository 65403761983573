import { px2rem, styled } from "@styles";
import { LayoutProps } from "@utils/typescript";

export const LocaleSwitcher = styled.div<{ asText?: boolean }>`
  padding: ${({ asText }) => (asText ? "16px 0" : "0")};
  color: ${({ theme }) => theme.colors.dark};
  width: 100%;
  white-space: nowrap;
`;

const BaseLocaleLink = styled.a<{ isActive: boolean } & LayoutProps>`
  color: ${({ theme, isActive }) =>
    isActive ? theme.colors.black : theme.colors.dark};
  font-weight: ${({ isActive }) => (isActive ? "600" : "400")};
`;

export const LocaleLink = styled(BaseLocaleLink)`
  border: 0;
  margin: 0;
  background-color: transparent;
  text-decoration: none;
  font-size: ${px2rem(16)};

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const Wrapper = styled.button`
  color: ${({ theme }) => theme.colors.black};
  font-weight: "400";
  border: 0;
  margin: 0;
  background-color: transparent;
  text-decoration: none;
  font-size: ${px2rem(16)};

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;
