import { createContext } from "react";

import { CheckoutContext } from "./types";

const defaultContext = {
  checkout: {
    lines: [],
  },
  updateCheckout: () => {},
} as unknown as CheckoutContext;

export const CheckoutProviderContext =
  createContext<CheckoutContext>(defaultContext);

CheckoutProviderContext.displayName = "CheckoutContext";
