import { MainNavigationDocumentDataMenuItemItem } from "prismicio-types";

import { media, styled, theme } from "@styles";

import { DropdownWrapper } from "../Dropdown/styles";
import { NavLink } from "../TopItem/styles";

export const getNavItemStyling = (
  variant: MainNavigationDocumentDataMenuItemItem["variant"],
  isActive?: boolean
) => {
  const { items } = theme.navigation;
  return `
    color: ${items[variant].default.color};
    background: ${items[variant].active.accent};

    ${media.lg.up} {
      background: transparent;
    }

    ${NavLink}{
      background: ${isActive ? items[variant].active.accent : "transparent"};
    }

    ${DropdownWrapper} {
      border-color: ${isActive ? items[variant].active.accent : "transparent"};
    }

  `;
};

export const ListWrapper = styled.li<{
  isActive?: boolean;
  variant: MainNavigationDocumentDataMenuItemItem["variant"];
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;

  ${({ isActive }) => (isActive ? `overflow: visible;` : `overflow: hidden;`)}

  ${({ variant, isActive }) => getNavItemStyling(variant, isActive)}
`;
