import { CreditCard } from "@gqlTypes/CreditCard";
import { CreditCard as OrderApiCreditCard } from "@graphql/orderAppGqlTypes/CreditCard";

import { Error, RequireAtLeastOne } from "@utils/typescript";

export type DeliveryOption = "delivery" | "clickAndCollect";

export type DeliveryType = "together" | "asBecomeAvailable";

export const CHECKOUT_STEPS = [
  "deliveryAddress",
  "deliveryOption",
  "deliveryShippingEdit",
  "billingAddress",
  "payment",
  "summary",
] as const;

export type CheckoutStep = (typeof CHECKOUT_STEPS)[number];

export type PaymentMethodBase = {
  id: string;
};

export type PaymentMethod = {
  data: Omit<OrderApiCreditCard, "__typename"> | CreditCard;
  gatewayId: string;
  type: "card" | "savedCard";
} & PaymentMethodBase;

export type CheckoutState = {
  deliveryOption: DeliveryOption;
  deliveryType: DeliveryType;
  errors: Error[];
  isProcessing: boolean;
  paymentMethod: PaymentMethod | null;
  type: CheckoutStep;
};

export type SetCheckoutState =
  | Omit<CheckoutState, "option">
  | ({ type: "deliveryAddress" | "deliveryOption" } & RequireAtLeastOne<{
      deliveryOption: DeliveryOption;
      deliveryType: DeliveryType;
    }>);

export type CheckoutLineInfo = { quantity: number; variantId: number };

export type CheckoutsQueue = {
  lastCreated: number;
  lines: CheckoutLineInfo[][];
  totalToCreate: number;
};
