import React from "react";
import { FormattedMessage } from "react-intl";

import { commonMessages } from "@messages/common";

export const LanguagePreferences = (
  <FormattedMessage
    defaultMessage="Currency & Language Preferences"
    id="RK2Mwb"
  />
);

export const SelectCurrency = (
  <FormattedMessage defaultMessage="View currency in" id="AGCnye" />
);

export const SelectLanguage = (
  <FormattedMessage defaultMessage="Select your language" id="AWt+cC" />
);

export const SubmitButton = <FormattedMessage {...commonMessages.submit} />;
