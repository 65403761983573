import { AttributeValue } from "@gqlTypes/AttributeValue";
import Image from "next/image";
import React from "react";

import { FormattedPrice } from "@components/FormattedPrice";
import { ItemQuantity } from "@components/ItemQuantity";
import { Link } from "@components/Link";
import { getArtists, getFormatNote } from "@utils/attributes";
import { getTestAttribute } from "@utils/misc";
import {
  generateArtistPath,
  generateProductPath,
  ProductLine,
} from "@utils/products";

import FallbackImage from "assets/images/fallback-image.jpg";

import * as GS from "../styles";

type LineProps = { line: ProductLine };

export const Line = ({
  line: { id, unitPrice, thumbnail, productName, variant, quantity },
}: LineProps) => {
  const variantAttributes = variant?.attributes ?? [];
  const productAttributes = variant?.product?.attributes ?? [];
  const artists = getArtists(productAttributes, true) as
    | AttributeValue[]
    | undefined;
  const formatNote = variant?.product?.productType
    ? getFormatNote(variant.product.productType, variantAttributes)
    : "";
  const productPath = variant
    ? generateProductPath({
        attributes: productAttributes,
        slug: variant.product.slug,
      })
    : null;

  const image = (
    <Image
      alt={thumbnail?.alt || productName}
      height={100}
      layout="fixed"
      objectFit="contain"
      sizes="12vw"
      src={thumbnail?.url || FallbackImage}
      width={100}
    />
  );
  const name = (
    <GS.Name {...getTestAttribute("line", "name")}>{productName}</GS.Name>
  );

  return (
    <tr {...getTestAttribute("line", id)}>
      <td>
        {productPath ? (
          <Link wrapperTag href={productPath}>
            {image}
          </Link>
        ) : (
          image
        )}
      </td>
      <td>
        <GS.ProductInfo>
          {productPath ? (
            <Link wrapperTag href={productPath}>
              {name}
            </Link>
          ) : (
            name
          )}
          {artists && (
            <GS.Artist {...getTestAttribute("line", "artist")}>
              {artists.map(({ name, slug }, i) => (
                <React.Fragment key={i}>
                  <Link href={generateArtistPath({ artist: [slug!] })}>
                    {name}
                  </Link>
                  {i + 1 < artists.length && ", "}
                </React.Fragment>
              ))}
            </GS.Artist>
          )}
          {formatNote && (
            <GS.Format {...getTestAttribute("line", "formatNote")}>
              {formatNote}
            </GS.Format>
          )}
          <ItemQuantity quantity={quantity} />
        </GS.ProductInfo>
      </td>
      <td>
        <GS.Price type="unit" {...getTestAttribute("line", "price")}>
          <FormattedPrice price={unitPrice.gross} />
        </GS.Price>
      </td>
    </tr>
  );
};
