import React from "react";

import { theme } from "@styles/theme";

import { LikeIconProps, LikeIconVariants } from "./types";

import * as S from "./styles";

export const getVariantColour = (variant: LikeIconVariants) => {
  switch (variant) {
    case "dark":
      return theme.colors.black;
    case "light":
      return theme.colors.light;
    case "white":
      return theme.colors.white;
    default:
      return theme.colors.black;
  }
};

export const LikeIcon = ({ isLiked, variant }: LikeIconProps) => {
  const colour = getVariantColour(variant);

  return (
    <S.LikeIcon
      data-is-active={isLiked ? "true" : "false"}
      fill={isLiked ? colour : "transparent"}
      stroke={colour}
    />
  );
};
