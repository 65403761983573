import { defineMessages } from "react-intl";

export const messages = defineMessages({
  openLanguageModal: {
    defaultMessage: "Select currency and language",
    id: "jA+JFm",
  },
  selectCurrency: {
    defaultMessage: "Select currency",
    id: "qYmyQn",
  },
  selectLanguage: {
    defaultMessage: "Select language",
    id: "eVlu1R",
  },
});
