import React from "react";
import { FormattedMessage } from "react-intl";

import { commonMessages } from "@messages/common";

export const Basket = <FormattedMessage {...commonMessages.inYourBasket} />;

export const Edit = <FormattedMessage {...commonMessages.edit} />;

export const Approved = <FormattedMessage {...commonMessages.approved} />;

export const GiftCard = <FormattedMessage {...commonMessages.giftCard} />;
