import React from "react";

import { LayoutProps } from "@utils/typescript";

import { FormContent, FormFooter, FormHeader } from "./components";

import * as S from "./styles";

export const ClubFormLayout = (props: LayoutProps) => {
  const { variant, children } = props;

  return (
    <S.PageLayoutWrapper className="club-content" variant={variant}>
      <FormHeader />
      <FormContent>{children}</FormContent>
      <FormFooter />
    </S.PageLayoutWrapper>
  );
};
