import { clubVoucherValidRegions } from "@config";

import { VoucherReturn } from "./types";

export const fetchClubVoucher = async (
  country: string,
  id: string,
  hasMetaData: boolean
): Promise<VoucherReturn> => {
  const countryID = clubVoucherValidRegions?.[country];

  if (!countryID) {
    return {
      message: "Selected country is not valid.",
      success: false,
    };
  }

  const res = await fetch("/api/club-member-check", {
    body: JSON.stringify({
      channel: { hasMetaData, id: countryID, name: country },
      id,
    }),
    method: "POST",
  });

  const data = await res.json();

  return data;
};
