import {
  ApolloQueryResult,
  OperationVariables,
  QueryHookOptions,
  useQuery,
} from "@apollo/client";
import { QueryResult } from "@apollo/client/react/types/types";
import { Order } from "@gqlTypes/Order";
import { OrderDetails, OrderDetailsVariables } from "@gqlTypes/OrderDetails";
import { DocumentNode } from "graphql";
import { useEffect, useMemo, useState } from "react";

import { Locale } from "@providers/TranslationProvider";
import { CanBeDispatchedMap } from "@views/ProductView";

import { orderDetailsQuery } from "./queries";

type LoadMore<TData> = (
  mergeFn: (prev: TData, next: TData) => TData,
  endCursor: string
) => Promise<ApolloQueryResult<TData>>;

export const useTypedQuery = <
  TData = any,
  TVariables extends OperationVariables = OperationVariables
>(
  query: DocumentNode,
  options?: QueryHookOptions<TData, TVariables>
): QueryResult<TData, TVariables> & {
  loadMore: LoadMore<TData>;
} => {
  const queryResult = useQuery<TData, TVariables>(query, options);

  const loadMore: LoadMore<TData> = (mergeFn, endCursor) =>
    queryResult.fetchMore({
      query,
      updateQuery: (previousResults, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previousResults;
        }
        return mergeFn(previousResults, fetchMoreResult);
      },
      variables: { ...options?.variables, endCursor },
    });

  return { loadMore, ...queryResult };
};

export const useOrderDetailsQuery = (token: string, skip = false) =>
  useTypedQuery<OrderDetails, OrderDetailsVariables>(orderDetailsQuery, {
    skip,
    variables: { token },
  });

const fetchOrders = async (tokens: string[], locale: Locale) => {
  const apiUrl = new URL("api/orders/token", window.location.origin);

  apiUrl.searchParams.set("tokens", tokens.join(","));
  apiUrl.searchParams.set("locale", locale.toString());

  const response = await fetch(apiUrl.toString());

  if (!response.ok) {
    return null;
  }

  const data = await response.json();
  return data;
};

export type OrderFetchedInfo = Order & {
  canBeDispatchedMap: CanBeDispatchedMap;
};

export const useOrdersDetailsQuery = (
  tokens: string[],
  locale: Locale,
  skip?: boolean
) => {
  const [orders, setOrders] = useState<OrderFetchedInfo[]>([]);
  const memoizedOrders = useMemo(() => orders, [orders]);

  useEffect(() => {
    const fetchOrderSSR = async () => {
      if (memoizedOrders.length === 0) {
        const ordersRes = await fetchOrders(tokens, locale);

        if (ordersRes) {
          setOrders(ordersRes.orders as unknown as OrderFetchedInfo[]);
        }
      }
    };

    if (!skip) {
      fetchOrderSSR();
    }
  }, [tokens, locale, skip, memoizedOrders]); // Include memoizedOrders in the dependency array

  return orders;
};
