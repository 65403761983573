import React, { forwardRef } from "react";

import { PartialOnly } from "@utils";

import { ReactComponent as SpotifyIcon } from "assets/icons/spotify.svg";

import * as S from "./styles";

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
  PartialOnly<S.ButtonStyledProps, "variant"> & {
    fontSize?: S.FontSizingProps;
  };

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      onSubmit,
      onClick,
      type = "button",
      variant = "primary",
      fontSize = "medium",
      disabled,
      processing,
      icon: Icon,
      ...props
    },
    ref
  ) => (
    <S.Button
      disabled={disabled || processing}
      onClick={disabled || processing ? undefined : onClick}
      onSubmit={disabled || processing ? undefined : onSubmit}
      processing={processing}
      ref={ref}
      variant={variant}
      withIcon={!!Icon}
      {...props}
    >
      {(Icon || variant === "spotify") && (
        <S.IconWrapper>
          {Icon ? <Icon height={16} /> : <SpotifyIcon height={16} />}
        </S.IconWrapper>
      )}
      <S.Content fontSize={fontSize}>{children}</S.Content>
    </S.Button>
  )
);
