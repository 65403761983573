import { LikeButtonStyling } from "@components/LikeIcon/styles";
import { media, px2rem, styled } from "@styles";

export interface OpenableElement {
  isOpen: boolean;
}

export const Header = styled.header`
  position: sticky;
  top: 0;
  background-color: ${({ theme }) => theme.header.backgroundColor};
  z-index: 99;

  @media print {
    display: none;
  }
`;

export const TopBar = styled.div<{ isTabVisible: boolean }>`
  background-color: ${({ theme }) => theme.header.backgroundColor};
  padding: 10px 0;
  max-width: 100%;
  min-height: 60px;
  transform: translateX(
    ${({ isTabVisible }) => (isTabVisible ? "calc(100% - 60px))" : "0px")}
  );

  ${media.lg.up} {
    height: 70px;
    padding: 0;
    transform: translateX(
      ${({ isTabVisible }) => (isTabVisible ? "calc(100% - 69px))" : "0px")}
    );
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(2, 30px) auto repeat(2, 30px);
  grid-column-gap: 20px;
  position: relative;

  ${media.lg.up} {
    position: initial;
    grid-template-columns: max-content auto repeat(2, 30px);
  }
`;

export const LogoWrapper = styled.div<{
  isSearchActive?: boolean;
}>`
  display: flex;
  justify-self: center;
  width: 47px;
  align-self: center;
  justify-content: center;
  transition: opacity ${({ theme }) => theme.transitionDuration}s;

  ${({ isSearchActive }) => isSearchActive && "opacity: 0;"}

  ${media.lg.up} {
    width: 60px;
    /* width: 54px; */
  }
`;

export const LogoLink = styled.a`
  width: 100%;
  margin: 4px 0;

  ${media.lg.up} {
    margin: 0;
  }
`;

export const IconWrapper = styled.button<{
  hidden?: boolean;
  isSearchActive?: boolean;
}>`
  background: none;
  border: none;
  height: 100%;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: opacity ${({ theme }) => theme.transitionDuration}s;

  ${({ hidden }) => hidden && "visibility: hidden;"}
  ${({ isSearchActive }) => isSearchActive && "opacity: 0;"}

  svg {
    height: 22px;
    width: 22px;

    path {
      fill: inherit;
    }
  }
`;

export const HeartIconWrapper = styled(IconWrapper)`
  ${LikeButtonStyling({ variant: "white" })}
`;

export const MenuToggle = styled.div<OpenableElement>`
  display: block;
  width: 40px;
  height: 32px;
  position: relative;
  padding: 5px;

  &::after,
  &::before {
    content: "";
    left: 0;
    width: 22px;
    position: absolute;
    height: 3px;
    background-color: ${({ theme }) => theme.colors.white};
    transition: all 0.2s linear;
    border-radius: 5px;
  }

  &::before {
    top: ${({ isOpen }) => (isOpen ? "13px" : "10px")};
    transform: rotate(${({ isOpen }) => (isOpen ? "45deg" : "0deg")});
  }

  &::after {
    bottom: ${({ isOpen }) => (isOpen ? "16px" : "10px")};
    transform: rotate(${({ isOpen }) => (isOpen ? "-45deg" : "0deg")});
  }
`;

export const SearchInput = styled.div`
  align-self: center;
  background: ${({ theme }) => theme.colors.white};
  padding: 8px 14px;
  margin-top: ${px2rem(4)};
  width: 100%;
  font-size: ${px2rem(16)};
  transition: clip-path ${({ theme }) => theme.input.transitionDuration},
    height ${({ theme }) => theme.input.transitionDuration};
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  height: 35px;

  /* max-width: 572px; */
  justify-self: center;

  ${media.lg.up} {
    margin-left: 80px;
  }

  input {
    outline: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
`;
