import { spotifyUserPlayerUrl } from "@config";

import {
  PlayerApi,
  PlayerApiDefinition,
  SpotifyErrorMessage,
  WebPlaybackPlayerOpts,
} from "../types";

interface WebPlaybackApiProps extends WebPlaybackPlayerOpts {
  setError: (error: SpotifyErrorMessage | null) => void;
}

export const webPlaybackApi: PlayerApiDefinition<WebPlaybackApiProps> = ({
  deviceId,
  accessToken,
  player,
  setError,
}) => {
  const headers = {
    Authorization: `Bearer ${accessToken}`,
    "Content-Type": "application/json",
  };
  let isPlayerLoaded = false;

  // After failed playback player needs to be reinitialized.
  const playerReInitialize = async () => {
    await player.disconnect();
    await player.connect();
    return getPlayerState(50);
  };

  const devicePlay = async (uri?: string): Promise<boolean> =>
    new Promise(resolve => {
      (async () => {
        const body = uri
          ? JSON.stringify({ offset: { position: 0 }, uris: [uri] })
          : undefined;

        const response = await fetch(
          `${spotifyUserPlayerUrl}/play?device_id=${deviceId}`,
          {
            body,
            headers,
            method: "PUT",
          }
        );

        if (!response.ok) {
          setError(response.status === 404 ? "spotifyError404" : "default");

          await playerReInitialize();

          return resolve(false);
        }

        // Initial player launch does not immediately trigger player state creation.
        // Wait for it for proper player flow.
        if (!isPlayerLoaded) {
          await getPlayerState(50);
        }

        return resolve(true);
      })();
    });

  const getPlayerState = async (
    retries = 0
  ): Promise<Promise<Spotify.PlaybackState | null>> =>
    new Promise(resolve => {
      (async function waitForState() {
        const state = await player.getCurrentState().catch(playerReInitialize);

        if (state) {
          isPlayerLoaded = true;
          return resolve(state);
        }

        if (retries <= 0) {
          isPlayerLoaded = false;
          return resolve(null);
        }
        --retries;

        setTimeout(waitForState, 25);
      })();
    });

  const isPlaying: PlayerApi["isPlaying"] = async () => {
    const state = await getPlayerState();
    return state ? state.paused === false : false;
  };

  const onPlay: PlayerApi["onPlay"] = async track => devicePlay(track.uri);

  const onPause: PlayerApi["onPause"] = async () => {
    if (await isPlaying()) {
      await player.pause().catch(playerReInitialize);
    }
  };

  const onResume: PlayerApi["onResume"] = async () => {
    player.togglePlay().catch(playerReInitialize);
  };

  const getCurrentTime: PlayerApi["getCurrentTime"] = async () => {
    const state = await getPlayerState();
    return state?.position ?? 0;
  };

  const getDuration: PlayerApi["getDuration"] = async () => {
    const state = await getPlayerState();
    return state?.duration ?? 0;
  };

  const onSeek: PlayerApi["onSeek"] = async (trackCompletionRatio: number) => {
    const duration = await getDuration();
    const trackProgress = Math.trunc(trackCompletionRatio * duration);
    await player.seek(trackProgress).catch(playerReInitialize);
  };

  const destroy: PlayerApi["destroy"] = () => player.disconnect();

  return {
    destroy,
    getCurrentTime,
    getDuration,
    isPlaying,
    onPause,
    onPlay,
    onResume,
    onSeek,
    type: "webPlayback",
  };
};
