import { RefObject, useEffect } from "react";

export const useOnClickOutside = (
  refs: RefObject<any>[],
  callback: () => void,
  check?: boolean
) => {
  useEffect(() => {
    const checkIfClickedOutside = (e: MouseEvent) => {
      if (
        (check === undefined || check) &&
        refs.every(ref => ref.current && !ref.current.contains(e.target))
      ) {
        callback();
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [check]);
};
