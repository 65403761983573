import { Checkout } from "@gqlTypes/Checkout";
import { CheckoutLine } from "@gqlTypes/CheckoutLine";
import { CollectionProduct } from "@gqlTypes/CollectionProduct";
import { Product } from "@gqlTypes/Product";
import { PurchaseOrder } from "@gqlTypes/PurchaseOrder";
import { UserDetailsFragment } from "@saleor/sdk/dist/apollo/types";

import { developmentMode, ssrMode } from "@config";
import { AlgoliaResult } from "@integrations/algolia";
import { COUNTRIES, Locale } from "@providers/TranslationProvider";
import { UserMetaTypes } from "@utils/favouriting/types";
import {
  CHECKOUT_STEPS,
  CheckoutStep,
} from "@views/CheckoutView/components/CheckoutFlowProvider";

import {
  serializeCollectionProduct,
  serializeLine,
  serializeProduct,
} from "./serializers";
import { CheckoutType, EventType, PaymentTypeEnum } from "./types";

export const dataLayerAvailable = () =>
  !developmentMode && !ssrMode && !!window.dataLayer;

export const gtmViewItemList = (
  listingItem: CollectionProduct,
  collectionName: string = "",
  location: string = "Home",
  index: number
) => {
  if (!dataLayerAvailable()) {
    return;
  }
  const currencyCode = listingItem.pricing?.priceRange?.start?.gross.currency;

  window.dataLayer.push({
    _clear: true,
    ecommerce: {
      currencyCode,
      impressions: [
        {
          ...serializeCollectionProduct(listingItem, index),
          list: `${location} - ${collectionName}`,
        },
      ],
    },
    event: EventType.VIEW_ITEM_LIST,
  });
};

export const gtmViewItemListingHit = (
  listingItem: AlgoliaResult,
  listName?: string
) => {
  if (!dataLayerAvailable()) {
    return;
  }

  const list = listName ? `Listing page - ${listName}` : "Listing page";

  window.dataLayer.push({
    _clear: true,
    ecommerce: {
      currencyCode: listingItem.currency,
      impressions: [
        {
          brand: listingItem.artists.map(artist => artist.name).join("/"),
          id: listingItem.id,
          list,
          name: listingItem.name,
          position: listingItem.__position,
        },
      ],
    },
    event: EventType.VIEW_ITEM_LIST,
  });
};

export const gtmSelectItem = (
  product: CollectionProduct,
  collectionName: string = "unnamed_collection",
  index: number,
  location: string = "Home"
) => {
  if (!dataLayerAvailable()) {
    return;
  }

  window.dataLayer.push({
    _clear: true,
    ecommerce: {
      click: {
        actionField: {
          list: `${location} - ${collectionName}`,
        },
        products: [serializeCollectionProduct(product, index)],
      },
      currencyCode: product.pricing?.priceRange?.start?.gross?.currency,
    },
    event: EventType.SELECT_ITEM,
  });
};

export const gtmSelectItemListingHit = (
  listingItem: {
    __position: number;
    artists?: { name: string | null }[];
    currency: string;
    id: string;
    name: string;
  },
  collectionName: string = "unnamed_collection",
  location: string = "Home"
) => {
  if (!dataLayerAvailable()) {
    return;
  }

  window.dataLayer.push({
    _clear: true,
    ecommerce: {
      click: {
        actionField: {
          list: `${location} - ${collectionName}`,
        },
        products: [
          {
            brand:
              listingItem.artists?.map(artist => artist.name).join("/") ||
              "No Artist",
            id: listingItem.id,
            list: "Listing page",
            name: listingItem.name,
            position: listingItem.__position,
          },
        ],
      },
      currencyCode: listingItem.currency,
    },
    event: EventType.SELECT_ITEM,
  });
};

export const gtmViewItem = (product: Product) => {
  if (!dataLayerAvailable()) {
    return;
  }

  window.dataLayer.push({
    _clear: true,
    ecommerce: {
      currencyCode: product?.pricing?.priceRange?.start?.net.currency,
      detail: {
        products: [serializeProduct(product)],
      },
    },
    event: EventType.VIEW_ITEM,
  });
};

export const gtmViewItemVariant = (product: Product, sku: string) => {
  if (!dataLayerAvailable()) {
    return;
  }

  window.dataLayer.push({
    _clear: true,
    ecommerce: {
      currencyCode: product?.pricing?.priceRange?.start?.net.currency,
      detail: {
        products: [serializeProduct(product, sku)],
      },
    },
    event: EventType.VIEW_ITEM,
  });
};

export const gtmAddToCart = (
  line: CheckoutLine,
  quantity: number,
  queryID: string | null
) => {
  if (!dataLayerAvailable()) {
    return;
  }

  window.dataLayer.push({
    _clear: true,
    ecommerce: {
      add: {
        products: [
          {
            ...serializeLine(line),
            quantity,
            "query-id": queryID,
          },
        ],
      },
      currencyCode: line.variant.pricing?.price?.gross.currency,
    },
    event: EventType.ADD_TO_CART,
  });
};

export const gtmRemoveFromCart = (line: CheckoutLine, quantity: number) => {
  if (!dataLayerAvailable()) {
    return;
  }

  window.dataLayer.push({
    _clear: true,
    ecommerce: {
      currencyCode: line.variant.pricing?.price?.gross.currency,
      remove: {
        products: [
          {
            ...serializeLine(line),
            quantity,
          },
        ],
      },
    },
    event: EventType.REMOVE_FROM_CART,
  });
};

export const gtmCheckoutStep = (
  checkoutStep: CheckoutStep,
  checkout: Checkout,
  checkoutType: CheckoutType
) => {
  if (!dataLayerAvailable()) {
    return;
  }

  const step = CHECKOUT_STEPS.indexOf(checkoutStep) + 1;
  const checkoutLines = checkout?.lines || [];
  const currencyCode =
    COUNTRIES.find(c =>
      c.supportedLocales.includes(window.next?.router.locale as Locale)
    )?.currency || "GBP";

  window.dataLayer.push({
    _clear: true,
    ecommerce: {
      checkout: {
        actionField: {
          id: checkout.id,
          step,
        },
        products: checkoutLines.map(line => ({
          ...serializeLine(line!),
          quantity: line?.quantity,
        })),
      },
      checkoutType,
      currencyCode,
    },
    event: EventType.CHECKOUT,
  });
};

export const gtmPurchase = (
  order: PurchaseOrder,
  paymentType: PaymentTypeEnum
) => {
  if (!dataLayerAvailable()) {
    return;
  }

  const { id, lines, total, shippingPrice, voucher } = order;
  const products = lines.map(line => ({
    ...serializeLine(line!),
    quantity: line?.quantity,
  }));

  window.dataLayer.push({
    _clear: true,
    ecommerce: {
      actionField: {
        coupon: voucher?.code,
        id,
        revenue: total.gross.amount,
        shipping: shippingPrice.gross.amount,
        tax: total.gross.amount - total.net.amount,
      },
      currencyCode: total.gross.currency,
      products,
      purchaseType: paymentType as string,
    },
    event: EventType.PURCHASE,
  });
};

export const gtmViewPromotion = (
  title: string,
  creative: string = "landscape banner",
  position: string = "Homepage"
) => {
  if (!dataLayerAvailable()) {
    return;
  }

  window.dataLayer.push({
    _clear: true,
    ecommerce: {
      promoView: {
        promotions: [
          {
            creative,
            name: title,
            position: `${position} - ${title}`,
          },
        ],
      },
    },
    event: EventType.VIEW_PROMOTION,
  });
};

export const gtmSelectPromotion = (
  title: string,
  creative: string = "landscape banner",
  position: string = "Homepage"
) => {
  if (!dataLayerAvailable()) {
    return;
  }

  window.dataLayer.push({
    _clear: true,
    ecommerce: {
      promoClick: {
        promotions: [
          {
            creative,
            name: title,
            position: `${position} - ${title}`,
          },
        ],
      },
    },
    event: EventType.SELECT_PROMOTION,
  });
};

export const gtmHitsViewed = () => {
  if (!dataLayerAvailable()) {
    return;
  }

  window.dataLayer.push({
    _clear: true,
    event: EventType.HITS_VIEWED,
  });
};

export const gtmAlgoliaUserToken = (token: string) => {
  if (!dataLayerAvailable()) {
    return;
  }

  window.dataLayer.push({
    algoliaUserToken: token,
  });
};

export const gtmPageView = () => {
  if (!dataLayerAvailable()) {
    return;
  }

  window.dataLayer.push({
    _clear: true,
    event: EventType.PAGE_VIEW,
    page: {
      location: window.next?.router.locale.toUpperCase(),
      path: window.location.pathname,
      referrer: document.referrer,
      title: document.title,
      url: window.location.href,
    },
  });
};

export const gtmUser = (
  { email, id, firstName, lastName }: UserDetailsFragment,
  loggedIn: boolean = true,
  createdAt?: number
) => {
  if (!dataLayerAvailable()) {
    return;
  }

  window.dataLayer.push({
    _clear: true,
    created_at: createdAt,
    email,
    event: EventType.USER,
    id,
    logged_in: loggedIn,
    name: `${firstName} ${lastName}`,
  });
};

type FavouritingParams = {
  action: string;
  name: string;
  sourceID: string;
  type: UserMetaTypes;
};

export const gtmAddToFavourite = ({
  sourceID: ID,
  action,
  type: location,
  name,
}: FavouritingParams) => {
  if (!dataLayerAvailable()) {
    return;
  }

  if (!name || !ID || !action || !location) {
    return;
  }

  window.dataLayer.push({
    _clear: true,
    ecommerce: {
      favourite: {
        add: {
          ID,
          action,
          location,
          name,
        },
      },
    },
    event: EventType.ADD_TO_FAVOURITE,
  });
};

export const gtmRemoveFromFavourites = ({
  sourceID: ID,
  action,
  type: location,
  name,
}: FavouritingParams) => {
  if (!dataLayerAvailable()) {
    return;
  }

  if (!name || !ID || !action || !location) {
    return;
  }

  window.dataLayer.push({
    _clear: true,
    ecommerce: {
      favourite: {
        remove: {
          ID,
          action,
          location,
          name,
        },
      },
    },
    event: EventType.REMOVE_FROM_FAVOURITE,
  });
};
