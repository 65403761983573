import { css } from "styled-components";

export const fontFaces = css`
  @font-face {
    font-family: "Roboto Condensed";
    font-weight: 300;
    src: url("/fonts/Roboto-Condensed-Light.woff2") format("woff2"),
      url("/fonts/Roboto-Condensed-Light.woff") format("woff");
    font-display: swap;
  }

  @font-face {
    font-family: "Roboto Condensed";
    font-weight: 400;
    src: url("/fonts/Roboto-Condensed-Regular.woff2") format("woff2"),
      url("/fonts/Roboto-Condensed-Regular.woff") format("woff");
    font-display: swap;
  }

  @font-face {
    font-family: "Roboto Condensed";
    font-weight: 600;
    src: url("/fonts/Roboto-Condensed-Bold.woff2") format("woff2"),
      url("/fonts/Roboto-Condensed-Bold.woff") format("woff");
    font-display: swap;
  }

  @font-face {
    font-family: "Knockout";
    font-weight: 600;
    src: url("/fonts/Knockout-69_Web.woff2") format("woff2"),
      url("/fonts/Knockout-69_Web.woff") format("woff");
    font-display: swap;
  }

  @font-face {
    font-family: "Knockout";
    font-weight: 700;
    src: url("/fonts/Knockout-69_Web.woff2") format("woff2"),
      url("/fonts/Knockout-69_Web.woff") format("woff");
    font-display: swap;
  }
`;
