import { asText, RichTextField } from "@prismicio/client";
import { useEffect } from "react";
import { matchPath } from "react-router";

import { ssrMode } from "@config";
import { useDiceStore } from "@stores/useDiceStore";
import { Object } from "@utils";

export const createWidgetConfig = (snippet: RichTextField, config?: Object) => {
  if (snippet.length) {
    snippet = snippet[0] ? JSON.parse(asText(snippet)) : {};
    return { ...snippet, ...config };
  }
};

export const isDiceEnabled = () => !ssrMode && !!window?.DiceEventListWidget;

export const destroyDiceWidget = () => window.DiceEventListWidget.destroy();

// Parse snippet and create Dice widget config
export const createDiceWidget = (config: any) => {
  if (isDiceEnabled()) {
    window.DiceEventListWidget.create(config);
  } else {
    window.onload = () => window.DiceEventListWidget.create(config);
  }
};

export const canDiceWidgetBeMounted = (path: string) =>
  !matchPath(path, { path: "/events/" }) &&
  !path.startsWith("/stores/") &&
  isDiceEnabled();

export const useCreateDiceWidget = (
  snippet: RichTextField,
  shouldMount: boolean,
  extraConfig?: Object
) => {
  const { scriptLoaded } = useDiceStore();

  useEffect(() => {
    if (shouldMount && snippet.length && scriptLoaded) {
      createDiceWidget(createWidgetConfig(snippet, extraConfig));
      return () => destroyDiceWidget();
    }
  }, [snippet, shouldMount, scriptLoaded]);
};
