import { clubFixedElements } from "@layouts/ClubFormLayout/common.styles";
import { media, styled } from "@styles";

export const ClubFormHeader = styled.header`
  justify-content: space-between;
  ${clubFixedElements}
`;

export const LogoWrapper = styled.div`
  display: flex;
  justify-self: center;
  width: 47px;
  align-self: center;
  justify-content: center;
  transition: opacity ${({ theme }) => theme.transitionDuration}s;

  ${media.lg.up} {
    width: 60px;
  }
`;

export const LogoLink = styled.a`
  width: 100%;
  margin: 4px 0;

  ${media.lg.up} {
    margin: 0;
  }
`;
