import { useNProgress } from "@tanem/react-nprogress";
import React from "react";

import { getTestAttribute } from "@utils/misc";

import * as S from "./styles";

type AppLoaderProps = {
  isRouteChanging: boolean;
};

export const AppLoader = ({ isRouteChanging }: AppLoaderProps) => {
  const { isFinished, progress } = useNProgress({
    isAnimating: isRouteChanging,
  });

  return (
    <S.Container
      {...getTestAttribute("appLoader", "")}
      style={{ opacity: +!isFinished }}
    >
      <S.Bar style={{ width: `${progress * 100}%` }}>
        <S.Spinner />
      </S.Bar>
    </S.Container>
  );
};

AppLoader.displayName = "AppLoader";
