import { LanguageSwitcher as LanguageSwitcherComponent } from "@components/LanguageSwitcher/LanguageSwitcher";
import { styled } from "@styles";

export const LanguageSwitcher = styled(LanguageSwitcherComponent)`
  .react-select__single-value {
    align-items: center;
    display: flex;

    svg {
      height: 21px;
      width: 21px;
    }
  }
`;
