import { CountryCode } from "@gqlTypes/globalTypes";
import { getCookie } from "cookies-next";
import { NextRouter, useRouter as useNextRouter } from "next/router";

import {
  defaultLocale,
  pageQueryParam,
  publicUrl,
  relayPageQueryParam,
} from "@config";
import {
  COUNTRIES,
  CountryDetails,
  DEFAULT_COUNTRY,
  Language,
  LanguageISOCode,
  Locale,
  Region,
} from "@providers/TranslationProvider";

export type CustomNextRouter = Omit<NextRouter, "locale"> &
  Pick<CountryDetails, "language" | "supportedLocales"> & {
    canonicalPath: string;
    country: CountryCode;
    lang: LanguageISOCode;
    locale: Locale;
    region: Region;
  };

const createAbsoluteCanonicalPath = (asPath: string, locale: Locale) => {
  const pageParamExp = new RegExp(
    `(${pageQueryParam}|${relayPageQueryParam})=\\w+(\&+)?`
  );

  const initialCanonical = `${publicUrl}${asPath.replace(pageParamExp, "")}`;

  return !initialCanonical.includes(`/${locale}/`)
    ? `${publicUrl}/${locale}${asPath.replace(pageParamExp, "")}`
    : initialCanonical;
};

export const useRouter = (): CustomNextRouter => {
  const nextRouter = useNextRouter();
  const { locale, asPath } = nextRouter;

  if (locale === defaultLocale) {
    return {
      ...nextRouter,
      canonicalPath: createAbsoluteCanonicalPath(asPath, locale as Locale),
      country: nextRouter.query?.country as CountryCode,
      lang: "en",
      language: DEFAULT_COUNTRY.language,
      locale: DEFAULT_COUNTRY.locale,
      region: "gb",
      supportedLocales: DEFAULT_COUNTRY.supportedLocales,
    };
  }
  const lang = locale?.split("-")[0] as LanguageISOCode;
  const region = locale?.split("-")[1] as Region;
  const language = `${lang}-${region?.toUpperCase()}` as Language;
  // INFO: Server geolocated country for potential UI improvements when
  // user navigates to different domain than country he lives
  const country = getCookie("requestCountry") as CountryCode;
  const supportedLocales =
    COUNTRIES.find(c => c.supportedLocales.includes(locale as Locale))
      ?.supportedLocales || [];

  return {
    ...nextRouter,
    canonicalPath: createAbsoluteCanonicalPath(asPath, locale as Locale),
    country,
    lang,
    language,
    locale: locale as Locale,
    region,
    supportedLocales,
  };
};
