import React from "react";
import { FormattedMessage } from "react-intl";

export const InclusiveVat = (
  <FormattedMessage defaultMessage="Inclusive of VAT" id="sW00PE" />
);

export const SalesTax = (
  <FormattedMessage defaultMessage="Sales Tax" id="e11II2" />
);

export const TooltipInfo = (
  <FormattedMessage
    defaultMessage="Taxes are calculated based on the tax rate of the state or country we are shipping to."
    id="kA1BSS"
  />
);
