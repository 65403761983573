import React from "react";

import { Container } from "@components/Container";
import { useRouter } from "@hooks/useRouter";
import { useGlobalConfigStore } from "@stores/useGlobalConfigStore";

import {
  NavLinks,
  NavMenu,
  NewsletterInput,
  PaymentIcons,
  SocialIcons,
} from "./components";
import * as M from "./message";

import * as S from "./styles";

const currentYear = new Date().getFullYear();

export const Footer = () => {
  const { locale } = useRouter();
  const { footer } = useGlobalConfigStore();

  if (!footer) {
    return null;
  }

  const {
    copyright,
    social_links,
    payment_icons,
    header,
    body,
    left_footer_nav,
    left_footer_title,
    right_footer_nav,
    right_footer_title,
  } = footer?.data || {};

  const leftColumnLinks: NavMenu = {
    links: left_footer_nav,
    title: left_footer_title || "",
  };
  const rightColumnLinks: NavMenu = {
    links: right_footer_nav,
    title: right_footer_title || "",
  };

  return (
    <S.FooterWrapper data-nosnippet>
      <Container disableGutters maxWidth="xxl" overflow="unset">
        <S.FooterContainer>
          <S.FooterRow>
            <S.RowItem>
              <S.FooterHeading>{header}</S.FooterHeading>
              <S.Description>{body}</S.Description>
              <NewsletterInput />
            </S.RowItem>

            <S.RowItem>
              <NavLinks list={[rightColumnLinks]} />
            </S.RowItem>
            <S.RowItem>
              <NavLinks list={[leftColumnLinks]} />
            </S.RowItem>
          </S.FooterRow>
          <S.FooterRow>
            <S.RowItem>
              <S.OptionalHeading>{M.ConnectWithUs}</S.OptionalHeading>
              <S.IconLinksColumn>
                <SocialIcons list={social_links} />
              </S.IconLinksColumn>
            </S.RowItem>
            <S.RowItem isEmpty />
            <S.RowItem>
              <S.OptionalHeading>{M.WaysToPay}</S.OptionalHeading>
              <S.IconLinksColumn>
                {payment_icons?.length && <PaymentIcons list={payment_icons} />}
              </S.IconLinksColumn>
            </S.RowItem>
          </S.FooterRow>
          <S.FooterRow>
            <S.RowItem>
              {locale && (
                <S.LanguageSwitcherWrapper
                  placement="footer-desktop"
                  styles={S.CustomSelectStyles}
                />
              )}
            </S.RowItem>
            <S.RowItem isEmpty />
            <S.RowItem style={{ justifyContent: "end" }}>
              <S.Copyrights>
                &#169; {currentYear} {copyright}
              </S.Copyrights>
            </S.RowItem>
          </S.FooterRow>
        </S.FooterContainer>
      </Container>
    </S.FooterWrapper>
  );
};
