import { media, px2rem, styled } from "@styles";

export const Label = styled.p`
  font-size: ${px2rem(15)};
  font-weight: 500;
  line-height: ${px2rem(21)};

  ${media.md.up} {
    font-size: ${px2rem(17)};
  }
`;

export const SwitchLabel = styled(Label)`
  padding-right: ${px2rem(10)};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
