import get from "lodash/get";
import React from "react";
import { FieldError, useFormContext } from "react-hook-form";

import { IconProps, Input, InputProps } from "@components/Input";

export interface InputFieldProps
  extends React.InputHTMLAttributes<HTMLInputElement>,
    InputProps {
  icon?: IconProps;
  label: string;
  name: string;
}

export const InputField = ({ name, ...props }: InputFieldProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const error = get(errors, name);

  return (
    <Input
      isReactHook
      error={error as FieldError}
      inputRef={register}
      name={name}
      {...props}
    />
  );
};
