import { useEffect } from "react";

import { useSpotifyContext } from "@hooks/providers";
import { SetState } from "@utils";

import { initAudioApi, initWebPlaybackApi } from "./helpers";
import { ApiType, PlayerApiState, SpotifyErrorMessage } from "./types";

const audioPlayerSupported = () => typeof Audio !== "undefined";

export const useInitPlayerApi = (
  playerApi: PlayerApiState,
  setPlayerApi: SetState<PlayerApiState>,
  setError: (error: SpotifyErrorMessage | null) => void
) => {
  const { token, isPremiumAccount } = useSpotifyContext();
  const accessToken = token?.access_token;

  const isApiType = (type: ApiType) => playerApi?.type === type;

  useEffect(() => {
    (async () => {
      const audioSupported = audioPlayerSupported();
      const webPlaybackReady = !!accessToken;
      const disconnect = () => playerApi?.api.destroy();

      if (playerApi) {
        await playerApi.api.onPause();
      }

      if (webPlaybackReady) {
        if (!isApiType("webPlayback")) {
          const api = await initWebPlaybackApi(accessToken, setError);

          if (api) {
            disconnect();
            setPlayerApi({ api, type: "webPlayback" });
          }
        }
      } else if (audioSupported) {
        if (!isApiType("audio")) {
          const api = initAudioApi();

          if (api) {
            disconnect();
            setPlayerApi({ api, type: "audio" });
          }
        }
      }

      return () => disconnect();
    })();
  }, [isPremiumAccount]);
};
