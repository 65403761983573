type ProcessAPIProps = {
  handleError?: (error: Error) => void;
  message: string;
  status?: number | null;
};

export const processAPIError = ({
  message,
  status = null,
  handleError,
}: ProcessAPIProps) => {
  const error = new Error(message.concat(`Status code: (${status})`));
  console.error(error);

  if (handleError) {
    handleError(error);
  }

  return null;
};

export const fetchNextAPIData = async <T>({
  apiUrl,
  handleError,
  options = { method: "GET" },
}: {
  apiUrl: URL;
  handleError?: (error: Error) => void;
  headers?: HeadersInit;
  options?: RequestInit;
}): Promise<T | null> => {
  const url = apiUrl.toString();

  try {
    const res = await fetch(url, options);

    try {
      const data = await res.json();

      if (!res.ok) {
        return processAPIError({
          handleError,
          message: `Internal API request failed. (Reason: ${data.error}).`,
          status: res.status,
        });
      }

      return data as T;
    } catch (e) {
      return processAPIError({
        handleError,
        message: `Failed to parse JSON: For the ${url}. (Reason: ${
          e ?? "unknown"
        }).`,
      });
    }
  } catch (e) {
    return processAPIError({
      handleError,
      message: `Failed fetching data: For the ${url}. (Reason: ${
        e ?? "unknown"
      }).`,
    });
  }
};
