import styled from "styled-components";

import { media } from "@styles/media";

export const UpsellModal = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  padding: 2rem 2rem 4rem;
  gap: 16px;
`;

export const UpsellModalHeader = styled.p`
  font-weight: 600;
`;

export const TextWrapper = styled.div`
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-flow: column;
  margin-bottom: 30px;

  * {
    color: ${({ theme }) => theme.colors.dark};
    text-align: center;
  }

  ${media.md.up} {
    margin-bottom: 0;
  }
`;
