import styled from "styled-components";

import { px2rem } from "@styles";

export const CartIcon = styled.div<{ orange?: boolean }>`
  height: 16px;
  width: 16px;
  background-color: ${({ theme: { colors }, orange }) =>
    orange ? colors.orange : colors.white};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  font-size: ${px2rem(10)};
  font-weight: 700;
  align-items: center;
  border: 0;
  position: absolute;
  bottom: 55%;
  left: 55%;

  &:hover {
    background-color: ${({ theme }) => theme.colors.yellow};
  }

  &:focus {
    outline: none;
  }
`;

export const IconWrapper = styled.div`
  background: none;
  border: none;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;
