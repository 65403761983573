import React from "react";
import { components, OptionProps } from "react-select";

import * as GS from "../styles";

export const Option = ({ data, ...props }: OptionProps<any, boolean>) => (
  <components.Option data={data} {...props}>
    {data?.icon && (
      <GS.IconWrapper>
        <data.icon />
      </GS.IconWrapper>
    )}
    {data.label}
  </components.Option>
);
