import { create } from "zustand";

type DiceStore = {
  scriptLoaded: boolean;
  setScriptLoaded: (scriptLoaded: boolean) => void;
};

export const useDiceStore = create<DiceStore>()(set => ({
  scriptLoaded: false,
  setScriptLoaded: scriptLoaded =>
    set(() => ({
      scriptLoaded,
    })),
}));
