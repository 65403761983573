import React, { useEffect, useState } from "react";

import { Theme } from "@styles/theme";

import * as S from "./styles";

export interface ProgressBarProps {
  children?: React.ReactNode;
  elementHeight?: string;
  initialPercent?: number;
  percent: number;
  variant: keyof Theme["progressBar"]["colors"];
}

export const ProgressBar = ({
  percent,
  children,
  variant = "default",
  elementHeight = "25px",
  initialPercent = 0,
}: ProgressBarProps) => {
  const [progressPercent, setProgressPercent] = useState(initialPercent);

  useEffect(() => {
    if (percent) {
      setProgressPercent(percent);
    }
  }, [percent]);

  return (
    <S.ProgressWrapper style={{ height: elementHeight }}>
      <S.ProgressInner
        style={{ width: `${progressPercent}%` }}
        variant={variant}
      >
        {children}
      </S.ProgressInner>
    </S.ProgressWrapper>
  );
};
