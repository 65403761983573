import { KeyTextField } from "@prismicio/client";
import { MainNavigationDocumentDataMenuItemItem } from "prismicio-types";
import React from "react";
import { useIntl } from "react-intl";

import * as M from "@components/Navigation/messages";
import { useHeaderStore } from "@stores/useHeaderStore";

import { ReactComponent as ArrowSvg } from "assets/icons/arrow-down.svg";

import * as S from "./styles";
import { WrapperWidths } from "./styles";

export interface WrapperProps {
  children?: React.ReactNode;
  header?: KeyTextField;
  index: number;
  totalCount?: number;
  variant?: MainNavigationDocumentDataMenuItemItem["variant"];
}

export const Wrapper = ({
  header,
  index,
  children,
  totalCount,
  variant = "default",
}: WrapperProps): JSX.Element => {
  const {
    nav: { menu },
  } = useHeaderStore();

  const { formatMessage } = useIntl();

  const isActive = menu.activeIndex === index + 1;
  const maxWidth = Math.round(100 / (totalCount ?? 1)) as WrapperWidths;

  return (
    <S.SliceWrapper maxWidth={maxWidth}>
      <S.SliceContent>
        <S.HeaderWrapper>
          <S.MobileSubMenuHeader
            iconFirst={false}
            isActive={isActive}
            onClick={() => {
              menu.updateMenu({
                activeIndex: isActive ? 0 : index + 1,
              });
            }}
            variant={variant || "default"}
          >
            <span>
              {header || formatMessage(M.NavigationMessages.fallbackHeader)}
            </span>
            <S.IconWrapper>
              <ArrowSvg />
            </S.IconWrapper>
          </S.MobileSubMenuHeader>
          {header && (
            <S.SubMenuHeader>
              <p>{header}</p>
            </S.SubMenuHeader>
          )}
        </S.HeaderWrapper>
        <S.MenuItemWrapper isActive={isActive}>{children}</S.MenuItemWrapper>
      </S.SliceContent>
    </S.SliceWrapper>
  );
};
