import {
  checkoutBaseFragment,
  checkoutErrorFragment,
  deliveryMethodFragment,
  shippingMethodFragment,
  useTypedMutation,
} from "@graphql";
import gql from "graphql-tag";

import {
  SplitCheckoutLines,
  SplitCheckoutLinesVariables,
} from "@views/CheckoutView/components/CheckoutFlowProvider/gqlTypes/SplitCheckoutLines";

export const splitCheckoutLinesMutation = gql`
  ${checkoutBaseFragment}
  ${checkoutErrorFragment}
  ${deliveryMethodFragment}
  ${shippingMethodFragment}
  mutation SplitCheckoutLines(
    $id: ID!
    $lines: [CheckoutLineUpdateInput!]!
    $languageCode: LanguageCodeEnum = EN
  ) {
    checkoutLinesUpdate(id: $id, lines: $lines) {
      checkout {
        ...CheckoutBase
        availableShippingMethods {
          ...ShippingMethod
        }
        deliveryMethod {
          ...DeliveryMethod
        }
      }
      errors {
        ...CheckoutError
      }
    }
  }
`;

export const useSplitCheckoutLinesMutation = () =>
  useTypedMutation<SplitCheckoutLines, SplitCheckoutLinesVariables>(
    splitCheckoutLinesMutation
  );
