import {
  pageInfoFragment,
  selectedAttributeFragment,
} from "@graphql/fragments";
import gql from "graphql-tag";

import { MetadataKey } from "@config";

export const productsRedirectQuery = gql`
  ${pageInfoFragment}
  ${selectedAttributeFragment}
  query ProductsRedirect(
    $channel: String!
    $after: String
    $first: Int = 1
    $filter: ProductFilterInput
    $languageCode: LanguageCodeEnum = EN
  ) {
    products(first: $first, channel: $channel, after: $after, filter: $filter) {
      pageInfo {
        ...PageInfo
      }
      edges {
        node {
          slug
          productType {
            slug
          }
          attributes {
            ...SelectedAttribute
          }
        }
      }
    }
  }
`;

export const productVariantsRedirectQuery = gql`
  ${pageInfoFragment}
  ${selectedAttributeFragment}
  query ProductVariantsRedirect(
    $channel: String!
    $after: String
    $first: Int = 1
    $filter: ProductVariantFilterInput
    $languageCode: LanguageCodeEnum = EN
  ) {
    productVariants(
      first: $first
      channel: $channel
      after: $after
      filter: $filter
    ) {
      pageInfo {
        ...PageInfo
      }
      edges {
        node {
          product {
            slug
            productType {
              slug
            }
            attributes {
              ...SelectedAttribute
            }
          }
          attributes {
            ...SelectedAttribute
          }
        }
      }
    }
  }
`;

export const collectionsRedirectQuery = gql`
  ${pageInfoFragment}
  query CollectionRedirect(
    $channel: String!
    $after: String
    $first: Int = 1
    $filter: CollectionFilterInput
  ) {
    collections(
      first: $first
      channel: $channel
      after: $after
      filter: $filter
    ) {
      pageInfo {
        ...PageInfo
      }
      edges {
        node {
          slug
          collectionSource: metafield(key: "${MetadataKey.COLLECTION_SOURCE}")
        }
      }
    }
  }
`;
