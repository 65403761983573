import debounce from "lodash/debounce";
import { useCallback, useEffect } from "react";

export const useOnWindowResize = (
  cb: () => void,
  opts?: Partial<{ leading: boolean; wait: number }>,
  deps: any[] = []
) => {
  const listener = useCallback(debounce(cb, opts?.wait ?? 100), [cb, ...deps]);

  useEffect(() => {
    if (opts?.leading) {
      cb();
    }

    window.addEventListener("resize", listener);
    return () => window.removeEventListener("resize", listener);
  }, [listener]);
};
