import { css, keyframes } from "styled-components";

import { ToastAnimationProps } from "@components/Toast/types";

const animatingIn = keyframes`
	from { transform: translate(100%,0) }
	to { transform: translate(0,0)}
`;

const animatingOut = keyframes`
	from { transform: translate(0,0); }
	to { transform: translate(100%,0);}
`;

const animateBar = keyframes`
	from { transform: translateX(-100%) }
	to { transform: translateX(0)}
`;

const animatingInMobile = keyframes`
	from { transform: translate(0,-100%) }
	to { transform: translate(0,0)}
`;

const animatingOutMobile = keyframes`
	from { transform: translate(0,0) }
	to { transform: translate(0,-100%) }
`;

export const containerAnimationMobile = ({
  speed,
  totalDuration,
}: ToastAnimationProps) =>
  css<ToastAnimationProps>`
    animation: ${speed}ms ${animatingInMobile} 0s linear forwards,
      ${speed}ms ${animatingOutMobile} ${totalDuration}ms linear forwards;
  `;

export const containerAnimation = ({
  speed,
  totalDuration,
}: ToastAnimationProps) =>
  css<ToastAnimationProps>`
    animation: ${speed}ms ${animatingIn} 0s linear forwards,
      ${speed}ms ${animatingOut} ${totalDuration + speed}ms linear forwards;
  `;

export const barAnimation = ({ speed, totalDuration }: ToastAnimationProps) =>
  css<ToastAnimationProps>`
    animation: ${totalDuration}ms ${animateBar} ${speed}ms linear forwards;
  `;
