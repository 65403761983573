import { css, CSSProperties } from "styled-components";

import { BREAKPOINTS, Breakpoints, media, styled, Theme } from "@styles";

export type StyleProps = {
  background?: keyof Theme["container"]["colors"]["background"];
  backgroundColor?: CSSProperties["backgroundColor"];
  disableGutters?: boolean;
  maxWidth?: Breakpoints | number | false;
  minHeightFull?: boolean;
  overflow?: CSSProperties["overflow"];
};

export const FullWidthWrapper = styled.div<StyleProps>`
  position: relative;
  width: 100%;
  max-width: 100vw;
  background-color: ${({ background, theme }) =>
    theme.container.colors.background[background || "default"]};
`;

export const Wrapper = styled.div<StyleProps>`
  display: block;
  margin: 0 auto;
  width: 100%;

  ${({ disableGutters }) => disableGutters && "overflow: unset;"}
  ${({ overflow }) => overflow && `overflow: ${overflow};`}

  ${({ disableGutters, theme: { spacing } }) =>
    !disableGutters &&
    css`
      padding-right: ${spacing.mobileGutter};
      padding-left: ${spacing.mobileGutter};

      ${media.md.up} {
        padding-right: ${spacing.gutter};
        padding-left: ${spacing.gutter};
      }
    `}

     ${({ minHeightFull }) =>
    minHeightFull &&
    `
      min-height: 100dvh;
      ${media.md.up} {
        min-height: 100dvh;
      }
    `}

  ${({ maxWidth }) =>
    maxWidth &&
    `
      ${media.md.up} {
        max-width: ${
          typeof maxWidth === "number" ? maxWidth : BREAKPOINTS[maxWidth]
        }px;
      }
    `}

  ${({ backgroundColor }) =>
    backgroundColor && `background-color: ${backgroundColor}`}
`;
