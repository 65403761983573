import { TopBannerDocumentDataItemsItem } from "prismicio-types";
import React, { useEffect, useMemo, useRef, useState } from "react";

import { SessionStorageKey } from "@config";
import { useOnWindowResize } from "@hooks/useOnWindowResize";
import { useGlobalConfigStore } from "@stores/useGlobalConfigStore";
import { useHeaderStore } from "@stores/useHeaderStore";
import { theme } from "@styles";

import { ReactComponent as CloseIcon } from "assets/icons/close.svg";

import * as S from "./styles";

export interface TopBannerProps extends Partial<S.TopBannerWrapperProps> {
  slideUp?: boolean;
}

export const TopBanner = ({ slideUp }: TopBannerProps) => {
  const { top_banner } = useGlobalConfigStore();
  const { topBanner } = useHeaderStore();

  const [tickerItems, setTickerItems] = useState<
    TopBannerDocumentDataItemsItem[] | null
  >(null);
  const [showBanner, setShowBanner] = useState<boolean>(topBanner.isActive);
  const [animationSpeed, setAnimationSpeed] = useState(20);
  const tickerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const showBanner =
      !!top_banner?.data?.items.length &&
      !sessionStorage.getItem(SessionStorageKey.HIDE_TOP_BANNER);

    if (showBanner) {
      setShowBanner(true);
      topBanner.toggleActive(true);
    }

    if (top_banner && top_banner?.data?.items.length > 0) {
      setTickerItems(top_banner?.data?.items);
    }
  }, [top_banner]);

  const updateAnimationSpeed = () => {
    if (tickerItems && tickerRef.current) {
      const totalWidth = tickerRef.current.scrollWidth;
      const uniqueContentWidth = totalWidth / 2; // Halve the width for duplicated content
      const pixelsPerSecond = 30; // Adjust this value as needed
      const newAnimationSpeed = uniqueContentWidth / pixelsPerSecond;
      setAnimationSpeed(newAnimationSpeed);
    }
  };

  useOnWindowResize(updateAnimationSpeed, { leading: true });

  // Duplicate the array to give the appearance of infinite looping
  const duplicatedTickerItems = useMemo(() => {
    if (tickerItems) {
      if (tickerItems.length === 1) {
        // Create four copies of the single item
        return Array(4).fill(tickerItems[0]);
      }
      return [...tickerItems, ...tickerItems];
    }
    return null;
  }, [tickerItems]);

  if (!showBanner || !topBanner.isActive) {
    return null;
  }

  return (
    <S.TopBannerWrapper isVisible={topBanner.isActive} slideUp={slideUp}>
      <S.AnnouncementTicker
        animationSpeed={animationSpeed}
        className="announcement-ticker"
        ref={tickerRef}
      >
        {duplicatedTickerItems?.map((item, index: number) => (
          <S.TickerItem key={`${item.url}-${index}`}>
            <S.TickerItemText>
              {item.text} {item.has_separator && <span>|</span>}{" "}
              {item.url && <a href={item.url}>{item.label}</a>}
            </S.TickerItemText>
          </S.TickerItem>
        ))}
      </S.AnnouncementTicker>
      <S.CloseIconWrapper
        onClick={() => {
          topBanner.toggleActive(false);
          sessionStorage.setItem(SessionStorageKey.HIDE_TOP_BANNER, "true");
        }}
      >
        <CloseIcon fill={theme.colors.black} height={14} />
      </S.CloseIconWrapper>
    </S.TopBannerWrapper>
  );
};

TopBanner.displayName = "TopBanner";
