import { paths } from "@paths";
import {
  ExtractRouteParams,
  generatePath,
  generatePath as reactRouterGeneratePath,
} from "react-router";

import { publicUrl } from "@config";
import { Locale } from "@providers/TranslationProvider";

import { CollectionTypes } from "./favouriting/types";

type GeneratePath = { path: string } & ExtractRouteParams<any>;

export const generateConfirmationUrl = (suffix: string, locale: Locale) =>
  `${publicUrl}/${locale}/${suffix}`;

export const isExternalUrl = (str: string) => {
  try {
    const { host } = new URL(publicUrl);
    const url = new URL(str);
    return url.host !== host;
  } catch (_) {
    return false;
  }
};

export const generateNextHref = (generatePath: GeneratePath) => {
  const { path, ...params } = generatePath;
  return reactRouterGeneratePath(path, params);
};

export const isValidHttpUrl = (str: string) => {
  let url;

  try {
    url = new URL(str);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
};

export const generateCollectionPath = (
  slug: string,
  type?: CollectionTypes
) => {
  switch (type) {
    case "artist":
      return generatePath(paths.artist, { slug });
    case "collection":
      return generatePath(paths.collection, { slug });
    case "label":
      return generatePath(paths.label, { slug });
    case "genre":
      return generatePath(paths.genre, { slug });
    default:
      return null;
  }
};
