import { px2rem, styled } from "@styles";

export const IMAGE_SIZE = 50;

export const Thumbnail = styled.span<{ isRounded: boolean }>`
  display: grid;
  aspect-ratio: 1;
  margin: 0 ${px2rem(14)} 0 0;
  padding: 0;
  place-content: center;
  overflow: hidden;
  max-width: ${IMAGE_SIZE}px;
  min-width: ${IMAGE_SIZE}px;
  height: ${IMAGE_SIZE}px;
  border-radius: ${({ isRounded }) => (isRounded ? "50%" : "0")};
`;

export const Wrapper = styled.div`
  display: flex;
  position: relative;
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  overflow: hidden;
`;

export const Name = styled.span`
  color: ${({ theme }) => theme.colors.white};
  font-size: ${px2rem(16)};
  text-decoration: none;
  font-weight: bold;
`;

export const Artist = styled.span`
  text-decoration: none;
  font-weight: 500;
  text-transform: capitalize;
  color: ${({ theme }) => theme.colors.light};
`;

export const Info = styled.div`
  font-size: ${px2rem(13)};
  display: flex;
  flex-direction: column;
  gap: 4px;
  overflow: hidden;

  &,
  p {
    color: ${({ theme }) => theme.colors.white};
  }

  & > * {
    overflow: hidden;
    line-height: 20px;
    white-space: nowrap;
    max-width: 100%;
    text-overflow: ellipsis;
    display: inline;
  }
`;
