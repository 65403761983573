import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";

import { Modal } from "@components/Modal";
import { useSpotifyContext } from "@hooks/providers";
import * as GS from "@views/AccountLoginView/components/AuthForm/styles";

import { SpotifyFollowButton } from "./SpotifyFollowButton";

import * as M from "./messages";
import * as S from "./styles";

export const SpotifySuccessModal = () => {
  const { replace, query, pathname } = useRouter();
  const { isPremiumAccount } = useSpotifyContext();
  const [spotifyModalOpen, setSpotifyModalOpen] = useState(false);

  const handleClose = () => {
    setSpotifyModalOpen(false);

    const { spotifyAuthorized, ...newQuery } = query;
    replace({ pathname, query: newQuery }, undefined, {
      shallow: true,
    });
  };

  useEffect(() => {
    setSpotifyModalOpen(query.spotifyAuthorized === "true");
  }, [query]);

  if (!spotifyModalOpen) {
    return null;
  }

  return (
    <Modal isOpen={spotifyModalOpen} onClose={handleClose}>
      <S.ModalBody>
        <S.ModalSpotifyLogo variant="green" />
        <GS.Heading>{M.Header}</GS.Heading>
        <S.Description>
          {isPremiumAccount ? M.DescriptionPremium : M.Description}
        </S.Description>
        <S.ButtonContainer>
          <SpotifyFollowButton />
          <S.Button fullWidth onClick={handleClose} variant="secondary">
            Close
          </S.Button>
        </S.ButtonContainer>
      </S.ModalBody>
    </Modal>
  );
};
