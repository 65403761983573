import React from "react";

import { styled } from "@styles";

import { ReactComponent as SpotifySvg } from "assets/icons/spotify.svg";

const StyledSpotifyLogo = styled(SpotifySvg)<{ variant: "green" | "black" }>`
  width: 21px;

  path {
    fill: ${({ theme, variant }) => theme.colors[variant]};
  }
`;

export const SpotifyLogo = (variant: { variant: "green" | "black" }) => (
  <StyledSpotifyLogo {...variant} />
);
