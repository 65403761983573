import { createContext } from "react";

import { Channel, Currency } from "@providers/TranslationProvider";

export const DEFAULT_CHANNEL = "gb" as const;
export const DEFAULT_CURRENCY = "GBP" as const;

export interface ConfigContextData {
  channel: Channel;
  currency: Currency;
}

export const defaultContext = {
  channel: DEFAULT_CHANNEL,
  currency: DEFAULT_CURRENCY,
};

export const ConfigContext = createContext<ConfigContextData>(defaultContext);
