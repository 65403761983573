import { styled, theme } from "@styles";

export interface DiscountedProps {
  discounted: boolean;
}

export const Wrapper = styled.span<DiscountedProps>`
  ${({ discounted }) =>
    discounted &&
    `
  display: flex;
  flex-direction: column-reverse;
  `}
`;

export type PriceProps = DiscountedProps & {
  isOnSale: boolean;
};

export const Price = styled.span<PriceProps>`
  ${({ discounted, isOnSale }) =>
    (discounted || isOnSale) &&
    `
  color: ${theme.colors.red};
  font-weight: bold;
  `}
`;

export const Discount = styled.span<DiscountedProps>`
  font-weight: normal;
  text-decoration: line-through;
  margin-right: 0.5rem;

  ${({ discounted }) =>
    discounted &&
    `
  margin-right: 0;
  color: ${theme.colors.dark};
  `}
`;
