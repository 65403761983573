import styled, { CSSProperties } from "styled-components";

import { media } from "@styles/media";

export const BasketHeader = styled.p`
  font-family: ${({ theme }) => theme.typography.condensedFontFamily};
  font-size: ${({ theme }) => theme.typography.sizes.largest};
  line-height: inherit;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
`;

export const BasketMain = styled.div<{ isBannerVisible: boolean }>`
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
  align-items: center;
  gap: 2rem;
  flex: 1;
  padding: ${({ isBannerVisible }) =>
    isBannerVisible ? "2rem 2rem 0 2rem" : "3rem 2rem 0 2rem"};

  ${media.md.up} {
    padding: ${({ isBannerVisible }) =>
      isBannerVisible ? "2rem 2rem 0 2rem" : "3rem 2rem 0 2rem"};
  }
`;

export const BasketContent = styled.div<{
  gap?: CSSProperties["gap"];
  isEmpty: boolean;
  justifyContent?: CSSProperties["justifyContent"];
}>`
  flex: 1;
  display: flex;
  flex-flow: column;
  justify-content: ${({ justifyContent }) => justifyContent || "center"};
  gap: ${({ gap }) => gap || "3rem"};
  width: 100%;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 4px;
    z-index: 99999;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.black};
    outline: 1px solid slategrey;
  }
`;

export const BasketButtonContainer = styled.div`
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;
  gap: 10px;

  > button {
    flex: 1;
    max-width: 100% !important;
  }
`;

export const CollectionWrapper = styled.div`
  display: flex;
  flex-flow: column;
  gap: 1rem;
`;

export const CollectionHeader = styled.span`
  font-weight: 600;
`;
