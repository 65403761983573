import { Country } from "@gqlTypes/Country";
import { User } from "@gqlTypes/User";
import { PaymentMethod as OrderApiPaymentMethod } from "@graphql/orderAppGqlTypes/PaymentMethod";
import React from "react";

import { Error } from "@utils/typescript";

import { CollectionPoint } from "./orderAppGqlTypes/CollectionPoint";
import {
  CheckoutState,
  CheckoutStep,
  PaymentMethod,
  SetCheckoutState,
} from "./types";

export interface CheckoutFlowContext {
  availableCollectionPoints: CollectionPoint[];
  defaultCard: Omit<OrderApiPaymentMethod, "__typename"> | null;
  enabledCountries: Country[];
  isClickAndCollect: () => boolean;
  isPaid: boolean;
  isStep: (step: CheckoutStep) => boolean;
  isStepDone: (nextStep: CheckoutStep) => boolean;
  paymentGateway: string;
  setErrors: (error: Error[]) => void;
  setPaymentMethod: (paymentMethod: PaymentMethod | null) => void;
  setProcessing: (processing: boolean) => void;
  setState: (step: SetCheckoutState) => void;
  setStep: (step: CheckoutStep) => void;
  setUser: (user: User) => void;
  state: CheckoutState;
  user: User | null;
}

export const initialContext: CheckoutFlowContext = {
  availableCollectionPoints: [],
  defaultCard: null,
  enabledCountries: [],
  isClickAndCollect: () => false,
  isPaid: false,
  isStep: step => false,
  isStepDone: nextStep => false,
  paymentGateway: "",
  setErrors: error => null,
  setPaymentMethod: data => null,
  setProcessing: processing => null,
  setState: step => null,
  setStep: step => null,
  setUser: user => null,
  state: {
    deliveryOption: "delivery",
    deliveryType: "together",
    errors: [],
    isProcessing: false,
    paymentMethod: null,
    type: "deliveryAddress",
  },
  user: {} as User,
};

export const Context = React.createContext(initialContext);
