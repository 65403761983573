import Image from "next/image";
import React, { CSSProperties, useEffect, useRef } from "react";

import { Link } from "@components/Link";
import { ProductBadges } from "@components/ProductBadges";
import { useListingContext } from "@hooks/providers";
import { useIsLgUp } from "@hooks/responsive";
import { useIntersectionObserver } from "@hooks/useIntersectionObserver";
import { useRouter } from "@hooks/useRouter";
import { useSearchInsights } from "@hooks/useSearchInsights";
import {
  ALGOLIA_PRODUCT_SEARCH_INDEXES,
  AlgoliaResult,
  getProductImage,
} from "@integrations";
import {
  gtmSelectItemListingHit,
  gtmViewItemListingHit,
} from "@providers/GoogleTagManagerProvider";
import { algoliaProductClicked } from "@providers/SearchInsightsProvider/actions";
import { useSearchInsightsStore } from "@stores/useSearchInsightsStore";
import { generateArtistPath, generateProductPath } from "@utils/products";

import * as S from "./styles";
import { DEFAULT_MOBILE_COVER_SIZE } from "./styles";

export interface AlgoliaHitProps {
  desktopCoverSize?: number;
  hit: AlgoliaResult;
  inNavigation?: boolean;
  isPriority?: boolean;
  light?: boolean;
  location?: string;
  onVisibleFireTag?: boolean;
  sizes?: string;
  textAlign?: CSSProperties["textAlign"];
}

export const AlgoliaHitComponent = ({
  hit,
  light = false,
  textAlign,
  inNavigation = false,
  desktopCoverSize = DEFAULT_MOBILE_COVER_SIZE,
  sizes,
  location = "Search",
  onVisibleFireTag = false,
  isPriority = false,
}: AlgoliaHitProps) => {
  const { locale } = useRouter();
  const isDesktop = useIsLgUp();
  const { insightsUserToken } = useSearchInsights();
  const { setSearchQueryID } = useSearchInsightsStore();
  const { filters } = useListingContext();
  const collectionName = filters["collections.name"];
  const {
    name,
    artists,
    thumbnails,
    slug,
    sale,
    signed,
    collections,
    exclusive,
  } = hit;
  const productRef = useRef(null);
  const entry = useIntersectionObserver(productRef, {
    freezeOnceVisible: true,
    threshold: 1,
  });
  const creators = artists.map(({ name }) => name).join(", ");
  const coverSize = isDesktop ? desktopCoverSize : DEFAULT_MOBILE_COVER_SIZE;
  const isElementInViewport = !!entry?.isIntersecting;
  const productPath = generateProductPath({
    artist: artists.map(({ slug }) => slug),
    slug,
  });
  const { alt, placeholder, src, title } = getProductImage({
    data: {
      creators,
      name,
    },
    thumbnails,
  });

  const handleClick = (hit: AlgoliaResult) => {
    setSearchQueryID(hit.__queryID);
    gtmSelectItemListingHit(hit, "Listing", location);
    algoliaProductClicked(
      hit,
      ALGOLIA_PRODUCT_SEARCH_INDEXES[locale],
      insightsUserToken
    );
  };

  useEffect(() => {
    if (isElementInViewport && onVisibleFireTag) {
      gtmViewItemListingHit(hit, collectionName);
    }
  }, [isElementInViewport, collectionName, onVisibleFireTag]);

  return (
    <S.ProductCard
      data-insights-object-id={hit.objectID}
      data-insights-position={hit.__position}
      data-insights-query-id={hit.__queryID}
      key={slug}
      onClick={() => handleClick(hit)}
      ref={productRef}
      textAlign={textAlign}
    >
      <Link
        wrapperTag
        aProps={{ title: `${name} by ${creators}` }}
        href={productPath}
      >
        <S.ThumbnailWrapper coverSize={coverSize}>
          <Image
            alt={alt}
            layout="fill"
            objectFit="contain"
            placeholder={placeholder}
            priority={isPriority}
            sizes={sizes ?? "(min-width: 1200px) 15vw, 20vw"}
            src={src}
            title={title}
          />
        </S.ThumbnailWrapper>
        <S.Name inNavigation={inNavigation} light={light}>
          {name}
        </S.Name>
      </Link>
      <S.Creators>
        {artists.map(({ name, slug }, i) => (
          <React.Fragment key={slug}>
            <Link wrapperTag href={generateArtistPath({ artist: [slug] })}>
              {name}
            </Link>
            {i + 1 < artists.length && ", "}
          </React.Fragment>
        ))}
      </S.Creators>
      <ProductBadges
        collections={collections}
        exclusive={exclusive}
        sale={sale}
        signed={signed}
      />
    </S.ProductCard>
  );
};

AlgoliaHitComponent.displayName = "AlgoliaHitComponent";
