import { FieldError } from "react-hook-form";
import { Styles, Theme as DefaultTheme } from "react-select";
import { Props } from "react-select/src/Select";

import { colors, px2rem, styled, theme as themeOpts } from "@styles";
import { DATA_TEST_ATTRIBUTE } from "@utils";

export type Theme = "light" | "dark";

export interface SelectProps extends Omit<Props<any>, "theme"> {
  [DATA_TEST_ATTRIBUTE]?: string;
  error?: FieldError;
  theme?: Theme;
}

export const SelectWrapper = styled.div<{
  error?: boolean;
  thickBorder?: boolean;
  variant?: Theme;
}>`
  position: relative;
  width: 100%;

  .react-select__control.react-select__control--is-focused {
    outline: 2px solid
      ${({ theme, variant }) =>
        variant === "dark" ? theme.colors.white : theme.colors.dark} !important;
    outline-offset: 2px;
  }

  ${({ error, theme, thickBorder }) =>
    error &&
    `
      .react-select__control {
        border: 1px solid ${theme.colors.red};
      }
      &:hover .react-select__control{
          border: 2px solid ${theme.colors.red};
      }
      .react-select__value-container,.react-select__placeholder {
        color: ${theme.colors.red};
      }
      .react-select__indicator svg path {
        fill: ${theme.colors.red};
      }
    `}
`;

export const Error = styled.div`
  padding-top: 5px;
  font-size: ${px2rem(13)};
  color: ${({ theme }) => theme.colors.red};
  font-weight: 500;
  min-height: 16px;
`;

const baseThemeSettings: DefaultTheme = {
  borderRadius: 0,
  colors: {
    danger: colors.red,
  },
  spacing: {
    baseUnit: 7,
    controlHeight: 0,
    menuGutter: 0,
  },
};

export const IconWrapper = styled.div`
  width: 30px;
  display: inline-block;
  margin-right: ${themeOpts.grid.spacing["1"]};
`;

// TODO: Handle disabled/error states...
export const themes: { [key in Theme]: DefaultTheme } = {
  dark: {
    ...baseThemeSettings,
    colors: {
      borderColor: themeOpts.colors.white,
      disabled: themeOpts.colors.white,
      placeholder: themeOpts.colors.white,
      primary: themeOpts.colors.black,
      secondary: themeOpts.colors.white,
    },
  },
  light: {
    ...baseThemeSettings,
    colors: {
      borderColor: themeOpts.input.borderColor,
      disabled: themeOpts.input.disabled.color,
      placeholder: themeOpts.colors.dark,
      primary: themeOpts.colors.white,
      secondary: themeOpts.colors.black,
    },
  },
};

export const defaultStyles: Styles<any, any> = {
  container: (base, { theme }) => ({
    ...base,
    backgroundColor: `${theme.colors.primary}`,
  }),
  control: (base, { theme, isDisabled }) => ({
    ...base,
    ":hover": {
      border: `2px solid ${theme.colors.secondary}`,
      padding: "0",
    },
    backgroundColor: theme.colors.primary,
    border: `1px solid ${theme.colors.borderColor}`,
    boxShadow: "none",
    color: theme.colors.secondary,
    height: themeOpts.input.height,
    padding: "1px",
    ...(isDisabled && {
      borderColor: `${themeOpts.input.disabled.borderColor} !important`,
      color: `${themeOpts.input.disabled.color} !important`,
    }),
  }),
  indicatorSeparator: base => ({
    ...base,
    display: "none",
  }),
  input: base => ({
    ...base,
    margin: 0,
    padding: 0,
  }),
  menu: (base, { theme }) => ({
    ...base,
    border: `1px solid ${theme.colors.secondary}`,
    boxShadow: "none",
    marginBottom: "-1px",
    marginTop: "-1px",
    zIndex: 5,
  }),
  menuList: (base, { theme }) => ({
    ...base,
    backgroundColor: theme.colors.primary,
    padding: 0,
  }),
  menuPortal: base => ({
    ...base,
    zIndex: 5,
  }),
  option: (base, { theme }) => ({
    ...base,
    ":active": {
      backgroundColor: `${theme.colors.primary}`,
      color: theme.colors.secondary,
    },
    ":hover": {
      backgroundColor: theme.colors.secondary,
      color: theme.colors.primary,
    },
    backgroundColor: theme.colors.primary,
    color: theme.colors.secondary,
  }),
  placeholder: (base, { theme, isDisabled }) => ({
    ...base,
    color: isDisabled ? theme.colors.disabled : theme.colors.placeholder,
  }),
  singleValue: (base, { theme, isDisabled }) => ({
    ...base,
    color: isDisabled ? theme.colors.disabled : theme.colors.secondary,
    overflow: "unset",
  }),
  valueContainer: base => ({
    ...base,
    padding: "20px 15px",
  }),
};
