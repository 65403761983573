// NOTE: Next.js 13 supports cookie in next/headers
import { paths } from "@paths";
import { getCookie } from "cookies-next";
import React, { useState } from "react";

import { Button } from "@components/Button";
import {
  CurrencySelect,
  getCurrencyOptions,
  getLanguageOptions,
  LANGUAGE_OPTIONS,
  LanguageSelect,
} from "@components/LanguageSwitcher";
import { Modal, ModalProps } from "@components/Modal";
import { useRouter } from "@hooks/useRouter";
import { getChannel } from "@providers/ConfigProvider/helpers";

import * as M from "./messages";
import * as S from "./styles";

interface LanguagePreferencesModalProps extends ModalProps {
  closeModal: () => void;
}

export function LanguagePreferencesModal({
  closeModal,
  isOpen,
  onClose,
}: LanguagePreferencesModalProps) {
  const { locale, push } = useRouter();
  const channel = getChannel(locale);
  const lang = locale?.split("-")[0];
  const currencyOptions = getCurrencyOptions();

  const [currentChannel, setCurrentChannel] = useState(
    currencyOptions.find(({ name }) => name === channel)
  );

  const [currentLanguage, setCurrentLanguage] = useState(
    LANGUAGE_OPTIONS.find(({ name }) => name === lang)
  );

  const languageOptions = getLanguageOptions(currentChannel?.channel || "gb");

  const handleSubmit = () => {
    // #1 - Update NEXT_LOCALE cookie
    const selectedLocale = currentChannel?.supportedLocales.find(locale =>
      locale.startsWith(currentLanguage?.name as string)
    );
    document.cookie = `NEXT_LOCALE=${selectedLocale}; max-age=31536000; path=/`;

    // #2 - Redirect to home page with new locale
    if (selectedLocale !== locale && selectedLocale) {
      push(paths.home, paths.home, {
        locale: selectedLocale,
      });
    }

    closeModal();
  };

  return (
    <Modal allowOverflow isOpen={isOpen} onClose={onClose}>
      <S.ModalBody>
        <S.Heading>{M.LanguagePreferences}</S.Heading>
        <S.Label>{M.SelectCurrency}</S.Label>
        <CurrencySelect
          onChange={(evt: (typeof currencyOptions)[0]) => {
            const channelLanguage = evt.defaultLocale.split("-")[0];
            setCurrentChannel(evt);
            setCurrentLanguage(
              LANGUAGE_OPTIONS.find(({ name }) => name === channelLanguage)
            );
          }}
          options={currencyOptions}
          placement="language-preferences-modal"
          theme="light"
          value={currentChannel}
        />
        <S.LanguageSelectWrapper>
          <S.Label>{M.SelectLanguage}</S.Label>
          <LanguageSelect
            disabled={languageOptions?.length === 1}
            onChange={(evt: (typeof LANGUAGE_OPTIONS)[0]) =>
              setCurrentLanguage(evt)
            }
            options={languageOptions}
            theme="light"
            value={currentLanguage}
          />
        </S.LanguageSelectWrapper>
        <Button fullWidth onClick={() => handleSubmit()} variant="secondary">
          {M.SubmitButton}
        </Button>
      </S.ModalBody>
    </Modal>
  );
}

export const LanguagePreferencesModalOverlay = () => {
  const { locale } = useRouter();
  const localeCookie = getCookie("NEXT_LOCALE");
  const [isLanguageModalOpen, setIsLanguageModalOpen] = useState(!localeCookie);

  const handleModalClose = () => {
    document.cookie = `NEXT_LOCALE=${locale}; max-age=31536000; path=/`;
    setIsLanguageModalOpen(false);
  };

  if (!isLanguageModalOpen) {
    return null;
  }

  return (
    <LanguagePreferencesModal
      closeModal={() => setIsLanguageModalOpen(false)}
      isOpen={isLanguageModalOpen}
      onClose={() => handleModalClose()}
    />
  );
};
