import {
  UpdateMetadata,
  UpdateMetadataVariables,
} from "@gqlTypes/UpdateMetadata";
import { MutationHookFn } from "@graphql";
import { paths } from "@paths";
import { UserDetailsFragment } from "@saleor/sdk/dist/apollo/types";
import sha256 from "crypto-js/sha256";

import {
  MetadataKey,
  publicUrl,
  spotifyAuthorizeUrl,
  spotifyClientId,
  spotifyTokenUrl,
  spotifyUserAuthorizationScopes,
  spotifyUserFollowContains,
  spotifyUserFollowingUrl,
  spotifyUserUrl,
} from "@config";
import { base64Encode, generateRandomString } from "@utils/text";

import {
  ExchangeToken,
  ExchangeTokenError,
  SpotifyArtist,
  SpotifyFollowingArtists,
  SpotifyUser,
} from "./types";

export const generateAuthorizationUrl = (
  state: string,
  codeChallenge: string
) => {
  const params = new URLSearchParams({
    client_id: spotifyClientId,
    code_challenge: codeChallenge,
    code_challenge_method: "S256",
    redirect_uri: `${publicUrl}${paths.spotifyAuthorize}`,
    response_type: "code",
    scope: spotifyUserAuthorizationScopes.join(" "),
    state,
  });

  return `${spotifyAuthorizeUrl}?${params.toString()}`;
};

export const exchangeSpotifyToken = async (
  code: string,
  code_verifier: string
): Promise<ExchangeToken | ExchangeTokenError> => {
  const result = await fetch(spotifyTokenUrl, {
    body: new URLSearchParams({
      client_id: spotifyClientId,
      code,
      code_verifier,
      grant_type: "authorization_code",
      redirect_uri: `${publicUrl}${paths.spotifyAuthorize}`,
    }),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    method: "POST",
  });
  return result.json();
};

export const refreshSpotifyToken = async (
  refresh_token: string
): Promise<ExchangeToken | ExchangeTokenError> => {
  const result = await fetch(spotifyTokenUrl, {
    body: new URLSearchParams({
      client_id: spotifyClientId,
      grant_type: "refresh_token",
      refresh_token,
    }),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    method: "POST",
  });
  return result.json();
};

export const generateVerifier = () => generateRandomString(70);

export const generateState = () => generateRandomString(35);

export const generateCodeChallenge = (verifier: string) =>
  base64Encode(sha256(verifier));

const getSpotifyApiHeaders = (accessToken: string) => ({
  Authorization: `Bearer ${accessToken}`,
});

export const checkIfUserFollowsArtists = async (
  accessToken: string,
  ids: string[],
  opts?: Partial<{ type: string }>
): Promise<boolean[]> => {
  const { type } = { type: "artist", ...opts };

  const query = new URLSearchParams({
    ...{ type },
    ids: ids.join(","),
  }).toString();

  const result = await fetch(`${spotifyUserFollowContains}?${query}`, {
    headers: getSpotifyApiHeaders(accessToken),
    method: "GET",
  });

  if (!result.ok) {
    return [];
  }

  const res = await result.json();

  return res;
};

export const getUserFollowedArtists = async (
  accessToken: string,
  opts?: Partial<{ limit: number }>
) => {
  const { limit } = { limit: 50, ...opts };

  const fetchFollowing = async (
    cursor: string | undefined,
    prevItems: SpotifyArtist[]
  ): Promise<SpotifyArtist[]> => {
    const query = new URLSearchParams({
      ...{ limit: limit.toString(), type: "artist" },
      ...(cursor && { after: cursor }),
    }).toString();

    const result = await fetch(`${spotifyUserFollowingUrl}?${query}`, {
      headers: getSpotifyApiHeaders(accessToken),
      method: "GET",
    });

    if (!result.ok) {
      return [];
    }

    const {
      artists: {
        cursors: { after },
        items,
      },
    }: SpotifyFollowingArtists = await result.json();
    const newItems = [...prevItems, ...items];

    if (after) {
      return fetchFollowing(after, newItems);
    }
    return newItems;
  };

  return fetchFollowing(undefined, []);
};

export const getUserData = async (
  accessToken: string
): Promise<SpotifyUser | null> => {
  const result = await fetch(spotifyUserUrl, {
    headers: getSpotifyApiHeaders(accessToken),
    method: "GET",
  });

  if (!result.ok) {
    return null;
  }

  return result.json();
};

export const followArtist = async (
  accessToken: string,
  ids: string[],
  type: string = "artist"
): Promise<boolean> => {
  const query = new URLSearchParams({
    ids: ids.join(","),
    type,
  }).toString();
  const result = await fetch(`${spotifyUserFollowingUrl}?${query}`, {
    headers: getSpotifyApiHeaders(accessToken),
    method: "PUT",
  });
  return result.ok;
};

export const unFollowArtist = async (accessToken: string, ids: string[]) => {
  const query = new URLSearchParams({
    ids: ids.join(","),
    type: "artist",
  }).toString();
  const result = await fetch(`${spotifyUserFollowingUrl}?${query}`, {
    headers: getSpotifyApiHeaders(accessToken),
    method: "DELETE",
  });
  return result.ok;
};

export const checkIsFollowing = (following: string[], id: string) =>
  following?.includes(id) || null;

export const extractRefreshToken = (
  user: UserDetailsFragment | undefined | null
) =>
  user?.metadata.find(entry => entry?.key === MetadataKey.SPOTIFY_REFRESH_TOKEN)
    ?.value || null;

export const storeUserSpotifyData = async (
  user: UserDetailsFragment,
  spotifyUser: SpotifyUser | null,
  updateMetadataMutation: MutationHookFn<
    UpdateMetadata,
    UpdateMetadataVariables
  >
) => {
  const metaSpotifyUser = user.metadata.find(
    entry => entry?.key === MetadataKey.SPOTIFY_USER_ID
  )?.value;

  if (spotifyUser && metaSpotifyUser && spotifyUser.id !== metaSpotifyUser) {
    await updateMetadataMutation({
      variables: {
        id: user.id,
        input: [
          {
            key: MetadataKey.SPOTIFY_USER_ID,
            value: spotifyUser.id,
          },
        ],
      },
    });
  }
};
