// colors
import { px2rem } from "./utils";

export const animations = {
  timing: {
    fast: "150ms",
    fastest: "50ms",
    medium: "250ms",
    slow: "350ms",
    slowest: "450ms",
  },
};

export const colors = {
  black: "#000000",
  blackLight: "#111",
  blue: "#1c9de1",
  blueLight: "#04BFBF",
  clubYellow: "#FFD561",
  dark: "#737373",
  darkGrey: "#262626",
  darkest: "#333333",
  essentialOrange: "#e3a66e",
  exclusiveBlue: "#009ee3",
  gold: "#f9d574",

  green: "#1DB954",
  greenLight: "#3fd073",
  light: "#EDEDED",
  lightest: "#F7F7F7",
  medium: "#B2B2B2",

  orange: "#f6a870",
  orangeLighter: "#f3bd95",
  pink: "#f2667f",
  purple: "#990099",
  red: "#ed6e68",
  saleRed: "#ff5f53",
  turquoise: "#6cc2bd",
  turquoiseLight: "#81baB6ff",
  vintageGreen: "#29844B",
  white: "#ffffff",
  yellow: "#ffd561",
  yellowLighter: "#FFF8E4",
};

// typography

export const baseFontFamily =
  '"Helvetica Neue", Helvetica, Roboto, Ubuntu, Arial, sans-serif';
export const clubFontFamily =
  '"Knockout", Arial, Roboto, Ubuntu, Arial, sans-serif';
export const condensedFontFamily =
  '"HelveticaNeue-CondensedBold", "Roboto Condensed", "Ubuntu Condensed", Arial, sans-serif';
export const baseFontSize = px2rem(16); // 16px
export const baseFontWeight = 400;
export const baseFontLineHeight = px2rem(25); // 25px
export const h1FontSize = px2rem(34); // 34px
export const h2FontSize = px2rem(30); // 30px
export const h3FontSize = px2rem(25); // 25px

export const transitionDuration = 0.2;

// spacing
export const gutter = "1rem";
export const mobileGutter = "1rem";

// breakpoints
export const xs = 0;
export const sm = 576;
export const md = 768;
export const lg = 992;
export const xl = 1200;
export const xxl = 1440;
export const retina = 1900;

// grid
export const columns = 12;
export const gridSpacing0 = "0px";
export const gridSpacing1 = "8px";
export const gridSpacing2 = "16px";
export const gridSpacing3 = "24px";
export const gridSpacing4 = "32px";
export const gridSpacing5 = "40px";
