import React, { RefObject, useMemo, useRef } from "react";

import { Button } from "@components/Button";
import { Link } from "@components/Link";
import { SpotifyLogo } from "@components/SpotifyLogo";
import { useIsLgUp } from "@hooks/responsive";
import { useOnClickOutside } from "@hooks/useOnClickOutside";
import { isTrackPlayable } from "@providers/PlayerProvider/helpers";
import { AlbumData } from "@providers/PlayerProvider/types";

import { AlbumInfo } from "..";

import { ReactComponent as SpotifyIcon } from "assets/icons/spotify.svg";

import * as GS from "../../styles";
import * as AS from "../AlbumInfo/styles";
import * as M from "./messages";
import * as S from "./styles";

export interface PlaylistProps {
  album: AlbumData;
  currentTrack: number;
  isExpanded: boolean;
  isPremiumAccount: boolean;
  onClickOutside: () => void;
  onTrackClick: (trackIndex: number) => void;
  productUrl?: string | null;
  progressBarRef: RefObject<HTMLDivElement>;
}

export const Playlist = ({
  currentTrack,
  album: { open_url, name: albumName, image, artists, discs, productUrl },
  album,
  isExpanded,
  onTrackClick,
  progressBarRef,
  onClickOutside,
  isPremiumAccount,
}: PlaylistProps) => {
  const isLgUp = useIsLgUp();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { trackIndexes, trackList } = useMemo(() => {
    const tracks = discs.flat();
    return { trackIndexes: tracks.map(({ id }) => id), trackList: tracks };
  }, [album]);

  const { name: trackName, open_url: trackOpenUrl } = trackList[currentTrack];
  const trackData = {
    albumName,
    albumOpenUrl: open_url,
    artists,
    image,
    trackName,
    trackOpenUrl,
  };

  const isActiveTrack = (id: string) => id === trackList[currentTrack].id;

  useOnClickOutside(
    isLgUp ? [wrapperRef] : [wrapperRef, progressBarRef],
    onClickOutside,
    isExpanded
  );

  return (
    <S.PlaylistWrapper ref={wrapperRef}>
      <S.Album isExpanded={isExpanded}>
        {isLgUp ? (
          <AlbumInfo isExpanded={false} {...trackData} />
        ) : (
          <S.FlexWrapper>
            <SpotifyLogo variant="green" />
            <AS.MobileLabel>
              <GS.SpotifyOpenUrl
                href={trackOpenUrl}
                rel="noreferrer"
                target="_blank"
              >
                {trackName}
              </GS.SpotifyOpenUrl>
              {" - "}
              {artists.map(({ name, open_url }, index) => (
                <React.Fragment key={index}>
                  <GS.SpotifyOpenUrl
                    href={open_url}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {name}
                  </GS.SpotifyOpenUrl>
                  {artists.length > index + 1 && ", "}
                </React.Fragment>
              ))}
            </AS.MobileLabel>
          </S.FlexWrapper>
        )}

        {isExpanded && (
          <S.Playlist>
            <AlbumInfo isExpanded {...trackData} />
            <S.SpotifyButtons>
              {productUrl && (
                <Link passHref href={productUrl}>
                  <Button fullWidth variant="dark">
                    {M.GoToAlbum}
                  </Button>
                </Link>
              )}
              <a href={open_url} rel="noreferer noreferrer" target="_blank">
                <Button fullWidth icon={SpotifyIcon} variant="dark">
                  {M.ListenOnSpotify}
                </Button>
              </a>
            </S.SpotifyButtons>
            <S.TrackList>
              {trackList.map(({ id, name, ...track }, i: number) => {
                const canBePlayed = isTrackPlayable(track, isPremiumAccount);
                const trackIndex = trackIndexes.indexOf(id);
                return (
                  <S.TrackName
                    disabled={!canBePlayed}
                    isActive={isActiveTrack(id)}
                    key={id}
                    onClick={() =>
                      canBePlayed ? onTrackClick(trackIndex) : undefined
                    }
                  >
                    {i + 1}. {name}
                  </S.TrackName>
                );
              })}
            </S.TrackList>
          </S.Playlist>
        )}
      </S.Album>
    </S.PlaylistWrapper>
  );
};
