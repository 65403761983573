import { Container } from "@components/Container";
import { media, styled } from "@styles";
import { dynamicFullHeight, fixedElementOffset } from "@utils/navigation";
import { LayoutProps } from "@utils/typescript";

const BaseNavigationBar = styled.div<
  LayoutProps & {
    isActive?: boolean;
    isBannerVisible: boolean;
  }
>`
  background-color: ${({ theme }) => theme.colors.black};

  ${media.lg.up} {
    background-color: ${({ theme }) => theme.colors.white};
  }
`;

export const NavigationBar = styled(BaseNavigationBar)`
  position: absolute;
  left: ${({ isActive }) => (isActive ? "0" : "-100%")};
  transition: ${({ isActive }) =>
    isActive ? "left 250ms ease-in-out" : "none"};
  height: ${({ isBannerVisible }) =>
    dynamicFullHeight(
      "mobile",
      isBannerVisible ? ["promoBanner", "header"] : ["header"]
    )};
  width: 100%;
  background: black;
  overflow-y: auto;
  gap: 10%;
  z-index: -1;
  top: 100%;

  ${media.lg.up} {
    position: relative;
    height: auto;
    top: 0;
    left: 0;
    flex-flow: row;
    gap: 4vw;
    align-items: stretch;
    justify-items: flex-start;
    background: white;
    transition: none;
    overflow-y: initial;
  }
`;

export const NavContent = styled(Container)`
  display: flex !important;
  flex-flow: column;

  ${media.lg.down} {
    margin: 1rem 0;
  }

  ${media.lg.up} {
    flex-flow: row;
    gap: 4vw;
    align-items: stretch;
    justify-items: flex-start;
  }
`;

export const MainNav = styled.nav`
  display: flex;
  flex-flow: column;
  height: 100%;
  position: relative;
  overflow: hidden;

  ${media.lg.up} {
    flex-flow: row;
    height: auto;
    overflow-y: initial;
    position: initial;
  }
`;

export const NavList = styled.ol`
  display: flex;
  flex-flow: column;
  gap: 2vh;
  width: 100%;
  height: 100%;
  justify-content: start;

  ${media.lg.up} {
    flex-flow: row;
    gap: 12px;
  }
`;

export const MobileNavWrapper = styled.li`
  display: flex;
  flex-flow: column;
  gap: inherit;

  hr {
    width: 100%;
    height: 2px;
    min-height: 2px;
    background: ${({ theme }) => theme.colors.white};
    opacity: 0.2;
  }

  > div {
    width: 100%;
  }

  ${media.lg.up} {
    display: none;
  }
`;

export const LanguageWrapper = styled.div`
  display: none;

  ${media.lg.up} {
    display: block;
  }
`;

export const BlurContainer = styled.div<{
  isActive?: boolean;
  isBannerVisible: boolean;
}>`
  ${media.lg.up} {
    position: fixed;
    z-index: -1;
    inset: 0;
    width: ${`100dvw`};
    max-width: 100vw;
    height: ${({ isBannerVisible }) =>
      dynamicFullHeight(
        "desktop",
        isBannerVisible ? undefined : ["header", "nav"]
      )};

    top: ${({ isBannerVisible }) =>
      fixedElementOffset(
        "desktop",
        isBannerVisible ? undefined : ["header", "nav"]
      )};

    backdrop-filter: blur(10px);
    visibility: ${({ isActive }) => (isActive ? "visible" : "hidden")};
    opacity: ${({ isActive }) => (isActive ? "1" : "0")};
    transition: 150ms all 250ms ease-in-out;
  }
`;
