import { ErrorBoundary as NextErrorBoundary } from "@sentry/nextjs";
import React from "react";

import { InternalServerErrorView } from "@views/InternalServerErrorView";

type ErrorBoundaryProps = {
  children?: React.ReactNode;
};
export const ErrorBoundary = ({ children }: ErrorBoundaryProps) => (
  <NextErrorBoundary
    fallback={({ eventId, resetError }) => (
      <InternalServerErrorView
        isModalView
        clearError={resetError}
        errorId={eventId}
        statusCode={500}
      />
    )}
  >
    {children}
  </NextErrorBoundary>
);
