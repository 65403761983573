import styled from "styled-components";

import { ReactComponent as LogoSvg } from "assets/logo.svg";

export interface LogoProps {
  $dark: boolean;
  size: string;
}

export const Logo = styled(LogoSvg)<LogoProps>`
  width: auto;
  height: ${({ size }) => size};

  path {
    fill: ${({ theme, $dark }) =>
      $dark ? theme.colors.black : theme.colors.white} !important;
  }
`;
