import { css } from "styled-components";

import { FloatingLabel } from "@components/Input/styles";
import { InputField } from "@components/InputField";
import { px2rem, styled } from "@styles";

export const noticeStyling = css`
  span {
    color: ${({ theme }) => theme.colors.black};
    font-size: 0.9rem;
    line-height: 140%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    svg {
      width: 16px;
      height: 16px;

      path {
        stroke: ${({ theme }) => theme.colors.black};
      }
    }
  }
`;

export const Info = styled.span<{ active: boolean }>`
  color: ${({ theme, active }) =>
    active ? theme.colors.medium : theme.colors.white};
  font-weight: 500;
  cursor: pointer;

  span {
    color: ${({ theme, active }) => (active ? "inherit" : theme.colors.dark)};
    text-decoration: underline;
    font-weight: 700;
  }
`;

export const Close = styled.div`
  padding: ${px2rem(15)};
  margin: ${px2rem(-15, -15, 0, 0)};
  cursor: pointer;

  svg {
    height: 15px;
    fill: ${({ theme }) => theme.colors.dark};
  }
`;

export const GiftCardsInfo = styled.span`
  color: ${({ theme }) => theme.colors.medium};
  font-size: ${px2rem(15)};
  display: flex;
  align-items: center;

  svg {
    height: 18px;
    margin-right: ${px2rem(5)};

    g {
      fill: ${({ theme }) => theme.colors.medium};
    }
  }
`;

export const SuccessWrapper = styled.div<{ show: boolean }>`
  background-color: ${({ theme }) => theme.colors.turquoise};
  padding: ${({ show }) => (show ? "16px" : 0)};
  margin-top: ${({ show }) => (show ? "20px" : 0)};
  height: ${({ show }) => (show ? "auto" : 0)};
  opacity: ${({ show }) => (show ? 1 : 0)};
  z-index: ${({ show }) => (show ? 1 : -1)};
  transition: opacity ${({ theme }) => theme.transitionDuration}s;

  ${noticeStyling}
`;

export const CheckoutInputField = styled(InputField)`
  ${FloatingLabel} {
    color: ${({ theme }) => theme.colors.light};
  }
`;
