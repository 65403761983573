import { px2rem, styled } from "@styles";

export type InputVariant = "default" | "hollow";

export const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const isHollow = (type: InputVariant) => type === "hollow";

export const FloatingLabel = styled.label<{
  disabled: boolean;
  error: boolean;
}>`
  color: ${({ theme, error, disabled }) =>
    error
      ? theme.colors.red
      : disabled
      ? theme.input.disabled.color
      : theme.input.color};
  pointer-events: none;
  font-size: ${({ theme }) => theme.input.fontSize};
  font-weight: 400;
  left: 20px;
  margin: 0;
  opacity: 1;
  position: absolute;
  top: 21px;
  transition: top ${({ theme }) => theme.input.transitionDuration};
`;

export const Icon = styled.div<{ disabled?: boolean; error?: boolean }>`
  color: ${({ error, theme }) =>
    error ? theme.colors.red : theme.input.color};
  font-size: ${({ theme }) => theme.input.fontSize};
  font-weight: 400;
  right: 20px;
  margin: 0;
  cursor: pointer;
  position: absolute;
  top: 21px;
  transition: color ${({ theme }) => theme.input.transitionDuration};

  ${({ disabled }) =>
    disabled &&
    `
      cursor: not-allowed;
      pointer-events: none;
    `}
`;

export const ComponentWrapper = styled.div<{
  disabled?: boolean;
  error?: boolean;
}>`
  color: ${({ error, theme }) =>
    error ? theme.colors.red : theme.input.color};
  font-size: ${({ theme }) => theme.input.fontSize};
  font-weight: 400;
  right: 20px;
  margin: 0;
  cursor: pointer;
  position: absolute;
  top: 17px;
  transition: color ${({ theme }) => theme.input.transitionDuration};

  ${({ disabled }) =>
    disabled &&
    `
      cursor: not-allowed;
      pointer-events: none;
    `}
`;

export const Error = styled.div`
  padding-top: 5px;
  font-size: ${px2rem(13)};
  color: ${({ theme }) => theme.colors.red};
  font-weight: 500;
  min-height: 16px;
`;

export const Input = styled.input<{ error?: boolean; variant: InputVariant }>`
  width: 100%;
  height: ${({ theme }) => theme.input.height};
  padding: ${({ theme }) => theme.input.padding};
  font-size: ${({ theme }) => theme.input.fontSize};
  outline: none;
  border-radius: 0;
  appearance: none;
  color: ${({ theme, error, variant }) =>
    error
      ? theme.colors.red
      : isHollow(variant)
      ? theme.input.colors.hollow.color
      : theme.colors.black};
  -webkit-text-fill-color: ${({ theme, error, variant }) =>
    error
      ? theme.colors.red
      : isHollow(variant)
      ? theme.input.colors.hollow.color
      : theme.colors.black};
  border: 1px solid
    ${({ error, theme, variant }) =>
      error
        ? theme.colors.red
        : isHollow(variant)
        ? theme.input.colors.hollow.borderColor
        : theme.input.borderColor};

  ${({ variant, theme }) =>
    isHollow(variant) &&
    `
      background-color: ${theme.input.colors.hollow.background};
  `}

  ${({ disabled, error, theme, variant }) =>
    disabled
      ? `
        background: initial;
        border-color: ${theme.input.disabled.borderColor};
        color: ${theme.input.disabled.color};
        -webkit-text-fill-color: ${theme.input.disabled.color};
      `
      : `
        &:hover {
          outline: 1px solid ${
            error
              ? theme.colors.red
              : isHollow(variant)
              ? theme.input.colors.hollow.hoverBorderColor
              : theme.colors.black
          };
          padding-left: 19px;
        }
  `}

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active,
  &:focus,
  &:not(:placeholder-shown) {
    ~ ${FloatingLabel} {
      top: 14px;
      font-size: ${px2rem(12)};
    }
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:active,
  &:-webkit-autofill:focus {
    box-shadow: 0 0 0 1000px ${({ theme }) => theme.colors.white} inset;
  }
`;
