import React from "react";
import { FormattedMessage } from "react-intl";

export const SearchButtonMessage = (
  <FormattedMessage defaultMessage="Search" id="xmcVZ0" />
);
export const HomeButtonMessage = (
  <FormattedMessage defaultMessage="Home" id="ejEGdx" />
);

export const HelpButtonMessage = (
  <FormattedMessage defaultMessage="Help" id="SENRqu" />
);

export const HelpLinksMessage = (
  <FormattedMessage
    defaultMessage="Here are some helpful links instead:"
    id="UTSapC"
  />
);
