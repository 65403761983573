import { styled, Theme, theme } from "@styles";

export type ProgressVariant = keyof Theme["progressBar"]["colors"];

type ProgressInnerType = {
  variant: ProgressVariant;
};

export const ProgressWrapper = styled.div`
  display: flex;
  height: 100%;
  min-height: 5px;
  background-color: ${theme.colors.light};
`;

export const getVariantStyle = (variant: ProgressVariant) => {
  const { colors } = theme.progressBar;
  return `
  background-color: ${colors[variant].backgroundColor}
  `;
};

export const ProgressInner = styled.div<ProgressInnerType>`
  height: 100%;
  text-align: center;
  transition: ${({ variant }) =>
    variant !== "default" ? "width 750ms ease-in 0s" : "none"};
  ${({ variant }) => getVariantStyle(variant)}
`;
