import React from "react";
import { useMediaQuery } from "react-responsive";

import { BREAKPOINTS, Breakpoints } from "@styles";

type TBaseProps = {
  height?: number;
  type?: "vertical" | "horizontal";
};
type TProps = TBaseProps &
  (
    | { down?: never; up?: never }
    | { down?: never; up: Breakpoints }
    | { down: Breakpoints; up?: never }
  );

export const Spacer = ({
  type = "vertical",
  height = 20,
  up,
  down,
}: TProps) => {
  const isVertical = type === "vertical";
  const match = useMediaQuery(
    down
      ? { maxWidth: BREAKPOINTS[down] - 1 }
      : { minWidth: BREAKPOINTS[up || "xs"] } // fallback to up xs === match all
  );

  return match ? (
    <span
      style={{
        display: "block",
        ...(isVertical ? { height } : { width: height }),
      }}
    >
      {"\u00A0"}
    </span>
  ) : null;
};
