import styled, { css } from "styled-components";

import { FormWidthStyling } from "@components/Footer/styles";
import { media, px2rem } from "@styles";

export const Column = styled.div<{ isActive?: boolean }>`
  display: flex;
  flex-flow: column;
  gap: inherit;
  gap: ${({ isActive }) => (isActive ? "inherit" : "0")};

  ${FormWidthStyling}

  ${media.lg.up} {
    gap: inherit;
  }
`;

export const LinkContainer = styled.div<{ isActive?: boolean }>`
  display: grid;
  justify-items: space-between;
  align-items: center;
  overflow: hidden;
  transition: grid-template-rows 350ms ease-in-out;
  grid-template-rows: ${({ isActive }) => (isActive ? "1fr" : "0fr")};

  ${media.lg.up} {
    grid-template-rows: 1fr;
  }
`;

export const InnerContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  overflow: hidden;

  .footer-nav-link {
    color: ${({ theme }) => theme.footer.foregroundColor};
    text-decoration: none;
    font-size: ${px2rem(16)};
    display: flex;
    justify-content: start;
    line-height: 1.5em;

    &:hover {
      opacity: 1;
    }

    svg {
      height: 18px;
      width: 20px;
    }

    ${media.lg.up} {
      display: flex;
      justify-content: flex-start;
      flex-flow: row;

      svg {
        height: 22px;
        width: 28px;
      }
    }
  }
`;

export const TitleContainer = styled.div<{ isActive?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${media.lg.up} {
    justify-content: flex-start;
  }
`;

export const MobileArrow = styled.label<{ isActive: boolean }>`
  padding: ${px2rem(10, 30)};
  margin-right: -${px2rem(30)};

  svg {
    width: 15px;
    height: 15px;
    transition: transform 350ms ease-in-out;

    path {
      fill: ${({ theme }) => theme.colors.white};
      stroke: ${({ theme }) => theme.colors.white};
    }

    ${({ isActive }) =>
      isActive &&
      css`
        transform: scaleY(-1);

        path {
          fill: ${({ theme }) => theme.colors.light};
          stroke: ${({ theme }) => theme.colors.light};
        }
      `}
  }

  ${media.lg.up} {
    display: none;
  }
`;
