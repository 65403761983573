import algoliaAnalytics from "search-insights";

import { BlogSearchResult } from "@components/Search/types";
import { AlgoliaEventResult, AlgoliaResult } from "@integrations/algolia";

export const algoliaEventClicked = (
  hit: AlgoliaEventResult,
  index: string,
  userToken: string
): void =>
  algoliaAnalytics("clickedObjectIDsAfterSearch", {
    eventName: "Search: Event Clicked",
    index,
    objectIDs: [hit.objectID],
    positions: [hit.__position],
    queryID: hit.__queryID,
    timestamp: Date.now(),
    userToken,
  });

export const algoliaBlogClicked = (
  hit: BlogSearchResult,
  index: string,
  userToken: string
): void =>
  algoliaAnalytics("clickedObjectIDsAfterSearch", {
    eventName: "Search: Blog Clicked",
    index,
    objectIDs: [hit.objectID],
    positions: [hit.__position],
    queryID: hit.__queryID,
    timestamp: Date.now(),
    userToken,
  });

export const algoliaProductClicked = (
  hit: AlgoliaResult,
  index: string,
  userToken: string
): void =>
  algoliaAnalytics("clickedObjectIDsAfterSearch", {
    eventName: "PLP: Product Clicked",
    index,
    objectIDs: [hit.objectID],
    positions: [hit.__position],
    queryID: hit.__queryID,
    timestamp: Date.now(),
    userToken,
  });

export const algoliaAddToCartEvent = (
  id: string,
  index: string,
  userToken: string,
  queryID: string | null
): void => {
  const commonProperties = {
    index,
    objectIDs: [id],
    timestamp: Date.now(),
    userToken,
  };

  if (queryID) {
    algoliaAnalytics("convertedObjectIDsAfterSearch", {
      eventName: "PDP: Product Added to Cart after Search",
      queryID,
      ...commonProperties,
    });
  } else {
    algoliaAnalytics("convertedObjectIDs", {
      eventName: "PDP: Product Added to Cart",
      ...commonProperties,
    });
  }
};

export const algoliaProductsPurchased = (
  objectIDs: string[],
  index: string,
  userToken: string
): void =>
  algoliaAnalytics("convertedObjectIDs", {
    eventName: "Checkout: Product Bought",
    index,
    objectIDs,
    timestamp: Date.now(),
    userToken,
  });
