import { asLink } from "@prismicio/client";
import React, { useState } from "react";

import { Button } from "@components/Button";
import { ButtonLink } from "@components/ButtonLink";
import { spotifyRoughTradeId } from "@config";
import { useConfigContext, useSpotifyContext } from "@hooks/providers";
import { useRouter } from "@hooks/useRouter";
import { useGlobalConfigStore } from "@stores/useGlobalConfigStore";

import { ReactComponent as CheckIcon } from "assets/icons/check-path.svg";
import { ReactComponent as ExternalLinkIcon } from "assets/icons/external-link.svg";

import * as M from "./messages";

export const SpotifyFollowButton = () => {
  const { query } = useRouter();
  const { channel } = useConfigContext();
  const { follow, isFollowingRT, user } = useSpotifyContext();

  const { footer } = useGlobalConfigStore();

  const spotifySocialLink = footer?.data?.social_links.find(
    ({ name }) => name?.toLowerCase() === "spotify"
  )?.link;

  const spotifyFollowLink = spotifySocialLink
    ? asLink(spotifySocialLink)
    : null;

  const isSpotifySignUp = !!(query.spotifySignUp === "true" && user);

  const [followError, setFollowError] = useState<boolean>(false);

  const handleOnClick = async () => {
    const { status } = await follow(spotifyRoughTradeId[channel], "user");
    if (!status.ok) {
      setFollowError(true);
    }
  };

  if (!followError && isSpotifySignUp) {
    return (
      <Button
        fullWidth
        disabled={isFollowingRT}
        icon={isFollowingRT ? CheckIcon : undefined}
        onClick={handleOnClick}
        variant="spotify"
      >
        {isFollowingRT ? M.FollowSuccess : M.FollowOnSpotify}
      </Button>
    );
  }

  if (!spotifyFollowLink) return null;

  return (
    <ButtonLink
      fullWidth
      isExternal
      href={spotifyFollowLink}
      icon={ExternalLinkIcon}
      variant="spotify"
      withIcon={false}
    >
      {isSpotifySignUp ? M.ErrorVisitSpotifyPage : M.VisitSpotifyPage}
    </ButtonLink>
  );
};
