import { MessageDescriptor } from "@formatjs/intl/src/types";
import { AccountError } from "@gqlTypes/AccountError";
import { Address } from "@gqlTypes/Address";
import { CheckoutError } from "@gqlTypes/CheckoutError";
import { DiscountError } from "@gqlTypes/DiscountError";
import { MetadataError } from "@gqlTypes/MetadataError";
import { MetadataItem } from "@gqlTypes/MetadataItem";
import { Money } from "@gqlTypes/Money";
import { OrderError } from "@gqlTypes/OrderError";
import { PaymentError } from "@gqlTypes/PaymentError";
import {
  AccountErrorFragment,
  AddressFragment,
} from "@saleor/sdk/dist/apollo/types";
import { NextPage } from "next";
import {
  Dispatch,
  FC,
  MutableRefObject,
  SetStateAction,
  SVGProps,
} from "react";
import { Message } from "react-hook-form";
import { LiteralUnion } from "react-hook-form/dist/types/utils";
import { RegisterOptions } from "react-hook-form/dist/types/validator";
import { OptionTypeBase } from "react-select/src/types";

import { ConfigContextData } from "@providers/ConfigProvider";
import { Layout } from "@views/LayoutSwitcher";

export type RequireAtLeastOne<T> = {
  [K in keyof T]-?: Required<Pick<T, K>> &
    Partial<Pick<T, Exclude<keyof T, K>>>;
}[keyof T];

export type RequireOnlyOne<T, Keys extends keyof T = keyof T> = Pick<
  T,
  Exclude<keyof T, Keys>
> &
  {
    [K in Keys]-?: Required<Pick<T, K>> &
      Partial<Record<Exclude<Keys, K>, undefined>>;
  }[Keys];

export type Object<T = any> = { [key: string]: T };
export type ObjectType = Object;
export type RefHook<T> = MutableRefObject<T | null>;
export type SetState<T> = Dispatch<SetStateAction<T>>;
export type SvgIcon = FC<SVGProps<SVGSVGElement>>;

export type PartialOnly<T, K extends PropertyKey = PropertyKey> = Partial<
  Pick<T, Extract<keyof T, K>>
> &
  Omit<T, K> extends infer O
  ? { [P in keyof O]: O[P] }
  : never;

export type LayoutProps = {
  children?: React.ReactNode;
  variant?: "dark" | "light" | "product-page" | "club";
};

export interface PageContentLayout extends LayoutProps {
  isFilterActive?: boolean;
}

export type PageLayoutProps = LayoutProps & ConfigContextData;

export type NextPageWithLayout<Props = {}> = NextPage<Props> & {
  layout?: Layout;
  layoutProps?: LayoutProps;
};

export type ApiError = Omit<
  | DiscountError
  | CheckoutError
  | OrderError
  | AccountError
  | PaymentError
  | MetadataError
  | AccountErrorFragment,
  "__typename"
>;

// TODO: replace any with properly typed message
export interface Error {
  code?: any;
  field: string | null | undefined;
  message: string | MessageDescriptor | null | any;
  values?: any;
}

export type FormError = {
  message: Message;
  shouldFocus?: boolean;
  type?: LiteralUnion<keyof RegisterOptions, string>;
};

export type PaymentConfirmationData = {
  client_secret: string;
  id: string;
};

export interface OptionType extends OptionTypeBase {
  label: string;
  value: string;
}

export type CommonAddress = (Address | Maybe<AddressFragment>) &
  Partial<Pick<Address, "__typename">> &
  Partial<
    Pick<
      AddressFragment,
      "isDefaultBillingAddress" | "isDefaultShippingAddress" | "id"
    >
  >;

export type AccountConfirmationViewProps = {
  email: string;
  token: string;
};

export type Discount = {
  code: string;
  currentBalance: Money | null | undefined;
  discount: Money | undefined;
  id: string | undefined;
};

export type Maybe<T> = T | null | undefined;

export type ObjectWithMeta<T> = {
  metadata: MetadataItem[];
} & T;

export type ConditionallyRequired<Require, T> = Require extends false
  ? T | null
  : T;

export enum UserActions {
  ADD = "add",
  INVALID = "invalid",
  REMOVE = "remove",
}
