import styled from "styled-components";

export const ActionWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
`;

export const ShippingWrapper = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  text-align: center;
  padding: 12px 2rem;
  z-index: 99;
  gap: 10px;
  background: ${({ theme }) => `${theme.colors.black}`};
`;

export const MainWrapper = styled.div`
  display: flex;
  flex-flow: column;
  gap: 20px;
  padding: 20px 2rem;
  background: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-flow: row;
  gap: 10px;
`;

export const ModalHeader = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  gap: 10px;
  padding: 20px 2rem;
  cursor: pointer;
`;

export const AlsoBoughtModallHeader = styled.p`
  font-weight: 600;
  font-size: ${({ theme }) => theme.typography.sizes.small};
`;

export const MobileArrow = styled.label<{ isActive: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    transition: transform ${({ theme }) => theme.transitionDuration * 2.5};
    width: 15px;
    ${({ isActive }) => isActive && `transform: scaleY(-1);`}

    path {
      fill: ${({ theme }) => theme.colors.black} !important;
      stroke: ${({ theme }) => theme.colors.white};
    }
  }
`;

export const CollectionWrapper = styled.div<{ isActive: boolean }>`
  height: ${({ isActive }) => (isActive ? "300px" : "0")};
  padding: ${({ isActive }) => (isActive ? "20px 2rem" : "0")};
  transition: ${({ isActive }) =>
    isActive ? `height 350ms ease-in-out` : "none"};
`;

export const FreeShipping = styled.p<{ isSuccess: boolean }>`
  color: ${({ theme, isSuccess }) =>
    isSuccess ? theme.colors.turquoise : theme.colors.orange};
  font-size: ${({ theme }) => theme.typography.sizes.small};
  font-weight: 500;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  gap: 8px;

  svg {
    height: 14px !important;
    width: 14px !important;
    path {
      stroke: ${({ theme, isSuccess }) =>
        isSuccess ? theme.colors.turquoise : theme.colors.orange};
    }
  }
`;
