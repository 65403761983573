import { clubFixedElements } from "@layouts/ClubFormLayout/common.styles";
import { px2rem, styled } from "@styles";

export const ClubFormFooter = styled.footer<{ isFooter?: boolean }>`
  justify-content: space-between;
  ${clubFixedElements}
`;

export const FormActions = styled.div`
  flex: 1;
  display: flex;
  flex-flow: row;
  gap: 1rem;

  a {
    white-space: nowrap;
  }

  button span {
    font-size: ${px2rem(12)} !important;
  }
`;

export const FormStepCounter = styled.span`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 14px;
`;

export const StepIndicator = styled.span`
  font-weight: 500;
  font-size: 2rem;
  margin-right: 4px;
`;

export const StepWrapper = styled.span`
  font-weight: 600;
  display: flex;
  flex-flow: row;
  align-items: center;
  gap: 3px;
`;
