import { FormWidthStyling } from "@components/Footer/styles";
import { media, px2rem, styled } from "@styles";

import { ReactComponent as NewsletterIndicator } from "assets/icons/newsletter-indicator.svg";

export const SentButtonIcon = styled(NewsletterIndicator)<{
  $completed: boolean;
}>`
  height: 30px;
  transform: rotate(${({ $completed }) => ($completed ? "90deg" : "0deg")})
    translateX(${({ $completed }) => ($completed ? "2px" : 0)});
  transition: transform ${({ theme }) => theme.transitionDuration}s linear;

  rect {
    fill: ${({ theme, $completed }) =>
      $completed ? theme.colors.turquoise : theme.colors.white};
    transition: height ${({ theme }) => theme.transitionDuration}s linear;
  }

  ${({ $completed }) =>
    $completed &&
    `
      rect:first-of-type {
        height: 6px;
      }
      rect:last-of-type {
        height: 10px;
      }
    `}

  &:hover {
    rect {
      fill: ${({ theme, $completed }) =>
        $completed ? theme.colors.turquoise : theme.colors.dark};
      transition: height ${({ theme }) => theme.transitionDuration}s linear;
    }
  }
`;

export const FormWrapper = styled.div`
  padding-bottom: 20px;
  width: 100%;

  ${media.lg.up} {
    padding-bottom: 0;
  }
  &:hover {
    ${SentButtonIcon} {
      fill: ${({ theme }) => theme.colors.white};
    }
  }
`;

export const InputWrapper = styled.div`
  position: relative;
  margin: 0 auto;

  ${FormWidthStyling}

  ${media.lg.up} {
    padding: 0;
    margin: 0;
  }
`;

export const CheckboxWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  padding-bottom: 12px;

  ${FormWidthStyling}

  ${media.lg.up} {
    padding: 0;
    margin: 0;
    padding-bottom: 16px;
  }
`;

export const InputErrors = styled.p`
  margin-top: 6px;
  font-size: ${px2rem(16)};
  color: ${({ theme }) => theme.colors.red};
`;

export const LanguageSwitcherWrapper = styled.div``;

export const Input = styled.input<{ $completed: boolean }>`
  background-color: ${({ theme }) => theme.colors.black};
  border-radius: 0;
  border: 1px solid ${({ theme }) => theme.colors.white};
  box-shadow: none;
  box-sizing: border-box;
  color: ${({ theme, $completed }) =>
    $completed ? theme.colors.turquoise : theme.colors.white};
  font-size: ${px2rem(16)};
  outline: none;
  padding: 16px 40px 16px 16px;
  width: 100%;
  font-weight: ${({ $completed }) => ($completed ? 500 : 400)};

  &:not(:placeholder-shown),
  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.white};
    box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.white} inset;
    color: ${({
      theme: {
        colors: { white, turquoise },
      },
      $completed,
    }) => ($completed ? turquoise : white)};
  }

  &:hover::placeholder {
    color: ${({
      theme: {
        colors: { dark, turquoise },
      },
      $completed,
    }) => ($completed ? turquoise : dark)};
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.white};
  }

  &:focus-visible {
    outline-color: ${({ theme }) => theme.colors.white} !important;
  }
`;

export const SentButton = styled.button<{ $completed: boolean }>`
  align-items: center;
  background-color: transparent;
  border: none;
  bottom: 3px;
  display: flex;
  justify-content: center;
  outline: none;
  position: absolute;
  right: 3px;
  top: 3px;
  width: 50px;

  &:hover {
    cursor: pointer;

    ~ ${Input} {
      border: 1px solid ${({ theme }) => theme.colors.medium};
      box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.white} inset;
      color: ${({ theme, $completed }) =>
        $completed ? theme.colors.turquoise : theme.colors.medium};

      &::placeholder {
        color: ${({ theme }) => theme.colors.white};
      }
    }
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.medium};

    ${SentButtonIcon} {
      rect {
        fill: ${({ theme }) => theme.colors.orange};
      }
    }
  }

  &:disabled {
    cursor: default;
  }
`;
