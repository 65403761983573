import { CollectionApiResponse } from "src/pages/api/collection/[locale]/[id]";

import { formatNumber } from "@utils/payment";

export type ProgressSettings = {
  amountRemaining: string;
  percent: number;
};

export const fetchBasketCollection = async (
  id: string,
  locale: string,
  pageSize: string
): Promise<CollectionApiResponse> => {
  if (!id) {
    return null;
  }

  const apiUrl = new URL(
    `api/collection/${locale}/${id}`,
    window.location.origin
  );

  apiUrl.searchParams.set("pageSize", pageSize);

  const response = await fetch(apiUrl.toString());

  if (!response.ok) {
    return null;
  }

  const data = await response.json();

  return data;
};

export const getBasketProgress = (
  curTotal: number,
  minAmount: number,
  currency: string,
  language: string
): ProgressSettings => {
  const actualPercent = (curTotal / minAmount) * 100;
  const percent = actualPercent >= 100 ? 100 : Math.floor(actualPercent);

  const amountRemaining =
    actualPercent >= 100
      ? "0"
      : formatNumber({
          amount: parseFloat((minAmount - curTotal).toFixed(2)),
          currency,
          language,
        });

  return {
    amountRemaining,
    percent,
  };
};
