import { paths } from "@paths";
import React, { useEffect, useRef } from "react";
import { createPortal } from "react-dom";

import { Link } from "@components/Link";
import { ssrMode } from "@config";
import { useIsMdDown } from "@hooks/responsive";
import { useBlockBodyScroll } from "@hooks/useBlockBodyScroll";
import { useOnWindowEvent } from "@hooks/useOnWindowEvent";
import { theme } from "@styles";
import { isFunction } from "@utils/js";

import { ReactComponent as ArrowBack } from "assets/icons/arrow-back.svg";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import { ReactComponent as Logo } from "assets/logo.svg";

import * as M from "./messages";
import * as S from "./styles";

export type ModalVariant = "default" | "club" | "small-left" | "authForm";

export interface ModalProps {
  allowOverflow?: boolean;
  children?:
    | React.ReactNode
    | ((opts: { onClose: (() => void) | undefined }) => React.ReactNode);
  closeControlMobile?: boolean;
  closeOnOverlayClick?: boolean;
  hasOverlay?: boolean;
  isOpen: boolean;
  onClose?: () => void;
  opacity?: number;
  variant?: ModalVariant;
  withFooter?: boolean;
  withLogo?: boolean;
}

export const Modal = ({
  children,
  isOpen,
  onClose,
  opacity,
  closeControlMobile,
  allowOverflow = false,
  withFooter = true,
  withLogo = true,
  variant = "default",
  hasOverlay = true,
  closeOnOverlayClick = true,
}: ModalProps) => {
  const isMdDown = useIsMdDown();

  useBlockBodyScroll(hasOverlay && isOpen);
  const optionsRef = useRef<HTMLDivElement | null>(null);
  const overlayRef = useRef<HTMLDivElement | null>(null);
  const contentRef = useRef<HTMLDivElement | null>(null);

  const portalRoot = ssrMode ? null : document.getElementById("modal-root");
  const showFooter = withFooter && isMdDown;

  const handleClickOutside = (event: MouseEvent) => {
    const targetObject = closeOnOverlayClick ? overlayRef : optionsRef;

    if (targetObject.current) {
      const triggerClose = closeOnOverlayClick
        ? event.target === targetObject.current
        : targetObject.current.contains(event.target as Element);

      if (triggerClose) {
        if (onClose) {
          onClose();
        }
      }
    }
  };

  useOnWindowEvent(
    "keydown",
    evt => {
      if (isOpen && onClose && evt.key === "Escape") {
        onClose();
      }
    },
    [isOpen]
  );

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);

    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    if (isOpen && contentRef?.current) {
      contentRef.current.focus();
    }
  }, [isOpen]);

  return (
    portalRoot &&
    createPortal(
      isOpen && (
        <>
          {hasOverlay ? (
            <S.Overlay
              opacity={opacity}
              open={isOpen}
              ref={overlayRef}
              variant={variant}
              withFooter={withFooter}
            >
              <S.Content
                allowOverflow={allowOverflow}
                className="modal-content"
                ref={contentRef}
                tabIndex={0}
                variant={variant}
                withLogo={withLogo}
              >
                {withLogo && isMdDown && (
                  <S.LogoWrapper>
                    <Logo fill={theme.colors.black} width={50} />
                  </S.LogoWrapper>
                )}
                {(!isMdDown || closeControlMobile) && onClose && (
                  <S.CloseButton hasOverlay onClick={onClose}>
                    <CloseIcon fill={theme.colors.black} />
                  </S.CloseButton>
                )}
                {isFunction(children) ? children({ onClose }) : children}
              </S.Content>

              {showFooter && (
                <S.Footer>
                  <S.LinksWrapper>
                    <S.FooterButton as="button" onClick={onClose}>
                      {onClose && (
                        <>
                          <ArrowBack width={20} />
                          {M.BackToStore}
                        </>
                      )}
                    </S.FooterButton>
                    <S.FooterPlaceholder as="span">
                      {"\u00A0"}
                    </S.FooterPlaceholder>
                    <Link passHref href={paths.termsAndConditions}>
                      <S.FooterLink>{M.TermsOfUse}</S.FooterLink>
                    </Link>
                    <Link passHref href={paths.privacyPolicy}>
                      <S.FooterLink>{M.PrivacyPolicy}</S.FooterLink>
                    </Link>
                  </S.LinksWrapper>
                </S.Footer>
              )}
            </S.Overlay>
          ) : (
            <S.ModalBody ref={optionsRef}>
              <S.ModalInner>
                {onClose && (
                  <S.CloseButton hasOverlay={false} onClick={onClose}>
                    <CloseIcon fill={theme.colors.black} />
                  </S.CloseButton>
                )}

                {isFunction(children) ? children({ onClose }) : children}
              </S.ModalInner>
            </S.ModalBody>
          )}
        </>
      ),
      portalRoot
    )
  );
};
