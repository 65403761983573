import gql from "graphql-tag";

const creditCardFragment = gql`
  fragment CreditCard on CreditCard {
    brand
    expMonth
    expYear
    lastDigits
  }
`;

export const paymentSourceFragmentOrderApp = gql`
  ${creditCardFragment}
  fragment PaymentMethod on PaymentMethod {
    id
    isDefault
    card {
      ...CreditCard
    }
  }
`;

export const errorsFragment = gql`
  fragment Error on Error {
    message
    code
    field
  }
`;
