import React from "react";
import { components, SingleValueProps } from "react-select";

import * as GS from "../styles";

export const SingleValue = ({
  data,
  children,
  ...props
}: SingleValueProps<any>) => (
  <components.SingleValue data={data} {...props}>
    {data?.icon && (
      <GS.IconWrapper>
        <data.icon />
      </GS.IconWrapper>
    )}
    {children}
  </components.SingleValue>
);
