import { CheckoutLine } from "@gqlTypes/CheckoutLine";
import { paths } from "@paths";
import { useRouter } from "next/router";
import React from "react";

import { CartLine } from "@components/CartLine";
import { Toast } from "@components/Toast/types";

import * as M from "./messages";
import * as S from "./styles";

export type ProductAddProps = {
  line: CheckoutLine;
};

export const ProductAdd = ({ props: { line } }: Toast<ProductAddProps>) => {
  const { push } = useRouter();
  if (!line) {
    return null;
  }

  const handleBasketClick = () => {
    push(paths.cart);
  };

  return (
    <>
      <CartLine border={false} line={line} />
      <S.BasketLink onClick={handleBasketClick}>{M.ViewBasket}</S.BasketLink>
    </>
  );
};
