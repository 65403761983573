import { create } from "zustand";

import { Toast } from "@components/Toast/types";

type ToastStore = {
  addToast: <T>(newToast: Toast<T>) => void;
  finished: Toast<unknown>[];
  removeToast: (toastId: number) => void;
  toasts: Toast<unknown>[];
};

export const useToastStore = create<ToastStore>()(set => ({
  addToast: toast => set(state => ({ toasts: [...state.toasts, toast] })),
  finished: [],
  removeToast: toastId =>
    set(state => ({
      finished: [
        ...state.finished,
        state.toasts.filter(toast => toast.id === toastId),
      ] as Toast<unknown>[],
    })),
  toasts: [],
}));
