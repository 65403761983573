// Helper types for common patterns
// Store Implementation (Pseudo-code)
import { create } from "zustand";

type Toggleable = {
  isActive: boolean;
  toggleActive: (value?: boolean, duration?: number) => void;
};

export interface NavigationOffset {
  menu: number;
  tab: number;
}

type Menu = {
  activeIndex: number;
  activeTab: string | null;
  updateMenu: (
    updates: Partial<{
      activeIndex: number;
      activeTab: string | null;
      isActive: boolean;
    }>
  ) => void;
} & Toggleable;

type Basket = {
  actionHeight: number;
  interval: number | null;
  setActionHeight: (height: number) => void;
} & Toggleable;

type HeaderStore = {
  basket: Basket;
  header: {
    height: number;
    setHeight: (height: number) => void;
  };
  nav: {
    isEventsHovered: boolean;
    menu: Menu;
    offset: NavigationOffset;
    setIsEventsHovered: (isHovered: boolean) => void;
    setOffset: (offset: NavigationOffset) => void;
  };
  search: Toggleable;
  topBanner: Toggleable;
};

export const useHeaderStore = create<HeaderStore>(set => ({
  basket: {
    actionHeight: 0,
    interval: null,
    isActive: false,
    setActionHeight: height =>
      set(state => ({ basket: { ...state.basket, actionHeight: height } })),
    toggleActive: (passedState, duration) => {
      set((state: HeaderStore) => {
        if (state.basket.interval !== null) {
          clearInterval(state.basket.interval);
        }

        const isActive =
          passedState !== undefined ? passedState : !state.basket.isActive;

        let newInterval: number | null = null;

        if (
          typeof window !== "undefined" &&
          isActive &&
          typeof duration === "number" &&
          duration > 0
        ) {
          newInterval = window.setInterval(() => {
            set(state => {
              // Ensures that the interval is cleared when the state is updated.
              if (state.basket.interval !== null) {
                clearInterval(state.basket.interval);
              }
              return {
                basket: { ...state.basket, interval: null, isActive: false },
              };
            });
          }, duration);
        }

        return {
          basket: {
            ...state.basket,
            interval: newInterval,
            isActive,
          },
        };
      });
    },
  },
  header: {
    height: 164,
    setHeight: height =>
      set(state => ({ header: { ...state.header, height } })),
  },
  nav: {
    isEventsHovered: false,
    menu: {
      activeIndex: 0,
      activeTab: null,
      isActive: false,
      toggleActive: passedState =>
        set(state => ({
          nav: {
            ...state.nav,
            menu: {
              ...state.nav.menu,
              isActive:
                passedState !== undefined
                  ? passedState
                  : !state.nav.menu.isActive,
            },
          },
        })),
      updateMenu: updates =>
        set(state => ({
          nav: { ...state.nav, menu: { ...state.nav.menu, ...updates } },
        })),
    },
    offset: { menu: 0, tab: 0 },
    setIsEventsHovered: isHovered =>
      set(state => ({ nav: { ...state.nav, isEventsHovered: isHovered } })),
    setOffset: offset => set(state => ({ nav: { ...state.nav, offset } })),
  },
  search: {
    isActive: false,
    toggleActive: passedState =>
      set(state => ({
        search: {
          ...state.search,
          isActive:
            passedState !== undefined ? passedState : !state.search.isActive,
        },
      })),
  },
  topBanner: {
    isActive: true,
    toggleActive: passedState =>
      set(state => ({
        topBanner: {
          ...state.topBanner,
          isActive:
            passedState !== undefined ? passedState : !state.topBanner.isActive,
        },
      })),
  },
}));
