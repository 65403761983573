import React, { useEffect, useRef, useState } from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import { useIntl } from "react-intl";
import { useSwipeable } from "react-swipeable";
import smoothscroll from "smoothscroll-polyfill";

import { Container } from "@components/Container";
import { useRouter } from "@hooks/useRouter";

import { ReactComponent as ArrowLeftIcon } from "assets/icons/arrow-left.svg";
import { ReactComponent as ArrowRightIcon } from "assets/icons/arrow-right.svg";

import { messages } from "./messages";
import * as S from "./styles";

export interface SliderProps {
  arrows?: boolean;
  children?: React.ReactNode[];
  className?: string;
  horizontalScroll?: boolean;
  light?: boolean;
  maxWidth?: number;
  scrollDistance?: number;
  variant?: string;
}

export const Slider = ({
  children,
  scrollDistance = 1220,
  horizontalScroll = true,
  light = false,
  arrows = true,
  className,
  maxWidth = 150,
  variant,
}: SliderProps) => {
  const { asPath } = useRouter();
  const { formatMessage } = useIntl();
  const handlers = useSwipeable({ trackMouse: true });
  const scrollRef = useRef<HTMLHeadingElement | null>(null);
  const productsWrapperRef = useRef<HTMLDivElement | null>(null);
  const [offset, setOffset] = useState(0);
  const [offsetLeft, setOffsetLeft] = useState(0);
  const [showArrows, setShowArrows] = useState({ left: false, right: false });

  const scroll = (scrollOffset: number) => {
    const currentOffset = productsWrapperRef.current!.scrollLeft;
    productsWrapperRef?.current?.scroll?.({
      behavior: "smooth",
      left: currentOffset - scrollOffset,
    });
  };

  const handleResize = () => {
    if (scrollRef.current) {
      const sliderPadding = scrollRef.current!.offsetLeft! * 2;
      const containerWidth =
        productsWrapperRef.current!.offsetWidth! - sliderPadding;
      const currentScroll = productsWrapperRef.current!.scrollLeft!;

      setShowArrows({
        left: offsetLeft > 0,
        right: containerWidth > currentScroll,
      });
      setOffset(scrollRef.current!.offsetLeft!);
    }
  };

  const handleScroll = () => {
    const containerWidth =
      productsWrapperRef.current!.scrollWidth -
        productsWrapperRef.current!.clientWidth || 1;
    const currentScroll = productsWrapperRef.current!.scrollLeft!;

    setOffsetLeft(Math.floor((currentScroll / containerWidth) * 100));
    setShowArrows({
      left: offsetLeft > 0,
      right: containerWidth > currentScroll,
    });
  };

  useEffect(() => {
    productsWrapperRef?.current?.scroll?.({
      behavior: "smooth",
      left: 0,
    });
  }, [asPath]);

  useEffect(() => {
    smoothscroll.polyfill();
    handleResize();
    window.addEventListener("resize", handleResize, {});

    return () => {
      window.removeEventListener("resize", handleResize, {});
    };
  }, []);
  return (
    <S.CollectionSliderWrapper className={className}>
      <Container disableGutters>
        <div ref={scrollRef}>
          {horizontalScroll && (
            <S.Slider
              readOnly
              active={!!offsetLeft}
              aria-label={formatMessage(messages.slider)}
              light={light}
              max={100}
              min={0}
              type="range"
              value={offsetLeft}
              variant={variant}
            />
          )}
        </div>
      </Container>
      <S.ProductsWrapper>
        {arrows && (
          <S.Arrows offset={offset}>
            <S.Arrow
              aria-label={formatMessage(messages.leftArrowMessage)}
              onClick={() => scroll(scrollDistance)}
              showArrow={showArrows.left}
            >
              <ArrowLeftIcon height={15} />
            </S.Arrow>
            <S.Arrow
              aria-label={formatMessage(messages.rightArrowMessage)}
              onClick={() => scroll(-scrollDistance)}
              showArrow={showArrows.right}
            >
              <ArrowRightIcon height={15} />
            </S.Arrow>
          </S.Arrows>
        )}
        <ScrollContainer
          innerRef={productsWrapperRef}
          onScroll={handleScroll}
          vertical={false}
        >
          <S.Products
            maxWidth={maxWidth}
            {...handlers}
            offset={offset}
            productsNumber={(children && children?.length) || 0}
          >
            {children}
          </S.Products>
        </ScrollContainer>
      </S.ProductsWrapper>
    </S.CollectionSliderWrapper>
  );
};

Slider.displayName = "Slider";
