import Image from "next/image";
import React from "react";

import { Toast } from "@components/Toast/types";
import { LikeDataProps } from "@hooks/useUpdateUserLike";

import * as S from "./styles";
import { IMAGE_SIZE } from "./styles";

export type LikeNotificationProps = {
  data: LikeDataProps;
};

export const LikeNotification = ({
  props: {
    data: { name, image, type },
  },
}: Toast<LikeNotificationProps>) => (
  <S.Wrapper>
    <S.Thumbnail isRounded={type === "artist"}>
      {image && (
        <Image
          alt={image || name}
          height={IMAGE_SIZE}
          layout="fixed"
          objectFit="cover"
          objectPosition="center"
          quality={100}
          src={image}
          width={IMAGE_SIZE}
        />
      )}
    </S.Thumbnail>
    <S.Container>
      <S.Info>
        <S.Name>{name}</S.Name>
        <S.Artist>{type}</S.Artist>
      </S.Info>
    </S.Container>
  </S.Wrapper>
);
