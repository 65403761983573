import { SearchResponse } from "@algolia/client-search";
import { Collection } from "@gqlTypes/Collection";
import { Product } from "@gqlTypes/Product";
import { ImageProps } from "next/image";

import {
  ReleaseDateParams,
  SearchableNames,
  SortByParams,
  ToggleAttributes,
  ToggleURLParams,
  URLFilterMapping,
} from "@components/ProductFilters/constants";
import {
  ListingParam,
  RangeParams,
  SortByFilterValue,
  SortByParam,
  ToggleAttribute,
  ToggleParams,
} from "@components/ProductFilters/types";
import { buildFiltersFromUrl } from "@components/ProductFilters/utils";
import { pageQueryParam, searchQueryParam } from "@config";
import { Locale } from "@providers/TranslationProvider";
import { getTimestamps } from "@utils/time";

import {
  ALGOLIA_PRODUCT_SEARCH_INDEXES,
  algoliaClient,
  recommendClient,
} from "./client";
import {
  AlgoliaCustomQuery,
  AlgoliaQuery,
  AlgoliaResult,
  PublishedItem,
  ReleaseDateItem,
  SearchState,
  Thumbnails,
  ThumbnailsEnum,
} from "./types";

import FallbackImage from "assets/images/fallback-image.jpg";

const timestamps = getTimestamps();

export const onlyPublishedFilter = () => `publishedAt<${timestamps.today}`;
export const onlyCurrentEventsFilter = () => `dateTime>${timestamps.today}`;

export const getSortByItems = (
  locale: Locale
): Array<{ label: SortByFilterValue; value: string }> => {
  const indexName = ALGOLIA_PRODUCT_SEARCH_INDEXES[locale as Locale] as string;

  return [
    { label: "most-relevant", value: indexName },
    { label: "most-popular", value: `${indexName}_popularity_desc` },
    { label: "newest-listed", value: `${indexName}_newest_listed` },
    { label: "newest-released", value: `${indexName}_releaseDates_desc` },
    { label: "oldest-released", value: `${indexName}_releaseDates_asc` },
    { label: "alpha-asc", value: `${indexName}_name_asc` },
    { label: "alpha-desc", value: `${indexName}_name_desc` },
  ];
};

export const getReleaseDates = (): {
  defaultRefinement: string;
  items: ReleaseDateItem[];
} => {
  const defaultRefinement = "0:";
  const items: ReleaseDateItem[] = [
    { label: "anytime", start: 0 },
    {
      label: "upcoming",
      start: timestamps.today,
    },
    {
      end: timestamps.weekEnd,
      label: "new-this-week",
      start: timestamps.weekStart,
    },
    {
      end: timestamps.today,
      label: "new-this-month",
      start: timestamps.monthStart,
    },
    {
      end: timestamps.today,
      label: "new-this-year",
      start: timestamps.yearStart + 1,
    },
  ];

  return { defaultRefinement, items };
};

export const getPublishedAt = (): {
  defaultRefinement: string;
  items: PublishedItem[];
} => {
  const items: PublishedItem[] = [
    { label: "all", start: 0 },
    {
      end: timestamps.today,
      label: "only-published",
      start: 0,
    },
    {
      label: "only-unpublished",
      start: timestamps.today,
    },
  ];
  const defaultRefinement = `0:${timestamps.today}`;

  return { defaultRefinement, items };
};

const getRecommendations = async (
  locale: Locale,
  facetFilters: string[],
  numberOfResults: number,
  productID: string
): Promise<AlgoliaResult[]> => {
  const { results } = await recommendClient.getRecommendations<AlgoliaResult>([
    {
      fallbackParameters: {
        facetFilters,
      },
      indexName: ALGOLIA_PRODUCT_SEARCH_INDEXES[locale],
      maxRecommendations: numberOfResults,
      model: "related-products",
      objectID: productID,
      queryParameters: {
        advancedSyntax: true,
        attributesToRetrieve: ["*"],
        filters: onlyPublishedFilter(),
      },
    },
  ]);

  return results[0].hits;
};

/** Prevents empty queries being sent to Algolia in batch requests but also maintains the order of the data returned is the same as the data input */
export const batchRequest = async (queries: AlgoliaQuery[]) => {
  const emptyQueries: number[] = [];
  const validQueries = queries.filter((item, i) => {
    if (item?.query !== "" || item?.params?.filters !== "") {
      return item;
    }
    emptyQueries.push(i);
  });

  const { results } = (await algoliaClient.multipleQueries(validQueries)) || {};

  if (emptyQueries.length) {
    emptyQueries.forEach(val => (results as any).splice(val, 0, { hits: [] }));
  }

  return (results as unknown as SearchResponse[])?.map(
    ({ hits }) => hits
  ) as AlgoliaResult[][];
};

export const getAlgoliaGroupedResults = async (
  urls: string[],
  locale: Locale,
  hitsPerPage: number,
  userToken?: string
): Promise<AlgoliaResult[][]> => {
  const queries = await Promise.all(
    urls.map(async url => {
      const { filters, query, sortByIndex } = await buildAlgoliaQueryFromUrl(
        url,
        locale
      );

      return {
        facet: "",
        indexName: sortByIndex,
        params: {
          analyticsTags: ["BATCH_SSR"],
          clickAnalytics: true,
          enablePersonalization: !!userToken,
          filters,
          hitsPerPage,
          numericFilters: [onlyPublishedFilter()],
          userToken,
        },
        query,
      };
    })
  );

  return batchRequest(queries);
};

export const buildAlgoliaQueryFromUrl = async (
  url: string,
  locale: Locale
): Promise<AlgoliaCustomQuery> => {
  try {
    const parsedUrl = new URL(url);
    const searchParams = new URLSearchParams(parsedUrl.search);
    const query = searchParams.get("q") ?? "";
    const indexName = ALGOLIA_PRODUCT_SEARCH_INDEXES[
      locale as Locale
    ] as string;
    const parsedFilters = buildFiltersFromUrl(url);
    const filtersArray: string[] = [];
    const { items } = getReleaseDates();
    const sortByItems = getSortByItems(locale);
    const sortByIndex =
      sortByItems.find(({ label }) => label === parsedFilters.sortBy)?.value ??
      indexName;

    Object.entries(parsedFilters).forEach(([key, value]) => {
      if (value !== "" && value !== false && key !== "sortBy") {
        const filter = `${key}: "${value}"`;

        if (key === "released") {
          const selectedPeriod =
            items.find(item => item.label === value) ?? items[0];

          if (selectedPeriod?.start) {
            filtersArray.push(`releaseDates>${selectedPeriod.start}`);
          }

          if (selectedPeriod?.end) {
            filtersArray.push(`releaseDates<${selectedPeriod.end}`);
          }

          return;
        }

        if (filter !== "") {
          filtersArray.push(filter);
        }
      }
    });

    const filters = filtersArray.join(" AND ");

    return {
      filters,
      query,
      sortByIndex,
    };
  } catch (error) {
    return {
      filters: "",
      query: "",
      sortByIndex: "",
    };
  }
};

export const getProductRecommendations = async (
  productID: Product["id"],
  productGenres: string[],
  channel: Locale,
  numberOfResults: number = 20
): Promise<AlgoliaResult[] | null> => {
  const recommendations: AlgoliaResult[] = await getRecommendations(
    channel,
    productGenres,
    numberOfResults,
    productID
  );

  if (recommendations) {
    return recommendations;
  }

  return null;
};

const buildSearchStateFromURL = (path: string, locale: Locale): SearchState => {
  const menu: SearchState["menu"] = {};
  const toggle: SearchState["toggle"] = {};

  let sortBy = ALGOLIA_PRODUCT_SEARCH_INDEXES[locale];
  const filters = buildFiltersFromUrl(path);

  const page = Number(filters[pageQueryParam] ?? 1);
  const query = filters[searchQueryParam] ?? "";

  SearchableNames.forEach(attribute => {
    if (filters[attribute]) {
      menu[attribute] = filters[attribute];
    }
  });

  ToggleAttributes.forEach(attribute => {
    if (filters[attribute]) {
      toggle[attribute] = filters[attribute];
    }
  });

  const selectedSortBy = filters.sortBy;
  if (selectedSortBy) {
    const items = getSortByItems(locale);
    sortBy = items.find(item => item.label === selectedSortBy)!.value;
  }

  const { items } = getPublishedAt();
  const publishedAtRange = items.find(
    ({ label }) => label === "only-published"
  )!;

  return {
    menu,
    multiRange: {
      publishedAt: `${publishedAtRange.start}:${publishedAtRange.end}`,
      releaseDates: "0:",
    },
    page,
    query,
    sortBy,
    toggle,
  };
};

export const searchStateFromURL = (
  path: string,
  locale: Locale,
  extraState?: Partial<SearchState>,
  collection?: Collection
): SearchState => {
  // Handle search view (that is using query parameters)
  if (path.startsWith("/search")) {
    return buildSearchStateFromURL(path, locale);
  }

  const url = new URL(`https://roughtrade.com${path}`);

  const query = url.searchParams.get(searchQueryParam) ?? "";
  const page = Number(url.searchParams.get(pageQueryParam) ?? 1);
  const urlParts = url.pathname.split("/") as ListingParam[];

  const menu: SearchState["menu"] = {};
  const toggle: SearchState["toggle"] = {};

  const artistSlug = urlParts[urlParts.indexOf("artist") + 1];
  if (artistSlug) {
    menu["artists.slug"] = artistSlug;
  }

  if (collection) {
    menu["collections.name"] = collection.name;
  }

  const genreSlug = urlParts[urlParts.indexOf("genre") + 1];
  if (genreSlug) {
    menu["genres.slug"] = genreSlug;
  }

  const labelSlug = urlParts[urlParts.indexOf("label") + 1];
  if (labelSlug) {
    menu["labels.slug"] = labelSlug;
  }

  const formatSlug = urlParts[urlParts.indexOf("format") + 1];
  if (formatSlug) {
    menu["formats.slug"] = formatSlug;
  }

  const productTypeSlug = urlParts[urlParts.indexOf("product-type") + 1];
  if (productTypeSlug) {
    menu["productType.slug"] = productTypeSlug;
  }

  urlParts.forEach(param => {
    if (ToggleURLParams.includes(param as ToggleParams)) {
      toggle[URLFilterMapping[param] as ToggleAttribute] = true;
    }
  });

  const { items } = getPublishedAt();
  const publishedAtRange = items.find(
    ({ label }) => label === "only-published"
  )!;

  let sortBy = ALGOLIA_PRODUCT_SEARCH_INDEXES[locale];
  const selectedSortBy = urlParts.find(param =>
    SortByParams.includes(param as SortByParam)
  );
  if (selectedSortBy) {
    const items = getSortByItems(locale);
    const label = URLFilterMapping[selectedSortBy as ListingParam];

    sortBy = items.find(item => item.label === label)!.value;
  }

  let releaseDates = "0:";
  const selectedReleasePeriod = urlParts.find(param =>
    ReleaseDateParams.includes(param as RangeParams)
  );
  if (selectedReleasePeriod) {
    const { items } = getReleaseDates();
    const label = URLFilterMapping[selectedReleasePeriod as ListingParam];

    const selectedPeriod = items.find(item => item.label === label)!;

    releaseDates = `${selectedPeriod.start}:${selectedPeriod.end ?? ""}`;
  }

  return {
    menu: {
      ...menu,
      ...extraState?.menu,
    },
    multiRange: {
      publishedAt: `${publishedAtRange.start}:${publishedAtRange.end}`,
      releaseDates,
      ...extraState?.multiRange,
    },
    page,
    query,
    sortBy,
    toggle: {
      ...toggle,
      ...extraState?.toggle,
    },
  };
};

export const getDefaultThumbnail = (thumbnails: Thumbnails) =>
  thumbnails[ThumbnailsEnum.DEFAULT] ||
  thumbnails[ThumbnailsEnum.FALLBACK] ||
  FallbackImage;

export type GetProductImageProps = {
  data: {
    creators?: string;
    name?: string;
    src?: string | null;
  };
  placeholder?: ImageProps["placeholder"];
  thumbnails?: Thumbnails;
  type?: "artist" | "product";
};

export const getProductImage = ({
  thumbnails,
  type = "product",
  placeholder: passedPlaceholder = "empty",
  data = {
    creators: "",
    name: "",
  },
}: GetProductImageProps) => {
  const placeholder = passedPlaceholder as ImageProps["placeholder"];

  if (type === "artist") {
    const src = data?.src || FallbackImage;
    const alt = `Artist(s) biography photo of ${data.creators}`;
    const title = `${data.creators} - bio photo`;
    return {
      alt,
      placeholder,
      src,
      title,
    };
  }
  const src = thumbnails
    ? getDefaultThumbnail(thumbnails)
    : data?.src || FallbackImage;
  const alt = `Album artwork for ${data.name} by ${data.creators}`;
  const title = `${data!.creators} - ${data.name}`;
  return {
    alt,
    placeholder,
    src,
    title,
  };
};
