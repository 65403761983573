import React, { RefObject, useEffect, useRef } from "react";

import { useCheckoutContext } from "@hooks/providers";
import { useToggle } from "@hooks/useToggle";
import { useGlobalConfigStore } from "@stores/useGlobalConfigStore";
import { useHeaderStore } from "@stores/useHeaderStore";

import { ActiveCart, EmptyCart } from "./components";

import * as S from "./styles";

type BasketProps = {
  isActive: boolean;
};

export type HandleCloseType = {
  preventDefault: () => void;
  stopPropagation: () => void;
};

export const BasketModal = ({ isActive }: BasketProps) => {
  const modalRef: RefObject<HTMLDivElement> = useRef<HTMLDivElement | null>(
    null
  );
  const { basket: basketDoc } = useGlobalConfigStore();
  const { data: basketData, collections } = basketDoc || {};
  const { activeCollection, emptyCollection } = collections || {};

  const {
    empty_basket_text,
    active_collection_header,
    empty_collection_header,
  } = basketData || {};

  const { processing, checkout, isCartEmpty } = useCheckoutContext();
  const [initializing, toggleInitializing] = useToggle(processing);
  const { basket, topBanner } = useHeaderStore();

  const handleClose = (e: HandleCloseType) => {
    e.stopPropagation();
    e.preventDefault();
    basket.toggleActive(false);
  };

  useEffect(() => {
    if (initializing && !processing && checkout) {
      toggleInitializing();
    }
  }, [processing, checkout]);

  const handleFocusOut = (event: any) => {
    if (modalRef?.current) {
      if (!modalRef?.current.contains(event.relatedTarget)) {
        handleClose(event);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("focusout", handleFocusOut);
    return () => {
      document.removeEventListener("focusout", handleFocusOut);
    };
  }, []);

  const activeCollectionData = activeCollection?.length
    ? activeCollection
    : emptyCollection?.length
    ? emptyCollection
    : null;

  const emptyCollectionData = emptyCollection?.length
    ? emptyCollection
    : activeCollection?.length
    ? activeCollection
    : null;

  return (
    <S.BasketModalWrapper
      isActive={isActive}
      isBannerVisible={topBanner.isActive}
      tabIndex={-1}
    >
      <S.Wrapper isActive={isActive} isBannerVisible={topBanner.isActive}>
        {!isCartEmpty && !initializing ? (
          <ActiveCart
            collection={activeCollectionData}
            collectionHeader={
              active_collection_header ?? active_collection_header ?? ""
            }
            handleClose={handleClose}
          />
        ) : (
          <EmptyCart
            basketText={empty_basket_text}
            collection={emptyCollectionData}
            collectionHeader={
              empty_collection_header ?? empty_collection_header ?? ""
            }
            handleClose={handleClose}
          />
        )}
      </S.Wrapper>
      <S.BackgroundClose
        isActive={isActive}
        isBannerVisible={topBanner.isActive}
      />
    </S.BasketModalWrapper>
  );
};
