import { defineMessages } from "react-intl";

export const playerMessages = defineMessages({
  closePlayer: {
    defaultMessage: "Close player",
    id: "jRigz3",
  },
  closePlaylist: {
    defaultMessage: "Close playlist",
    id: "lGEUWu",
  },
  default: {
    defaultMessage:
      "Something went wrong while playing the album. Please {refreshLink} the page and try again.",
    id: "nW842D",
  },
  nextTrack: {
    defaultMessage: "Next track",
    id: "1iX+o8",
  },
  openPlaylist: {
    defaultMessage: "Open playlist",
    id: "SndLn8",
  },
  pause: {
    defaultMessage: "Pause",
    id: "tFFMkF",
  },
  play: {
    defaultMessage: "Play",
    id: "J3ca41",
  },
  prevTrack: {
    defaultMessage: "Previous track",
    id: "PyAUyD",
  },
  refresh: {
    defaultMessage: "refresh",
    id: "FcwBEE",
  },
  spotifyError404: {
    defaultMessage:
      "Something went wrong while playing the album. The device was not found. Please {refreshLink} the page and try again.",
    id: "W89kGR",
  },
});
