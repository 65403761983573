import React from "react";
import { FormattedMessage } from "react-intl";

export const Quantity = (quantity: number) => (
  <FormattedMessage
    defaultMessage="Quantity: {quantity}"
    id="xD3Bn3"
    values={{ quantity }}
  />
);
