import { defineMessages } from "react-intl";

export const validationMessages = defineMessages({
  consent: {
    defaultMessage: "Please confirm.",
    id: "Ecls+L",
  },
  invalidEmailFormat: {
    defaultMessage: "Invalid email format.",
    id: "05q+7T",
  },
  maxChars: {
    defaultMessage: "{max} characters maximum.",
    id: "SE96Qn",
  },
  passwordLength: {
    defaultMessage: "Password must be at least 8 characters long.",
    id: "KQ8XIA",
  },
  passwordsMustMatch: {
    defaultMessage: "Password must match.",
    id: "wjA06E",
  },
  required: {
    defaultMessage: "This field is required.",
    id: "6B5Jtu",
  },
});

export const schemaMessages = defineMessages<string>({
  companyName: {
    defaultMessage: "Company, C/O, Apt Suite Unit",
    id: "5kVU9i",
  },
  confirmPassword: {
    defaultMessage: "Confirm Password",
    id: "vfG+nh",
  },
  country: {
    defaultMessage: "Country",
    id: "vONi+O",
  },
  countryArea: {
    defaultMessage: "Country Region",
    id: "jh7VIc",
  },
  email: {
    defaultMessage: "Email address",
    id: "hJZwTS",
  },
  firstName: {
    defaultMessage: "First name",
    id: "pONqz8",
  },
  lastName: {
    defaultMessage: "Last name",
    id: "txUL0F",
  },
  newPassword: {
    defaultMessage: "New Password",
    id: "Ev6SEF",
  },
  password: {
    defaultMessage: "Password",
    id: "5sg7KC",
  },
  phone: {
    defaultMessage: "Phone number",
    id: "jdJhOL",
  },
  postcode: {
    defaultMessage: "Postcode",
    id: "FJhjgz",
  },
  street: {
    defaultMessage: "Street",
    id: "BaIwdV",
  },
  townCity: {
    defaultMessage: "Town/City",
    id: "byaTQZ",
  },
  zipcode: {
    defaultMessage: "Zip Code",
    id: "Solm6E",
  },
});
