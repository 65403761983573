import { useMediaQuery } from "react-responsive";

import { Breakpoints, lg, md, retina, sm, xl, xs, xxl } from "@styles";
import { Object } from "@utils";

export const useIsXsUp = () => useMediaQuery({ minWidth: xs });

export const useIsMdUp = () => useMediaQuery({ minWidth: md });
export const useIsMdDown = () => useMediaQuery({ maxWidth: md - 1 });

export const useIsSmUp = () => useMediaQuery({ minWidth: sm });
export const useIsSmDown = () => useMediaQuery({ maxWidth: sm - 1 });

export const useIsLgUp = () => useMediaQuery({ minWidth: lg });
export const useIsLgDown = () => useMediaQuery({ maxWidth: lg - 1 });

export const useIsXlUp = () => useMediaQuery({ minWidth: xl });
export const useIsXlDown = () => useMediaQuery({ maxWidth: xl - 1 });

export const useIsXxlUp = () => useMediaQuery({ minWidth: xxl });
export const useIsXxlDown = () => useMediaQuery({ maxWidth: xxl - 1 });

export const useIsRetinaDown = () => useMediaQuery({ maxWidth: retina - 1 });

type ResponsiveHook = Object<() => boolean>;

const fromMapping: ResponsiveHook = {
  lg: useIsLgUp,
  md: useIsMdUp,
  sm: useIsSmUp,
  xl: useIsXlUp,
  xs: useIsXsUp,
  xxl: useIsXxlUp,
};

const toMapping: ResponsiveHook = {
  lg: useIsLgDown,
  md: useIsMdDown,
  retina: useIsRetinaDown,
  sm: useIsSmDown,
  xl: useIsXlDown,
  xxl: useIsXxlDown,
};

export const useIsBetween = (from: Breakpoints, to: Breakpoints) => {
  const isUp = fromMapping[from]();
  const isDown = toMapping[to]();
  return isUp && isDown;
};
