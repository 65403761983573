import React from "react";

import * as M from "./messages";
import * as S from "./styles";

export interface ItemQuantityProps {
  quantity: number;
}

export const ItemQuantity = ({ quantity }: ItemQuantityProps) => (
  <S.Quantity>{M.Quantity(quantity)}</S.Quantity>
);
