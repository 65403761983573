import { AttributeValue } from "@gqlTypes/AttributeValue";
import { CheckoutLine } from "@gqlTypes/CheckoutLine";
import { ProductVariant } from "@gqlTypes/ProductVariant";
import { paths } from "@paths";
import Image from "next/image";
import React from "react";
import { generatePath } from "react-router";

import { ItemQuantity } from "@components/ItemQuantity";
import { PreOrderBadge } from "@components/PreOrderBadge";
import { useCheckoutContext } from "@hooks/providers";
import { UseListActions } from "@hooks/useListActions";
import {
  getArtists,
  getFormatNote,
  getVariantSlugWithSuffix,
  populateArtistsSlug,
} from "@utils/attributes";
import {
  hasVariantSufficientStock,
  isInPreorder as _isInPreorder,
} from "@utils/products";

import { FormattedPrice } from "../FormattedPrice";
import { QuantityControls } from "./components";

import FallbackImage from "assets/images/fallback-image.jpg";

import * as S from "./styles";

export interface CartLineProps {
  border?: boolean;
  line: CheckoutLine;
  listActions?: Pick<UseListActions<string>, "add" | "remove" | "isSelected">;
  pricing?: boolean;
  quantityControls?: boolean;
  thumbnailSize?: number;
  variant?: "light" | "dark";
}

export const CartLine = ({
  quantityControls,
  thumbnailSize = 54,
  line: {
    quantity,
    variant: {
      pricing: variantPricing,
      id,
      quantityAvailable,
      quantityLimitPerCustomer,
      attributes: variantAttributes,
      media: variantImage,
      product: {
        attributes: productAttributes,
        productType,
        name,
        media,
        slug: productSlug,
        variants,
      },
    },
  },
  variant = "light",
  pricing = true,
  listActions,
  border = true,
}: CartLineProps) => {
  const { lineAdd, lineRemove, checkout } = useCheckoutContext();

  const { add, remove, isSelected } = listActions || {};
  const formatNote = getFormatNote(productType, variantAttributes);
  const artists = getArtists(productAttributes, true) as AttributeValue[];
  const isInPreorder = _isInPreorder(variantAttributes);
  const processing = !!isSelected?.(id);
  const variantIndex = variants?.findIndex(variant => variant?.id === id);
  const image = variantImage?.[0] || media?.[0];

  const artist = populateArtistsSlug({ attributes: productAttributes });
  const variantSlug = variantIndex
    ? getVariantSlugWithSuffix(
        variantIndex,
        productType,
        variants as ProductVariant[]
      ).slug
    : null;
  const href = `${generatePath(paths.product, { artist, productSlug })}${
    variantSlug ? `#${variantSlug}` : ""
  }`;

  const limit =
    quantityLimitPerCustomer &&
    quantityAvailable &&
    quantityLimitPerCustomer < quantityAvailable
      ? quantityLimitPerCustomer
      : quantityAvailable;

  const handleAdd = async () => {
    add?.(id);
    await lineAdd(id);
    remove?.(id);
  };

  const handleRemove = async () => {
    add?.(id);
    await lineRemove(id);
    remove?.(id);
  };

  return (
    <S.Wrapper $noCover={!image?.src} border={border} processing={processing}>
      <S.Thumbnail wrapperTag href={href} thumbnailSize={thumbnailSize}>
        <Image
          alt={image?.alt || name}
          height={50}
          layout="fixed"
          objectFit="contain"
          src={image?.src ?? FallbackImage}
          width={50}
        />
      </S.Thumbnail>
      <S.Container>
        <S.Info variant={variant}>
          <S.Name wrapperTag href={href} variant={variant}>
            {name}
          </S.Name>
          {artists.map(({ name, slug }) => (
            <S.Artist
              wrapperTag
              generatePath={{ path: paths.searchArtist, slug: slug! }}
              key={slug}
              variant={variant}
            >
              {name}
            </S.Artist>
          ))}
          <p>{formatNote}</p>
          {!quantityControls && <ItemQuantity quantity={quantity} />}
        </S.Info>
        <S.ActionWrapper>
          {quantityControls && quantityAvailable && (
            <QuantityControls
              canAddMore={hasVariantSufficientStock(
                id,
                limit ?? 0,
                checkout!.lines
              )}
              onAdd={handleAdd}
              onRemove={handleRemove}
              processing={processing}
              quantity={quantity}
            />
          )}
          {pricing && (
            <S.Price>
              <FormattedPrice
                price={variantPricing!.price!.gross}
                salePrice={variantPricing!.priceUndiscounted!.gross}
                tag="a"
                variant="cart"
              />
            </S.Price>
          )}
        </S.ActionWrapper>
        {isInPreorder && <PreOrderBadge />}
      </S.Container>
    </S.Wrapper>
  );
};
