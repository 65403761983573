import { css, keyframes } from "styled-components";

import { media, px2rem, styled, theme } from "@styles";

import { ModalVariant } from "./Modal";

const overlayPadding = 32;
const noOverlayPadding = 21;

const animateModalIn = keyframes`
	0% { transform: translateX(-200%); }
	100% { transform: translateX(0)}
`;

export const ContentWrapper = styled.div`
  overflow: auto;
`;

const variantStyles = ({ variant }: { variant: ModalVariant }) => {
  switch (variant) {
    case "club":
      return css`
        h3 {
          font-family: ${({ theme }) => theme.typography.condensedFontFamily};
          font-size: ${px2rem(30)};
          text-align: center;
          text-transform: uppercase;
        }

        max-width: 100vw;
        padding: 0;
        background-color: ${({ theme }) => theme.colors.yellow};
      `;

    case "authForm":
      return css`
        background-color: transparent;
        /* background-color: blue; */
      `;

    case "small-left":
      return css`
        h3 {
          font-family: ${({ theme }) => theme.typography.condensedFontFamily};
          font-size: ${px2rem(30)};
          text-align: center;
          text-transform: uppercase;
        }

        background-color: ${({ theme }) => theme.colors.white};

        ${media.md.up} {
          padding: 20px;
        }
      `;

    default:
      return css`
        h3 {
          font-family: ${({ theme }) => theme.typography.condensedFontFamily};
          font-size: ${px2rem(30)};
          text-align: center;
          text-transform: uppercase;
        }

        padding: 0 15px;
        background-color: ${({ theme }) => theme.colors.white};

        ${media.md.up} {
          padding: ${px2rem(0, 90)};
        }
      `;
  }
};

export const Content = styled.div<{
  allowOverflow: boolean;
  variant: ModalVariant;
  withLogo: boolean;
}>`
  position: relative;
  ${({ withLogo }) => !withLogo && "padding-top: 15px;"}

  ${variantStyles}


  ${media.md.up} {
    overflow: ${({ allowOverflow }) => (allowOverflow ? "visible" : "auto")};
    max-height: 90vh;
  }
`;

export const ModalBody = styled.div`
  position: fixed;
  max-height: 350px;
  width: 100%;
  height: 100%;
  top: ${`100dvh`};
  left: 0;
  z-index: 999;

  ${media.md.up} {
    max-width: 400px;
  }
`;

export const ModalInner = styled.div`
  position: absolute;
  bottom: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  gap: 20px;
  width: calc(100% - 20px);
  margin: 0 0 78px 10px;
  transform: translateX(-200%);
  animation: 1s ${animateModalIn} 400ms ease-in forwards;
  background: ${({ theme }) => theme.colors.white};
  border: solid 1px ${({ theme }) => theme.colors.black};
  padding: 30px 20px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  ${media.md.up} {
    width: 100%;
    margin: 0 0 10px 10px;
  }
`;

export const Overlay = styled.div<{
  opacity?: number;
  open: boolean;
  variant: ModalVariant;
  withFooter: boolean;
}>`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: auto;
  background-color: ${({ theme, variant }) =>
    variant === "club" ? theme.colors.yellow : theme.colors.white};

  display: ${({ open }) => (open ? "grid" : "none")};
  z-index: 999;
  justify-content: ${({ withFooter }) => (withFooter ? "top" : "center")};
  align-items: ${({ withFooter }) => (withFooter ? "top" : "center")};

  ${media.md.up} {
    align-items: center;
    justify-content: center;
    background-color: rgb(
      0 0 0 / ${({ opacity }) => (opacity ? `${opacity}%` : "70%")}
    );
    align-content: center;

    ${({ variant }) =>
      variant === "small-left" &&
      css`
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;

        top: -100%;
        position: absolute;
        z-index: 1000;
      `}
  }
`;

export const CloseButton = styled.button<{ hasOverlay: boolean }>`
  position: absolute;
  top: ${({ hasOverlay }) =>
    hasOverlay ? `${overlayPadding}px` : `${noOverlayPadding}px`};
  right: ${({ hasOverlay }) =>
    hasOverlay ? `${overlayPadding}px` : `${noOverlayPadding}px`};
  display: block;
  width: 18px;
  height: 18px;
  border: 0;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }
`;

export const Footer = styled.div`
  background-color: ${theme.colors.black};
  align-self: flex-end;
  display: flex;
  justify-content: center;
`;

export const LinksWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0;
`;

export const FooterLink = styled.a`
  color: ${theme.colors.white};
  padding: 15px;
  flex: 1 1 50%;
  text-align: center;
  text-decoration: none;
  width: 50%;
`;

export const FooterPlaceholder = styled(FooterLink)``;

export const LogoWrapper = styled.div`
  margin: 50px 0;
`;

export const FooterButton = styled(FooterLink)`
  background-color: ${theme.colors.black};
  font-size: ${theme.typography.baseFontSize};
  border: 0;
  display: flex;
  justify-content: center;

  svg {
    margin-left: -12px;
    margin-right: 8px;
  }
`;
