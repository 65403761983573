export const ANIMATION_SPEED = 350;
export const TOTAL_ANIMATION_DURATION = 3000;
export const SLIDE_OUT_DELAY = TOTAL_ANIMATION_DURATION + ANIMATION_SPEED + 150;

export const SHORT_TOTAL_ANIMATION_DURATION = 2000;
export const SHORT_SLIDE_OUT_DELAY =
  SHORT_TOTAL_ANIMATION_DURATION + ANIMATION_SPEED + 150;

export const shortNotificationAnimation = {
  delay: SHORT_SLIDE_OUT_DELAY,
  speed: ANIMATION_SPEED,
  totalDuration: SHORT_TOTAL_ANIMATION_DURATION,
};

export const defaultAnimation = {
  delay: SLIDE_OUT_DELAY,
  speed: ANIMATION_SPEED,
  totalDuration: TOTAL_ANIMATION_DURATION,
};
