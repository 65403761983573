import { Order_lines_variant } from "@gqlTypes/Order";
import { Product, Product_variants } from "@gqlTypes/Product";
import { initializeApollo } from "@graphql";
import { fetchCollection } from "@ssr/helpers";

import {
  saleorMainWarehouses,
  sellingFastTrigger,
  ssrFetchLimit,
} from "@config";
import { getChannel } from "@providers/ConfigProvider/helpers";
import { Locale } from "@providers/TranslationProvider";
import { CanBeDispatchedMap } from "@views/ProductView/types";

import {
  ProductSlugsQuery,
  ProductSlugsQuery_products_edges_node,
  ProductSlugsQueryVariables,
} from "./gqlTypes/ProductSlugsQuery";
import {
  ProductTypeQuery,
  ProductTypeQueryVariables,
} from "./gqlTypes/ProductTypeQuery";
import { productSlugsQuery, productTypeQuery } from "./queries";

export const fetchAllProductSlugs = async (channel: Locale = "en-us") => {
  const apolloClient = initializeApollo();
  const fetchQuery = await fetchCollection<
    ProductSlugsQuery,
    ProductSlugsQueryVariables
  >(apolloClient, productSlugsQuery);
  const fetchAll = async (
    after: string | null,
    items: ProductSlugsQuery_products_edges_node[]
  ): Promise<ProductSlugsQuery_products_edges_node[]> => {
    const { products } = await fetchQuery({ after, channel });

    items.push(...(products?.edges.map(e => e.node) || []));

    if (items.length > ssrFetchLimit) {
      return items;
    }

    if (products?.pageInfo?.hasNextPage) {
      return fetchAll(products?.pageInfo?.endCursor, items);
    }

    return items;
  };

  return fetchAll(null, []);
};

export const getProductType = async (slug: string, channel: Locale) => {
  const apolloClient = initializeApollo();
  const { data } = await apolloClient.query<
    ProductTypeQuery,
    ProductTypeQueryVariables
  >({ query: productTypeQuery, variables: { channel, slug } });
  return data?.product?.productType;
};

export const checkCanBeDispatchedMap = (
  variants: Product_variants[] | Order_lines_variant[],
  locale: Locale
) => {
  const channel = getChannel(locale);
  const mainWarehouseId = saleorMainWarehouses[channel.toUpperCase()] || null;

  return variants!.reduce((mapping, variant) => {
    const hasStockInOtherThanMainWarehouse = variant?.stocks
      ? variant.stocks!.some(
          ({ quantity, quantityAllocated, warehouse: { id } }) => {
            const isMainWarehouse = id === mainWarehouseId;
            const warehouseHasStock = quantity - quantityAllocated > 0;
            const variantHasStock = variant.quantityAvailable! > 0;

            return variantHasStock && isMainWarehouse && !warehouseHasStock;
          }
        )
      : false;

    mapping[variant.id] = hasStockInOtherThanMainWarehouse;

    return mapping;
  }, {} as CanBeDispatchedMap);
};

export const checkProductSellingFast = (variants: Product_variants[]) =>
  variants!.map(({ id, stocks }) => {
    const { available, allocated } = stocks!.reduce(
      (prev, { quantity, quantityAllocated }) => {
        prev.available += quantity;
        prev.allocated += quantityAllocated;
        return prev;
      },
      { allocated: 0, available: 0 }
    );

    const sellthrough = (allocated / available) * 100;

    return {
      id,
      lowStock: available !== 0 && sellthrough >= sellingFastTrigger,
      noStock: available === 0,
    };
  });

export const checkProductStock = ({ variants }: Product, channel: Locale) => ({
  canBeDispatchedMap: checkCanBeDispatchedMap(variants!, channel),
  stock: checkProductSellingFast(variants!),
});
