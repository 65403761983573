import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";

import { fontFaces } from "./fonts";
import { Theme } from "./theme";

export const GlobalStyle = createGlobalStyle<{ theme: Theme }>`
  ${reset}
  ${fontFaces}

  html {
    background-color: ${({ theme }) => theme.colors.black};
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  body {
    margin: 0;
    min-width: 320px;
    /* stylelint-disable font-family-no-missing-generic-family-keyword */
    font-family: ${({ theme }) => theme.typography.baseFontFamily};
    font-size: ${({ theme }) => theme.typography.baseFontSize};
    font-weight: ${({ theme }) => theme.typography.baseFontWeight};
    background-color: ${({ theme }) => theme.colors.white};

    .club-content{
      h1,h2,h3,h4,h5{
        font-family: ${({ theme }) => theme.typography.clubFontFamily};
        text-transform: uppercase;
      }
      h1{
        font-size: ${({ theme }) => theme.typography.sizes.largest};
      }
    }

  }

  h1 {
    font-size: ${({ theme }) => theme.typography.h1FontSize};
    font-weight: 700;
  }

  h2 {
    font-size: ${({ theme }) => theme.typography.h2FontSize};
    font-weight: 700;
  }

  h3 {
    font-size: ${({ theme }) => theme.typography.h3FontSize};
    font-weight: 700;
  }

  p {
    line-height: ${({ theme }) => theme.typography.baseFontLineHeight};
  }

  hr {
    border: none;
    margin: 0;
    height: 1px;
    background: ${({ theme }) => theme.colors.black};
  }

  input[type="search"]::-webkit-credentials-auto-fill-button {
    visibility: hidden;
  }

  #root {
    display: flex;
    min-height: 100vh;
    flex-direction: column;

    & > div:first-of-type {
      flex: 1;
    }
  }

  #__next {
    display: flex;

    & > div:first-of-type {
      flex: 1;
    }
  }

  img {
    width: 100%;
  }

  .dice_price-container{
    display: none;
  }

  button{
    background : none;
    border: none;
  }

  /* Reset the default focus for all elements */
  a:focus, button:focus, input:focus {
    outline: none;
  }

  /* Apply focus styles only when focus is visible (usually only when using keyboard) */
  a:focus-visible, button:focus-visible {
    outline: 2px solid #0000FF !important;
    outline-offset: 2px;
  }

  input:focus-visible {
    outline: 2px solid ${({ theme }) => theme.colors.black} !important;
    outline-offset: 2px;
  }

  .screenreader-text {
    position: absolute;
    left: -999px;
    width: 1px;
    height: 1px;
    top: auto;
  }
  
  .screenreader-text:focus {
    color: black;
    display: inline-block;
    height: auto;
    width: auto;
    position: static;
    margin: auto;
  }
`;
