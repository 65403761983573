import { TaxedPrice } from "@gqlTypes/TaxedPrice";
import React from "react";
import { FormattedMessage } from "react-intl";

import { FormattedPrice } from "@components/FormattedPrice";
import { useConfigContext } from "@hooks/providers";
import { commonMessages } from "@messages/common";

import * as S from "./styles";

type TotalProps = {
  className?: string;
  price: TaxedPrice;
  showAsterisk?: boolean;
  showNetPrice?: boolean;
  type: "subtotal" | "total" | "shipping";
};

export const Total = ({
  className,
  type,
  price,
  showNetPrice,
  showAsterisk,
}: TotalProps) => {
  const { channel } = useConfigContext();

  return (
    <S.Container className={className} isTotal={type === "total"}>
      <p>
        <FormattedMessage
          {...(type === "subtotal"
            ? commonMessages.subtotal
            : type === "shipping"
            ? commonMessages.shipping
            : commonMessages.total)}
        />
        {showAsterisk && "*"}
      </p>
      <p>
        <FormattedPrice
          price={
            channel === "us" && (type !== "total" || showNetPrice)
              ? price.net
              : price.gross
          }
        />
      </p>
    </S.Container>
  );
};
