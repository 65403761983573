import { navButtonStyling } from "@components/Navigation/mixins";
import { media, styled } from "@styles";

export const ItemLink = styled.a<{ iconFirst?: boolean }>`
  ${navButtonStyling({ fontSize: "small", iconFirst: false })}

  &::before {
    background: transparent;
    border: 1px ${({ theme }) => theme.colors.white} solid;
  }

  &:active,
  &:focus {
    &::before {
      background: ${({ theme }) => theme.colors.white};
    }
  }
  ${media.lg.up} {
    display: none;
  }
`;

export const MobileWrapper = styled.div`
  display: block;
  width: 100%;

  ${media.lg.up} {
    display: none;
  }
`;

export const DesktopWrapper = styled.div`
  display: none;
  width: 100%;

  ${media.lg.up} {
    display: block;
  }
`;
