import { styled } from "@styles";
import { dynamicFullHeight } from "@utils/navigation";

export const ClubFormWrapper = styled.main`
  display: flex;
  flex-flow: row;
  width: 100%;
  max-width: 100%;
  flex: 0 0 ${dynamicFullHeight("desktop", [], 100, "200px")};
  height: 100%;
  margin-top: 100px;
  overflow: hidden;
`;

export const ClubInnerWrapper = styled.div<{ isAnimating: boolean }>`
  display: flex;
  flex-flow: row;
  height: 100%;
  ${({ isAnimating }) =>
    isAnimating && `transition: transform 0.5s ease-in-out;`}
`;
