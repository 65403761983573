import { CmsGlobalReturn } from "src/pages/api/shared-content";
import { create } from "zustand";

type GlobalConfigStore = CmsGlobalReturn & {
  updateGlobalData: (data: CmsGlobalReturn) => void;
};

export const useGlobalConfigStore = create<GlobalConfigStore>()(set => ({
  basket: null,
  checkout: null,
  footer: null,
  locale_switcher: null,
  navigation: null,
  product_page: null,
  search: null,
  top_banner: null,
  updateGlobalData: data => set(state => ({ ...data })),
}));
