import React, { useEffect, useMemo, useRef, useState } from "react";

import * as M from "@components/BasketModal/messages";
import { getBasketProgress } from "@components/BasketModal/utils";
import { ProgressBar } from "@components/OrderSummary/components";
import { useCheckoutContext } from "@hooks/providers";
import { useRouter } from "@hooks/useRouter";
import { getChannel } from "@providers/ConfigProvider/helpers";
import { getCountryByLocale } from "@providers/TranslationProvider";
import { useGlobalConfigStore } from "@stores/useGlobalConfigStore";
import { useHeaderStore } from "@stores/useHeaderStore";
import { formatNumber } from "@utils/payment";

import { Total } from "../Total";

import * as S from "./styles";

interface CartActionsProps {
  children?: React.ReactNode;
}

export const CartActions = ({ children }: CartActionsProps) => {
  const { checkout, isCartEmpty, processing } = useCheckoutContext();
  const { locale } = useRouter();
  const channel = getChannel(locale);
  const isEuChannel = channel === "eu";
  const country = useMemo(() => getCountryByLocale(locale), [locale]);

  const { basket } = useHeaderStore();
  const { basket: basketDoc } = useGlobalConfigStore();
  const { data: basketData } = basketDoc || {};

  const { free_shipping_min_amount } = basketData || {};

  const [progressSetting, setProgressSetting] = useState({
    amountRemaining: formatNumber({
      amount: free_shipping_min_amount!,
      currency: country.currency,
      language: !isEuChannel ? country.language : "de",
    }),
    percent: 0,
  });

  const elRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!elRef?.current?.clientHeight) {
      return;
    }
    basket.setActionHeight(elRef?.current?.clientHeight);
  }, [elRef?.current?.clientHeight, basket.isActive]);

  useEffect(() => {
    if (!processing) {
      const { amountRemaining, percent } = getBasketProgress(
        checkout?.subtotalPrice.gross.amount || 0,
        free_shipping_min_amount!,
        country?.currency,
        country?.language
      );

      setProgressSetting({
        amountRemaining,
        percent,
      });
    }
  }, [checkout, processing, isCartEmpty, country, free_shipping_min_amount]);

  return (
    <S.ActionWrapper ref={elRef}>
      {!isEuChannel && (
        <S.ShippingWrapper>
          <S.FreeShipping isSuccess={progressSetting.percent === 100}>
            {progressSetting.percent === 100
              ? M.FreeShippingQualified
              : M.FreeShippingMessage(progressSetting.amountRemaining)}
          </S.FreeShipping>
          <ProgressBar
            elementHeight="6px"
            percent={progressSetting.percent}
            variant={progressSetting.percent === 100 ? "success" : "basket"}
          />
        </S.ShippingWrapper>
      )}
      <S.MainWrapper>
        {checkout && checkout?.subtotalPrice && (
          <Total price={checkout!.subtotalPrice!} />
        )}

        <S.ButtonWrapper>{children}</S.ButtonWrapper>
      </S.MainWrapper>
    </S.ActionWrapper>
  );
};
