import { GiftCard as TGiftCard } from "@gqlTypes/GiftCard";
import React, { useEffect, useRef, useState } from "react";

import { Grid, GridColumn } from "@components/Grid";
import { Hr } from "@components/Hr";
import { Spacer } from "@components/Spacer";
import { calculateRefChildrenHeight, getTestAttribute } from "@utils";

import * as GM from "../messages";
import * as GS from "../styles";

type GiftCardProps = {
  giftCard: TGiftCard | null;
};

export const GiftCard = ({ giftCard }: GiftCardProps) => {
  const displayCode = giftCard?.displayCode;
  const containerRef = useRef(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    setHeight(displayCode ? calculateRefChildrenHeight(containerRef) : 0);
  }, [displayCode]);

  return (
    <GS.GridCardNotification
      height={height}
      ref={containerRef}
      {...getTestAttribute("giftCard", "notification")}
    >
      <Grid>
        <GridColumn>
          <Spacer height={30} />
        </GridColumn>
        <GridColumn>
          <Hr background="white" />
        </GridColumn>
        <GridColumn>
          <Spacer />
        </GridColumn>
        <GridColumn flex justify="space-between">
          <span>
            {displayCode}
            <br />
            <GS.Text>{GM.GiftCard}</GS.Text>
          </span>
          <GS.Approved>{GM.Approved}</GS.Approved>
        </GridColumn>
      </Grid>
    </GS.GridCardNotification>
  );
};
