import React from "react";

import { ReactComponent as CartIconNav } from "assets/icons/cart.svg";

import * as S from "./styles";

type CartIconProps = {
  quantity: number;
};

export const CartIcon = ({ quantity }: CartIconProps) => (
  <S.IconWrapper>
    <CartIconNav />

    <S.CartIcon orange={!!quantity}>{quantity ?? 0}</S.CartIcon>
  </S.IconWrapper>
);
