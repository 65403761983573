import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { ToastItemProps } from "@components/Toast/types";

import * as S from "./styles";

export const ToastItem = ({
  removeToast,
  Component,
  messages,
  animations,
  id,
  props,
}: ToastItemProps) => {
  const { formatMessage } = useIntl();
  const [hidden, setHidden] = useState(false);

  useEffect(() => {
    const totalDuration =
      animations.delay + animations.speed + 10 - (Date.now() - id);
    // Needed to calculate the remaining time for the toast

    const animationTimeout = setTimeout(() => {
      removeToast();
      setHidden(true);
    }, totalDuration);

    return () => clearTimeout(animationTimeout);
  }, [animations.totalDuration, id]);

  if (!Component || !messages) {
    return null;
  }

  const handleClick = () => {
    if (props?.mobileButton) {
      props.mobileButton();
    }
  };

  const { button, desktop, mobile } = messages;

  return (
    <S.ToastContainer hidden={hidden}>
      <S.ToastInnerWrapper {...animations}>
        <div className="desktop-content">
          <S.Title>{formatMessage(desktop)}</S.Title>
          <S.BarContainer>
            <S.Bar {...animations} />
          </S.BarContainer>
          <Component props={props} />
        </div>
        <div className="mobile-content">
          <S.Title>{formatMessage(mobile)}</S.Title>
          <S.MobileButton onClick={handleClick}>
            {formatMessage(button)}
          </S.MobileButton>
        </div>
      </S.ToastInnerWrapper>
    </S.ToastContainer>
  );
};
