import React, { CSSProperties } from "react";

import * as S from "./styles";

type IContainerProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> &
  S.StyleProps & {
    as?: keyof JSX.IntrinsicElements | React.ComponentType<any>;
    children?: React.ReactNode;
    className?: string;
    style?: CSSProperties;
  };

export const Container = ({
  maxWidth = "xl",
  minHeightFull = false,
  disableGutters = false,
  children,
  background = "default",
  style,
  ...props
}: IContainerProps) => (
  <S.FullWidthWrapper style={style} {...props} background={background}>
    <S.Wrapper
      disableGutters={disableGutters}
      maxWidth={maxWidth}
      minHeightFull={minHeightFull}
      {...props}
    >
      {children}
    </S.Wrapper>
  </S.FullWidthWrapper>
);
