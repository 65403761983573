import {
  DynamicLinkClientName,
  paymentSourceFragmentOrderApp,
  useTypedMutation,
  useTypedQuery,
} from "@graphql";
import gql from "graphql-tag";

import { errorsFragment } from "./fragments.orderApp";
import {
  PaymentMethodCreate,
  PaymentMethodCreateVariables,
} from "./orderAppGqlTypes/PaymentMethodCreate";
import {
  PaymentMethodDetach,
  PaymentMethodDetachVariables,
} from "./orderAppGqlTypes/PaymentMethodDetach";
import {
  PaymentMethodSetDefault,
  PaymentMethodSetDefaultVariables,
} from "./orderAppGqlTypes/PaymentMethodSetDefault";
import {
  UserCardsQuery,
  UserCardsQueryVariables,
} from "./orderAppGqlTypes/UserCardsQuery";

export const userCardsQuery = gql`
  ${paymentSourceFragmentOrderApp}
  query UserCardsQuery($channelSlug: String!) {
    paymentMethods(channelSlug: $channelSlug) {
      ...PaymentMethod
    }
  }
`;

const orderAppClient: { client: DynamicLinkClientName } = {
  client: "orderApp",
};

export const useUserCardsQuery = (
  variables?: UserCardsQueryVariables,
  skip?: boolean
) =>
  useTypedQuery<UserCardsQuery, UserCardsQueryVariables>(userCardsQuery, {
    context: orderAppClient,
    skip,
    variables,
  });

export const paymentMethodCreateMutation = gql`
  ${errorsFragment}
  mutation PaymentMethodCreate(
    $paymentMethodId: String!
    $channelSlug: String!
    $user: User
  ) {
    paymentMethodCreate(
      paymentMethodId: $paymentMethodId
      channelSlug: $channelSlug
      user: $user
    ) {
      secret
      requiresConfirmation
      errors {
        ...Error
      }
    }
  }
`;

export const usePaymentMethodCreate = () =>
  useTypedMutation<PaymentMethodCreate, PaymentMethodCreateVariables>(
    paymentMethodCreateMutation,
    {
      context: orderAppClient,
    }
  );

export const paymentMethodDetachMutation = gql`
  ${errorsFragment}
  mutation PaymentMethodDetach(
    $paymentMethodId: String!
    $channelSlug: String!
  ) {
    paymentMethodDetach(
      paymentMethodId: $paymentMethodId
      channelSlug: $channelSlug
    ) {
      errors {
        ...Error
      }
    }
  }
`;

export const usePaymentMethodDetach = () =>
  useTypedMutation<PaymentMethodDetach, PaymentMethodDetachVariables>(
    paymentMethodDetachMutation,
    {
      context: orderAppClient,
    }
  );

export const paymentMethodSetDefaultMutation = gql`
  ${errorsFragment}
  mutation PaymentMethodSetDefault(
    $paymentMethodId: String!
    $channelSlug: String!
  ) {
    paymentMethodSetDefault(
      paymentMethodId: $paymentMethodId
      channelSlug: $channelSlug
    ) {
      errors {
        ...Error
      }
    }
  }
`;

export const usePaymentMethodSetDefault = () =>
  useTypedMutation<PaymentMethodSetDefault, PaymentMethodSetDefaultVariables>(
    paymentMethodSetDefaultMutation,
    {
      context: orderAppClient,
    }
  );
