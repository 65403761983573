import { Grid } from "@components/Grid";
import { media, noPrint, px2rem, styled } from "@styles";

export type FooterProps = { $dark: boolean };

export const Wrapper = styled(Grid)<FooterProps>`
  font-size: ${px2rem(13)};
  padding: ${px2rem(60, 0, 20)};

  * {
    color: white !important;
  }

  ${({ theme, $dark }) =>
    $dark &&
    `
    color: ${theme.colors.white};
    background-color: ${theme.colors.black};
  `}

  ${noPrint}

  svg {
    height: 16px;
    padding-right: 1rem;
  }

  a {
    text-decoration: none;
    color: inherit;
    padding: 0 ${px2rem(18)};
  }
`;

export const FlexContent = styled.div<{ $fullWidth: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: ${({ $fullWidth }) => ($fullWidth ? "1 0 100%" : "1")};

  ${media.lg.up} {
    flex: 1;
  }
`;

export const P = styled.p<{ withFlag?: boolean }>`
  opacity: 0.7;
  display: flex;
  align-items: center;
  padding: ${px2rem(6)} 0;
  color: inherit;

  ${media.lg.down} {
    justify-content: center;
    text-align: center;

    ${({ withFlag }) => withFlag && `padding-bottom: 1rem;`}
  }
`;
