import {
  pageInfoFragment,
  productFragment,
  selectedAttributeFragment,
} from "@graphql";
import gql from "graphql-tag";

import { MetadataKey } from "@config";

export const productSlugsQuery = gql`
  ${pageInfoFragment}
  ${selectedAttributeFragment}
  query ProductSlugsQuery(
    $channel: String!
    $after: String
    $first: Int = 100,
    $languageCode: LanguageCodeEnum = EN
  ) {
    products(
      first: $first,
      channel: $channel,
      after: $after,
      sortBy: {
        field: RATING,
        direction: DESC
      }
    ) {
      pageInfo {
        ...PageInfo
      }
      edges {
        cursor
        node {
          id
          slug
          name
          productType {
            slug
          }
          attributes {
            ...SelectedAttribute
          }
          variants {
            id
            attributes {
              ...SelectedAttribute
            }
            metafields(keys: [
              "${MetadataKey.VARIANT_RATING}",
              "${MetadataKey.VARIANT_UNPUBLISHED}",
              "${MetadataKey.VARIANT_ARCHIVING}"
            ])
          }
        }
      }
    }
  }
`;

export const productDetailsQuery = gql`
  ${productFragment}
  query ProductDetailsQuery(
    $id: ID
    $slug: String
    $channel: String!
    $languageCode: LanguageCodeEnum = EN
  ) {
    product(id: $id, slug: $slug, channel: $channel) {
      ...Product
    }
  }
`;

export const productTypeQuery = gql`
  query ProductTypeQuery($slug: String!, $channel: String!) {
    product(slug: $slug, channel: $channel) {
      productType {
        slug
      }
    }
  }
`;

export const baseProductDataQuery = gql`
  ${selectedAttributeFragment}
  query ProductBaseDataQuery(
    $id: ID
    $slug: String
    $channel: String!
    $languageCode: LanguageCodeEnum = EN
  ) {
    product(id: $id, slug: $slug, channel: $channel) {
      id
      name
      slug
      channel
      attributes {
        ...SelectedAttribute
      }
    }
  }
`;
