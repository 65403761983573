import { FooterDocumentDataPaymentIconsItem } from "prismicio-types";
import React from "react";

import { PAYMENT_ICONS } from "../../misc";

import * as S from "../../styles";

export interface PaymentIconProps {
  list: FooterDocumentDataPaymentIconsItem[];
}

export const PaymentIcons = ({ list }: PaymentIconProps) => (
  <>
    {list?.map(({ name, is_active_ = false }, index) => {
      const isActive = is_active_ ?? false;
      const selector = PAYMENT_ICONS[name ? name?.toLowerCase() : index];

      if (name && isActive && selector) {
        return <S.PayIcon key={name}>{selector}</S.PayIcon>;
      }
    })}
  </>
);
