import { media, px2rem, styled, theme } from "@styles";

export const FormFlexContainer = styled.div`
  display: flex;
  flex-flow: column;
  align-items: stretch;
  justify-content: center;
  gap: 30px;

  ${media.md.up} {
    gap: 40px;
  }

  ${media.lg.up} {
    align-items: flex-start;
    flex-flow: row wrap;
    gap: 60px;
  }
`;

export const FormContainer = styled.div<{
  $hasHiddenContent: boolean;
  $isActive: boolean;
}>`
  display: grid;
  grid-template-rows: 1fr;
  gap: ${({ $hasHiddenContent, $isActive }) =>
    $hasHiddenContent ? ($isActive ? "inherit" : "0") : "inherit"};
`;

export const CollapsibleContainer = styled.div<{
  isActive: boolean;
}>`
  display: grid;
  grid-template-rows: ${({ isActive }) => (isActive ? "1fr" : "0fr")};
  gap: ${({ isActive }) => (isActive ? "1rem" : "0")};
  transition: grid-template-rows 0.5s ease-in-out;

  .inner-container {
    overflow: hidden;
    display: grid;
    gap: inherit;
  }
`;

export const FormWrapper = styled.div`
  background: red;
`;

export const FormHeader = styled.div`
  display: flex;
  flex-flow: column;
  align-items: start;
  gap: 18px;
`;

export const Heading = styled.h3`
  font-size: ${px2rem(30)};
`;

export const HeaderCopy = styled.p`
  font-size: ${px2rem(15)};
`;

export const Separator = styled.hr`
  width: 100%;
  height: 1px;
  margin: 40px 0;
  border: 1px solid ${theme.colors.black};
`;
