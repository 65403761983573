import { PricedProductVariant } from "@gqlTypes/PricedProductVariant";
import React from "react";

import { CollectionSlider } from "@components/CollectionSlider";
import { useHeaderStore } from "@stores/useHeaderStore";

import * as S from "./styles";

type ContentProps = {
  children?: React.ReactNode;
  collection?: PricedProductVariant[] | null;
  collectionHeader?: string;
  isEmpty?: boolean;
  title: JSX.Element;
};

export const Content = ({
  title,
  collection,
  children,
  collectionHeader,
  isEmpty = false,
}: ContentProps) => {
  const { topBanner, basket } = useHeaderStore();

  return (
    <S.BasketMain isBannerVisible={topBanner.isActive}>
      <S.BasketHeader>{title}</S.BasketHeader>
      <S.BasketContent
        gap="0"
        isEmpty={isEmpty}
        justifyContent="flex-start"
        style={{
          marginBottom: `${basket.actionHeight}px`,
        }}
        tabIndex={-1}
      >
        {children}
        <S.CollectionWrapper>
          {collectionHeader && (
            <S.CollectionHeader>{collectionHeader}</S.CollectionHeader>
          )}
          {collection && (
            <CollectionSlider
              inNavigation
              isPriority
              light
              showPrice
              collectionType="saleorVariant"
              data={collection}
              desktopCoverSize={150}
              horizontalScroll={false}
              isAlgolia={false}
              location="Cart"
              paddingLeft={false}
              paddingTop={0}
              withHeader={false}
            />
          )}
        </S.CollectionWrapper>
      </S.BasketContent>
    </S.BasketMain>
  );
};
