import React from "react";
import { FormattedMessage } from "react-intl";

export const TermsOfSale = (
  <FormattedMessage defaultMessage="Terms of Sale" id="mScXIG" />
);

export const PrivacyPolicy = (
  <FormattedMessage defaultMessage="Privacy Policy" id="vx0nkZ" />
);
