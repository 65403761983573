import styled, { CSSProperties } from "styled-components";

import { media } from "@styles/media";
import { dynamicFullHeight } from "@utils/navigation";

export const BasketModalWrapper = styled.div<{
  isActive?: boolean;
  isBannerVisible: boolean;
}>`
  pointer-events: none;
  height: ${({ isBannerVisible }) =>
    dynamicFullHeight(
      "mobile",
      isBannerVisible ? ["promoBanner", "header"] : ["header"]
    )};
  width: 100%;
  position: fixed;
  top: 100%;
  right: 0;
  overflow: hidden;

  ${media.md.up} {
    position: absolute;
    height: ${({ isBannerVisible }) =>
      dynamicFullHeight(
        "desktop",
        isBannerVisible ? ["promoBanner", "header"] : ["header"]
      )};
  }
`;

export const Wrapper = styled.div<{
  isActive: boolean;
  isBannerVisible: boolean;
}>`
  pointer-events: all;

  height: 100%;
  background-color: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 1;
  z-index: 999;
  transform: ${({ isActive }) =>
    isActive ? "translateX(0)" : "translateX(100%)"};
  transition-property: transform;
  transition-duration: 350ms;
  transition-timing-function: ${({ isActive }) =>
    isActive ? `ease-in-out` : "ease-out"};

  ${media.md.up} {
    max-width: 500px;
  }
`;

export const BackgroundClose = styled.div<{
  isActive?: boolean;
  isBannerVisible: boolean;
}>`
  pointer-events: none;
  display: ${({ isActive }) => (isActive ? "block" : "none")};
  opacity: ${({ isActive }) => (isActive ? "0.3" : "0")};
  will-change: transform;
  transition: ${({ isActive }) =>
    isActive
      ? `350ms opacity 350ms ease-in-out`
      : "350ms opacity 350ms ease-out"};
  inset: 0;
  position: absolute;
  background-color: ${({ theme }) => theme.colors.black};
  z-index: -1;

  &:hover {
    opacity: 0.1;
    transition: opacity 150ms ease-in;
    cursor: pointer;
  }
`;

export const BasketHeader = styled.p`
  font-family: ${({ theme }) => theme.typography.condensedFontFamily};
  font-size: ${({ theme }) => theme.typography.sizes.largest};
  line-height: inherit;
  text-transform: uppercase;
  font-weight: 600;
`;

export const BasketContainer = styled.div<{ hasPadding: boolean }>`
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  height: 100%;
  gap: 3rem;
  gap: ${({ hasPadding }) => (hasPadding ? "3rem" : "0")};
`;

export const BasketContent = styled.div<{
  gap?: CSSProperties["gap"];
  justifyContent?: CSSProperties["justifyContent"];
}>`
  flex: 1;
  display: flex;
  flex-flow: column;
  justify-content: ${({ justifyContent }) => justifyContent || "center"};
  gap: ${({ gap }) => gap || "3rem"};
  width: 100%;
`;

export const BasketButtonContainer = styled.div`
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;
  gap: 10px;

  > button {
    flex: 1;
    max-width: 100% !important;
  }
`;
