import { AttributeValue } from "@gqlTypes/AttributeValue";
import { ProductType } from "@gqlTypes/ProductType";
import { ProductVariant } from "@gqlTypes/ProductVariant";
import { SelectedAttribute } from "@gqlTypes/SelectedAttribute";
import slugify from "slugify";

import { VariantSlug } from "@utils/attributes/types";

import {
  getArtists,
  getColour,
  getExclusive,
  getExtendedFormat,
  getFormat,
  getFormatMultiplier,
  getSigned,
} from "./attributes";

export const populateArtistsSlug = (
  opts:
    | {
        artists?: never;
        attributes: SelectedAttribute[];
      }
    | { artists: string[]; attributes?: never }
) => {
  const artists = opts.attributes
    ? (getArtists(opts.attributes, true) as AttributeValue[]).map(
        artist => artist!.slug
      )
    : opts.artists;
  return slugify(artists!.join("-"));
};

export function populateArtistsName<T extends true>(
  attributes: SelectedAttribute[],
  asList: T
): string[];
export function populateArtistsName<T extends never>(
  attributes: SelectedAttribute[],
  asList?: T
): string;
export function populateArtistsName(
  attributes: SelectedAttribute[],
  asList?: boolean
) {
  const artists = (getArtists(attributes, true) as AttributeValue[]).map(
    artist => artist!.name!
  );
  if (asList) {
    return artists;
  }
  return artists.join(", ");
}

const CREATORS_KEYS = ["Artist", "Author", "Manufacturer"];

export const getCreators = (attributes: SelectedAttribute[]) =>
  attributes
    ?.find(({ attribute: { name } }) => CREATORS_KEYS.includes(name!))
    ?.values.map(v => v!.name)
    ?.join(", ") ?? "";

export const getVariantSlug = (
  productType: Pick<ProductType, "slug">,
  attributes: SelectedAttribute[]
) => {
  const format = getFormat(attributes, productType, true) as AttributeValue;
  const isExclusive = getExclusive(attributes) as boolean;
  const isSigned = getSigned(attributes) as boolean;
  const isExtendedFormat = getExtendedFormat(attributes) as boolean;
  const formatMultiplier = getFormatMultiplier(attributes) as number;
  const colour = (getColour(attributes, true) as AttributeValue)?.slug;

  const formatSlug =
    format.slug! === "lp"
      ? "vinyl-lp"
      : format.name!.includes('"') || format.name!.includes("''")
      ? `${format!.slug}-inch`
      : format.slug!;

  const slugs = [
    formatSlug,
    isExclusive && "exclusive",
    colour,
    isSigned && "signed",
    isExtendedFormat && "plus",
    formatMultiplier > 1 && `x${formatMultiplier}`,
  ].filter(Boolean);

  return slugs.join("-").toLowerCase();
};

/**
 * Duplicated variant slugs should be suffixed with corresponding index, i.e.:
 *
 * /cd-black
 * /vinyl-lp-black
 * /vinyl-lp-pink-plus
 * /vinyl-lp-pink-plus-1
 * /cd-black-plus
 * /cd-black-plus-1
 */
export const getVariantSlugWithSuffix = (
  variantIndex: number,
  productType: Pick<ProductType, "slug">,
  productVariants: ProductVariant[]
): { slug: string; suffix: number | null } => {
  let variantSlug = "";
  let variantSuffix = null;
  const count: { [key: string]: number } = {};
  const variantsMap = productVariants!.map(variant => ({
    slug: getVariantSlug(productType, variant!.attributes),
    variant,
  }));

  variantsMap.some(({ variant, slug }, i) => {
    if (slug in count) {
      count[slug] = ++count[slug];
    } else {
      count[slug] = 0;
    }
    const end = i === variantIndex;

    if (end) {
      variantSlug = count[slug] > 0 ? `${slug}-${count[slug]}` : slug;
      if (count[slug] > 0) {
        variantSuffix = count[slug];
      }
    }
    return end;
  });

  return { slug: variantSlug, suffix: variantSuffix };
};

export const getProductAllVariantsSlug = ({
  variants,
  productType,
}: {
  productType: Pick<ProductType, "slug">;
  variants: ProductVariant[];
}): VariantSlug[] =>
  variants.map((variant, i) => ({
    ...getVariantSlugWithSuffix(i, productType, variants),
    variant: variant as ProductVariant,
  }));

export const getFormatNote = (
  productType: Pick<ProductType, "slug">,
  attributes: SelectedAttribute[]
) => {
  const format = getFormat(attributes, productType, true) as AttributeValue;
  const isExtended = getExtendedFormat(attributes) as boolean;
  const discs = getFormatMultiplier(attributes) as number;
  const note = [format?.name, discs > 1 && `x${discs}`, isExtended && " + "];

  return note.filter(Boolean).join("");
};
