import React, { useRef } from "react";
import { useIntl } from "react-intl";

import { Container } from "@components/Container";
import { SpotifyLogo } from "@components/SpotifyLogo";
import { useSpotifyContext } from "@hooks/providers";
import { useIsLgUp } from "@hooks/responsive";
import {
  PlayerState,
  SpotifyErrorMessage,
} from "@providers/PlayerProvider/types";

import { Playlist, ProgressBar } from "./components";

import { ReactComponent as Close } from "assets/icons/controls/close-desktop-icon.svg";
import { ReactComponent as MinimizeIcon } from "assets/icons/controls/minimize-mobile-icon.svg";
import { ReactComponent as Pause } from "assets/icons/controls/pause.svg";
import { ReactComponent as Play } from "assets/icons/controls/play-icon.svg";
import { ReactComponent as PlaylistIcon } from "assets/icons/controls/playlist.svg";
import { ReactComponent as Prev } from "assets/icons/controls/skip.svg";

import { playerMessages } from "./messages";
import * as S from "./styles";

export interface PlayerHandlers {
  handlePlayerClose: () => Promise<void>;
  handlePlayerError: (error: SpotifyErrorMessage | null) => void;
  handlePlaylistExpand: () => void;
  handlePlaylistMinimize: () => void;
  handleSeekChange: (trackCompletionRatio: number) => Promise<void>;
  handleTrackPlay: (trackIndex: number) => Promise<void>;
  handleTrackPlayNext: () => Promise<void>;
  handleTrackPlayPrev: () => Promise<void>;
  handleTrackToggle: () => Promise<void>;
}

export interface PlayerProps {
  handlers: PlayerHandlers;
  state: PlayerState<true>;
}

export const Player = ({
  state: {
    isPlaying,
    isPlaylistExpanded,
    trackIndex,
    trackDuration,
    controlsDisabled,
    error,
    album,
    productUrl,
  },

  handlers: {
    handlePlayerClose,
    handleTrackPlayPrev,
    handleTrackPlayNext,
    handleTrackToggle,
    handleSeekChange,
    handlePlaylistExpand,
    handlePlaylistMinimize,
    handleTrackPlay,
  },
}: PlayerProps) => {
  const { formatMessage } = useIntl();
  const isLgUp = useIsLgUp();
  const { isPremiumAccount } = useSpotifyContext();
  const progressBarRef = useRef(null);

  const isExpandedMobile = !isLgUp && isPlaylistExpanded;

  return (
    <>
      {!!album && error && (
        <S.PlayerErrorWrapper>
          <Container>
            <S.PlayerError>
              {formatMessage(playerMessages[error], {
                refreshLink: (
                  <S.RefreshLink onClick={() => window.location.reload()}>
                    {formatMessage(playerMessages.refresh)}
                  </S.RefreshLink>
                ),
              })}
            </S.PlayerError>
          </Container>
        </S.PlayerErrorWrapper>
      )}
      <S.PlayerContainer isVisible={!!album}>
        <S.Container>
          <S.PlaylistWrapper>
            {album && (
              <Playlist
                album={album}
                currentTrack={trackIndex}
                isExpanded={isPlaylistExpanded}
                isPremiumAccount={isPremiumAccount}
                onClickOutside={handlePlaylistMinimize}
                onTrackClick={handleTrackPlay}
                productUrl={productUrl}
                progressBarRef={progressBarRef}
              />
            )}
          </S.PlaylistWrapper>

          <S.ControlsWrapper>
            {isLgUp && (
              <S.SpotifyLogoWrapper>
                <SpotifyLogo variant="green" />
              </S.SpotifyLogoWrapper>
            )}
            <S.ControlButton
              aria-label={formatMessage(
                playerMessages[
                  isPlaylistExpanded ? "closePlaylist" : "openPlaylist"
                ]
              )}
              isActive={isPlaylistExpanded}
              key={`${isPlaylistExpanded}`}
              onClick={evt => {
                evt.preventDefault();
                handlePlaylistExpand();
              }}
            >
              {isExpandedMobile ? <MinimizeIcon /> : <PlaylistIcon />}
            </S.ControlButton>

            <S.Controls>
              <S.ControlButton
                aria-label={formatMessage(playerMessages.prevTrack)}
                disabled={controlsDisabled}
                onClick={handleTrackPlayPrev}
              >
                <Prev />
              </S.ControlButton>

              <S.ControlButton
                aria-label={formatMessage(
                  playerMessages[isPlaying ? "pause" : "play"]
                )}
                disabled={controlsDisabled}
                onClick={handleTrackToggle}
              >
                {isPlaying ? <Pause /> : <Play />}
              </S.ControlButton>

              <S.ControlButton
                isFlipped
                aria-label={formatMessage(playerMessages.nextTrack)}
                disabled={controlsDisabled}
                onClick={handleTrackPlayNext}
              >
                <Prev />
              </S.ControlButton>
            </S.Controls>

            {!isLgUp && (
              <S.ControlButton
                aria-label={formatMessage(playerMessages.closePlayer)}
                onClick={handlePlayerClose}
              >
                <Close />
              </S.ControlButton>
            )}
          </S.ControlsWrapper>

          <ProgressBar
            album={album}
            isExpanded={isPlaylistExpanded}
            isPlaying={isPlaying}
            onSeek={handleSeekChange}
            onTrackEnd={handleTrackPlayNext}
            ref={progressBarRef}
            trackDuration={trackDuration}
            trackIndex={trackIndex}
          />

          {isLgUp && (
            <S.ControlButton
              aria-label={formatMessage(playerMessages.closePlayer)}
              onClick={handlePlayerClose}
            >
              <Close />
            </S.ControlButton>
          )}
        </S.Container>
      </S.PlayerContainer>
    </>
  );
};
