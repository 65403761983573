import { AttributeValue } from "@gqlTypes/AttributeValue";
import { ProductType } from "@gqlTypes/ProductType";
import { SelectedAttribute } from "@gqlTypes/SelectedAttribute";

import { Channel } from "@providers/TranslationProvider";

import { parseEditorJSContent } from "../editorjs";
import { RequireOnlyOne } from "../typescript";
import {
  GetAttribute,
  GetListAttribute,
  GetLocalizedAttribute,
  GetLocalizedListAttribute,
  GlobalAttributes,
  LocalizedAttributes,
  NonNullFilterAttribute,
  ProductTypeFormatMapping,
} from "./types";

export const getAttributeBy = (
  attributes: SelectedAttribute[],
  options: RequireOnlyOne<{ name: string; slug: string }>
) =>
  (attributes as NonNullFilterAttribute[])?.find(
    ({ attribute: { name, slug } }) =>
      options.name ? options.name === name : options.slug === slug
  );

export const mapListAttribute = (attr: AttributeValue) => attr!.name!;

export const getLocalizedAttribute = (
  attributes: SelectedAttribute[],
  name: string,
  channel: Channel
) =>
  getAttributeBy(attributes, {
    slug: `${name}-${channel}`,
  });

export const getFormat = (
  attributes: SelectedAttribute[],
  productType: Pick<ProductType, "slug">,
  bare?: boolean
) => {
  const formatAttr =
    ProductTypeFormatMapping[
      productType.slug as keyof typeof ProductTypeFormatMapping
    ];

  if (!attributes) {
    return null;
  }

  const attributeResult = getAttributeBy(attributes, {
    slug: formatAttr,
  });

  if (
    !attributeResult ||
    !attributeResult.values ||
    attributeResult.values.length === 0
  ) {
    return null;
  }

  const value = attributeResult.values[0];
  return bare ? value : value.slug;
};

export const getCatalogueNumber: GetAttribute<string> = (attributes, bare) => {
  const value = getAttributeBy(attributes, {
    slug: GlobalAttributes.CATALOGUE_NUMBER,
  })?.values?.[0];
  return bare
    ? value ?? null
    : value?.richText
    ? parseEditorJSContent(value.richText)
    : null;
};

export const getColourDetails: GetAttribute<string> = (attributes, bare) => {
  const value = getAttributeBy(attributes, {
    slug: GlobalAttributes.COLOUR_DETAILS,
  })?.values?.[0];
  return bare
    ? value ?? null
    : value?.translation?.richText ?? value?.richText
    ? parseEditorJSContent(value.translation?.richText ?? value.richText)
    : "";
};

export const getFormatDetail: GetAttribute<string> = (attributes, bare) => {
  const value = getAttributeBy(attributes, {
    slug: GlobalAttributes.FORMAT_DETAIL,
  })?.values?.[0];
  return bare
    ? value ?? null
    : value?.translation?.richText ?? value?.richText
    ? parseEditorJSContent(value.translation?.richText ?? value.richText)
    : "";
};

export const getReleaseDate: GetAttribute<Date> = (
  attributes: SelectedAttribute[],
  bare?
) => {
  const value = getAttributeBy(attributes, {
    slug: GlobalAttributes.RELEASE_DATE,
  })?.values?.[0];

  if (bare) {
    return value ?? null;
  }
  return value?.date ? new Date(value.date) : null;
};

export const getExclusive: GetAttribute<boolean> = (
  attributes: SelectedAttribute[],
  bare
) => {
  const value = getAttributeBy(attributes, {
    slug: GlobalAttributes.EXCLUSIVE,
  })?.values?.[0];
  return bare ? value ?? null : value?.boolean ?? false;
};

export const getExtendedFormat: GetAttribute<boolean> = (attributes, bare) => {
  const value = getAttributeBy(attributes, {
    slug: GlobalAttributes.EXTENDED_FORMAT,
  })?.values?.[0];
  return bare ? value ?? null : value?.boolean ?? false;
};

export const getSigned: GetAttribute<boolean> = (attributes, bare?) => {
  const value = getAttributeBy(attributes, {
    slug: GlobalAttributes.SIGNED,
  })?.values?.[0];
  return bare ? value ?? null : value?.boolean ?? false;
};

export const getLabel: GetLocalizedAttribute<string> = (
  attributes,
  channel,
  bare?
) => {
  const value = getLocalizedAttribute(
    attributes,
    LocalizedAttributes.LABEL,
    channel
  )?.values?.[0];
  return bare ? value ?? null : value?.name ?? null;
};

export const getArtists: GetListAttribute<string> = (attributes, bare) => {
  const values =
    getAttributeBy(attributes, {
      slug: GlobalAttributes.ARTIST,
    })?.values ?? [];
  return bare ? values : values!.map(mapListAttribute) ?? [];
};

export const getGenres: GetLocalizedListAttribute<string> = (
  attributes,
  channel,
  bare?
) => {
  const values =
    getLocalizedAttribute(attributes, LocalizedAttributes.GENRE, channel)
      ?.values ?? [];
  return bare ? values : values.map(mapListAttribute) ?? [];
};

export const getSupplier: GetLocalizedAttribute<string> = (
  attributes,
  locale,
  bare?
) => {
  const value = getLocalizedAttribute(
    attributes,
    LocalizedAttributes.SUPPLIER,
    locale
  )?.values[0];
  return bare ? value ?? null : value?.name ?? null;
};

export const getColour: GetAttribute<string> = (attributes, bare?) => {
  const value = getAttributeBy(attributes, {
    slug: GlobalAttributes.COLOUR,
  })?.values?.[0];
  return bare ? value ?? null : value?.value ?? null;
};

export const getFormatMultiplier: GetAttribute<number> = (
  attributes,
  bare?
) => {
  const value = getAttributeBy(attributes, {
    slug: GlobalAttributes.DISCS,
  })?.values?.[0];
  return bare ? value ?? null : Number(value?.name ?? "0");
};

export const getLimited: GetAttribute<number> = (attributes, bare?) => {
  const value = getAttributeBy(attributes, {
    slug: GlobalAttributes.LIMITED,
  })?.values?.[0];
  return bare ? value ?? null : Number(value?.name ?? "0");
};

export const getBonusDisc: GetAttribute<boolean> = (attributes, bare?) => {
  const value = getAttributeBy(attributes, {
    slug: GlobalAttributes.BONUS_DISC,
  })?.values?.[0];

  return bare ? value ?? null : value?.boolean!;
};

export const getIncludesDownload: GetAttribute<boolean> = (
  attributes,
  bare?
) => {
  const value = getAttributeBy(attributes, {
    slug: GlobalAttributes.INCLUDES_DOWNLOAD,
  })?.values?.[0];

  return bare ? value ?? null : value?.boolean!;
};
