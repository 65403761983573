import { px2rem, styled } from "@styles";

export const Container = styled.div`
  color: ${({ theme }) => theme.colors.medium};
  width: 100%;
  display: flex;
  font-size: ${px2rem(15)};
  justify-content: space-between;
  text-decoration: underline;

  &:hover {
    color: ${({ theme }) => theme.colors.white};
  }
`;
