import React from "react";
import ReactSelect, { mergeStyles } from "react-select";

import { DATA_TEST_ATTRIBUTE, getTestAttribute } from "@utils";

import { Control, Option, SingleValue } from "./components";

import * as GS from "./styles";

export type SelectProps = GS.SelectProps;

export const Select = ({
  disabled,
  theme = "light",
  styles,
  error,
  [DATA_TEST_ATTRIBUTE as string]: dataTest,
  ...props
}: SelectProps) => (
  <GS.SelectWrapper error={!!error} variant={theme}>
    <ReactSelect
      {...getTestAttribute("select", props.name)}
      {...props}
      openMenuOnFocus
      tabSelectsValue
      classNamePrefix="react-select"
      components={{
        Control,
        Option,
        SingleValue,
      }}
      dataTest={dataTest}
      isDisabled={disabled}
      styles={mergeStyles(GS.defaultStyles, styles)}
      tabIndex={null}
      theme={GS.themes[theme as GS.Theme]}
    />
    {error && (
      <GS.Error {...getTestAttribute("inputError", props.name)}>
        {error.message}
      </GS.Error>
    )}
  </GS.SelectWrapper>
);
