import { css } from "styled-components";

import { media } from "@styles/media";

export const clubFixedElements = css<{ isFooter?: boolean }>`
  position: fixed;
  left: 0;
  z-index: 20;
  display: flex;
  flex-flow: row;
  align-items: center;
  width: 100%;
  overflow: hidden;
  max-width: 100%;
  height: 100px;
  padding: 0 1rem;

  ${media.md.up} {
    padding: 0 3rem;
  }

  ${({ isFooter, theme }) =>
    isFooter
      ? `bottom:0; border-top: 1px ${theme.colors.dark} solid}`
      : `top: 0; border-bottom: 1px ${theme.colors.dark} solid`}
`;
