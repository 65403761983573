import { KeyTextField } from "@prismicio/client";
import React from "react";

import * as S from "./styles";

interface SubMenuProps {
  children?: React.ReactNode;
  iconFirst?: boolean;
  label: KeyTextField;
  url: KeyTextField;
}

/** Second level nav item */
export const SecondaryItem = ({
  children,
  label,
  url,
  iconFirst,
}: SubMenuProps) => (
  <>
    {url && (
      <S.ItemLink href={url} iconFirst={iconFirst}>
        <span>{label}</span>
      </S.ItemLink>
    )}

    {children}
  </>
);
