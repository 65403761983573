import styled, { css, CSSObject } from "styled-components";

import { media, px2rem, responsive } from "@styles";

import { LanguageSwitcher } from "../LanguageSwitcher";

export const FormWidthStyling = css`
  width: 100%;
  max-width: 80%;

  ${media.md.up} {
    max-width: 500px;
  }

  ${media.lg.up} {
    max-width: 340px;
  }
`;

export const CustomSelectStyles = {
  control(base: CSSObject): CSSObject {
    return {
      ...base,
      borderColor: "white",
    };
  },
};

export const FooterWrapper = styled.footer`
  background-color: ${({ theme }) => theme.footer.backgroundColor};
  color: ${({ theme }) => theme.footer.foregroundColor};

  ${media.lg.up} {
    gap: 40px;
  }

  @media print {
    display: none;
  }
`;

export const FooterContainer = styled.div`
  display: flex;
  flex-flow: column;
  padding: 80px 0px 30px;
  gap: 30px;

  ${media.lg.up} {
    gap: 40px;
  }
`;

export const FooterRow = styled.div`
  display: grid;
  gap: inherit;
  grid-template-columns: 1fr;
  margin: 0 18px;

  ${media.lg.up} {
    margin: 0 50px 0 50px;
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const RowItem = styled.div<{ isEmpty?: boolean }>`
  display: ${({ isEmpty }) => (isEmpty ? "none" : "flex")};
  flex-flow: column;
  align-items: center;
  gap: 28px;

  form {
    width: 100%;
  }

  ${media.lg.up} {
    display: flex;
    align-items: flex-start;
  }
`;

export const Link = styled.a`
  color: ${({ theme }) => theme.footer.foregroundColor};
  text-decoration: none;
  font-size: ${px2rem(16)};
  display: flex;
  justify-content: center;

  &:hover {
    opacity: 1;
  }

  svg {
    height: 18px;
    width: 20px;
  }

  ${media.lg.up} {
    display: flex;
    justify-content: flex-start;
    flex-flow: row;

    svg {
      height: 22px;
      width: 28px;
    }
  }
`;

export const PayIcon = styled.a`
  display: flex;
  color: ${({ theme }) => theme.footer.foregroundColor};
  text-decoration: none;

  &:hover {
    opacity: 1;
  }

  svg {
    height: 18px;
    width: 20px;
  }

  ${media.lg.up} {
    svg {
      height: 22px;
      width: 28px;
    }
  }
`;

export const Description = styled.p`
  line-height: ${px2rem(24)};
  font-weight: 400;
  color: ${({ theme }) => theme.colors.dark};
  font-size: ${px2rem(16)};
  margin: 0 auto;
  text-align: center;

  ${media.lg.up} {
    text-align: left;

    margin: 0;
    color: ${({ theme }) => theme.colors.white};
  }

  ${FormWidthStyling}
`;

export const Copyrights = styled.p`
  display: flex;
  flex-flow: row;
  justify-content: center;
  gap: 6px;
  ${responsive("font-size", px2rem(12), px2rem(16))}
  ${({ theme }) => responsive("color", theme.colors.dark, theme.colors.white)}

  ${media.lg.up} {
    justify-content: flex-start;
    align-items: center;
    margin-bottom: ${px2rem(20)};
  }
`;

export const IconLinksColumn = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;

  ${media.lg.up} {
    justify-content: flex-start;
  }
`;

export const LanguageSwitcherWrapper = styled(LanguageSwitcher)`
  align-self: center;
  margin: 0 auto;
  max-width: 340px;

  ${media.lg.up} {
    align-self: start;
    margin: 0;
  }

  .react-select__value-container {
    padding-left: 0;
  }

  .react-select__control {
    border: none;
  }

  .react-select__single-value {
    align-items: center;
    display: flex;

    svg {
      height: 21px;
      width: 21px;
    }
  }
`;

export const FooterHeading = styled.h3<{ isActive?: boolean }>`
  margin: 0 auto;
  font-size: ${px2rem(34)};
  color: ${({ isActive, theme }) =>
    isActive ? theme.colors.light : theme.colors.white};

  ${media.lg.up} {
    color: ${({ theme }) => `${theme.colors.white} !important`};
    margin: 0;
    font-size: ${px2rem(22)};
  }
`;

export const NavHeading = styled(FooterHeading)`
  font-size: ${px2rem(22)};
  margin: 0;
`;

export const OptionalHeading = styled(NavHeading)`
  display: none;

  ${media.lg.up} {
    display: flex;
  }
`;
