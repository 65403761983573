import { PricedProductVariant } from "@gqlTypes/PricedProductVariant";
import { RichTextField } from "@prismicio/client";
import { PrismicRichText } from "@prismicio/react";
import React from "react";

import { HandleCloseType } from "@components/BasketModal/BasketModal";
import * as M from "@components/BasketModal/messages";
import * as CS from "@components/BasketModal/styles";
import { Button } from "@components/Button";

import { CartActions } from "../CartActions";
import { Content } from "../Content";

import * as S from "./styles";

interface EmptyCartProps {
  basketText?: RichTextField;
  collection?: PricedProductVariant[] | null;
  collectionHeader?: string;
  handleClose: (e: HandleCloseType) => void;
}

export const EmptyCart = ({
  handleClose,
  basketText,
  collectionHeader,
  collection,
}: EmptyCartProps) => (
  <CS.BasketContainer hasPadding={false}>
    <Content
      isEmpty
      collection={collection}
      collectionHeader={collectionHeader}
      title={M.emptyBasket}
    >
      {basketText && (
        <S.TextWrapper>
          <PrismicRichText field={basketText} />
        </S.TextWrapper>
      )}
    </Content>

    <CartActions>
      <Button
        fullWidth
        fontSize="small"
        onClick={handleClose}
        tabIndex={-1}
        variant="hollowLight"
      >
        {M.continueShoppingButton}
      </Button>
    </CartActions>
  </CS.BasketContainer>
);
