import { Checkout } from "@gqlTypes/Checkout";
import { DiscountErrorCode } from "@gqlTypes/globalTypes";
import { yupResolver } from "@hookform/resolvers/yup";
import { SliceZone } from "@prismicio/react";
import { useAuthState } from "@saleor/sdk";
import { components } from "@slicesComponents";
import React, { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import { Button } from "@components/Button";
import { FormProvider } from "@components/FormProvider";
import { Grid, GridColumn } from "@components/Grid";
import { useCheckoutContext } from "@hooks/providers";
import { useIsMdDown } from "@hooks/responsive";
import { useApiFormErrors } from "@hooks/useApiFormErrors";
import { useIsMounted } from "@hooks/useIsMounted";
import { useCheckoutAddPromoCodeMutation } from "@providers/CheckoutProvider";
import { useCheckoutStore } from "@stores/useCheckoutStore";
import { useGlobalConfigStore } from "@stores/useGlobalConfigStore";
import { ApiError } from "@utils";
import {
  clubVoucherValidForUser,
  isActiveClubMember,
  isClubShippingVoucher,
} from "@utils/club";

import { PromoSchema, promoSchema } from "./schema";

import { ReactComponent as CloseSvg } from "assets/icons/close.svg";
import { ReactComponent as InfoSvg } from "assets/icons/info.svg";

import * as M from "./messages";
import * as S from "./styles";

const MESSAGE_DELAY = 3000;

type PromoCodeSectionProps = {
  onFormToggle?: () => void;
};

export const PromoCodeSection = ({ onFormToggle }: PromoCodeSectionProps) => {
  const intl = useIntl();
  const isMobile = useIsMdDown();

  const { setPromoActive, setPromoSuccess, promoActive, promoSuccess } =
    useCheckoutStore();

  const {
    checkout: { id: checkoutId, voucherCode },
    updateCheckout,
  } = useCheckoutContext<true>();

  const [mutation, { loading }] = useCheckoutAddPromoCodeMutation();
  const isMounted = useIsMounted();
  const schema = promoSchema(intl);
  const { user } = useAuthState();

  const formCtx = useForm<PromoSchema>({
    defaultValues: schema.getDefault(),
    mode: "onChange",
    resolver: yupResolver(schema),
  });
  const handleErrors = useApiFormErrors(formCtx.setError, { intl });

  const clubVoucherError = {
    code: DiscountErrorCode.INVALID,
    field: "promoCode",
    message: intl.formatMessage(M.PromoCodeError),
  };

  const onSuccess = (checkout: Partial<Checkout>) => {
    updateCheckout(checkout!);
    setPromoSuccess();
    setPromoActive();
  };

  const onFailure = (errors: ApiError[]) => {
    if (errors.some(({ code }) => code !== DiscountErrorCode.INVALID)) {
      const apiErrors = errors.filter(
        ({ code }) => code !== DiscountErrorCode.INVALID
      );
      handleErrors([...apiErrors, clubVoucherError]);
    } else {
      handleErrors(errors);
    }
  };

  const handleSubmit: SubmitHandler<PromoSchema> = async ({ promoCode }) => {
    if (user && isClubShippingVoucher(promoCode)) {
      if (
        !clubVoucherValidForUser(promoCode, user.id) ||
        !isActiveClubMember(user)
      ) {
        onFailure([clubVoucherError]);
        return;
      }
    }

    const { data } = await mutation({
      variables: { checkoutId, code: promoCode },
    });
    const errors = data!.checkoutAddPromoCode?.errors || [];

    if (errors.length) {
      onFailure(errors);
    } else {
      onSuccess(data.checkoutAddPromoCode!.checkout!);
    }
  };

  useEffect(() => {
    if (promoSuccess) {
      const timeout = setTimeout(() => {
        if (isMounted.current) {
          setPromoSuccess();
        }
      }, MESSAGE_DELAY);
      return () => clearTimeout(timeout);
    }
  }, [promoSuccess]);

  useEffect(() => {
    onFormToggle?.();
  }, [promoSuccess, promoActive]);

  const { checkout } = useGlobalConfigStore();
  const { slices } = checkout?.data || {};

  return (
    <FormProvider name="promo" {...formCtx} onSubmit={handleSubmit}>
      <Grid verticalSpacing={1}>
        {!voucherCode && (
          <>
            <GridColumn flex justify="space-between">
              <S.Info
                active={promoActive}
                onClick={loading ? undefined : setPromoActive}
              >
                {M.HaveACode}
              </S.Info>
              {promoActive && (
                <S.Close onClick={loading ? undefined : setPromoActive}>
                  <CloseSvg />
                </S.Close>
              )}
            </GridColumn>
            {promoActive && (
              <>
                <GridColumn>
                  <S.CheckoutInputField
                    autoComplete="off"
                    disabled={loading}
                    label={intl.formatMessage(M.enterCode)}
                    name="promoCode"
                    variant="hollow"
                  />
                </GridColumn>
                <GridColumn>
                  <Button fullWidth disabled={loading} variant="checkout">
                    {M.Redeem}
                  </Button>
                </GridColumn>
                <GridColumn>
                  <S.GiftCardsInfo>
                    <InfoSvg />
                    {M.GiftCardsInfo}
                  </S.GiftCardsInfo>
                </GridColumn>
              </>
            )}
          </>
        )}
      </Grid>

      <S.SuccessWrapper show={promoSuccess}>
        <span>{M.Success}</span>
      </S.SuccessWrapper>

      {!isMobile && <SliceZone components={components} slices={slices} />}
    </FormProvider>
  );
};
