import queryString from "query-string";

import {
  CollectionPageUrls,
  SearchableSlugs,
} from "@components/ProductFilters/constants";
import {
  Filters,
  SearchableAttributeSlug,
} from "@components/ProductFilters/types";
import { defaultFilters } from "@providers/ListingProvider";

const excludedValues: unknown[] = ["most-relevant", "anytime"];
export const buildUrlFromFilters = (filters: Filters): string => {
  const params = new URLSearchParams();

  Object.keys(filters).forEach(key => {
    const filterValue = filters[key as keyof Filters];

    if (
      filterValue &&
      !SearchableSlugs.includes(key as SearchableAttributeSlug)
    ) {
      if (!excludedValues.includes(filterValue)) {
        switch (typeof filterValue) {
          case "boolean":
            params.set(key, "true");
            return;
          case "string":
            params.set(key, filterValue);
            return;
          case "number":
            params.set(key, filterValue.toString());
            return;
          default:
            params.set(key, filterValue);
        }
      }
    }
  });

  return params.toString();
};

export const filterInvalidParams = (query: { [x: string]: any }) => {
  const validQueryParams: string[] = Object.keys(defaultFilters);

  return Object.keys(query).reduce(
    (obj: { [x: string]: unknown }, key: string) => {
      if (!validQueryParams.includes(key)) return obj;
      obj[key] = query[key];
      return obj;
    },
    {}
  );
};

export const buildFiltersFromUrl = (
  url: string,
  providedFilters: Partial<Filters> = {}
): Filters => {
  const { query } = queryString.parseUrl(url, { parseBooleans: true });

  const validQuery = filterInvalidParams(query);

  CollectionPageUrls.forEach(type => {
    if (url.includes(`/${type}/`)) {
      const urlParts = url.split("/");
      const collectionIndex = urlParts.indexOf(type);

      // eslint-disable-next-line default-case
      switch (type) {
        case "collection": {
          providedFilters["collections.slug"] = urlParts[collectionIndex + 1];
          break;
        }
        case "artist": {
          providedFilters["artists.slug"] = urlParts[collectionIndex + 1];
          break;
        }
        case "genres": {
          providedFilters["genres.slug"] = urlParts[collectionIndex + 1];
          break;
        }
        case "label": {
          providedFilters["labels.slug"] = urlParts[collectionIndex + 1];
          break;
        }
      }
    }
  });

  return { ...defaultFilters, ...providedFilters, ...validQuery } as Filters;
};
