import { Container } from "@components/Container";
import { GridColumn } from "@components/Grid";
import { styled } from "@styles";

export const Wrapper = styled.div`
  width: 100%;
  align-items: stretch;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  background: ${({ theme }) => theme.colors.black};
`;

export const Column = styled(GridColumn)`
  &:first-child {
    min-height: 100%;
    display: flex;
    flex-direction: column;
  }

  &:nth-child(2) {
    position: relative;
  }
`;

export const Content = styled(Container)`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
`;

export const CartWrapper = styled.div`
  padding-bottom: 1rem;
`;
