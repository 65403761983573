import { createContext } from "react";

import { ExchangeToken, SpotifyUser } from "./types";

export type FollowResponse = {
  following: string[];
  status: {
    ok: boolean;
  };
};

export type SpotifyContext = {
  clearUserRefreshToken: () => Promise<void>;
  exchangeToken: (code: string, codeVerifier: string) => Promise<boolean>;
  follow: (id: string, type?: string) => Promise<FollowResponse>;
  followedArtists: string[];
  isConnected: boolean;
  isFollowingRT: boolean;
  isPremiumAccount: boolean;
  processing: boolean;
  token: ExchangeToken | null;
  unFollow: (id: string) => Promise<string[]>;
  user: SpotifyUser | null;
};

export const SpotifyProviderContext = createContext<SpotifyContext>({
  processing: false,
  token: null,
} as SpotifyContext);

SpotifyProviderContext.displayName = "SpotifyContext";
