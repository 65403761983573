import Link, { LinkProps as NextLinkProps } from "next/link";
import React, { forwardRef } from "react";
import {
  ExtractRouteParams,
  generatePath as reactRouterGeneratePath,
} from "react-router";

import { ButtonStyledProps } from "@components/Button/styles";
import * as BS from "@components/Button/styles";
import { PartialOnly } from "@utils";

import { ReactComponent as SpotifyIcon } from "assets/icons/spotify.svg";

import * as S from "./styles";

type GeneratePath = { path: string } & ExtractRouteParams<any>;
type NextHref = NextLinkProps["href"];

export type ButtonLinkProps = React.LinkHTMLAttributes<HTMLAnchorElement> &
  PartialOnly<ButtonStyledProps, "variant"> & {
    disabled?: boolean;
    fontSize?: BS.FontSizingProps;
    generatePath?: GeneratePath;
    isExternal?: boolean;
  };

export const generateHref = (
  isExternal: boolean,
  generatePath?: GeneratePath,
  nextHref?: string
) => {
  if (generatePath && !isExternal) {
    const { path, ...params } = generatePath;
    return reactRouterGeneratePath(path, params);
  }

  return nextHref;
};

export const ButtonLink = forwardRef<HTMLAnchorElement, ButtonLinkProps>(
  (
    {
      children,
      variant = "primary",
      disabled,
      processing,
      icon: Icon,
      href: nextHref,
      as,
      generatePath,
      fontSize = "medium",
      isExternal = false,
      ...props
    },
    ref
  ) => {
    const href = generateHref(isExternal, generatePath, nextHref);

    if (!href) {
      return null;
    }

    const linkElement = (
      <S.Link
        disabled={disabled || processing}
        fontSize={fontSize}
        href={href}
        processing={processing}
        ref={ref}
        rel={isExternal ? "nofollow noopener noreferrer" : props.rel}
        target={isExternal ? "_blank" : "_self"}
        variant={variant}
        withIcon={!!Icon}
        {...props}
      >
        {(Icon || variant === "spotify") && (
          <BS.IconWrapper>
            {Icon ? <Icon height={16} /> : <SpotifyIcon height={16} />}
          </BS.IconWrapper>
        )}
        {children}
      </S.Link>
    );

    return isExternal ? (
      linkElement
    ) : (
      <Link passHref href={href as NextHref}>
        {linkElement}
      </Link>
    );
  }
);
