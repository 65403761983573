import { ApolloClient, OperationVariables } from "@apollo/client";
import {
  CollectionData,
  CollectionDataVariables,
} from "@gqlTypes/CollectionData";
import { Country } from "@gqlTypes/Country";
import { EnabledCountries } from "@gqlTypes/EnabledCountries";
import {
  GetIndividualOrder,
  GetIndividualOrderVariables,
} from "@gqlTypes/GetIndividualOrder";
import { CountryCode, ProductFilterInput } from "@gqlTypes/globalTypes";
import { Order_lines_variant } from "@gqlTypes/Order";
import {
  OrderDetails,
  OrderDetails_orderByToken_lines_variant,
  OrderDetailsVariables,
} from "@gqlTypes/OrderDetails";
import {
  OrderExistence,
  OrderExistenceVariables,
} from "@gqlTypes/OrderExistence";
import { Page } from "@gqlTypes/Page";
import { PricedProductVariant } from "@gqlTypes/PricedProductVariant";
import {
  ProductVariants,
  ProductVariantsVariables,
} from "@gqlTypes/ProductVariants";
import {
  UpdateMetadata,
  UpdateMetadataVariables,
} from "@gqlTypes/UpdateMetadata";
import { metadataUpdateMutation } from "@graphql";
import {
  initializeApollo,
  initializeSecureApolloClient,
} from "@graphql/client";
import {
  collectionDataQuery,
  enabledCountriesQuery,
  getIndividualOrderQuery,
  orderDetailsQuery,
  orderExistenceQuery,
  pageQuery,
  productVariantsQuery,
} from "@graphql/queries";
import { paths } from "@paths";
import {
  CollectionRedirect,
  CollectionRedirectVariables,
} from "@ssr/gqlTypes/CollectionRedirect";
import {
  ProductVariantsRedirect,
  ProductVariantsRedirectVariables,
} from "@ssr/gqlTypes/ProductVariantsRedirect";
import {
  collectionsRedirectQuery,
  productsRedirectQuery,
  productVariantsRedirectQuery,
} from "@ssr/queries";
import { DocumentNode } from "graphql";
import { IncomingMessage, ServerResponse } from "http";
import { GetServerSidePropsResult } from "next";
import { generatePath } from "react-router";

import { MetadataKey, serverSideCaching, ssrFetchBatch } from "@config";
import { checkoutCaptureOrder } from "@providers/CheckoutProvider";
import {
  CheckoutCaptureOrder,
  CheckoutCaptureOrderVariables,
} from "@providers/CheckoutProvider/gqlTypes/CheckoutCaptureOrder";
import {
  Channel,
  DEFAULT_COUNTRY,
  Locale,
} from "@providers/TranslationProvider";
import { generateProductPath, generateProductVariantPath } from "@utils";
import {
  UserOrders,
  UserOrdersVariables,
} from "@views/AccountOrdersView/gqlTypes/UserOrders";
import { userOrderQueries } from "@views/AccountOrdersView/queries";
import { checkCanBeDispatchedMap } from "@views/ProductView/ssr";

import {
  ProductsRedirect,
  ProductsRedirectVariables,
} from "./gqlTypes/ProductsRedirect";
import {
  CollectionBaseDataVariables,
  GenericApiResponse,
  MetaKey,
  MetaValue,
} from "./types";

export const fetchCollectionBaseData = async (
  variables: CollectionBaseDataVariables
): Promise<CollectionData["collection"] | null> => {
  const apolloClient = initializeApollo();

  const { data } = await apolloClient.query<
    CollectionData,
    CollectionDataVariables
  >({
    query: collectionDataQuery,
    variables,
  });

  return data?.collection;
};

export const fetchVariants = async (
  ids: string[],
  channel: Channel,
  limit = 100
): Promise<PricedProductVariant[]> => {
  const apolloClient = initializeApollo();

  const { data } = await apolloClient.query<
    ProductVariants,
    ProductVariantsVariables
  >({
    query: productVariantsQuery,
    variables: { channel, ids, limit },
  });

  return data.productVariants?.edges?.map(edge => edge.node) ?? [];
};

export const checkIfOrdersExist = async (
  ...tokens: string[]
): Promise<string[]> => {
  const apolloClient = await initializeApollo();

  const orderTokens = await Promise.all(
    tokens.map(token =>
      apolloClient
        .query<OrderExistence, OrderExistenceVariables>({
          query: orderExistenceQuery,
          variables: { token },
        })
        .then(data => data.data?.orderByToken?.token)
    )
  );

  return orderTokens.filter(Boolean) as unknown as string[];
};

export const fetchEnabledCountries = async (): Promise<Country[]> => {
  const apolloClient = await initializeApollo();

  return apolloClient
    .query<EnabledCountries>({
      query: enabledCountriesQuery,
    })
    .then(({ data }) => data.shop.countries);
};

export const fetchPage = async (slug: string): Promise<Page | null> => {
  const apolloClient = await initializeApollo();

  const { data } = await apolloClient.query({
    query: pageQuery,
    variables: { slug },
  });
  return data.page;
};

export const checkIfOrderExists = async (
  apolloClient: ApolloClient<any>,
  token: string
): Promise<boolean> => {
  const { data } = await apolloClient.query<
    OrderExistence,
    OrderExistenceVariables
  >({
    query: orderExistenceQuery,
    variables: { token },
  });
  return !!data?.orderByToken?.id;
};

export const capturePaymentMutation = async (
  apolloClient: ApolloClient<any>,
  variables: CheckoutCaptureOrderVariables
): Promise<CheckoutCaptureOrder | null | undefined> => {
  const { data } = await apolloClient.mutate<
    CheckoutCaptureOrder,
    CheckoutCaptureOrderVariables
  >({
    mutation: checkoutCaptureOrder,
    variables: { ...variables },
  });
  return data;
};

/**
 * This is needed because this does not rely on the user existing, therefore this ensures we're able to fetch order information for a user that may not exist (e.g. guest user).
 */
export const getIndividualOrder = async (
  apolloClient: ApolloClient<any>,
  variables: GetIndividualOrderVariables
): Promise<GetIndividualOrder> => {
  const { data } = await apolloClient.query<
    GetIndividualOrder,
    GetIndividualOrderVariables
  >({
    query: getIndividualOrderQuery,
    variables: { ...variables },
  });
  return data;
};

export const getOrderInformation = async (
  apolloClient: ApolloClient<any>,
  variables: {
    after?: string;
    id: string;
  }
): Promise<UserOrders> => {
  const { data } = await apolloClient.query<UserOrders, UserOrdersVariables>({
    query: userOrderQueries,
    variables: { ...variables },
  });
  return data;
};

export const getOrderDetails = async (
  apolloClient: ApolloClient<any>,
  variables: {
    token: string;
  }
): Promise<OrderDetails> => {
  const { data } = await apolloClient.query<
    OrderDetails,
    OrderDetailsVariables
  >({
    query: orderDetailsQuery,
    variables: { ...variables },
  });
  return data;
};

export const getLegacyRedirectUrl = async (
  channel: Channel,
  urlParts: string[]
) => {
  const apolloClient = initializeApollo();
  const searchUrl = urlParts.join("/");
  const metadataFilter: ProductFilterInput = {
    metadata: [
      {
        key: MetadataKey.LEGACY_SLUG,
        value: `/${searchUrl}`,
      },
    ],
  };

  const [product, productVariant, collection] = await Promise.all([
    apolloClient
      .query<ProductsRedirect, ProductsRedirectVariables>({
        query: productsRedirectQuery,
        variables: { channel, filter: metadataFilter },
      })
      .then(({ data }) => data.products?.edges?.map(edge => edge.node)?.[0]),
    apolloClient
      .query<ProductVariantsRedirect, ProductVariantsRedirectVariables>({
        query: productVariantsRedirectQuery,
        variables: { channel, filter: metadataFilter },
      })
      .then(
        ({ data }) => data.productVariants?.edges?.map(edge => edge.node)?.[0]
      ),
    apolloClient
      .query<CollectionRedirect, CollectionRedirectVariables>({
        query: collectionsRedirectQuery,
        variables: { channel, filter: metadataFilter },
      })
      .then(({ data }) => data.collections?.edges?.map(edge => edge.node)?.[0]),
  ]);

  if (product) {
    const { attributes, slug } = product;
    return generateProductPath(
      {
        attributes,
        slug,
      },
      true
    );
  }

  if (productVariant) {
    const {
      product: { attributes: productAttributes, productType, slug },
      attributes: variantAttributes,
    } = productVariant;
    return generateProductVariantPath(
      {
        productAttributes,
        productType,
        slug,
        variantAttributes,
      },
      true
    );
  }
  if (collection) {
    const path = {
      artist: paths.artist,
      collection: paths.collection,
      genre: paths.genre,
      label: paths.label,
    }[collection.collectionSource ?? "collection"];

    return generatePath(path!, {
      slug: collection.slug,
    });
  }
};

// https://vercel.com/changelog/ip-geolocation-for-serverless-functions
export const getCountryFromRequest = (req: IncomingMessage): CountryCode =>
  (req.headers?.["x-vercel-ip-country"] as CountryCode) ?? DEFAULT_COUNTRY.code;

export const cacheServerSideResult = <P>(
  res: ServerResponse,
  result: GetServerSidePropsResult<P>
): GetServerSidePropsResult<P> => {
  res.setHeader("Cache-Control", serverSideCaching());

  return result;
};

export const updateMetadata = async (
  apolloClient: ApolloClient<any>,
  entityId: string,
  metadata: [MetaKey, MetaValue][]
) =>
  apolloClient.mutate<UpdateMetadata, UpdateMetadataVariables>({
    mutation: metadataUpdateMutation,
    variables: {
      id: entityId,
      input: metadata.map(([key, value]) => ({ key, value })),
    },
  });

export const handleUpdateMetadata = async (
  apolloClient: ApolloClient<any>,
  entityId: string,
  metadata: [MetaKey, MetaValue][],
  actionName: string = "Unnamed action"
): Promise<GenericApiResponse> => {
  const { data } = await updateMetadata(apolloClient, entityId, metadata);

  if (!data?.updateMetadata) {
    return {
      message: "Unable to update user meta data",
      success: false,
    };
  }

  if (data.updateMetadata?.errors.length) {
    const userMetaDataError = data.updateMetadata?.errors[0];

    return {
      code: userMetaDataError.code,
      message: userMetaDataError?.message || "Unable to update user metadata.",
      success: false,
    };
  }

  return {
    message: `${actionName}: Metadata successfully updated.`,
    success: true,
  };
};

/**
 *
 * @param tokens
 * @param locale
 * @summary Fetches order information for multiple orders using the provided tokens. Uses apollo client with permissions to fetch the data.
 */
export const fetchOrderInformationSSR = async (
  tokens: string[],
  locale: Locale
) => {
  const apolloClient = initializeSecureApolloClient();

  const orders = await Promise.all(
    tokens.map(token =>
      apolloClient
        .query<OrderDetails, OrderDetailsVariables>({
          query: orderDetailsQuery,
          variables: { token },
        })
        .then(data => {
          const variantsArray = data.data?.orderByToken?.lines.reduce<
            OrderDetails_orderByToken_lines_variant[]
          >((acc, line) => {
            if (line.variant) {
              acc.push(line.variant);
            }
            return acc;
          }, []);

          const canBeDispatchedMap =
            variantsArray && variantsArray?.length > 0
              ? checkCanBeDispatchedMap(
                  variantsArray as unknown as Order_lines_variant[],
                  locale
                )
              : {};

          return {
            ...data.data?.orderByToken,
            canBeDispatchedMap,
          };
        })
    )
  );

  return orders;
};

export const fetchCollection =
  async <T, V extends OperationVariables>(
    client: ApolloClient<any>,
    query: DocumentNode
  ) =>
  async (variables: V) => {
    const { data } = await client.query<T, V>({
      query,
      variables: { first: ssrFetchBatch, ...variables },
    });

    return (data || {}) as T;
  };
