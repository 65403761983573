import React from "react";
import { components, ControlProps } from "react-select";

import { DATA_TEST_ATTRIBUTE, OptionType } from "@utils";

export const Control = ({
  children,
  selectProps,
  ...props
}: ControlProps<OptionType, boolean>) => (
  <components.Control
    {...props}
    innerProps={{
      ...props.innerProps,
      ...{
        [DATA_TEST_ATTRIBUTE]: selectProps.dataTest,
      },
    }}
    selectProps={selectProps}
  >
    {children}
  </components.Control>
);
