import React, { useEffect, useRef } from "react";

import { Container } from "@components/Container";
import { Navigation } from "@components/Navigation";
import { Toast } from "@components/Toast";
import { TopBanner } from "@components/TopBanner";
import { useIsMdUp } from "@hooks/responsive";
import { useHeaderStore } from "@stores/useHeaderStore";
import { theme } from "@styles";
import { LayoutProps } from "@utils/typescript";

import { MainHeader } from "./components/MainHeader";

import * as S from "./styles";

export const Header = ({ variant }: LayoutProps) => {
  const isMdUp = useIsMdUp();
  const topBarRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);

  const {
    search,
    basket,
    topBanner,
    nav: { menu, setOffset },
    header: { setHeight },
  } = useHeaderStore();

  const slideUp = menu.isActive || search.isActive || basket.isActive;

  useEffect(() => {
    setOffset({
      menu: topBanner.isActive
        ? isMdUp
          ? theme.promoBanner.size.desktop
          : theme.promoBanner.size.mobile
        : 0,
      tab: topBarRef.current?.clientHeight ?? 0,
    });

    const updateHeaderHeight = () => {
      if (headerRef?.current) {
        setHeight(headerRef.current.getBoundingClientRect().height);
      }
    };

    window.addEventListener("resize", updateHeaderHeight);

    updateHeaderHeight();

    return () => {
      window.removeEventListener("resize", updateHeaderHeight);
    };
  }, [topBanner.isActive, isMdUp]);

  useEffect(() => {
    menu.toggleActive(false);
  }, [isMdUp]);

  return (
    <S.Header ref={headerRef}>
      <TopBanner slideUp={slideUp} />
      <S.TopBar isTabVisible={false} ref={topBarRef}>
        <Container style={{ height: "100%" }}>
          <MainHeader />
        </Container>
      </S.TopBar>
      <Toast />
      <Navigation variant={variant} />
    </S.Header>
  );
};

Header.displayName = "Header";
