import React from "react";

import { Theme } from "@styles/theme";

import * as M from "../messages";
import * as S from "./styles";

export type BadgeVariant = "listing" | "popularSearch" | "productPage";
export type ButtonType = keyof Theme["productBadge"]["colors"];

export interface ProductBadgeProps {
  isActive: boolean;
  type: ButtonType;
  variant: BadgeVariant;
}

export const ProductBadge = ({
  variant,
  type,
  isActive,
}: ProductBadgeProps) => {
  const getMessage = (type: ButtonType) => {
    switch (type) {
      case "exclusive":
        return M.ExclusiveBadge();
      case "essential":
        return M.EssentialBadge();
      case "sale":
        return M.SaleBadge();
      case "signed":
        return M.SignedBadge();
      case "stock":
        return M.StockBadge();
      default:
        return null;
    }
  };

  const message = getMessage(type);

  if (!message) {
    return null;
  }

  return (
    <S.Text isActive={isActive} type={type} variant={variant}>
      {message}
    </S.Text>
  );
};
