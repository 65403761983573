import { createContext } from "react";

import { generateInsightsAnonymousToken } from "./utils";

export type SearchInsightsState = {
  insightsUserToken: string;
  setInsightsUserToken: (token: string) => void;
};

export const defaultSearchInsightsState: SearchInsightsState = {
  insightsUserToken: generateInsightsAnonymousToken(),
  setInsightsUserToken: () => {
    throw new Error("Method not implemented");
  },
};

export const SearchInsightsContext = createContext<SearchInsightsState>(
  defaultSearchInsightsState
);
