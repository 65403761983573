import { Checkout } from "@gqlTypes/Checkout";
import { CheckoutLine } from "@gqlTypes/CheckoutLine";
import { CollectionProduct } from "@gqlTypes/CollectionProduct";
import { OrderLine } from "@gqlTypes/OrderLine";
import { PricedProductVariant } from "@gqlTypes/PricedProductVariant";
import { Product, Product_collections } from "@gqlTypes/Product";
import { ProductType } from "@gqlTypes/ProductType";
import { ProductVariant } from "@gqlTypes/ProductVariant";
import { SelectedAttribute } from "@gqlTypes/SelectedAttribute";
import { paths } from "@paths";
import { generatePath } from "react-router";

import { MetadataKey } from "@config";

import {
  getReleaseDate,
  getVariantSlug,
  populateArtistsSlug,
  VariantSlug,
} from "./attributes";
import { PartialOnly } from "./typescript";

export interface ProductLine extends PartialOnly<OrderLine, "__typename"> {
  attributes?: SelectedAttribute[];
  onSale: boolean;
  variantId?: string;
}

export const orderLineToProduct = (line: OrderLine): ProductLine => {
  const variant = line?.variant;
  let attributes = [] as SelectedAttribute[];

  if (variant) {
    attributes = [...variant.attributes, ...variant.product.attributes];
  }

  return {
    ...line,
    attributes,
    onSale: line.unitPrice.net.amount !== line.undiscountedUnitPrice.net.amount,
  };
};
type GeneratePathOpts = { slug: string } & (
  | { artist?: never; attributes: Product["attributes"] }
  | { artist: string[]; attributes?: never }
);

export const generateArtistPath = (
  opts: Omit<GeneratePathOpts, "slug">,
  silent = false
) => {
  const artist = populateArtistsSlug(
    opts.artist ? { artists: opts.artist } : { attributes: opts.attributes! }
  );
  if (!artist) {
    if (!silent) {
      console.error("Missing artists.", { opts });
    }
    return "";
  }
  return generatePath(paths.artist, { slug: artist });
};

export const generateProductPath = (
  { slug, ...opts }: GeneratePathOpts,
  silent = false
) => {
  const artist = populateArtistsSlug(
    opts.artist ? { artists: opts.artist } : { attributes: opts.attributes }
  );

  if (!artist) {
    if (!silent) {
      console.error("Missing artists for product", { slug });
    }
    return "";
  }
  return generatePath(paths.product, {
    artist,
    productSlug: slug,
  });
};

export const generateProductVariantPath = (
  {
    productAttributes,
    slug,
    productType,
    variantAttributes,
  }: Pick<Product, "slug"> & {
    productAttributes: Product["attributes"];
    productType: Pick<ProductType, "slug">;
    variantAttributes: ProductVariant["attributes"];
  },
  silent = false
) => {
  const artist = populateArtistsSlug({ attributes: productAttributes });
  const variantSlug = getVariantSlug(productType, variantAttributes);

  if (!artist) {
    if (!silent) {
      console.error("Missing artists for product", { slug });
    }
    return "";
  }
  return `${generatePath(paths.product, {
    artist,
    productSlug: slug,
  })}#${variantSlug}`;
};

export const hasVariantSufficientStock = (
  variantId: string,
  variantQuantityAvailable: number,
  lines: Checkout["lines"] | null | undefined
) => {
  const checkoutLines = lines as CheckoutLine[];
  const checkoutQuantity =
    checkoutLines?.find(({ variant }) => variant.id! === variantId)?.quantity ??
    0;
  return variantQuantityAvailable > checkoutQuantity;
};

export const filterNotAvailableVariant = (variant: PricedProductVariant) =>
  (variant.quantityAvailable ?? 0) > 0;

export const shouldNotBeDisplayed = (variant: ProductVariant) =>
  variant.pricing?.price?.gross?.amount === 0;

export const isInPreorder = (attributes: SelectedAttribute[]) => {
  const releaseDate = getReleaseDate(attributes);
  return releaseDate && new Date() < releaseDate;
};

export const filterOutCollectionType = (
  type: string,
  collections: Product_collections[] | null | undefined
) =>
  collections?.filter(collection =>
    collection?.metadata.some(
      entry =>
        entry?.key === MetadataKey.COLLECTION_SOURCE && entry?.value === type
    )
  ) ?? [];

export const isProductValid = (product: Product | CollectionProduct) => {
  if (!product.channelListings?.length) {
    return false;
  }

  const channelListing = product.channelListings.find(listing => listing)!;
  const { publishedAt, isPublished } = channelListing;
  const shouldNotBePublished =
    publishedAt && new Date() < new Date(publishedAt);
  if (!isPublished || shouldNotBePublished) {
    return false;
  }

  const hasNoVariants = product.variants?.length === 0;
  if (hasNoVariants) {
    return false;
  }

  const hasNoPrice =
    (product.pricing?.priceRange?.stop?.gross.amount ?? 0) === 0;
  if (hasNoPrice) {
    return false;
  }

  return true;
};

const formatRegExp = new RegExp("^format-.+-(gb|us|eu)");
export const invalidVariantsFilter = (variant: ProductVariant) => {
  if (variant.attributes) {
    const hasNoFormat = !variant.attributes.find(
      ({ attribute }) => attribute.slug && formatRegExp.test(attribute.slug)
    )?.values.length;

    // Exclude variants without format
    if (hasNoFormat) {
      console.error(
        `\nSkipping variant ${variant.id} because of missing format attribute.`
      );
      return false;
    }
  }

  if (variant.metafields) {
    // Exclude unpublished variants
    const isUnpublished = !!variant.metafields[MetadataKey.VARIANT_UNPUBLISHED];
    if (isUnpublished) {
      return false;
    }

    // Exclude archived variants
    const isArchived =
      variant.metafields[MetadataKey.VARIANT_ARCHIVING] === "archived";
    if (isArchived) {
      return false;
    }
  }

  // Exclude variants that cost 0
  if (
    variant?.pricing?.price &&
    (variant.pricing.price.net?.amount ?? 0) === 0
  ) {
    console.error(`\nSkipping variant ${variant.id} because its price is 0.`);
    return false;
  }

  return true;
};

export const getVariantSku = (asPath: string, variants: VariantSlug[]) => {
  const variantSlug = asPath.split("#")[1];
  if (variantSlug) {
    return (
      variants.find(variant => variant.slug === variantSlug)?.variant.sku ||
      null
    );
  }
  return null;
};
