import React from "react";

import { Container } from "@components/Container";
import { ssrMode } from "@config";
import { useCheckoutContext } from "@hooks/providers";
import { useIsLgDown } from "@hooks/responsive";

import { Cart } from "./components/Cart";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";

import * as M from "./messages";
import * as S from "./styles";

type CheckoutWithCartLayoutProps = {
  children?: React.ReactNode;
};

export const CheckoutWithCartLayout = ({
  children,
}: CheckoutWithCartLayoutProps) => {
  const { isCartNotEmpty, queue } = useCheckoutContext();
  const isLgDown = useIsLgDown();

  const showCart = isCartNotEmpty;
  const cartHeader = queue
    ? M.CartOrderOf(queue.lastCreated, queue.totalToCreate)
    : undefined;

  return ssrMode ? null : (
    <S.Wrapper>
      <S.Column lg={isCartNotEmpty ? 8 : 12}>
        <Header dark={isLgDown}>
          {showCart && isLgDown && (
            <S.CartWrapper>
              <Cart header={cartHeader} />
            </S.CartWrapper>
          )}
        </Header>
        <Container maxWidth="md">
          <S.Content>{children}</S.Content>
        </Container>
        <Footer />
      </S.Column>
      {showCart && !isLgDown && (
        <S.Column lg={4}>
          <Cart header={cartHeader} />
        </S.Column>
      )}
    </S.Wrapper>
  );
};
