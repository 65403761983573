import { MainNavigationDocumentDataMenuItemItem } from "prismicio-types";
import React from "react";

import { useIsMdUp } from "@hooks/responsive";
import { useHeaderStore } from "@stores/useHeaderStore";
import { SvgIcon } from "@utils";

import * as S from "./styles";

interface SubMenuProps {
  icon?: SvgIcon;
  iconFirst?: boolean;
  isActive?: boolean;
  isButton?: boolean;
  justifyContent?: string;
  label: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  uid?: string;
  url?: string;
  variant?: MainNavigationDocumentDataMenuItemItem["variant"];
}

/** Top level nav item */
export const TopItem = ({
  label,
  url,
  uid,
  icon: Icon,
  isButton,
  isActive,
  justifyContent = "space-between",
  iconFirst = false,
  variant = "default",
  onClick,
}: SubMenuProps) => {
  const {
    nav: { isEventsHovered, setIsEventsHovered, menu },
  } = useHeaderStore();
  const isMdUp = useIsMdUp();

  const handleFocus = (
    e: React.FocusEvent<HTMLButtonElement>,
    uid?: string
  ) => {
    e.stopPropagation();
    e.preventDefault();

    if (e.type === "focus" && !isMdUp) {
      menu.updateMenu({
        activeTab: uid,
        isActive: true,
      });
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    setTimeout(() => {
      if (!document.activeElement?.closest(".list-wrapper")) {
        menu.updateMenu({
          activeTab: null,
          isActive: false,
        });
      }
    }, 0);
  };

  return (
    <>
      <S.MobileTopItem
        iconFirst={iconFirst}
        isActive={isButton}
        justify={justifyContent}
        onBlur={e => handleBlur(e)}
        onClick={onClick}
        onFocus={e => handleFocus(e, uid)}
        variant={variant}
      >
        {label}
        {Icon && (
          <S.IconWrapper>
            <Icon height={16} />
          </S.IconWrapper>
        )}
      </S.MobileTopItem>

      {url && !isButton && (
        <S.LinkWrapper
          onMouseEnter={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            if (uid === "events" || !isEventsHovered) {
              setIsEventsHovered(true);
            }
          }}
        >
          <S.NavLink href={url} isActive={isActive}>
            {Icon && (
              <S.IconWrapper>
                <Icon height={16} />
              </S.IconWrapper>
            )}
            {label}
          </S.NavLink>
        </S.LinkWrapper>
      )}
    </>
  );
};
