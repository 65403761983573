import { clientPaths, paths } from "@paths";
import isEqual from "lodash/isEqual";
import queryString from "query-string";
import React, { useCallback, useEffect, useRef, useState } from "react";

import { Filters } from "@components/ProductFilters/types";
import { buildUrlFromFilters } from "@components/ProductFilters/utils";
import { useRouter } from "@hooks/useRouter";

import { defaultFilters, ListingContext, ListingContextState } from "./context";

interface ListingProviderProps {
  children?: React.ReactNode;
  initialContext: ListingContextState;
}

export const ListingProvider = ({
  initialContext,
  children,
}: ListingProviderProps) => {
  const { push } = useRouter();
  const [currentCtx, setCurrentCtx] =
    useState<ListingContextState>(initialContext);
  const ctxRef = useRef(initialContext);

  const updateFilters = useCallback(
    (filters: Partial<Filters>): void =>
      setCurrentCtx(ctx => ({
        ...ctx,
        filters: { ...ctx.filters, ...filters },
      })),
    []
  );

  const resetFilters = useCallback(
    () =>
      setCurrentCtx(ctx => ({
        ...ctx,
        collection: null,
        filters: defaultFilters,
      })),
    []
  );

  const updateUrl = useCallback(async () => {
    const urlFilters = buildUrlFromFilters(currentCtx?.filters);
    const redirectToListing = !currentCtx.filters["collections.name"];
    const collectionSlug = currentCtx.collection?.slug;

    let pathname;
    let query;

    if (redirectToListing) {
      pathname = paths.search;
      query = urlFilters;
    } else {
      pathname = clientPaths.collectionView;
      const filters = queryString.parse(urlFilters);

      // Delete collection filter to avoid such URL: /collection/<slug>?collections.name=<slug>
      delete filters["collections.name"];

      query = {
        collectionSlug,
        ...filters,
      };
    }

    await push(
      {
        pathname,
        query,
      },
      undefined,
      { scroll: true, shallow: true }
    );
  }, [currentCtx]);

  useEffect(() => {
    const filtersHaveChanged = !isEqual(
      currentCtx.filters,
      ctxRef.current.filters
    );

    if (filtersHaveChanged) {
      updateUrl();
    }

    ctxRef.current = currentCtx;
  }, [currentCtx, updateUrl]);

  useEffect(() => {
    ctxRef.current = initialContext;
    setCurrentCtx(initialContext);
  }, [initialContext]);

  return (
    <ListingContext.Provider
      value={{ ...currentCtx, resetFilters, updateFilters }}
    >
      {children}
    </ListingContext.Provider>
  );
};
