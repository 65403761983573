import { defineMessages } from "react-intl";

export const cart = defineMessages({
  AddedToCart: {
    defaultMessage: "Added to basket",
    id: "jXiH1S",
  },
  AddedToCartMobile: {
    defaultMessage: "Product added",
    id: "OIvQCm",
  },
  CartFailed: {
    defaultMessage: "Failed to add to basket",
    id: "gW78ug",
  },
  CartFailedMobile: {
    defaultMessage: "Failed to add",
    id: "y/maVa",
  },
  ShowBag: {
    defaultMessage: "Show bag",
    id: "FVE8sx",
  },
});

export const favouriting = defineMessages({
  LikeAdded: {
    defaultMessage: "Added to favourites",
    id: "JmwW+O",
  },
  LikeAddedMobile: {
    defaultMessage: "Added",
    id: "rIAJlE",
  },
  LikeFailed: {
    defaultMessage: "Failed to update favourites",
    id: "Iyi6/5",
  },
  LikeFailedMobile: {
    defaultMessage: "Failed to add",
    id: "y/maVa",
  },
  LikeRemoved: {
    defaultMessage: "Removed from favourites",
    id: "7E6El3",
  },
  LikeRemovedMobile: {
    defaultMessage: "Removed",
    id: "KGzfLt",
  },
  ShowLikes: {
    defaultMessage: "Show favourites",
    id: "BFZmcY",
  },
});
