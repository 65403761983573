import { media, px2rem, responsive, styled } from "@styles";

import { AlgoliaHitProps } from "./AlgoliaHitComponent";

export const DEFAULT_MOBILE_COVER_SIZE = 150;

export const ProductCard = styled.div<Pick<AlgoliaHitProps, "textAlign">>`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  text-align: ${({ textAlign }) => textAlign || "unset"};

  a {
    text-decoration: none;
  }
`;

export const ThumbnailWrapper = styled.div<{
  coverSize?: number;
}>`
  position: relative;
  justify-content: center;
  width: ${DEFAULT_MOBILE_COVER_SIZE}px;
  overflow: hidden;
  margin-bottom: 10px;
  aspect-ratio: 1;
  place-content: center;

  ${media.lg.up} {
    width: ${({ coverSize }) => `${coverSize}px`};
    margin-bottom: 24px;
  }
`;

export const Name = styled.span<{ inNavigation: boolean; light: boolean }>`
  font-size: ${px2rem(13)};
  font-weight: 700;
  padding: 6px 0;

  color: ${({ theme, light }) =>
    light ? theme.colors.white : theme.colors.black};

  ${media.md.up} {
    font-size: ${({ inNavigation }) =>
      inNavigation ? px2rem(13) : px2rem(16)};
  }
`;

export const Creators = styled.p`
  margin-top: 2px;

  ${media.md.down} {
    line-height: 1.25rem;
  }

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.dark};
    ${responsive("font-size", px2rem(12), px2rem(14))}
    font-weight: 500;

    :hover {
      color: ${({ theme }) => theme.colors.black};
      text-decoration: underline;
    }
  }
`;
