import { ApolloProvider } from "@apollo/client";
import { PrismicPreview } from "@prismicio/next";
import { SaleorProvider } from "@saleor/sdk";
import * as Sentry from "@sentry/nextjs";
import { checkIfMobileFromUserAgent } from "@ssr/utils";
import { IncomingHttpHeaders } from "http2";
import type {
  AppContext as NextAppContext,
  AppProps as NextAppProps,
} from "next/app";
import NextApp from "next/app";
import Head from "next/head";
import Script from "next/script";
import { DefaultSeo, DefaultSeoProps } from "next-seo";
import React, { useEffect } from "react";
import { Context as ResponsiveContext } from "react-responsive";
import { sentryInitDefaults } from "sentry.client.config";
import { ThemeProvider } from "styled-components";

import { AppLoader } from "@components/AppLoader";
import { ErrorBoundary } from "@components/ErrorBoundary";
import { RegionRedirectModal } from "@components/RegionRedirectModal";
import { defaultLocale, prismicRepoName, ssrMode } from "@config";
import { useApollo } from "@hooks/useApollo";
import { useGlobalConfig } from "@hooks/useGlobalConfig";
import { useKlaviyoScript } from "@hooks/useKlaviyoScript";
import { useRouterState } from "@hooks/useRouterState";
import { useSaleor } from "@hooks/useSaleor";
import { CheckoutProvider } from "@providers/CheckoutProvider/provider";
import { ConfigContextData, ConfigProvider } from "@providers/ConfigProvider";
import { GoogleTagManagerProvider } from "@providers/GoogleTagManagerProvider/provider";
import { PlayerProvider } from "@providers/PlayerProvider/PlayerProvider";
import { SearchInsightsProvider } from "@providers/SearchInsightsProvider/provider";
import { SpotifyProvider } from "@providers/Spotify/provider";
import { TranslationProvider } from "@providers/TranslationProvider/TranslationProvider";
import type { Locale } from "@providers/TranslationProvider/types";
import { useDiceStore } from "@stores/useDiceStore";
import { GlobalStyle } from "@styles/global";
import { theme } from "@styles/theme";
import { LayoutSwitcher } from "@views/LayoutSwitcher";

import packageInfo from "../../package.json";

if (!ssrMode) {
  window.version = packageInfo.version;
}

type AppProps = NextAppProps & {
  config: ConfigContextData;
  headers?: IncomingHttpHeaders;
  isMobile: boolean;
  passedLocale: Locale;
};

Sentry.init(sentryInitDefaults);

const seoConfig: DefaultSeoProps = {
  defaultTitle: "Rough Trade - Independent Record Store | Vinyl, CDs, Books +",
  description:
    "Purveyors of great music, since 1976. Shop now for new, classic, and exclusive albums, singles, books and merch. Limited editions. Rarities. Collections.",
  openGraph: {
    images: [
      {
        alt: "Rough Trade OG Logo",
        height: 600,
        url: "https://www.roughtrade.com/RoughTradeOGWhite.png",
        width: 800,
      },
    ],
    locale: "en-gb",
    site_name: "Rough Trade",
    type: "website",
    url: "https://www.roughtrade.com",
  },
  twitter: {
    cardType: "summary_large_image",
    handle: "@RoughTrade",
    site: "@RoughTrade",
  },
};

const App = ({ Component, pageProps, isMobile, config, router }: AppProps) => {
  const apolloClient = useApollo(null);
  const saleorClient = useSaleor();
  const routerState = useRouterState();
  const { setScriptLoaded } = useDiceStore();

  useEffect(() => {
    // Mock the prefetch fn to avoid redundant fetching
    router.prefetch = async () => {};
  }, []);

  useGlobalConfig();
  useKlaviyoScript();

  return (
    <>
      <Head>
        <meta
          content="width=device-width, initial-scale=1, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
          name="viewport"
        />
      </Head>
      <DefaultSeo {...seoConfig} />
      <Script
        async
        id="dice-event-list-script"
        onLoad={() => setScriptLoaded(true)}
        src="https://widgets.dice.fm/dice-event-list-widget.js"
        strategy="lazyOnload"
        type="text/javascript"
      />
      <Script
        async
        crossOrigin="anonymous"
        src={`https://static.cdn.prismic.io/prismic.js?new=true&repo=${prismicRepoName}}`}
        strategy="afterInteractive"
        type="text/javascript"
      />

      <ThemeProvider theme={theme}>
        <PrismicPreview repositoryName={prismicRepoName}>
          <ApolloProvider client={apolloClient}>
            <SaleorProvider client={saleorClient}>
              <TranslationProvider>
                <ConfigProvider config={config}>
                  <CheckoutProvider>
                    <GoogleTagManagerProvider>
                      <SearchInsightsProvider>
                        <SpotifyProvider>
                          <GlobalStyle />
                          <PlayerProvider>
                            <AppLoader {...routerState} />
                            <ErrorBoundary>
                              <RegionRedirectModal />
                              {ssrMode ? (
                                <ResponsiveContext.Provider
                                  value={{
                                    width: isMobile
                                      ? theme.breakpoints.sm
                                      : theme.breakpoints.lg,
                                  }}
                                >
                                  <LayoutSwitcher>
                                    <Component {...pageProps} />
                                  </LayoutSwitcher>
                                </ResponsiveContext.Provider>
                              ) : (
                                <LayoutSwitcher>
                                  <Component {...pageProps} />
                                </LayoutSwitcher>
                              )}
                            </ErrorBoundary>
                          </PlayerProvider>
                        </SpotifyProvider>
                      </SearchInsightsProvider>
                    </GoogleTagManagerProvider>
                  </CheckoutProvider>
                </ConfigProvider>
              </TranslationProvider>
            </SaleorProvider>
          </ApolloProvider>
        </PrismicPreview>
      </ThemeProvider>
    </>
  );
};

App.getInitialProps = async (appContext: NextAppContext) => {
  const appProps = await NextApp.getInitialProps(appContext);
  const locale = appContext.router.locale as Locale;

  if (!locale || (locale as string) === defaultLocale) {
    return {};
  }

  const isMobile = checkIfMobileFromUserAgent(appContext.ctx.req);

  return {
    isMobile,
    ...appProps,
  };
};

export default App;
