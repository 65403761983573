import { GridColumn } from "@components/Grid";
import { media, styled } from "@styles";

export const Wrapper = styled.div`
  width: 100%;
  align-items: stretch;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
`;

export const Column = styled(GridColumn)`
  &:first-child {
    min-height: 100%;
    display: flex;
    flex-direction: column;
  }

  &:nth-child(2) {
    background: black;
    position: relative;
  }
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;

  ${media.lg.only} {
    padding-right: 2rem;
  }
`;

export const CartWrapper = styled.div`
  padding-bottom: 1rem;
`;
