import { createClient } from "@prismicConfig";
import { AllDocumentTypes } from "prismicio-types";

import { SUPPORTED_LOCALE_PATHS } from "@providers/TranslationProvider/context";

/**
 * Fetches all documents by ID in all locales
 */
const fetchUpdatedDocuments = async (documents: string[]) => {
  const client = createClient();

  // Use Promise.all to wait for all promises returned by map to resolve
  const allResults = await Promise.all(
    SUPPORTED_LOCALE_PATHS.map(lang => client.getAllByIDs(documents, { lang }))
  );

  if (allResults.length === 0) {
    return null;
  }

  const mergedResults = ([] as AllDocumentTypes[]).concat(...allResults);

  return mergedResults;
};

/**
 * Maps over all updated documents and returns an array of paths
 */
const mapDocumentPaths = (docs: AllDocumentTypes[]) => {
  const paths = docs
    ?.map(({ url, type, uid, lang }) => {
      if (type === "club_form_modal") {
        const [page, action] = uid?.split("_") || [];
        return `/${lang}/club/${page}/${action}`;
      }

      return url;
    })
    ?.filter(Boolean) as string[];

  // Check for any update to a comp and add all the comp pages to the revalidation list
  const containsCompPage = paths.some(element => element.includes("/comp"));

  if (containsCompPage) {
    SUPPORTED_LOCALE_PATHS.map(lang => paths.push(`/${lang}/comp`));
  }

  return paths;
};

/**
 * Generates an array of paths for revalidation. Can only be used in getStaticPaths
 */
export const generateRevalidatePaths = async (documents: string[]) => {
  try {
    const docs = await fetchUpdatedDocuments(documents);

    if (!docs) {
      return null;
    }

    const paths = mapDocumentPaths(docs);

    return paths;
  } catch (error) {
    console.error(error);
    return null;
  }
};
