import React from "react";

import { styled, theme } from "@styles";

interface HrProps {
  background?: string | (typeof theme)["colors"];
  height?: number;
}

const StyledHr = styled.hr<HrProps>`
  height: ${({ height }) => height || 1}px;
  background: ${({ background, theme }) => background || theme.colors.black};
`;

export const Hr = (props: HrProps) => <StyledHr {...props} />;
