import React from "react";
import { FormattedMessage } from "react-intl";

export const SecureCheckout = (
  <FormattedMessage defaultMessage="Secure Checkout" id="mwQs8j" />
);

export const CartOrderOf = (checkoutNumber: number, totalToCreate: number) => (
  <FormattedMessage
    defaultMessage="Bag: order {checkoutNumber}/{totalToCreate}"
    id="csBhMK"
    values={{ checkoutNumber, totalToCreate }}
  />
);
