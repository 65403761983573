import React, { useEffect, useState } from "react";

import { Player } from "@components/Player";
import { useSpotifyContext } from "@hooks/providers";
import { useOnUpdateRef } from "@hooks/useOnUpdateRef";
import { isTrackPlayable } from "@providers/PlayerProvider/helpers";
import { useInitPlayerApi } from "@providers/PlayerProvider/hooks";

import { defaultPlayerContextState, PlayerProviderContext } from "./context";
import { useInitPlayerHandlers } from "./handlers";
import {
  PlayerApiState,
  PlayerContext,
  PlayerContextState,
  PlayerState,
  UpdatePlayerState,
} from "./types";

type PlayerProviderProps = {
  children?: React.ReactNode;
};

export const PlayerProvider = ({ children }: PlayerProviderProps) => {
  const { isPremiumAccount } = useSpotifyContext();
  const [ctx, setCtx] = useState<PlayerContextState>(defaultPlayerContextState);
  const [playerApi, setPlayerApi] = useState<PlayerApiState>(null);
  const ctxRef = useOnUpdateRef(ctx);

  const updateContext = (state: Partial<PlayerContextState>) =>
    setCtx(ctx => ({
      ...ctx,
      ...state,
    }));

  const updatePlayerState: UpdatePlayerState = state => {
    const newState: PlayerState = {
      ...ctxRef.current.playerState,
      ...state,
    };
    updateContext({ ...ctx, playerState: newState });
    return newState;
  };

  const openPlayer = () => updateContext({ isVisible: true });

  const closePlayer = () => updateContext({ ...defaultPlayerContextState });

  const { playerHandlers, publicHandlers } = useInitPlayerHandlers({
    api: playerApi?.api,
    closePlayer,
    isPremiumAccount,
    state: ctx.playerState,
    updateState: updatePlayerState,
  });

  const albumPush: PlayerContext["albumPush"] = ({
    album,
    trackIndex = 0,
    pushToPlayList = true,
  }) => {
    if (!isReady) {
      throw new Error("Player API not ready.");
    }

    const trackList = album?.discs?.flat();
    const albums = pushToPlayList
      ? [...ctx.albums.filter(({ id }) => id !== album.id), album]
      : [album];

    updateContext({
      albums,
      playerState: {
        ...ctx.playerState,
        album,
        isVisible: true,
        trackIndex,
        trackList,
      },
    });
  };

  const isReady = !!(playerHandlers && playerApi);

  useInitPlayerApi(playerApi, setPlayerApi, playerHandlers.handlePlayerError);

  useEffect(() => {
    // Close the player if tracks are only playable for one of the accounts, and after
    // api change they are not available anymore.
    if (playerApi && ctx.playerState.album) {
      const isNonPlayable = ctx.playerState.trackList.every(
        track => !isTrackPlayable(track, isPremiumAccount)
      );

      if (isNonPlayable) {
        closePlayer();
      }
    }
  }, [playerApi]);

  return (
    <PlayerProviderContext.Provider
      value={{
        ...ctx,
        albumPush,
        closePlayer,
        handlers: publicHandlers,
        isReady,
        openPlayer,
      }}
    >
      {isReady && <Player handlers={playerHandlers} state={ctx.playerState} />}
      {children}
    </PlayerProviderContext.Provider>
  );
};
