import { theme } from "@styles/theme";

/** TODO: re-instate this for prismic data if deemed needed */

// export const getNavigation = async (
//   locale: Locale
// ): Promise<NavigationItem[]> => {
//   const navDocument = await getPrismicNavigation(locale as Locale);
//   if (!navDocument) {
//     return [];
//   }

//   const urls: string[] = [];
//   const transformedData = transformPrismicData(navDocument);
//   transformedData.forEach(({ sideMenu }) =>
//     sideMenu?.forEach(({ url }) => urls.push(url))
//   );

//   // const results = await getAlgoliaGroupedResults(urls, locale, 5);
//   // const urlsResultsMap = new Map<string, AlgoliaResult[]>();
//   // urls.forEach((url, index) => {
//   //   // Removing unnecessary attributes to reduce initial payload data
//   //   const reducedProducts = results[index].map(product => ({
//   //     artists: product.artists,
//   //     name: product.name,
//   //     slug: product.slug,
//   //     thumbnails: {
//   //       thumbnail1080: product.thumbnails.thumbnail1080,
//   //     },
//   //   })) as AlgoliaResult[];
//   //
//   //   urlsResultsMap.set(url, reducedProducts);
//   // });

//   return transformedData.map(item => ({
//     ...item,
//     sideMenu: item.sideMenu?.map(sideMenuItem => ({
//       ...sideMenuItem,
//       // products: urlsResultsMap.get(sideMenuItem.url),
//     })),
//   }));
// };

type ElementSizing = "desktop" | "mobile";
type ElementTypes = "nav" | "header" | "promoBanner";
type AllElements = {
  name: ElementTypes;
  value: {
    desktop: number;
    mobile: number;
  };
};

export const fixedElementOffset = (
  selector: ElementSizing = "desktop",
  includeArray: ElementTypes[] = ["nav", "header", "promoBanner"]
) => {
  const {
    navigation: { size: nav },
    header: { size: header },
    promoBanner: { size: promoBanner },
  } = theme;

  const allElements: AllElements[] = [
    { name: "nav", value: nav },
    { name: "header", value: header },
    { name: "promoBanner", value: promoBanner },
  ];

  const filteredValues = allElements
    .filter(item => includeArray?.includes(item.name))
    .map(item => item.value[selector]);

  const sum =
    filteredValues.length > 0
      ? filteredValues?.reduce((acc, cur) => acc + cur)
      : 0;

  return `${sum}px`;
};

export const dynamicFullHeight = (
  selector: ElementSizing = "desktop",
  includeArray: ElementTypes[] = ["nav", "header", "promoBanner"],
  base: number = 100,
  optionalExtra: string = "0px"
) => {
  const sum = fixedElementOffset(selector, includeArray);
  return `calc(${base}dvh - ${sum} - ${optionalExtra})`;
};
