import React from "react";
import { FormattedMessage } from "react-intl";

export const StockBadge = () => (
  <FormattedMessage defaultMessage="selling fast" id="YvbMaJ" />
);

export const SaleBadge = () => (
  <FormattedMessage defaultMessage="sale" id="hY6FSl" />
);

export const SignedBadge = () => (
  <FormattedMessage defaultMessage="signed" id="1uXa0/" />
);

export const ExclusiveBadge = () => (
  <FormattedMessage defaultMessage="exclusive" id="oLJmdF" />
);

export const EssentialBadge = () => (
  <FormattedMessage defaultMessage="essential" id="72jGas" />
);
