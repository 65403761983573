import { defineMessages } from "react-intl";

export const commonMessages = defineMessages({
  addNewAddress: { defaultMessage: "Add a new address", id: "gwANqu" },
  addToBag: {
    defaultMessage: "Add to Bag",
    id: "c2LsDg",
  },
  apply: {
    defaultMessage: "Apply",
    id: "EWw/tK",
  },
  applyVoucherError: {
    defaultMessage: "Voucher is not applicable to this checkout.",
    id: "oLyv90",
  },
  approved: {
    defaultMessage: "Approved",
    id: "6XFO/C",
  },
  artist: {
    defaultMessage: "Artist",
    id: "MHbdXv",
  },
  bag: {
    defaultMessage: "Your basket",
    id: "EWbmo5",
  },
  basketEmpty: {
    defaultMessage: "Your basket is empty",
    id: "qLYUng",
  },
  billingAddress: {
    defaultMessage: "Billing address",
    id: "6orx1c",
  },
  cancel: {
    defaultMessage: "Cancel",
    id: "47FYwb",
  },
  cardDetails: {
    defaultMessage: "Card Details",
    id: "aFJYR3",
  },
  changePassword: {
    defaultMessage: "Change password",
    id: "L4nXIc",
  },
  checkout: {
    defaultMessage: "Checkout",
    id: "BJ2TKX",
  },
  collectFromStore: {
    defaultMessage: "Collect from your store",
    id: "ZMqYmz",
  },
  connectWithSpotify: {
    defaultMessage: "Connect with Spotify",
    id: "WBLPks",
  },
  continue: {
    defaultMessage: "Continue",
    id: "acrOoz",
  },
  continueShopping: {
    defaultMessage: "Continue shopping",
    id: "Yywm0p",
  },
  creditCard: {
    defaultMessage: "Credit card",
    id: "kvOMtB",
  },
  delete: {
    defaultMessage: "Delete",
    id: "K3r6DQ",
  },
  deleteAddress: {
    defaultMessage: "Delete address",
    id: "74xz0X",
  },
  delivery: {
    defaultMessage: "Delivery",
    id: "drqP2L",
  },
  deliveryAddress: {
    defaultMessage: "Delivery address",
    id: "v3tQCl",
  },
  deliveryOption: {
    defaultMessage: "Delivery option",
    id: "PCqc5g",
  },
  deliveryOptions: {
    defaultMessage: "Delivery options",
    id: "cOa1Df",
  },
  discounts: {
    defaultMessage: "Discounts",
    id: "n+Gwbu",
  },
  edit: {
    defaultMessage: "Edit",
    id: "wEQDC6",
  },
  email: {
    defaultMessage: "Your Email",
    id: "SqR1My",
  },
  emailMobile: {
    defaultMessage: "Email",
    id: "sy+pv5",
  },
  finalPriceAtCheckout: {
    defaultMessage: "Shipping and final taxes will be calculated at checkout",
    id: "Hi1dy4",
  },
  followOnSpotify: {
    defaultMessage: "Follow us on Spotify",
    id: "zQi2PH",
  },
  followOnSpotifySuccess: {
    defaultMessage: "Following Rough Trade",
    id: "SiG1Ar",
  },
  getInspired: {
    defaultMessage: "Get inspired and discover new music",
    id: "TmBbos",
  },
  giftCard: {
    defaultMessage: "Gift card",
    id: "4X0ZI8",
  },
  goToStore: {
    defaultMessage: "Go to store",
    id: "hVJKgy",
  },
  inYourBag: {
    defaultMessage: "In your bag",
    id: "bXYZmx",
  },
  inYourBasket: {
    defaultMessage: "In your basket",
    id: "tkADhJ",
  },
  marketingConsent: {
    defaultMessage: "I consent to receive emails from Rough Trade",
    id: "UkSxZ5",
  },
  myProfile: {
    defaultMessage: "My profile",
    id: "BEX6A7",
  },
  payment: {
    defaultMessage: "Payment",
    id: "NmK6zy",
  },
  preOrder: {
    defaultMessage: "Pre-order",
    id: "NIzVk0",
  },
  redeemCode: {
    defaultMessage: "Redeem code",
    id: "mpt63+",
  },
  remove: {
    defaultMessage: "Remove",
    id: "G/yZLu",
  },
  reset: {
    defaultMessage: "Reset",
    id: "jm/spn",
  },
  roughTradeDescription: {
    defaultMessage: "Independent purveyors of great music, since 1976",
    id: "o7pbeP",
  },
  sameAsShipping: {
    defaultMessage: "Same as delivery address",
    id: "HRNlH0",
  },
  seeAll: {
    defaultMessage: "See all",
    id: "078WAr",
  },
  shipping: {
    defaultMessage: "Shipping",
    id: "PRlD0A",
  },
  submit: {
    defaultMessage: "Submit",
    description: "Confirm language and currency preferences",
    id: "V/m7Ya",
  },
  subtotal: {
    defaultMessage: "Subtotal",
    id: "L8seEc",
  },
  success: {
    defaultMessage: "Success",
    id: "xrKHS6",
  },
  summary: {
    defaultMessage: "Summary",
    id: "RrCui3",
  },
  termsAndConditions: {
    defaultMessage: "Terms & Conditions",
    id: "arPp4e",
  },
  total: {
    defaultMessage: "Total",
    id: "MJ2jZQ",
  },
  visitSpotifyPage: {
    defaultMessage: "Visit Rough Trade on Spotify",
    id: "rz0fIS",
  },
  yourBag: {
    defaultMessage: "Your bag",
    id: "eJS+/3",
  },
});

export const paginationMessages = defineMessages({
  currentPage: {
    defaultMessage: "Current page",
    id: "MdntHP",
  },
  firstPage: {
    defaultMessage: "First page",
    id: "A64Z+T",
  },
  lastPage: {
    defaultMessage: "Last page",
    id: "N8BBfe",
  },
  nextPage: {
    defaultMessage: "Next page",
    id: "rBj9Ib",
  },
  previousPage: {
    defaultMessage: "Previous page",
    id: "k9hDFZ",
  },
});
