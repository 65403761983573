import { media, noPrint, px2rem, responsive, styled, theme } from "@styles";

import { ReactComponent as LockSvg } from "assets/icons/lock.svg";

export type HeaderProps = { dark: boolean };

export const Wrapper = styled.div<HeaderProps>`
  display: flex;
  align-items: center;
  padding: ${px2rem(31)} ${px2rem(35)} ${px2rem(10)};

  ${responsive("margin-bottom", px2rem(42), px2rem(45))}
  ${noPrint}


  ${({ theme, dark }) =>
    dark &&
    `
    color: ${theme.colors.white};
    background-color: ${theme.colors.black};
  `}


  h2 {
    ${responsive("font-size", px2rem(16), theme.typography.sizes.large)}
  }

  svg path {
    fill: ${({ theme, dark }) =>
      dark ? theme.colors.white : theme.colors.black};
  }

  ${media.md.up} {
    padding: 2rem 2.25rem;
  }
`;

export const Lock = styled(LockSvg)`
  height: 16px;
  padding: 0 ${px2rem(12)};
`;
