import React from "react";

import * as S from "./styles";

interface QuantityControlsProps {
  canAddMore: boolean;
  onAdd: () => void;
  onRemove: () => void;
  processing: boolean;
  quantity: number;
}

export const QuantityControls = ({
  quantity,
  onAdd,
  onRemove,
  canAddMore,
  processing,
}: QuantityControlsProps) => (
  <S.Wrapper>
    <S.QuantityControl
      aria-label="remove quantity"
      disabled={processing}
      onClick={evt => {
        evt.preventDefault();
        evt.stopPropagation();

        if (!processing) {
          onRemove();
        }
      }}
      processing={processing}
    >
      <span>-</span>
    </S.QuantityControl>
    <span aria-label="quantity">{quantity}</span>
    <S.QuantityControl
      aria-label="add quantity"
      disabled={processing || !canAddMore}
      onClick={evt => {
        evt.preventDefault();
        evt.stopPropagation();

        if (!processing && canAddMore) {
          onAdd();
        }
      }}
      processing={processing}
    >
      <span>+</span>
    </S.QuantityControl>
  </S.Wrapper>
);
