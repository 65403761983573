import {
  endOfISOWeek,
  endOfMonth,
  endOfYear,
  startOfISOWeek,
  startOfMonth,
  startOfYear,
} from "date-fns";

type ReleaseTimestamps = {
  monthEnd: number;
  monthStart: number;
  today: number;
  weekEnd: number;
  weekStart: number;
  yearEnd: number;
  yearStart: number;
};

const SECONDS_IN_MINUTE = 60;
const SECONDS_IN_HOUR = SECONDS_IN_MINUTE * 60;
const SECONDS_IN_DAY = SECONDS_IN_HOUR * 24;
const SECONDS_IN_WEEK = SECONDS_IN_DAY * 7;
const SECONDS_IN_MONTH = SECONDS_IN_DAY * 30; // Approximation for a month
const SECONDS_IN_YEAR = SECONDS_IN_DAY * 365;

const now = new Date();

export const getTimestamps = (): ReleaseTimestamps => {
  const milliseconds = 1000;
  const today = now.getTime() / milliseconds;
  const weekStart = startOfISOWeek(now).getTime() / milliseconds;
  const weekEnd = endOfISOWeek(now).getTime() / milliseconds;
  const monthStart = startOfMonth(now).getTime() / milliseconds;
  const monthEnd = endOfMonth(now).getTime() / milliseconds;
  const yearStart = startOfYear(now).getTime() / milliseconds;
  const yearEnd = endOfYear(now).getTime() / milliseconds;

  return {
    monthEnd,
    monthStart,
    today,
    weekEnd,
    weekStart,
    yearEnd,
    yearStart,
  };
};

/**
 * Get caching durations in seconds for various time periods.
 */
export const getCachingDuration = () => ({
  FIFTEEN_MINUTES: SECONDS_IN_MINUTE * 15,
  FIVE_MINUTES: SECONDS_IN_MINUTE * 5,
  HALF_HOUR: SECONDS_IN_MINUTE * 30,
  ONE_DAY: SECONDS_IN_DAY,
  ONE_HOUR: SECONDS_IN_HOUR,
  ONE_MONTH: SECONDS_IN_MONTH,
  ONE_WEEK: SECONDS_IN_WEEK,
  ONE_YEAR: SECONDS_IN_YEAR,
  TWELVE_HOURS: SECONDS_IN_HOUR * 12,
  TWO_DAYS: SECONDS_IN_DAY * 2,
});

const monthAbbreviations = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const getMonthName = (dateTimeString: string | number) => {
  const date = new Date(dateTimeString);
  const monthIndex = date.getMonth();

  return monthAbbreviations[monthIndex];
};

export const getDateDay = (dateTimeString: string | number) => {
  const date = new Date(dateTimeString);

  return date.getDate();
};
