import {
  MutationHookOptions,
  OperationVariables,
  useMutation,
} from "@apollo/client";
import type {
  DeleteMetadata,
  DeleteMetadataVariables,
} from "@gqlTypes/DeleteMetadata";
import type {
  UpdateMetadata,
  UpdateMetadataVariables,
} from "@gqlTypes/UpdateMetadata";
import type { DynamicLinkClientName } from "@graphql/client";
import type { DocumentNode } from "graphql";
import { gql } from "graphql-tag";

import {
  accountErrorFragment,
  metadataErrorFragment,
  metadataFragment,
} from "./fragments";
import { MutationHookFn, MutationTuple } from "./types";

export function useTypedMutation<TData = {}, TVariables = OperationVariables>(
  mutation: DocumentNode,
  options?: MutationHookOptions<TData, TVariables> & {
    context: { client: DynamicLinkClientName };
  }
): MutationTuple<TData, TVariables> {
  const [mutationFn, result] = useMutation<TData, TVariables>(
    mutation,
    options
  );
  return [mutationFn as MutationHookFn<TData, TVariables>, result];
}

export const metadataUpdateMutation = gql`
  ${metadataFragment}
  ${metadataErrorFragment}
  mutation UpdateMetadata($id: ID!, $input: [MetadataInput!]!) {
    updateMetadata(id: $id, input: $input) {
      errors {
        ...MetadataError
      }
      item {
        metadata {
          ...MetadataItem
        }
      }
    }
  }
`;

export const useMetadataUpdate = () =>
  useTypedMutation<UpdateMetadata, UpdateMetadataVariables>(
    metadataUpdateMutation
  );

const metadataDeleteMutation = gql`
  ${metadataFragment}
  ${metadataErrorFragment}
  mutation DeleteMetadata($id: ID!, $keys: [String!]!) {
    deleteMetadata(id: $id, keys: $keys) {
      errors {
        ...MetadataError
      }
      item {
        metadata {
          ...MetadataItem
        }
      }
    }
  }
`;

export const useMetadataDelete = () =>
  useTypedMutation<DeleteMetadata, DeleteMetadataVariables>(
    metadataDeleteMutation
  );

export const tokenVerifyMutation = gql`
  ${accountErrorFragment}
  ${metadataFragment}
  mutation TokenVerify($token: String!) {
    tokenVerify(token: $token) {
      isValid
      user {
        id
        email
        metadata {
          ...MetadataItem
        }
      }
      errors {
        ...AccountError
      }
    }
  }
`;
