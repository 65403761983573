import React, { useEffect, useState } from "react";

import fallbackJpg from "../assets/images/fallback-image.jpg";

type HtmlImageProps = React.DetailedHTMLProps<
  React.ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>;
type ImageProps = Omit<HtmlImageProps, "src"> & {
  fallbackSrc?: string;
  src?: string | null | undefined;
};

export const Image = ({ src, onError, fallbackSrc, ...props }: ImageProps) => {
  const placeholder = fallbackSrc || fallbackJpg;
  const [imgSrc, setImgSrc] = useState<string>(src || placeholder);

  useEffect(() => {
    setImgSrc(src || placeholder);
  }, [src]);

  return (
    <img
      alt=""
      key={src}
      onError={evt => {
        setImgSrc(placeholder);
        if (onError) {
          onError(evt);
        }
      }}
      {...props}
      src={imgSrc}
    />
  );
};
