import React from "react";

import { getTestAttribute, ProductLine } from "@utils";

import { Line } from "./components";

import * as S from "./styles";

type CartProps = { lines: ProductLine[] };

export const Cart = ({ lines }: CartProps) => (
  <S.Table {...getTestAttribute("lines", "cart")}>
    <tbody>
      {lines.map((line, idx) => (
        <Line key={idx} line={line} />
      ))}
    </tbody>
  </S.Table>
);
