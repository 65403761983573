import NextLink, { LinkProps as NextLinkProps } from "next/link";
import { useRouter } from "next/router";
import React, { useMemo } from "react";
import { ExtractRouteParams } from "react-router";

import { generateNextHref } from "@utils/urls";

type NextHref = NextLinkProps["href"];
type GeneratePath = { path: string } & ExtractRouteParams<any>;
export type LinkProps = Omit<NextLinkProps, "href"> &
  (
    | { generatePath: GeneratePath; href?: never }
    | { generatePath?: never; href: NextHref }
  ) & {
    aProps?: React.DetailedHTMLProps<
      React.AnchorHTMLAttributes<HTMLAnchorElement>,
      HTMLAnchorElement
    >;
    children?: React.ReactNode;
    className?: string;
    wrapperTag?: boolean;
  };

export const Link = ({
  href: nextHref,
  generatePath,
  wrapperTag = false,
  passHref = true,
  children,
  aProps,
  ...props
}: LinkProps) => {
  const { locale } = useRouter();
  const href = useMemo(() => {
    if (generatePath) {
      return generateNextHref(generatePath);
    }
    return nextHref;
  }, [nextHref, generatePath, props]) as NextHref;
  const body = wrapperTag ? (
    <a className={props.className} {...aProps}>
      {children}
    </a>
  ) : (
    children
  );

  return (
    <NextLink
      as={href}
      href={href}
      locale={locale}
      passHref={passHref}
      prefetch={false}
      {...props}
    >
      {body}
    </NextLink>
  );
};
