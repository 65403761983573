import { media, styled } from "@styles";

export const Track = styled.div`
  background-color: rgba(94, 94, 94, 0.4);
  width: 100%;
  height: 3px;
  margin: 0;

  ${media.lg.up} {
    height: 4px;
  }
`;

export const Progress = styled.div`
  position: relative;
  height: 100%;
  width: 0;
  background-color: ${({ theme }) => theme.colors.gold};

  ${media.lg.up} {
    background-color: ${({ theme }) => theme.colors.white};
  }
`;

export const Dragger = styled.div`
  display: none;
  position: relative;
  left: 8px;
  top: -6px;
  background-color: ${({ theme }) => theme.colors.gold};
  border-radius: 50%;
  width: 16px;
  height: 16px;
  flex: 0 0 16px;
  float: right;
`;

export const ProgressBarContainer = styled.div<{ isExpanded?: boolean }>`
  cursor: pointer;
  position: relative;
  width: 100%;
  display: flex;
  height: 24px;
  align-items: center;
  background-color: ${({ isExpanded, theme }) =>
    isExpanded ? theme.colors.black : "transparent"};

  ${media.lg.down} {
    padding: ${({ isExpanded }) => (isExpanded ? "20px 0" : "0")};

    ${Track} {
      height: ${({ isExpanded }) => (isExpanded ? "6px" : "3px")};
    }
  }

  &:hover {
    ${Dragger} {
      display: flex;
    }

    ${Progress} {
      background-color: ${({ theme }) => theme.colors.gold};
    }
  }
`;
