import {
  ApolloClient,
  ApolloClientOptions,
  ApolloLink,
  createHttpLink,
  from,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  Operation,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import { relayStylePagination } from "@apollo/client/utilities";
import {
  createFetch,
  createSaleorClient as sdkCreateSaleorClient,
  SaleorClient,
} from "@saleor/sdk";
import { RestLink } from "apollo-link-rest";

import {
  apiUri,
  AppEvents,
  graphqlOperationPrefix,
  orderAppApiUri,
  orderCancelAppApiUri,
  saleorAppToken,
  ssrMode,
} from "@config";
import { invalidVariantsFilter } from "@utils/products";

import possibleTypes from "../../possibleTypes.json";

const retryLink = new RetryLink({
  attempts: {
    max: 3,
  },
  delay: {
    initial: 500,
    jitter: true,
    max: 500,
  },
});

const restFetch = async (input: RequestInfo, init: RequestInit = {}) => {
  const response = await createFetch({
    autoTokenRefresh: false,
    refreshOnUnauthorized: false,
  })(input, init);

  if (!response.ok && response.status < 500) {
    const json = await response.json();
    return new Response(JSON.stringify({ error: json }), {
      headers: response.headers,
      status: 200,
      statusText: response.statusText,
    });
  }
  return response;
};

const createRestHttpLink = (uri: string, location: any) => {
  if (typeof uri !== "string" || uri.trim() === "") {
    throw new Error(
      `Invalid URI provided to createRestHttpLink ${uri} ${location} `
    );
  }

  return new RestLink({
    customFetch: restFetch,
    uri,
  });
};

const saleorLink = new HttpLink({
  fetch: createFetch(),
  uri: apiUri,
});
const orderAppLink = new HttpLink({
  fetch: createFetch({ autoTokenRefresh: false, refreshOnUnauthorized: false }),
  uri: orderAppApiUri,
});
const orderCancelAppRestLink = createRestHttpLink(
  orderCancelAppApiUri,
  "orderCancelApp"
);
const localApiRestLink = createRestHttpLink("/api/", "local");

export type DynamicLinkClientName = "localApi" | "orderApp" | "orderCancelApp";
type Link = RestLink | HttpLink;
type DynamicLink = { link: Link; name: DynamicLinkClientName };
const LINK_MAP: DynamicLink[] = [
  { link: localApiRestLink, name: "localApi" },
  { link: orderAppLink, name: "orderApp" },
  { link: orderCancelAppRestLink, name: "orderCancelApp" },
];

const isClientFromContext = (client: string) => (op: Operation) =>
  op.getContext().client === client;

const DynamicApolloLink = LINK_MAP.reduce<ApolloLink | undefined>(
  (prevLink, nextLink) => {
    // When no name is specified, fallback to saleor client.
    if (!prevLink) {
      return ApolloLink.split(
        isClientFromContext(nextLink.name),
        nextLink.link,
        saleorLink
      );
    }
    return ApolloLink.split(
      isClientFromContext(nextLink.name),
      nextLink.link,
      prevLink
    );
  },
  undefined
) as ApolloLink;

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
          locations
        )}, Path: ${path}\n[Operation]: ${
          operation.operationName
        }: ${JSON.stringify(operation.variables)}`
      )
    );

    const isSignatureExpiredError = graphQLErrors.some(
      err => err.message === "Signature has expired"
    );
    const evtName: AppEvents = isSignatureExpiredError
      ? "signatureExpired"
      : "unhandledError";

    if (!ssrMode) {
      window.dispatchEvent(new Event(evtName));
    }
  }

  if (networkError) {
    console.error(
      `[Network error]: ${networkError}\n[Operation]: ${
        operation.operationName
      }: ${JSON.stringify(operation.variables)}`
    );
  }
});

/**
 * Simple link for prefixing every apollo outgoing query for easier monitoring.
 */
const operationSuffixLink = new ApolloLink((operation, forward) => {
  if (operation.operationName.startsWith(graphqlOperationPrefix)) {
    return forward(operation);
  }

  const originalOperationName = operation.operationName;
  const newOperationName = `${graphqlOperationPrefix}${originalOperationName}`;

  operation.operationName = newOperationName;
  operation.query.definitions.forEach(definition => {
    if (
      definition.kind === "OperationDefinition" &&
      definition.name?.value === originalOperationName
    ) {
      (definition.name as any).value = newOperationName;
    }
  });
  return forward(operation);
});

const dataLink = new ApolloLink((operation, forward) =>
  forward(operation).map(response => {
    if (response?.data?.product?.variants) {
      response.data.product.variants = response.data.product.variants.filter(
        invalidVariantsFilter
      );
    }

    if (response?.data?.products?.edges) {
      response.data.products.edges = response.data.products.edges.map(
        (edge: any) =>
          edge.node?.variants
            ? {
                ...edge,
                node: {
                  ...edge.node,
                  variants: edge.node.variants.filter(invalidVariantsFilter),
                },
              }
            : edge
      );
    }

    if (response?.data?.collection?.products?.edges) {
      response.data.collection.products.edges =
        response.data.collection.products.edges.map((edge: any) => ({
          ...edge,
          node: {
            ...edge.node,
            variants: edge.node.variants.filter(invalidVariantsFilter),
          },
        }));
    }

    return response;
  })
);

function createApolloClient(
  opts?: Partial<ApolloClientOptions<NormalizedCacheObject>>
) {
  return new ApolloClient({
    cache: new InMemoryCache({
      possibleTypes,
      typePolicies: {
        User: {
          fields: {
            orders: relayStylePagination(),
          },
        },
      },
    }),
    defaultOptions: {
      mutate: { errorPolicy: "all" },
      query: { errorPolicy: "all" },
    },
    link: from([
      operationSuffixLink,
      retryLink,
      errorLink,
      dataLink,
      DynamicApolloLink,
    ]),
    ssrMode, // set to true for SSR
    ...opts,
  });
}

let apolloClient: ApolloClient<NormalizedCacheObject> | undefined;

export function initializeApollo(initialState: any = null) {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const _apolloClient = apolloClient ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client,
  // the initial state gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Restore the cache using the data passed from
    // getStaticProps/getServerSideProps combined with the existing cached data
    _apolloClient.cache.restore({ ...existingCache, ...initialState });
  }

  // For SSG and SSR always create a new Apollo Client
  if (typeof window === "undefined") {
    return _apolloClient;
  }

  // Create the Apollo Client once in the client
  if (!apolloClient) {
    apolloClient = _apolloClient;
  }
  return _apolloClient;
}

let saleorClient: SaleorClient | undefined;
export const createSaleorClient = (channel: string) =>
  sdkCreateSaleorClient({
    apiUrl: apiUri,
    channel,
    opts: { autologin: !ssrMode },
  });

export const initializeSaleor = (channel: string) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const _saleorClient = saleorClient ?? createSaleorClient(channel);

  if (ssrMode) {
    return _saleorClient;
  }

  if (!saleorClient) {
    saleorClient = _saleorClient;
  }
  return _saleorClient;
};

/**
 * Apollo client meant to be used only in SSR and only for fetching
 * things that require permission.
 * Uses app token with certain admin permissions.
 */
export const initializeSecureApolloClient = () => {
  if (!ssrMode) {
    throw new Error("This client must be used only on the server side");
  }

  return createApolloClient({
    link: from([
      operationSuffixLink,
      retryLink,
      errorLink,
      dataLink,
      createHttpLink({
        headers: { "Authorization-Bearer": saleorAppToken },
        uri: apiUri,
      }),
    ]),
    uri: apiUri,
  });
};
