import { lg, md, retina, sm, xl, xs, xxl } from "./constants";

/* eslint-disable */
export const BREAKPOINTS = { xs, sm, md, lg, xl, xxl, retina };
/* eslint-enable */

export type Breakpoints = keyof typeof BREAKPOINTS;

type Media = {
  [key in Breakpoints]: { down: string; only: string; up: string };
};

const mediaUp = (breakpoint: Breakpoints, mediaSuffix?: boolean) =>
  `${mediaSuffix ? "@media " : ""}(min-width: ${BREAKPOINTS[breakpoint]}px)`;

const mediaDown = (breakpoint: Breakpoints, mediaSuffix?: boolean) =>
  `${mediaSuffix ? "@media " : ""}(max-width: ${
    BREAKPOINTS[breakpoint] - 1
  }px)`;

export const mediaOnly = (breakpoint: Breakpoints) => {
  const breakpointKeys = Object.keys(BREAKPOINTS) as Breakpoints[];
  const nextBreakpointIndex = breakpointKeys.indexOf(breakpoint) + 1;
  const prevBreakpointIndex = breakpointKeys.indexOf(breakpoint) - 1;
  const nextBreakpoint =
    nextBreakpointIndex < breakpointKeys.length
      ? breakpointKeys[nextBreakpointIndex]
      : null;

  if (!nextBreakpoint) {
    return mediaUp(breakpoint, true);
  }
  if (prevBreakpointIndex === -1) {
    return mediaDown(nextBreakpoint, true);
  }
  return `@media ${mediaUp(breakpoint)} and ${mediaDown(nextBreakpoint)}`;
};

export const media = Object.keys(BREAKPOINTS).reduce(
  (acc: Media, breakpoint) => {
    const label = breakpoint as Breakpoints;
    acc[label] = {
      down: mediaDown(label, true),
      only: mediaOnly(label),
      up: mediaUp(label, true),
    };
    return acc;
  },
  {} as Media
);
