import React from "react";

import { ReactComponent as AmexLogoSvg } from "assets/icons/amex.svg";
import { ReactComponent as ApplePayLogoSvg } from "assets/icons/apple-pay.svg";
import { ReactComponent as FacebookLogoSvg } from "assets/icons/facebook.svg";
import { ReactComponent as GooglePayLogoSvg } from "assets/icons/google-pay.svg";
import { ReactComponent as InstagramLogoSvg } from "assets/icons/instagram.svg";
import { ReactComponent as MaestroLogoSvg } from "assets/icons/maestro.svg";
import { ReactComponent as MastercardLogoSvg } from "assets/icons/mastercard.svg";
import { ReactComponent as SoundCloudLogoSvg } from "assets/icons/soundcloud.svg";
import { ReactComponent as SpotifyLogoSvg } from "assets/icons/spotify.svg";
import { ReactComponent as StripeLogoSvg } from "assets/icons/stripe.svg";
import { ReactComponent as TikTokLogoSvg } from "assets/icons/tiktok.svg";
import { ReactComponent as TwitterLogoSvg } from "assets/icons/twitter-x.svg";
import { ReactComponent as VisaLogoSvg } from "assets/icons/visa.svg";
import { ReactComponent as YouTubeLogoSvg } from "assets/icons/youtube.svg";

export const SOCIAL_LOGOS: { [key: string]: any } = {
  facebook: <FacebookLogoSvg />,
  instagram: <InstagramLogoSvg />,
  soundcloud: <SoundCloudLogoSvg />,
  spotify: <SpotifyLogoSvg />,
  tiktok: <TikTokLogoSvg />,
  twitter: <TwitterLogoSvg />,
  youtube: <YouTubeLogoSvg />,
};

export const PAYMENT_ICONS: { [key: string]: any } = {
  amex: <AmexLogoSvg />,
  applepay: <ApplePayLogoSvg />,
  googlepay: <GooglePayLogoSvg />,
  maestro: <MaestroLogoSvg />,
  mastercard: <MastercardLogoSvg />,
  stripe: <StripeLogoSvg />,
  visa: <VisaLogoSvg />,
};
