import baseStyled, { ThemedStyledInterface } from "styled-components";

import * as C from "./constants";
import { px2rem } from "./utils";

export const theme = {
  breakpoints: {
    lg: `${C.lg}px`,
    md: `${C.md}px`,
    retina: `${C.retina}px`,
    sm: `${C.sm}px`,
    xl: `${C.xl}px`,
    xs: `${C.xs}px`,
    xxl: `${C.xxl}px`,
  },

  button: {
    colors: {
      checkout: {
        backgroundColor: C.colors.orange,
        borderColor: C.colors.orange,
        color: C.colors.black,
        hoverBackgroundColor: C.colors.orangeLighter,
        hoverBorderColor: C.colors.orangeLighter,
        hoverColor: C.colors.black,
      },
      danger: {
        backgroundColor: "transparent",
        borderColor: C.colors.red,
        color: C.colors.red,
        hoverBackgroundColor: C.colors.red,
        hoverBorderColor: C.colors.red,
        hoverColor: C.colors.white,
      },
      dark: {
        backgroundColor: C.colors.black,
        borderColor: C.colors.black,
        color: C.colors.white,
        hoverBackgroundColor: C.colors.blackLight,
        hoverBorderColor: C.colors.blackLight,
        hoverColor: C.colors.white,
      },
      hollow: {
        backgroundColor: "transparent",
        borderColor: C.colors.black,
        color: C.colors.black,
        hoverBackgroundColor: C.colors.black,
        hoverBorderColor: C.colors.black,
        hoverColor: C.colors.white,
      },
      hollowLight: {
        backgroundColor: "transparent",
        borderColor: C.colors.white,
        color: C.colors.white,
        hoverBackgroundColor: C.colors.white,
        hoverBorderColor: C.colors.white,
        hoverColor: C.colors.black,
      },
      light: {
        backgroundColor: C.colors.white,
        borderColor: C.colors.white,
        color: C.colors.black,
        hoverBackgroundColor: C.colors.light,
        hoverBorderColor: C.colors.black,
        hoverColor: C.colors.black,
      },
      primary: {
        backgroundColor: C.colors.yellow,
        borderColor: C.colors.yellow,
        color: C.colors.black,
        hoverBackgroundColor: C.colors.yellowLighter,
        hoverBorderColor: C.colors.yellow,
        hoverColor: C.colors.black,
      },
      secondary: {
        backgroundColor: C.colors.black,
        borderColor: C.colors.black,
        color: C.colors.white,
        hoverBackgroundColor: C.colors.dark,
        hoverBorderColor: C.colors.black,
        hoverColor: C.colors.white,
      },
      spotify: {
        backgroundColor: C.colors.green,
        borderColor: C.colors.green,
        color: C.colors.white,
        hoverBackgroundColor: C.colors.greenLight,
        hoverBorderColor: C.colors.green,
        hoverColor: C.colors.white,
      },
      success: {
        backgroundColor: C.colors.turquoise,
        borderColor: C.colors.turquoise,
        color: C.colors.white,
        hoverBackgroundColor: C.colors.turquoiseLight,
        hoverBorderColor: C.colors.turquoise,
        hoverColor: C.colors.white,
      },
    },
    fontSize: "0.875rem",
    fontSizes: {
      large: "1.125rem",
      medium: "0.875rem",
      small: "0.75rem",
    },
    padding: {
      large: "2.25 2.0625rem",
      main: px2rem(14.5, 33),
      small: "0.688rem 1.438rem",
    },
  },

  colors: C.colors,

  container: {
    colors: {
      background: {
        default: "transparent",
        grey: C.colors.light,
        white: C.colors.white,
      },
    },
  },

  footer: {
    backgroundColor: C.colors.black,
    foregroundColor: C.colors.white,
  },

  grid: {
    columns: C.columns,
    spacing: {
      0: C.gridSpacing0,
      1: C.gridSpacing1,
      2: C.gridSpacing2,
      3: C.gridSpacing3,
      4: C.gridSpacing4,
      5: C.gridSpacing5,
    },
  },

  header: {
    backgroundColor: C.colors.black,
    size: {
      desktop: 70,
      mobile: 68,
    },
  },

  input: {
    borderColor: C.colors.black,
    color: C.colors.dark,
    colors: {
      hollow: {
        background: "transparent",
        borderColor: C.colors.white,
        color: C.colors.white,
        hoverBorderColor: C.colors.light,
      },
    },
    disabled: {
      borderColor: "#e7e7e7",
      color: "#737373",
    },
    fontSize: C.baseFontSize,
    height: "60px",
    mobileHeight: "54px",
    padding: "25px 20px 10px",
    transitionDuration: `${C.transitionDuration}s`,
  },

  loader: {
    size: 50,
  },

  navigation: {
    items: {
      club: {
        active: {
          accent: C.colors.yellow,
          color: C.colors.black,
        },
        default: {
          accent: C.colors.yellow,
          color: C.colors.yellow,
        },
      },
      default: {
        active: {
          accent: C.colors.black,
          color: C.colors.white,
        },
        default: {
          accent: C.colors.white,
          color: C.colors.black,
        },
      },
      feature: {
        active: {
          accent: C.colors.orange,
          color: C.colors.black,
        },
        default: {
          accent: C.colors.orange,
          color: C.colors.orange,
        },
      },
      sale: {
        active: {
          accent: C.colors.red,
          color: C.colors.white,
        },
        default: {
          accent: C.colors.red,
          color: C.colors.red,
        },
      },
    },
    size: {
      desktop: 53,
      mobile: 0,
    },
  },

  opacity: 0.7,

  productBadge: {
    colors: {
      essential: {
        backgroundColor: C.colors.essentialOrange,
        color: C.colors.black,
      },
      exclusive: {
        backgroundColor: C.colors.exclusiveBlue,
        color: C.colors.white,
      },
      sale: {
        backgroundColor: C.colors.saleRed,
        color: C.colors.white,
      },
      signed: {
        backgroundColor: C.colors.purple,
        color: C.colors.white,
      },
      stock: {
        backgroundColor: C.colors.blueLight,
        color: C.colors.white,
      },
    },
  },

  progressBar: {
    colors: {
      basket: {
        backgroundColor: C.colors.orange,
        color: C.colors.white,
      },
      default: {
        backgroundColor: C.colors.blackLight,
        color: C.colors.white,
      },
      failure: {
        backgroundColor: C.colors.red,
        color: C.colors.white,
      },
      success: {
        backgroundColor: C.colors.turquoise,
        color: C.colors.white,
      },
      warning: {
        backgroundColor: C.colors.yellow,
        color: C.colors.black,
      },
    },
  },

  promoBanner: {
    defaultBackgroundColor: C.colors.yellow,
    desktopHeight: 40,
    foregroundColor: C.colors.black,
    mobileHeight: 34,
    size: {
      desktop: 40,
      mobile: 34,
    },
  },

  radio: {
    checkout: {
      checkmarkBorderSize: "0",
      checkmarkSize: "15px",
      size: "14px",
    },
    listing: {
      checkmarkBorderSize: "2px",
      checkmarkSize: "18px",
      size: "12px",
    },
  },
  sectionGridBackground: {
    black: {
      backgroundColor: C.colors.black,
    },
    white: {
      backgroundColor: C.colors.white,
    },
  },

  sections: {
    grey: {
      backgroundColor: C.colors.light,
    },
    light: {
      backgroundColor: C.colors.white,
    },
  },

  spacing: {
    gutter: C.gutter,
    mobileGutter: C.mobileGutter,
  },

  transitionDuration: C.transitionDuration,

  typography: {
    baseFontFamily: C.baseFontFamily,
    baseFontLineHeight: C.baseFontLineHeight,
    baseFontSize: C.baseFontSize,
    baseFontWeight: C.baseFontWeight,
    clubFontFamily: C.clubFontFamily,
    condensedFontFamily: C.condensedFontFamily,

    h1FontSize: C.h1FontSize,

    h2FontSize: C.h2FontSize,
    h3FontSize: C.h3FontSize,
    sizes: {
      extraLarge: px2rem(48),
      large: px2rem(18),
      larger: px2rem(24),
      largest: px2rem(38),
      medium: C.baseFontSize,
      small: px2rem(14),
      smallest: px2rem(12),
    },
  },
};

export type Theme = typeof theme;
export const styled = baseStyled as ThemedStyledInterface<Theme>;
