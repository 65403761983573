import React from "react";

import { theme } from "@styles";

import { ReactComponent as LoaderDarkSvg } from "assets/icons/loader-dark.svg";
import { ReactComponent as LoaderLightSvg } from "assets/icons/loader-light.svg";

import * as S from "./styles";

interface LoaderProps extends Partial<S.WrapperProps> {
  size?: number;
}

export const Loader = ({
  size = theme.loader.size,
  big,
  cover,
  absoluteCenter,
  verticalCenter,
  variant = "dark",
  diskSize = 50,
}: LoaderProps) => (
  <S.Wrapper
    absoluteCenter={absoluteCenter!!}
    big={big!!}
    cover={cover!!}
    diskSize={diskSize}
    size={size}
    variant={variant}
    verticalCenter={!!verticalCenter}
  >
    {variant === "dark" ? <LoaderDarkSvg /> : <LoaderLightSvg />}
  </S.Wrapper>
);
