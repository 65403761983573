import { debounce } from "lodash";
import {
  MainNavigationDocumentDataMenuItemItem,
  NavSubMenuDocument,
} from "prismicio-types";
import React, { useEffect, useMemo } from "react";

import { useIsMdUp } from "@hooks/responsive";
import { useOnWindowResize } from "@hooks/useOnWindowResize";
import { useHeaderStore } from "@stores/useHeaderStore";

import { Dropdown } from "../Dropdown";
import { TopItem } from "../TopItem";

import * as S from "./styles";

export interface NavListItemProps
  extends Omit<MainNavigationDocumentDataMenuItemItem, "sub_menu"> {
  index: number;
  isActive?: boolean;
  isButton?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  sub_menu: NavSubMenuDocument;
  uid?: string;
}

export const NavItem = ({
  variant,
  label,
  url,
  uid,
  sub_menu,
  isButton,
  isActive,
  index,
  onClick,
  onMouseEnter,
  onMouseLeave,
}: NavListItemProps) => {
  const {
    nav: { menu },
  } = useHeaderStore();
  const isMdUp = useIsMdUp();

  const handleHoverChange = (
    e: React.MouseEvent<HTMLLIElement, MouseEvent>,
    uid: any
  ) => {
    e.stopPropagation();
    e.preventDefault();

    if (e.type === "mouseleave") {
      menu.updateMenu({
        activeTab: null,
        isActive: false,
      });
    } else if (e.type === "mouseenter") {
      menu.updateMenu({
        activeTab: uid,
        isActive: true,
      });
    }
  };
  const tabIsActive = menu.activeTab === uid;

  const handleResize = () => {
    menu.updateMenu({
      activeIndex: 0,
      activeTab: null,
      isActive: false,
    });
  };

  const debouncedChangeHandler = useMemo(
    () => debounce(handleHoverChange, 300),
    []
  );

  useEffect(
    () => () => {
      debouncedChangeHandler.cancel();
    },
    []
  );

  useOnWindowResize(handleResize, { wait: 500 });

  const handleFocus = (e: React.FocusEvent, uid?: string) => {
    e.stopPropagation();
    e.preventDefault();

    if (e.type === "focus" && isMdUp) {
      menu.updateMenu({
        activeTab: uid,
        isActive: true,
      });
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLLIElement>, uid?: string) => {
    e.stopPropagation();

    setTimeout(() => {
      if (!document.activeElement?.closest(".list-wrapper")) {
        menu.updateMenu({
          activeTab: null,
          isActive: false,
        });
      }
    }, 0);
  };

  return (
    <S.ListWrapper
      className="list-wrapper"
      data-uid={uid}
      isActive={tabIsActive}
      onBlur={e => handleBlur(e, uid)}
      onFocus={e => handleFocus(e, uid)}
      onMouseEnter={e => debouncedChangeHandler(e, uid)}
      onMouseLeave={e => debouncedChangeHandler(e, uid)}
      tabIndex={0}
      variant={variant || "default"}
    >
      <TopItem
        isActive={tabIsActive}
        isButton={isButton}
        label={label || ""}
        onClick={onClick}
        uid={uid}
        url={url || "/"}
        variant={variant || "default"}
      />
      <Dropdown
        data={sub_menu.data}
        isActive={tabIsActive}
        variant={variant || "default"}
      />
    </S.ListWrapper>
  );
};
