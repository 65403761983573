import {
  orderLineFragment,
  pageInfoFragment,
  taxedPriceFragment,
} from "@graphql";
import gql from "graphql-tag";

export const myOrdersQuery = gql`
  ${pageInfoFragment}
  ${orderLineFragment}
  ${taxedPriceFragment}
  query Orders(
    $first: Int = 5
    $after: String
    $languageCode: LanguageCodeEnum = EN
  ) {
    me {
      id
      orders(first: $first, after: $after) {
        pageInfo {
          ...PageInfo
        }
        edges {
          node {
            id
            number
            created
            token
            status
            paymentStatus
            total {
              ...TaxedPrice
            }
            lines {
              ...OrderLine
            }
          }
        }
      }
    }
  }
`;

export const userOrderQueries = gql`
  ${pageInfoFragment}
  ${orderLineFragment}
  ${taxedPriceFragment}
  query UserOrders(
    $id: ID!
    $first: Int = 5
    $after: String
    $languageCode: LanguageCodeEnum = EN
  ) {
    user(id: $id) {
      id
      orders(first: $first, after: $after) {
        pageInfo {
          ...PageInfo
        }
        edges {
          node {
            id
            number
            created
            token
            status
            paymentStatus
            total {
              ...TaxedPrice
            }
            lines {
              ...OrderLine
            }
          }
        }
      }
    }
  }
`;
