import { CheckCustomer, CheckCustomerVariables } from "@gqlTypes/CheckCustomer";
import { initializeSecureApolloClient } from "@graphql/client";
import { checkCustomerExists } from "@graphql/queries";

export const checkUserExists = async (variables: CheckCustomerVariables) => {
  const apolloClient = initializeSecureApolloClient();

  const { data } = await apolloClient.query<
    CheckCustomer,
    CheckCustomerVariables
  >({
    query: checkCustomerExists,
    variables,
  });

  return data?.customers;
};
