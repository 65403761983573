import { create } from "zustand";

type CheckoutStore = {
  deliveryHasChanged: boolean;
  deliveryPrice: number;
  isPaymentStep: boolean;
  promoActive: boolean;
  promoSuccess: boolean;
  requiredFields: string[];
  setDeliveryChanged: (deliveryHasChanged: boolean) => void;
  setDeliveryPrice: (deliveryPrice: number) => void;
  setIsPaymentStep: (isPaymentStep: boolean) => void;
  setPromoActive: () => void;
  setPromoSuccess: () => void;
  setRequiredFields: (requiredFields: string[]) => void;
  setUpsellClub: (upsellClub: boolean) => void;
  upsellClub: boolean;
};

export const useCheckoutStore = create<CheckoutStore>()(set => ({
  deliveryHasChanged: false,
  deliveryPrice: 0,
  isPaymentStep: false,
  promoActive: false,
  promoSuccess: false,
  requiredFields: [],
  setDeliveryChanged: deliveryHasChanged =>
    set(() => ({
      deliveryHasChanged,
    })),
  setDeliveryPrice: deliveryPrice =>
    set(state => {
      if (state.deliveryPrice !== deliveryPrice) {
        return {
          deliveryHasChanged: true,
          deliveryPrice,
        };
      }
      return {
        deliveryPrice,
      };
    }),
  setIsPaymentStep: isPaymentStep =>
    set(() => ({
      isPaymentStep,
    })),
  setPromoActive: () =>
    set(({ promoActive }) => ({ promoActive: !promoActive })),
  setPromoSuccess: () =>
    set(({ promoSuccess }) => ({ promoSuccess: !promoSuccess })),
  setRequiredFields: requiredFields =>
    set(() => ({
      requiredFields,
    })),
  setUpsellClub: upsellClub =>
    set(() => ({
      upsellClub,
    })),
  upsellClub: false,
}));
