import { create } from "zustand";

export enum AuthFormView {
  Default = "default",
  ResetPasswordForm = "reset-password-form",
  ResetPasswordSuccess = "reset-password-success",
  SignUpSuccess = "sign-up-success",
  SpotifySignUp = "spotify-sign-up",
}

export type AuthFormLocation = "account" | "checkout" | "order" | "default";

export enum AuthFormLocationEnum {
  ACCOUNT = "account",
  CHECKOUT = "checkout",
  CLUB = "club",
  DEFAULT = "default",
  FAVOURITES = "favourites",
  ORDER = "order",
  PROFILE = "profile",
}

type AuthFormStore = {
  activeIndex: number | null;
  email: string | null;
  isProcessing: boolean;
  location: AuthFormLocationEnum;
  setActiveIndex: (index: number) => void;
  setLocation: (location: AuthFormLocationEnum) => void;
  setSignUpEmail: (email: string) => void;
  setSuccessPath: (path: string | null) => void;
  setView: (view: AuthFormView) => void;
  successPath: string | null;
  toggleProcessing: () => void;
  view: AuthFormView;
};

export const useAuthFormStore = create<AuthFormStore>()(set => ({
  activeIndex: 0,
  email: null,
  isProcessing: false,
  location: AuthFormLocationEnum.DEFAULT,
  setActiveIndex: index => set({ activeIndex: index }),
  setLocation: location => set({ location }),
  setSignUpEmail: email => set({ email }),
  setSuccessPath: path => set({ successPath: path }),
  setView: view => set({ view }),
  successPath: null,
  toggleProcessing: () => set(state => ({ isProcessing: !state.isProcessing })),
  view: AuthFormView.Default,
}));
