import { create } from "zustand";

type SearchInsightsStore = {
  searchQueryID: string | null;
  setSearchQueryID: (queryID: string | null) => void;
};

export const useSearchInsightsStore = create<SearchInsightsStore>()(set => ({
  searchQueryID: null,
  setSearchQueryID: queryID =>
    set(() => ({
      searchQueryID: queryID,
    })),
}));
