import { useEffect } from "react";
import { CmsGlobalReturn } from "src/pages/api/shared-content";

import { Locale } from "@providers/TranslationProvider";
import { useGlobalConfigStore } from "@stores/useGlobalConfigStore";
import { fetchNextAPIData } from "@utils/api/misc";

import { useRouter } from "./useRouter";

const fetchGlobalConfig = async (
  locale: Locale
): Promise<CmsGlobalReturn | null> => {
  if (!locale) {
    console.error("Locale parameter is missing");
  }

  const apiUrl = new URL(`api/shared-content`, window.location.origin);

  apiUrl.searchParams.set("locale", locale);

  const data = await fetchNextAPIData({ apiUrl });

  if (!data) {
    return null;
  }

  return {
    ...(data as CmsGlobalReturn),
  };
};

/**
 * Fetches the global config from the CMS and updates the global config store
 */
export function useGlobalConfig() {
  const { locale } = useRouter();
  const { updateGlobalData } = useGlobalConfigStore();

  useEffect(() => {
    const updateGlobalStore = async () => {
      const data = await fetchGlobalConfig(locale);

      if (data) {
        updateGlobalData(data);
      }
    };

    updateGlobalStore();
  }, [locale]);
}
