import { paths } from "@paths";
import Image from "next/image";
import Link from "next/link";
import React, { CSSProperties } from "react";

import { RecentlyViewedItem } from "@components/RecentlyViewedProducts";
import { useIsLgUp } from "@hooks/responsive";

import FallbackImage from "assets/images/fallback-image.jpg";

import * as S from "../AlgoliaHitComponent/styles";
import { DEFAULT_MOBILE_COVER_SIZE } from "../AlgoliaHitComponent/styles";

export type CustomProductHit = RecentlyViewedItem;

export interface CustomHitProps {
  desktopCoverSize?: number;
  hit: CustomProductHit;
  inNavigation?: boolean;
  light?: boolean;
  location?: string;
  sizes?: string;
  textAlign?: CSSProperties["textAlign"];
}

export const CustomHitComponent = ({
  desktopCoverSize = 290,
  hit,
  textAlign,
  location,
  inNavigation = false,
  light = false,
  sizes,
}: CustomHitProps) => {
  const { id, name, image, url, artists } = hit;
  const isDesktop = useIsLgUp();

  const creators = artists.map(({ name }) => name).join(", ");
  const coverSize = isDesktop ? desktopCoverSize : DEFAULT_MOBILE_COVER_SIZE;

  return (
    <S.ProductCard data-insights-object-id={id} textAlign={textAlign}>
      <Link
        aria-label={name}
        className="carousel-product-link"
        href={url || paths.home}
        title={`${name} by ${creators}`}
      >
        <S.ThumbnailWrapper coverSize={coverSize}>
          <Image
            alt={`${name} by ${creators}`}
            height={coverSize}
            layout="fill"
            objectFit="contain"
            placeholder="empty"
            sizes={sizes ?? "(min-width: 1200px) 15vw, 20vw"}
            src={image?.url ?? FallbackImage}
            title={`${name} by ${creators}`}
            width={coverSize}
          />
        </S.ThumbnailWrapper>
      </Link>
      <S.Name inNavigation={inNavigation} light={light}>
        {name}
      </S.Name>
      <S.Creators>
        {artists.map(({ name, slug }, i) => (
          <React.Fragment key={slug}>
            <Link href={url || paths.home}>{name ?? ""}</Link>
            {i + 1 < artists.length && ", "}
          </React.Fragment>
        ))}
      </S.Creators>
    </S.ProductCard>
  );
};
