import React from "react";
import { IntlProvider } from "react-intl";

import { useRouter } from "@hooks/useRouter";

import messagesdeDE from "../../../locale/de-DE.json";
import messagesEnGB from "../../../locale/en-GB.json";
import messagesEnUS from "../../../locale/en-US.json";
import messagesfrFR from "../../../locale/fr-FR.json";
import { DEFAULT_COUNTRY } from "./context";
import { isSupportedLocale } from "./helpers";

const messages = {
  "de-de": messagesdeDE,
  "en-de": messagesEnGB,
  "en-gb": messagesEnGB,
  "en-us": messagesEnUS,
  "fr-fr": messagesfrFR,
};

type TranslationProviderProps = {
  children?: React.ReactNode;
};

export const TranslationProvider = ({ children }: TranslationProviderProps) => {
  const { language, locale: routerLocale } = useRouter();

  const locale = isSupportedLocale(routerLocale)
    ? routerLocale
    : DEFAULT_COUNTRY.locale;

  return (
    <IntlProvider locale={language} messages={messages[locale]}>
      {children}
    </IntlProvider>
  );
};
