import debounce from "lodash/debounce";
import { useCallback, useEffect, useRef } from "react";
import { useQueueState } from "rooks";

import { PlayerHandlers } from "@components/Player";
import { useOnUpdateRef } from "@hooks/useOnUpdateRef";
import { ObjectType } from "@utils/typescript";

import { isTrackPlayable } from "./helpers";
import {
  PlayerActionHandlers,
  PlayerApi,
  PlayerContext,
  PlayerState,
  QueueActions,
  SpotifyErrorMessage,
  UpdatePlayerState,
} from "./types";

const ERROR_TIMEOUT = 5000;

export const useInitPlayerHandlers = ({
  state,
  api,
  isPremiumAccount,
  updateState,
  closePlayer,
}: {
  api: PlayerApi | undefined;
  closePlayer: () => void;
  isPremiumAccount: boolean;
  state: PlayerState<true>;
  updateState: UpdatePlayerState;
}): {
  playerHandlers: PlayerHandlers;
  publicHandlers: PlayerActionHandlers;
} => {
  const stateRef = useOnUpdateRef(state);
  const processingRef = useRef(false);
  // Handlers won't be passed to player if player api is not created.
  const apiRef = useOnUpdateRef<PlayerApi>(api!);
  const errorTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [actionsQueue, controls] = useQueueState<
    { action: QueueActions } & ObjectType
  >([]);

  const canBePlayed = (index: number) => {
    const track = stateRef.current.trackList[index];
    return isTrackPlayable(track, isPremiumAccount);
  };

  const prevIndex = (index: number): number => {
    const prevTrack =
      index - 1 < 0 ? stateRef.current.trackList.length - 1 : index - 1;
    const isPlayable = canBePlayed(prevTrack);
    return isPlayable ? prevTrack : nextIndex(--index);
  };

  const nextIndex = (index: number): number => {
    const nextTrack =
      index < stateRef.current.trackList.length - 1 ? index + 1 : 0;
    const isPlayable = canBePlayed(nextTrack);
    return isPlayable ? nextTrack : nextIndex(++index);
  };

  /**
   * Player handlers
   */
  const handlePlayerClose = async () => {
    closePlayer();
    await apiRef.current.onPause();
  };

  const handleTrackPlayPrev = () =>
    handleTrackPlay(prevIndex(stateRef.current.trackIndex));

  const handleTrackPlayNext = () =>
    handleTrackPlay(nextIndex(stateRef.current.trackIndex));

  const handleTrackPlay = useCallback(
    debounce(
      async (trackIndex: number) => {
        if (processingRef.current) {
          return;
        }
        processingRef.current = true;

        const { trackList, isPlaying } = stateRef.current;

        updateState({ controlsDisabled: true });

        if (isPlaying) {
          await apiRef.current.onPause();
        }

        if (await apiRef.current.onPlay(trackList[trackIndex])) {
          const duration = await apiRef.current.getDuration();
          updateState({
            controlsDisabled: false,
            isPlaying: true,
            trackDuration: duration,
            trackIndex,
          });
        } else {
          updateState({
            controlsDisabled: false,
            isPlaying: false,
          });
        }

        processingRef.current = false;
      },
      250,
      { leading: true }
    ),
    [state, api]
  );

  const handleSeekChange = async (trackCompletionRatio: number) => {
    await apiRef.current.onSeek(trackCompletionRatio);

    const { trackList, isPlaying, trackIndex } = stateRef.current;

    if (!isPlaying && (await apiRef.current.onPlay(trackList[trackIndex]))) {
      updateState({ isPlaying: true });
    }
  };

  const handleTrackToggle = async () => {
    if (processingRef.current) {
      return;
    }

    const { trackDuration, isPlaying, trackIndex } = stateRef.current;

    if (!trackDuration) {
      // initial play click
      await handleTrackPlay(trackIndex);
    } else {
      processingRef.current = true;
      updateState({ controlsDisabled: true });

      if (isPlaying) {
        await apiRef.current.onPause();
      } else {
        await apiRef.current.onResume();
      }
      updateState({ controlsDisabled: false, isPlaying: !isPlaying });
      processingRef.current = false;
    }
  };

  const handlePlaylistExpand = () => updateState({ isPlaylistExpanded: true });

  const handlePlaylistMinimize = () =>
    updateState({ isPlaylistExpanded: false });

  const setError = (error: SpotifyErrorMessage) => updateState({ error });

  const handlePlayerError: PlayerHandlers["handlePlayerError"] = error => {
    controls.enqueue({ action: "error", error });
  };

  const handleTrackStop = async () => {
    const { isPlaying } = stateRef.current;
    updateState({ controlsDisabled: true });

    if (isPlaying) {
      await apiRef.current.onPause();
    }

    updateState({
      controlsDisabled: false,
      isPlaying: false,
      trackDuration: 0,
    });
  };

  /**
   * Public handlers
   */
  const trackToggle: PlayerContext["handlers"]["trackToggle"] = () => {
    controls.enqueue({ action: "toggle" });
  };

  const trackPause: PlayerContext["handlers"]["trackPause"] = () => {
    controls.enqueue({ action: "pause" });
  };

  const trackPlay: PlayerContext["handlers"]["trackPlay"] = (
    trackIndex = 0
  ) => {
    controls.enqueue({ action: "play", trackIndex });
  };

  const trackStop: PlayerContext["handlers"]["trackStop"] = () => {
    controls.enqueue({ action: "stop" });
  };

  useEffect(() => {
    // Handle public player actions i.e. from components in a queue since we need
    // to have a preceding rerender to have a `fresh` player & context state
    (async () => {
      const action = controls.dequeue();
      if (action) {
        // eslint-disable-next-line default-case
        switch (action.action) {
          case "play":
            return handleTrackPlay(action.trackIndex);

          case "pause":
            if (stateRef.current.isPlaying) {
              await handleTrackToggle();
            }
            return;

          case "toggle":
            await handleTrackToggle();
            return;

          case "stop":
            await handleTrackStop();
            return;

          case "error":
            setError(action.error);
        }
      }
    })();
  }, [actionsQueue]);

  useEffect(() => {
    // Handle player errors.
    if (state.error) {
      // Clear timeout from previous render.
      if (errorTimeoutRef.current) {
        clearTimeout(errorTimeoutRef.current);
      }

      errorTimeoutRef.current = setTimeout(
        () => updateState({ error: null }),
        ERROR_TIMEOUT
      );
    }
    return () => {
      if (errorTimeoutRef.current) {
        clearTimeout(errorTimeoutRef.current);
      }
    };
  }, [state.error]);

  useEffect(() => {
    // Handle API change.
    (async () => {
      if (api && stateRef.current.album) {
        processingRef.current = true;
        await apiRef.current.onPause();
        updateState({
          controlsDisabled: false,
          isPlaying: false,
          trackCompletionRatio: new Date().getTime(),
          trackDuration: 0,
        });
        processingRef.current = false;
      }
    })();
  }, [api]);

  return {
    playerHandlers: {
      handlePlayerClose,
      handlePlayerError,
      handlePlaylistExpand,
      handlePlaylistMinimize,
      handleSeekChange,
      handleTrackPlay,
      handleTrackPlayNext,
      handleTrackPlayPrev,
      handleTrackToggle,
    },
    publicHandlers: {
      trackPause,
      trackPlay,
      trackStop,
      trackToggle,
    },
  };
};
