import React from "react";
import { defineMessages, FormattedMessage } from "react-intl";

export const FooterMessages = defineMessages({
  iconTitle: {
    defaultMessage: "Rough Trade {name} profile",
    id: "vO3GMf",
  },
});

export const WaysToPay = (
  <FormattedMessage defaultMessage="Ways to pay" id="LeXAEz" />
);

export const ConnectWithUs = (
  <FormattedMessage defaultMessage="Connect with us" id="0Cbtd9" />
);
