import type { NextApiRequest, NextApiResponse } from "next";

import {
  extractBearerToken,
  extractFromJson,
  isMethod,
  jsonResponse,
} from "./helpers";
import { RequestWithHelpers, ResponseWithHelpers } from "./types";

export const handlerWithHelpers =
  <ResponseData>(
    handler: (
      req: RequestWithHelpers,
      res: ResponseWithHelpers<ResponseData>
    ) => Promise<void>
  ) =>
  (req: NextApiRequest, res: NextApiResponse) => {
    const reqWithHelpers = req as unknown as RequestWithHelpers;
    const resWithHelpers = res as unknown as ResponseWithHelpers<ResponseData>;

    reqWithHelpers.isMethod = isMethod(req);
    reqWithHelpers.extractFromJson = extractFromJson(req);
    reqWithHelpers.extractBearerToken = extractBearerToken(req);

    resWithHelpers.jsonResponse = jsonResponse(res);

    return handler(reqWithHelpers, resWithHelpers);
  };
