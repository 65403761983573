import { useEffect, useState } from "react";

import { klaviyoIds } from "@config";
import { getChannel } from "@providers/ConfigProvider/helpers";

import { useRouter } from "./useRouter";

/**
 * Creates a Klaviyo script based on the channel and reloads it when the locale changes
 */
export const useKlaviyoScript = () => {
  const { locale } = useRouter();
  const channel = getChannel(locale).toUpperCase();
  const [klaviyoID, setKlaviyoID] = useState(klaviyoIds[channel]);

  useEffect(() => {
    // Update klaviyoID in the state when the locale changes
    setKlaviyoID(klaviyoIds[getChannel(locale).toUpperCase()]);

    // Remove the existing klaviyo script if it exists
    const klaviyoScript = document.getElementById("klaviyo-analyics-script");
    if (klaviyoScript) {
      klaviyoScript.remove();
    }

    // Append a new klaviyo script with the updated klaviyoID
    const script = document.createElement("script");
    script.id = "klaviyo-analyics-script";
    script.type = "text/javascript";
    script.src = `https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=${klaviyoID}`;
    script.async = true;
    document.body.appendChild(script);
  }, [locale]);
};
