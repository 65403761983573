import get from "lodash/get";
import React from "react";
import { useFormContext } from "react-hook-form";

import { Checkbox } from "@components/Checkbox";

export interface CheckboxFieldProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
}

export const CheckboxField = ({
  name,
  type = "checkbox",
  ...props
}: CheckboxFieldProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const error = get(errors, name);

  return (
    <Checkbox
      isReactHook
      error={error}
      inputRef={register}
      name={name}
      {...props}
    />
  );
};
