import { paths } from "@paths";
import React, { useState } from "react";
import { useIntl } from "react-intl";

import { LanguagePreferencesModal } from "@components/LanguagePreferencesModal/LanguagePreferencesModal";
import { Select, SelectProps } from "@components/Select";
import { useRouter } from "@hooks/useRouter";
import { getChannel } from "@providers/ConfigProvider/helpers";
import {
  Channel,
  CHANNELS,
  COUNTRIES,
  Locale,
} from "@providers/TranslationProvider";
import { getTestAttribute } from "@utils/misc";

import { ReactComponent as FlagDE } from "assets/flags/flag-de.svg";
import { ReactComponent as FlagFR } from "assets/flags/flag-fr.svg";
import { ReactComponent as FlagGB } from "assets/flags/flag-gb.svg";
import { ReactComponent as CountryCardIcon } from "assets/icons/country-card.svg";
import { ReactComponent as LanguageIcon } from "assets/icons/language.svg";

import { messages } from "./messages";
import * as S from "./styles";
import { StylesConfig } from "react-select/src/styles";

export interface LanguageSwitcherProps {
  asText?: boolean;
  className?: string;
  placement: string;
  styles?: StylesConfig<any, any>;
}

export function getLocaleOptions() {
  return COUNTRIES.map(({ code, intlLocale, locale, supportedLocales }) => ({
    code,
    intlLocale,
    label: new Intl.DisplayNames([locale], { type: "region" }).of(code),
    name: locale,
    supportedLocales,
  }));
}

export type RegionChannel = {
  channel: Channel;
  currency: {
    symbol: string;
    text: string;
  };
  defaultLocale: Locale;
  label: string;
  name: Channel;
  supportedLocales: Locale[];
};

export const getCurrencyOptions = () =>
  CHANNELS.map(({ channel, defaultLocale, currency, supportedLocales }) => ({
    channel,
    currency,
    defaultLocale,
    label: `${currency.text} (${currency.symbol})`,
    name: channel,
    supportedLocales,
  })) as RegionChannel[];

export const LANGUAGE_OPTIONS = [
  {
    icon: FlagDE,
    label: "Deutsch",
    name: "de",
  },
  {
    icon: FlagFR,
    label: "Français",
    name: "fr",
  },
  {
    icon: FlagGB,
    label: "English",
    name: "en",
  },
];

export function getLanguageOptions(channel: Channel) {
  if (channel === "gb" || channel === "us") {
    return LANGUAGE_OPTIONS.filter(lang => lang.name === "en");
  }
  if (channel === "eu") {
    return LANGUAGE_OPTIONS.filter(
      lang => lang.name === "de" || lang.name === "fr" || lang.name === "en"
    );
  }
  return LANGUAGE_OPTIONS;
}

export const LanguageSwitcher = ({
  placement,
  asText,
  className,
  styles,
}: LanguageSwitcherProps) => {
  const [isLanguageModalOpen, setIsLanguageModalOpen] = useState(false);
  const { formatMessage } = useIntl();
  const { lang, locale, push } = useRouter();
  const channel = getChannel(locale);
  const currencyOptions = getCurrencyOptions();

  const currentChannel = currencyOptions.find(({ supportedLocales }) =>
    supportedLocales.includes(locale as Locale)
  );
  const currentLocale = {
    ...currentChannel,
    icon: CountryCardIcon,
  };
  const currentLanguage = {
    ...LANGUAGE_OPTIONS.find(({ name }) => name === lang),
    icon: LanguageIcon,
  };
  const languageOptions = getLanguageOptions(channel);
  const channelName = channel === "gb" ? "UK" : channel?.toUpperCase();

  return (
    <S.LocaleSwitcher
      {...getTestAttribute("locale-switcher", placement)}
      asText={asText}
      className={className}
    >
      {asText ? (
        <>
          <S.Wrapper
            {...getTestAttribute("lang-currency-button", "locale-switcher")}
            aria-label={formatMessage(messages.openLanguageModal)}
            onClick={() => setIsLanguageModalOpen(true)}
          >
            {currentChannel?.currency.symbol}
            {" / "}
            {channelName}
          </S.Wrapper>
          {isLanguageModalOpen && (
            <LanguagePreferencesModal
              closeModal={() => setIsLanguageModalOpen(false)}
              isOpen={isLanguageModalOpen}
              onClose={() => setIsLanguageModalOpen(false)}
            />
          )}
        </>
      ) : (
        <>
          <CurrencySelect
            {...getTestAttribute("country-switcher-input", placement)}
            inputId={`${placement}-country-switcher-input`}
            onChange={(evt: (typeof currencyOptions)[0]) => {
              if (evt.defaultLocale !== locale) {
                push(paths.home, paths.home, {
                  locale: evt.defaultLocale,
                });
              }
            }}
            options={currencyOptions}
            placement={placement}
            styles={styles}
            value={currentLocale}
          />
          <LanguageSelect
            {...getTestAttribute("language-switcher-input", placement)}
            disabled={languageOptions.length === 1}
            inputId={`${placement}-language-switcher-input`}
            onChange={(evt: (typeof LANGUAGE_OPTIONS)[0]) => {
              const selectedLocale = currentChannel?.supportedLocales.find(
                locale => locale.startsWith(evt?.name as string)
              );

              if (selectedLocale !== locale && selectedLocale) {
                push(paths.home, paths.home, {
                  locale: selectedLocale,
                });
              }
            }}
            options={languageOptions}
            styles={styles}
            value={currentLanguage}
          />
        </>
      )}
    </S.LocaleSwitcher>
  );
};

export function CurrencySelect({
  placement,
  theme = "dark",
  ...props
}: Partial<Pick<LanguageSwitcherProps, "placement" | "styles"> & SelectProps>) {
  const { formatMessage } = useIntl();

  return (
    <>
      <Select
        {...props}
        aria-label={formatMessage(messages.selectCurrency)}
        isSearchable={false}
        menuPlacement="auto"
        theme={theme}
      />
    </>
  );
}

export function LanguageSelect({
  placement,
  theme = "dark",
  ...props
}: Partial<Pick<LanguageSwitcherProps, "placement" | "styles"> & SelectProps>) {
  const { formatMessage } = useIntl();

  return (
    <Select
      {...props}
      aria-label={formatMessage(messages.selectLanguage)}
      isSearchable={false}
      menuPlacement="auto"
      styles={{
        color: "red",
      }}
      theme={theme}
    />
  );
}
