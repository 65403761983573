import { ButtonStyledProps, buttonStyles } from "@components/Button/styles";
import { styled } from "@styles";

import { ButtonLinkProps } from "./ButtonLink";

export const Link = styled.a<ButtonStyledProps & ButtonLinkProps>`
  ${buttonStyles}
  text-decoration: none;
  font-size: ${({ theme, fontSize }) => theme.button.fontSizes[fontSize!]};
`;
